import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import FormControl from '@mui/material/FormControl';
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useStyles} from './styles';
import {getMonthsArray} from '@app/helper/plan-and-meet';
import {Label} from '@app/components/elements/Label';
import {searchDoctorCreator} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux/slice';
import {masterDataCreator} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux';
import {doctorApprovalSelector} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux/selector';

export const DoctorSearchNavBar = () => {
  const months = getMonthsArray();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [area, setArea] = useState([]);
  const [areaId, setAreaId] = useState(null);
  const [docSearch, setDocSearch] = useState('');
  const [mobile, setMobile] = useState<any>('');
  const monthSelected = {checked: false, months: months};
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );

  const classes = useStyles(monthSelected);
  const getMasterDataDocSearch: any = useSelector(
    doctorApprovalSelector.getMasterData(),
  );

  useEffect(() => {
    try {
      if (getMasterDataDocSearch) {
        const area: any = getMasterDataDocSearch?.filter((key: any) => {
          return key.sectionName === 'adminarea';
        });
        setArea(area[0].masterData);
      }
    } catch (error) {}
  }, [getMasterDataDocSearch]);

  const handleSearch = (value: any) => {
    const postData: any = {
      doctor: docSearch,
      area: areaId,
      mobileNo: mobile,
      pageNo: 1,
      pageSize: 100,
    };
    dispatch(searchDoctorCreator(postData));
  };

  useEffect(() => {
    handleSearch('');
  }, []);

  return (
    <Box className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        <div></div>
      </div>
      <div className={classes.searchContainer}>
        <FormControl sx={{width: '40%'}}>
          <Typography variant="subtitle1" sx={{paddingLeft: '6px'}}>
            Search Doctor
          </Typography>
          <TextField
            // value={''}
            placeholder="Search Doctor"
            id="outlined-start-adornment"
            className={classes.searchField}
            onChange={(event: any) => {
              const {
                target: {value},
              } = event;
              setDocSearch(value);
            }}
            data-testid={'searchBar'}
            sx={{
              height: '35px',
              width: '100%',
              background: '#ffffff',
            }}
            inputProps={{
              style: {
                padding: '8px',
              },
            }}
            InputLabelProps={{shrink: true}}
            autoComplete="off"
          />
        </FormControl>
        <FormControl sx={{width: '40%'}}>
          <Typography variant="subtitle1" sx={{paddingLeft: '6px'}}>
            Search Area
          </Typography>
          <Autocomplete
            key={'search'}
            open={open}
            sx={{
              width: '100%',
              background: '#ffffff',
            }}
            autoComplete={true}
            onChange={(_event, value) => {
              setAreaId(value?.value ? value.value : null);
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.label == value.label
            }
            onInputChange={(event, newInputValue) => {
              if (newInputValue.length >= 3) {
                const body: any = [
                  {sectionName: 'adminarea', param1: newInputValue},
                ];
                dispatch(masterDataCreator(body));
              }
            }}
            options={area && area.length > 0 ? area : []}
            groupBy={option => option.firstLetter}
            getOptionLabel={(option: any) => option.label}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={'Search area'}
                sx={{
                  height: '40px',
                  background: '#ffffff',
                  '& .MuiOutlinedInput-root': {
                    padding: '2px',
                    paddingRight: '10px!important',
                    fontWeight: 600,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }}
                data-testid="areaOptions"
              />
            )}
          />
        </FormControl>
        <FormControl sx={{width: '40%'}}>
          <Typography variant="subtitle1" sx={{paddingLeft: '6px'}}>
            Search By Mobile
          </Typography>
          <TextField
            // value={''}
            // type='number'
            placeholder="+91"
            id="outlined-start-adornment"
            className={classes.searchField}
            sx={{
              height: '35px',
              width: '100%',
              background: '#ffffff',
            }}
            inputProps={{
              style: {
                padding: '8px',
              },
            }}
            onChange={(event: any) => {
              const {
                target: {value},
              } = event;
              setMobile(value);
            }}
            data-testid={'searchBar'}
            autoComplete="off"
          />
        </FormControl>

        <Button
          variant="contained"
          data-testid="reset-button"
          sx={{
            backgroundColor: '#ffffff',
            color: '#322b7c',
            border: '2px solid #322b7c',
            borderRadius: '5px',
            width: '150px',
            height: '21%',
            boxShadow: 'none',
            marginLeft: '8px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'center',
            fontSize: 12.7,
            padding: '13px',
            '&:hover': {
              background: '#ffffff',
            },
          }}
          onClick={handleSearch}>
          Search
        </Button>
      </div>
    </Box>
  );
};
