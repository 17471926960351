import moment from 'moment';

const DateFormat = (date: string | null): string => {
  return moment(date).format('DD/MM/YY');
};

const IstFormat = (date: string | null): string => {
  return moment.utc(date).utcOffset('+05:30').format('hh:mm A');
};

export default {DateFormat, IstFormat};
