import {useState, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';

import {
  fetchHrAutoSuggestCreator,
  hrApprovalStateActions,
  fetchHrApprovalsCreator,
} from '@app/screens/employee-specific-utilities/pages/hr-approval/redux/slice';
import {hrApprovalStateSelector} from '@app/screens/employee-specific-utilities/pages/hr-approval/redux/selectors';
import {filterSelector} from '../../components/right-sidebar/redux/filterHandlerSelector';
import {filterStateActions} from '../../components/right-sidebar/redux/filterHandler';
import {SearchBar} from '../search-bar';
import {store} from '../../App';

export const Utilas: any = {
  handleTypeChange: null,
  textBoxOnChangeHandler: null,
};
type EmployeeType = any;

export const SearchBarHrApproval = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const staffHoldAutosuggestLoading = useSelector(
    hrApprovalStateSelector.getHrApprovalSuggestionsLoading(),
    shallowEqual,
  );

  const hrApprovalsAutosuggestData = useSelector(
    hrApprovalStateSelector.getHrApprovals(),
    shallowEqual,
  );

  const filter = useSelector(filterSelector.getFilterOptions());

  const handleTypeChange = useCallback(
    function (value: EmployeeType) {
      dispatch(
        filterStateActions.setFilterOptions({
          ...filter,
          roleIds: value === 'ALL' ? null : value,
        }),
      );
      dispatch(
        hrApprovalStateActions.setHrApprovalLoadingPerPage({
          pageNo: 0,
          perPage: 20,
        }),
      );
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchHrApprovalsCreator());
    },
    [filter],
  );

  const textBoxOnChangeHandler = useCallback(function (value: any) {
    let filterReq = store?.getState()?.filterState?.filterOptions;
    // dispatch(fetchHrAutoSuggestCreator('Vin'));
    dispatch(
      filterStateActions.setFilterOptions({
        ...filterReq,
        searchData: value,
      }),
    );
    dispatch(
      hrApprovalStateActions.setHrApprovalLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    dispatch(filterStateActions.setFilterApplied(true));
    dispatch(fetchHrApprovalsCreator());
  }, []);
  Utilas.handleTypeChange = handleTypeChange;
  Utilas.textBoxOnChangeHandler = textBoxOnChangeHandler;
  return (
    <SearchBar
      open={open}
      setOpen={setOpen}
      autocompleteOnChangeHandler={() => {}}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={hrApprovalsAutosuggestData}
      selectedAutoSuggestValue={''}
      suggestionsLoading={false}
      handleTypeChange={handleTypeChange}
    />
  );
};
