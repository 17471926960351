import {Box} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {makeStyles} from '@mui/styles';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {setSubTabSwitch} from '../../pages/fc-admin/redux/tabName';

const useStyles = makeStyles({
  customTabLabel: {
    textTransform: 'capitalize',
    fontSize: '12.7px',
  },
});

interface SubToggleProps {
  title1: string;
  title2: string;
}
const SubToggle: React.FC<SubToggleProps> = ({title1, title2}) => {
  const dispatch = useDispatch();
  const currentSubTab = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.subTabSwitch,
  );

  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSubTabSwitch(newValue));
  };

  return (
    <>
      <TabContext value={currentSubTab}>
        <Box sx={{marginLeft: '20px', marginBottom: '10px'}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              sx={{fontWeight: `${currentSubTab === 1 ? 'bolder' : 'normal'}`}}
              label={title1}
              className={classes.customTabLabel}
              value={1}
              // onClick={()=>handleTabChange(1)}
            />
            <Tab
              sx={{fontWeight: `${currentSubTab === 2 ? 'bolder' : 'normal'}`}}
              label={title2}
              className={classes.customTabLabel}
              value={2}
              // onClick={()=>handleTabChange(2)}
            />
          </TabList>
        </Box>
      </TabContext>
    </>
  );
};
export default SubToggle;
