import {useEffect, useCallback, useState, useRef, ChangeEvent} from 'react';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Box,
  Stack,
  SvgIcon,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useDispatch, useSelector, RootStateOrAny} from 'react-redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import Togglefilter from '@app/screens/zho/components/toggle-filter';
import {useStyles} from '@app/screens/zho/styles';
import Modal from 'react-modal';
import RequestFormHeading from './components/RequestFormHeading';
import ApprovalStatus from './components/approvalStatus';
import Details from './components/details';
import ProductDetails from './components/productDetails';
import {Scrollbar} from 'react-scrollbars-custom';
import RequestFormTable from './components/request-from-tables/requestFormTable';
import {
  fetchStateDataCreator,
  fetchHqDataCreator,
  fetchMrDataCreator,
  fetchRequestFormDataCreator,
  fetchReviewFormProductDataCreator,
  fetchFormRejectReviewCreator,
  fcDashboardActions,
} from '../redux-api/slice';
import dayjs from 'dayjs';
import {
  setStateSelect,
  updateRequestPayload,
  clearRequestPayload,
} from '../redux/tabName';
import {strings} from '@app/common/strings';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {ValueCache} from 'ag-grid-community';

const CustomArrowIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M7 10l5 5 5-5z" fill="#322b7c" />
    </SvgIcon>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface StateFilter {
  stateId: number;
  stateName: string;
}

interface HqFilter {
  hqId: number;
  hqName: string;
}

interface MrFilter {
  mrId: number;
  hqId: number;
  mrName: string;
  sapCode: string;
}

export const RequestForm = ({userLoginInfo}: any) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isRejectModelOpen, setIsRejectModelOpen] = useState(false);
  const [hasDeactivatedProduct, setHasDeactivatedProduct] = useState(false);
  const [stateId, setStateId] = useState<number | string>('');
  const [requestFormNo, setRequestFormNo] = useState<string>('');
  const [hqName, setHqName] = useState<number[]>([]);
  const [mrName, setMrName] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null | string>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null | string>(null);
  const [requestFormId, setRequestFormId] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState<null | number>(null);
  const [rejectOption, setRejectOption] = useState<string>('');
  const [rejectOptionalValue, setRejectOptionalvalue] = useState<string>('');
  const [isRemarkSubmitted, setIsRemarkSubmitted] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState({id: 0, isOpen: false});
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isEditProducts, setIsEditProducts] = useState(false);
  const [isFocused, setIsFocused] = useState<{
    focus: boolean;
    id: number | null;
  }>({
    focus: false,
    id: null,
  });
  const dispatch = useDispatch();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const handleInputFocus = (id: number) => {
    setIsFocused({
      focus: true,
      id,
    });
  };

  const handleInputBlur = () => {
    setIsFocused({
      focus: false,
      id: null,
    });
  };

  const handleToggleModal = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleRejectToggle = () => {
    setIsRejectModelOpen(!isRejectModelOpen);
    setRejectOption('');
    setIsRemarkSubmitted(false);
    setRejectOptionalvalue('');
  };
  const stateData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.stateData,
  );

  const stateLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.stateLoading,
  );

  const hqData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.hqData,
  );

  const hqLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.hqLoading,
  );

  const mrData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.mrData,
  );

  const [copyMrData, setCopyMrData] = useState(mrData);

  const mrLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.mrLoading,
  );
  const requestFormPayload = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.requestPayload,
  );

  const toogleStatusNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const productData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewFormProductData,
  );

  const productRecallDetails = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.reviewFormAdditonalQtyCompleted,
  );

  const rejectedListOptions = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewRejectRemarksData,
  );

  const approvalData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewFormApprovalData,
  );

  useEffect(() => {
    dispatch(fetchStateDataCreator(userLoginInfo?.staffPositionId));
    dispatch(
      updateRequestPayload({
        ...requestFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(clearRequestPayload());
      dispatch(fcDashboardActions.setHqData([]));
      dispatch(fcDashboardActions.setMrData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestFormId !== 0) {
      dispatch(fetchReviewFormProductDataCreator(requestFormId));
    }
  }, [productRecallDetails]);

  useEffect(() => {
    clearStateValues();
    setModalOpen({id: 0, isOpen: false});
    dispatch(fcDashboardActions.setHqData([]));
    dispatch(fcDashboardActions.setMrData([]));
  }, [toogleStatusNo]);

  useEffect(() => {
    setCopyMrData(mrData);
  }, [mrData]);

  const classes = useStyles();

  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
      />
    );
  }, []);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const handleDateChange = (date: dayjs.Dayjs | null | any, type: string) => {
    if (type === 'start') {
      if (date) {
        const formattedStartDate = date.format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
      }
    } else {
      if (date) {
        const formattedEndDate = date.format('YYYY-MM-DD');
        setEndDate(formattedEndDate);
      }
    }
  };

  const handleFilterChange = (event: any, type: string, dropValue: any) => {
    console.log('this is form state eveny', event.target.value);
    const {
      target: {value},
    } = event;

    let selectedIds: any;
    if (type === 'state') {
      const stateId = dropValue ? parseInt(dropValue.stateId) : '';
      setStateId(stateId);
      setHqName([]);
      setMrName([]);
      dispatch(fcDashboardActions.setHqData([]));
      dispatch(fcDashboardActions.setMrData([]));
      dispatch(setStateSelect(stateId));
      if (stateId !== '') {
        dispatch(fetchHqDataCreator(stateId));
      }
    } else if (type === 'search') {
      setRequestFormNo(value);
    } else if (type === 'mr') {
      selectedIds = dropValue.map((item: MrFilter) => item.mrId);
      setMrName(selectedIds);
    } else {
      selectedIds = dropValue.map((item: HqFilter) => item.hqId);
      setHqName(selectedIds);
      console.log(selectedIds);
      if (selectedIds.length !== 0) {
        dispatch(fetchMrDataCreator(selectedIds));
      } else {
        dispatch(fcDashboardActions.setMrData([]));
        setMrName([]);
      }
    }
  };

  console.log('this is the selected mr id', mrData);

  const handleFilterApiCall = () => {
    let dateCheck = false;

    if (endDate && startDate) {
      if (endDate < startDate) {
        dateCheck = true;
        ToasterService.hideToaster();
        ToasterService.showToaster('Invalid Date Range!', ToastType.ERROR);
      }
    }

    setModalOpen({id: 0, isOpen: false});
    dispatch(
      updateRequestPayload({
        ...requestFormPayload,
        pageSize: 10,
        pageNumber: 0,
        requestFormNo: requestFormNo,
        mrStaffIds: mrName,
        state: stateId,
        hq: hqName,
        startDate: startDate,
        endDate: endDate,
      }),
    );
    if (!dateCheck) {
      dispatch(
        fetchRequestFormDataCreator(
          {
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: requestFormPayload.pageSize,
            pageNumber: 0,
            requestFormNo: requestFormNo,
            mrStaffIds: mrName,
            state: stateId,
            hq: hqName,
            startDate: startDate,
            endDate: endDate,
          },
          toogleStatusNo,
        ),
      );
    }
  };

  const clearStateValues = () => {
    dispatch(clearRequestPayload());
    setStartDate(null);
    setStateId('');
    setEndDate(null);
    setRequestFormNo('');
    setHqName([]);
    setMrName([]);
  };

  const handleRejectionChange = (event: any, type: string) => {
    const {
      target: {value},
    } = event;

    if (type === 'select') {
      setIsRemarkSubmitted(false);
      setRejectOption(value);
    } else {
      setIsRemarkSubmitted(false);
      setRejectOptionalvalue(value);
    }
  };

  const handleRejectReviewForm = () => {
    if (
      rejectOption !== 'Others' &&
      !isRemarkSubmitted &&
      rejectOption !== '' &&
      rejectOption !== undefined
    ) {
      setIsRemarkSubmitted(false);
      setIsRejectModelOpen(false);
      dispatch(
        fetchFormRejectReviewCreator(
          userLoginInfo?.staffPositionId,
          rejectOption,
          userLoginInfo?.userId,
          requestFormId,
        ),
      );

      setRejectOption('');
      setRejectOptionalvalue('');
      handleToggleModal();
    } else if (rejectOptionalValue.trim() !== '' && rejectOption === 'Others') {
      setIsRemarkSubmitted(false);
      setIsRejectModelOpen(false);
      dispatch(
        fetchFormRejectReviewCreator(
          userLoginInfo?.staffPositionId,
          rejectOptionalValue,
          userLoginInfo?.userId,
          requestFormId,
        ),
      );

      setRejectOption('');
      setRejectOptionalvalue('');
      handleToggleModal();
    } else {
      setIsRemarkSubmitted(true);
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please fill the additional remarks!',
        ToastType.ERROR,
      );
    }
  };
  return (
    <>
      {/* Popup */}
      <div style={{overflow: 'auto'}}>
        <Modal
          isOpen={isModelOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '65%',
              height: '94vh',
              border: '25px',
              margin: 'auto',
              overflow: 'auto',
              right: 0,
              backgroundColor: '#ffffff',
              // boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '15px',
              padding: '10px',
              boxShadow: '0px 3.48px 23.22px 0px #00000040',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <Box sx={{height: '100%', overflow: 'auto', padding: '10px'}}>
            <RequestFormHeading handleToggleModal={handleToggleModal} />
            <ApprovalStatus
              requestFormId={requestFormId}
              productData={productData}
              userLoginInfo={userLoginInfo}
              isEditProducts={isEditProducts}
              isEditDetails={isEditDetails}
              handleToggleModal={handleToggleModal}
              setHasDeactivatedProduct={setHasDeactivatedProduct}
              hasDeactivatedProduct={hasDeactivatedProduct}
              setApprovalStatus={setApprovalStatus}
              approvalStatus={approvalStatus}
              approvalData={approvalData}
              handleRejectToggle={handleRejectToggle}
            />
            <Details
              isEditDetails={isEditDetails}
              setIsEditDetails={setIsEditDetails}
              setIsEditProducts={setIsEditProducts}
              requestFormId={requestFormId}
              productData={productData}
              userLoginInfo={userLoginInfo}
              approvalStatus={approvalStatus}
            />
            <ProductDetails
              isEditProducts={isEditProducts}
              isEditDetails={isEditDetails}
              setIsEditProducts={setIsEditProducts}
              setIsEditDetails={setIsEditDetails}
              productData={productData}
              handleToggleModal={handleToggleModal}
              userLoginInfo={userLoginInfo}
              requestFormId={requestFormId}
              setHasDeactivatedProduct={setHasDeactivatedProduct}
              hasDeactivatedProduct={hasDeactivatedProduct}
              setApprovalStatus={setApprovalStatus}
              approvalStatus={approvalStatus}
              handleRejectToggle={handleRejectToggle}
            />
          </Box>
        </Modal>
      </div>
      {/* Popup */}

      <div>
        <Modal
          isOpen={isRejectModelOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '330px',
              height: 'fit-content',
              border: '25px',
              margin: 'auto',

              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              // borderRadius: '20px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <Box>
            <Typography
              sx={{
                fontSize: '18.7px',
                cursor: 'pointer',
                color: '#1c1939',
              }}
              variant="subtitle2">
              {strings.zho.requestRejectionRemarks}
            </Typography>
          </Box>
          <Stack spacing={2} direction="column" marginTop={6}>
            <Box>
              <Typography
                sx={{fontSize: '15.7px', cursor: 'pointer', color: '#1c1939'}}
                variant="subtitle2">
                {
                  strings.zho.fcAdmin.dashboard.tableHeading
                    .ReasonsforRejections
                }
                <Typography variant="caption" color="red">
                  *
                </Typography>
              </Typography>
            </Box>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center">
              <FormControl fullWidth className={classes.dropdownBgWhite}>
                <InputLabel size="small" id="state" sx={{fontSize: '12.7px'}}>
                  {strings.zho.fcAdmin.requestForm.filters.rejection}
                </InputLabel>
                <Select
                  className={`${classes.remarksDropdown} ${
                    isRemarkSubmitted && rejectOption === ''
                      ? classes.errorBorder
                      : ''
                  }  `}
                  size="small"
                  labelId="remarks"
                  id="remarks"
                  value={rejectOption}
                  label={strings.zho.fcAdmin.requestForm.filters.rejection}
                  onChange={e => handleRejectionChange(e, 'select')}>
                  {rejectedListOptions?.map((options: any, index: number) => {
                    return (
                      <MenuItem
                        sx={{
                          whiteSpace: 'normal !important',
                          maxWidth: '280px',
                          minWidth: '280px',
                          minHeight: 'auto',
                          fontSize: '12.7px',
                          height: 'auto',
                          color: '#1c1939',
                        }}
                        key={index}
                        value={options}>
                        {options}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {rejectOption === 'Others' && (
                <Box marginTop="40px" width="100%">
                  <textarea
                    onChange={e => handleRejectionChange(e, 'input')}
                    value={rejectOptionalValue}
                    cols={3}
                    rows={4}
                    className={`${classes.rejectedTextArea} ${
                      isRemarkSubmitted && rejectOptionalValue.trim() === ''
                        ? classes.errorBorder
                        : ''
                    } `}
                    placeholder="Type remakrs Ex: Mr quit the company"></textarea>
                </Box>
              )}
            </Stack>
          </Stack>

          <Box
            marginTop={rejectOption === 'Others' ? 3 : 7}
            borderTop="1px solid #ececec ">
            <Stack direction="row" marginTop={2}>
              <Button
                data-testid="button-cancel"
                variant="outlined"
                size="large"
                sx={{textTransform: 'capitalize'}}
                onClick={handleRejectToggle}
                className={`${classes.paperButton} ${classes.rejectionButtonCancel}`}>
                {strings.cancel}
              </Button>
              <Button
                data-testid="button-reject"
                variant="contained"
                sx={{textTransform: 'capitalize'}}
                onClick={handleRejectReviewForm}
                className={`${classes.applyButton} ${classes.rejectionButtonRej} `}>
                {strings.reject}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>

      <Container
        sx={{
          padding: '12px 20px !important',
          maxHeight: 'calc(100vh - 155px)',
          overflow: 'auto',
        }}
        maxWidth={false}>
        <Togglefilter role={1} />
        {/* <Stack
          marginTop={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}>
          <Chip
            sx={{minWidth: '90px', display: 'flex', gap: '1.3rem'}}
            label="All"
            color="primary"
            onClick={handleClick}
            onDelete={handleClick}
            deleteIcon={
              <DoneIcon
                sx={{
                  fill: `${theme.colors.primary}`,
                  borderRadius: '3000px',
                  backgroundColor: '#ffffff',
                  height: '18px',
                  width: '18px',
                }}
              />
            }
          />
          <Chip
            sx={{
              minWidth: '90px',
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '2rem',
              background: '#ffffff',
              border: '1px solid lightgrey',
              color: `${theme.colors.primary}`,
            }}
            label="Self"
          />
          <Chip
            sx={{
              minWidth: '90px',
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '2rem',
              background: '#ffffff',
              border: '1px solid lightgrey',
              color: `${theme.colors.primary}`,
            }}
            label="Other"
          />
        </Stack> */}
        <div className={classes.toolbarRequestForm}>
          <Scrollbar
            style={{
              width: '100%',
              height: '55px',
              marginRight: '10px',
            }}>
            <Grid
              sx={{paddingTop: '3px'}}
              container
              className={classes.filterGrid}
              spacing={1}>
              <Grid item xs sx={{flexBasis: '16%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    disableFuture={true}
                    label={strings.zho.fcAdmin.requestForm.filters.startDate}
                    className={classes.filterFromDate}
                    // PopperProps={DialogProps}
                    views={['year', 'month', 'day']}
                    value={startDate}
                    onChange={e => handleDateChange(e, 'start')}
                    renderInput={renderInputBill}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs sx={{flexBasis: '16%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture={true}
                    label={strings.zho.fcAdmin.requestForm.filters.endDate}
                    inputFormat="DD/MM/YYYY"
                    className={classes.filterToDate}
                    // PopperProps={DialogProps}
                    views={['year', 'month', 'day']}
                    value={endDate}
                    onChange={e => handleDateChange(e, 'end')}
                    renderInput={renderInputBill}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs sx={{flexBasis: '16%', fontSize: '12.7px'}}>
                <TextField
                  onChange={e => handleFilterChange(e, 'search', null)}
                  data-testid="requestform-no-filter"
                  size="small"
                  id="requestFormNo"
                  placeholder="Request Form No."
                  value={requestFormNo}
                  sx={{color: 'red !important', fontSize: '12.7px !important'}}
                  label={strings.zho.fcAdmin.requestForm.filters.requestFormNo}
                  variant="outlined"
                  className={` ${classes.filter}`}
                />
              </Grid>
              <Grid item sx={{flexBasis: '16%'}}>
                <FormControl fullWidth className={classes.dropdownBgWhite}>
                  {/* <InputLabel
                    sx={{fontSize: '12.7px', color: '#1c1939B3'}}
                    size="small"
                    id="state"
                    className={classes.font12}>
                    {strings.zho.fcAdmin.requestForm.filters.state}
                  </InputLabel> */}
                  {/* <Select
                    data-testid="state-filter"
                    className={classes.dropdown}
                    size="small"
                    labelId="state"
                    id="state"
                    value={stateId}
                    IconComponent={props => (
                      <CustomArrowIcon {...props} sx={{color: '##322b7c'}} />
                    )}
                    label={strings.zho.fcAdmin.requestForm.filters.state}
                    onChange={e => handleFilterChange(e, 'state')}>
                    {stateData.map((state: any, index: number) => (
                      <MenuItem
                        sx={{fontSize: '12.7px !important', color: '#1c1939'}}
                        key={index}
                        value={state.stateId}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Autocomplete
                    value={
                      stateData.find(
                        (item: StateFilter) => item.stateId === stateId,
                      ) || null
                    }
                    onChange={(e, value: any) =>
                      handleFilterChange(e, 'state', value)
                    }
                    noOptionsText="No options"
                    data-testid="test-product-dropdown"
                    key={'search-state'}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',

                      '& .MuiAutocomplete-input': {
                        width: '1px !important',
                        minWidth: '1px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    options={stateData}
                    getOptionLabel={(option: StateFilter) =>
                      option?.stateName?.length > 13
                        ? option?.stateName?.slice(0, 13) + '...'
                        : option?.stateName
                    }
                    renderOption={(props: any, option: StateFilter) => (
                      <li
                        {...props}
                        key={option?.stateId}
                        style={{
                          fontSize: '12.7px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        {option.stateName}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Select State'}
                        className={classes.textField}
                        label="Select State"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {stateLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value: any) => {
                      if (!value) return null;
                      const firstLabel =
                        value.stateName?.length > 13
                          ? value.stateName?.slice(0, 13) + '...'
                          : value.stateName;
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{flexBasis: '16%'}}>
                <FormControl
                  sx={{height: '34px !important'}}
                  fullWidth
                  className={classes.dropdownBgWhite}>
                  {/* <InputLabel
                    size="small"
                    sx={{fontSize: '12.7px', color: '#1c1939B3'}}
                    id="hq">
                    {strings.zho.fcAdmin.requestForm.filters.hq}
                  </InputLabel>
                  <Select
                    data-testid="hq-filter"
                    className={classes.dropdown}
                    multiple
                    value={hqName}
                    id='hq'
                    labelId='hq'
                    IconComponent={props => (
                      <CustomArrowIcon data-testid="hq-filter-arrow" {...props} sx={{color: '#322b7c'}} />
                    )}
                    onChange={e => handleFilterChange(e, 'hq', null)}
                    renderValue={selected =>
                      selected
                        .map(
                          value =>
                            hqData.find((hq: any) => hq.hqId === value)
                              ?.hqName || '',
                        )
                        .join(', ')
                    }
                    input={<OutlinedInput data-testid="hq-filter" label="Select HQ" />}
                    MenuProps={MenuProps}>
                    {hqData.map((hq: any) => (
                      <MenuItem
                        sx={{padding: '0px'}}
                        key={hq.hqId}
                        value={hq.hqId}>
                        <Checkbox checked={hqName.includes(hq.hqId)} />
                        <ListItemText
                          sx={{
                            '& .MuiListItemText-primary': {
                              fontSize: '12.7px',
                              fontWeight: '400',
                              color: '#1c1939',
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            },
                          }}
                          primary={hq.hqName}
                        />
                      </MenuItem>
                    ))}
                  </Select> */}

                  <Autocomplete
                    noOptionsText="No options"
                    disableCloseOnSelect
                    data-testid="test-product-dropdown"
                    onChange={(e, value) => handleFilterChange(e, 'hq', value)}
                    value={hqData.filter((item: HqFilter) =>
                      hqName.includes(item.hqId),
                    )}
                    key={'search Hq'}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',
                      '& .MuiAutocomplete-input': {
                        width: '2px !important',
                        minWidth: '2px !important',
                        paddingLeft: '1px !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '0px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    multiple
                    options={hqData}
                    getOptionLabel={(option: any) => option.hqName}
                    renderOption={(props, option, {selected}) => (
                      <li
                        {...props}
                        key={option.hqId}
                        style={{
                          fontSize: '12.7px',
                          padding: '0px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={
                            option.hqId === 'select_all'
                              ? hqName.length === hqData.length
                              : selected
                          }
                        />
                        {option.hqName}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Select HQ'}
                        className={classes.textField}
                        label="Select HQ"
                        InputProps={{
                          ...params.InputProps,
                          onFocus: () => handleInputFocus(2),
                          onBlur: handleInputBlur,
                          endAdornment: (
                            <>
                              {hqLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                              opacity:
                                hqName.length !== 0 &&
                                isFocused.focus &&
                                isFocused.id === 2
                                  ? 0.5
                                  : 0,
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      if (isFocused.focus && isFocused.id === 2) return null; // Hide tags when input is focused
                      if (value.length === 0) return null;

                      const firstLabel = value[0]?.hqName?.slice(0, 13) + '...';
                      const additionalCount =
                        value?.length > 1
                          ? ` ${value?.length} HQ's selected`
                          : '';

                      if (value?.length === 1) {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {firstLabel}
                          </span>
                        );
                      } else {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {additionalCount}
                          </span>
                        );
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{flexBasis: '16%'}}>
                <FormControl
                  sx={{height: '34px !important'}}
                  fullWidth
                  className={classes.dropdownBgWhite}>
                  {/* <InputLabel
                    sx={{fontSize: '12.7px', color: '#1c1939B3'}}
                    size="small"
                    id="mr">
                    {strings.zho.fcAdmin.requestForm.filters.mr}
                  </InputLabel> */}

                  {/* <Select
                    sx={{fontSize: '12.7px !important'}}
                    className={classes.dropdown}
                    multiple
                    id='mr'
                    labelId='mr'
                    value={mrName}
                    IconComponent={props => (
                      <CustomArrowIcon {...props} sx={{color: '##322b7c'}} />
                    )}
                    onChange={e => handleFilterChange(e, 'mr', null)}
                    renderValue={selected =>
                      selected
                        .map(
                          value =>
                            mrData.find((mr: any) => mr.mrId === value)
                              ?.mrName || '',
                        )
                        .join(', ')
                    }
                    input={<OutlinedInput label="Select MR" />}
                    MenuProps={MenuProps}>
                    {mrData.map((mr: any) => (
                      <MenuItem
                        sx={{padding: '0px'}}
                        key={mr?.mrId}
                        value={mr?.mrId}>
                        <Checkbox checked={mrName?.includes(mr?.mrId)} />
                        <ListItemText
                          sx={{
                            '& .MuiListItemText-primary': {
                              fontSize: '12.7px',
                              fontWeight: '400',
                              whiteSpace: 'normal',
                              color: '#1c1939',
                              wordBreak: 'break-word',
                            },
                          }}
                          primary={mr?.mrName}
                        />
                      </MenuItem>
                    ))}
                  </Select> */}

                  <Autocomplete
                    noOptionsText="No options"
                    disableCloseOnSelect
                    onChange={(e, value) => handleFilterChange(e, 'mr', value)}
                    value={mrData.filter((item: MrFilter) =>
                      mrName.includes(item.mrId),
                    )}
                    data-testid="test-product-dropdown"
                    key={'search-Mr'}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',
                      '& .MuiAutocomplete-input': {
                        width: '2px !important',
                        minWidth: '2px !important',
                        paddingLeft: '1px !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '0px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    multiple
                    options={mrData}
                    getOptionLabel={(option: MrFilter) =>
                      option?.mrName?.length > 13
                        ? option.mrName?.slice(0, 13) + '...'
                        : option.mrName
                    }
                    renderOption={(props, option: any, {selected}, index) => (
                      <li
                        {...props}
                        key={+option.mrId}
                        style={{
                          fontSize: '12.7px',
                          padding: '0px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={
                            option.mrId === 'select_all'
                              ? mrName.length === mrData.length
                              : selected
                          }
                        />
                        {option.mrName}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Select MR'}
                        className={classes.textField}
                        label="Select MR"
                        InputProps={{
                          ...params.InputProps,
                          onFocus: () => handleInputFocus(3),
                          onBlur: handleInputBlur,
                          endAdornment: (
                            <>
                              {mrLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                              opacity:
                                hqName.length !== 0 &&
                                isFocused.focus &&
                                isFocused.id === 3
                                  ? 0.5
                                  : 0,
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value: any, getTagProps) => {
                      if (isFocused.focus && isFocused.id === 3) return null; // Hide tags when input is focused
                      if (value.length === 0) return null;
                      const firstLabel = value[0]?.mrName?.slice(0, 13) + '...';
                      const additionalCount =
                        value?.length > 1
                          ? ` ${value.length} Mr's selected`
                          : '';
                      if (value.length === 1) {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {firstLabel}
                          </span>
                        );
                      } else {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {additionalCount}
                          </span>
                        );
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{flexShrink: 0}}></Grid>
            </Grid>
          </Scrollbar>
          <Button
            onClick={handleFilterApiCall}
            data-testid="goButton"
            variant="contained"
            component="span"
            className={classes.requestFormgo}
            // disabled={goButton}
            sx={{
              maxWidth: '10px',
              fontSize: '12.7px',
              textTransform: 'capitalize',
            }}
            size="medium">
            {strings.go}
          </Button>
        </div>

        {/* <GridTable handleToggleModal={handleToggleModal} /> */}
        <RequestFormTable
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRequestFormId={setRequestFormId}
          handleToggleModal={handleToggleModal}
          userLoginInfo={userLoginInfo}
        />
        {/* <Togglefilter segments={segmentData2} /> */}
      </Container>
    </>
  );
};
