import {createSelector} from '@reduxjs/toolkit';

export const selectedEmployeeStateSelector = {
  getSelectedEmployeesState: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees],
      state => state,
    );
  },
  getSelectedEmployees: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.selectedEmployees],
      adminUsers => adminUsers,
    );
  },
  getTotalCount: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.totalCount],
      totalCount => totalCount,
    ); 
  },
  getSelectedEmployeesSnapshot: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.selectedEmployeesSnapShot],
      adminUsers => adminUsers,
    );
  },
  getSelectedEmployeesLoading: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.selectedEmployeesLoading],
      loading => loading,
    );
  },
  getSelectedEmployeesError: () => {
    return createSelector([(state: any) => state.selectedEmployees], error => ({
      error: error?.selectedEmployeesError,
      message: error?.selectedEmployeesErrorMessage,
    }));
  },
  getSelectedEmployeesSuggestionsLoading: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.SelectedEmployeesSuggestLoading],
      loading => loading,
    );
  },
  getSelectedEmployeesType: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.employeeType],
      text => text,
    );
  },
  getSelectedEmployeesSearchText: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.searchText],
      text => text,
    );
  },
  getSelectedEmployeesAutoSuggestData: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.autoSuggestData],
      data => data,
    );
  },
  getSelectedEmployeesAutoSuggestValue: () => {
    return createSelector(
      [(state: any) => state.selectedEmployees.selectedAutoSuggestValue],
      text => text,
    );
  },
};
