import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {SearchBar} from '@app/components/widgets/searchBar';
import {Box} from '@mui/material';
import {
  planAndMeetStatusStateActions,
  fetchPlanAndMeetListCreator,
  planAndMeetSelector,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import {useStyles} from './styles';
import {getMonthsArray} from '@app/helper/plan-and-meet';
import {Label} from '@app/components/elements/Label';
import {fetchFilterOptionsCreator} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/slice';
import {searchApprovalRequestsCreator} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux';

const months = getMonthsArray();

export const DoctorApprovalNavBar = () => {
  const dispatch = useDispatch();
  const monthSelected: any = {checked: false, months: months};
  const [searchError, setSearchError] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles(monthSelected);
  const gridOptions = useSelector(
    planAndMeetSelector.getPlanAndMeetGridOption(),
  );
  const fetchListData = () => {
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );
    dispatch(fetchPlanAndMeetListCreator());
    dispatch(fetchFilterOptionsCreator());
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(planAndMeetStatusStateActions.setPlanAndMeetSearchText(value));
    setSearchValue(value);
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchValue?.length >= 3 || searchValue?.length === 0) {
        setSearchError(false);
        fetchListData();
        dispatch(searchApprovalRequestsCreator(searchValue));
      } else {
        setSearchError(true);
      }
    }
  };

  return (
    <Box className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        <div></div>
      </div>
      <div className={classes.searchContainer}>
        <SearchBar
          searchText={searchValue}
          handleChange={handleSearchTextChange}
          handleSearch={handleSearchPress}
          showError={searchError}
          placeHolder={'Search by name or employee code'}
          errorMessage={'Please Enter Minimum 3 Characters'}
        />
      </div>
    </Box>
  );
};
