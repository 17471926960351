import React, {useEffect, useState} from 'react';
import {manageUserRoleStyles as styles} from '../styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Typography,
  FormGroup,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {Box, styled} from '@mui/system';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fetchUpdateUserApprovalDataCreator,
  fetchUserApprovalDataCreator,
} from '../../redux-api/slice';
import {strings} from '@app/common/strings';

interface ActiontableProps {
  tableType: string;
  divisionId: number;
  staffPositionId: number;
}

interface Role {
  roleId: number;
  status: boolean;
  currentStatus: string;
  isRequestForm: boolean;
  roleName: string;
}

interface UserApprovalData {
  approvalList: Role[];
  divisionId?: number;
  staffPositionId?: number;
}

const StyledNameCell = styled(TableCell)<{isOdd: boolean}>(
  ({theme, isOdd}) => ({
    backgroundColor: isOdd ? '#F3F3F3' : 'transparent',
    color:theme.palette.text.primary,
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    borderBottom: '0px',
    // padding: theme.spacing(1),
    width: '33.33%',
    fontSize:12.7
  }),
);

const StyledStatusCell = styled(TableCell)<{isOdd: boolean; isActive: boolean}>(
  ({theme, isOdd, isActive}) => ({
    backgroundColor: isOdd ? '#F3F3F3' : 'transparent',
    color: isActive ? '#18A685' : theme.palette.text.secondary,
    fontWeight: 'bold',
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottom: '0px',
    // padding: theme.spacing(1),
    width: '33.33%',
    fontSize:12.7
  }),
);

const ActionTable: React.FC<ActiontableProps> = ({
  tableType,
  divisionId,
  staffPositionId,
}) => {
  const dispatch = useDispatch();
  const [tableData, setTabledata] = useState<UserApprovalData>({
    approvalList: [],
    staffPositionId: staffPositionId,
  });
  const userApprovalData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard?.userApprovalData,
  );
  const UpdateuserApprovalCompleted = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard?.updateUserApprovalCompleted,
  );

  const handleToggleChange = (roleId: number) => {
    setTabledata(prevData => ({
      ...prevData,
      approvalList: prevData.approvalList.map(item =>
        item.roleId === roleId
          ? {
              ...item,
              status: !item.status,
              currentStatus: item?.status === false ? 'Active' : 'InActive',
            }
          : item,
      ),
      staffPositionId: staffPositionId,
    }));
  };

  const updateUserApproval = () => {
    dispatch(fetchUpdateUserApprovalDataCreator(tableType, tableData));
  };

  useEffect(() => {
    dispatch(fetchUserApprovalDataCreator(divisionId));
  }, [UpdateuserApprovalCompleted]);

  useEffect(() => {
    if (tableType === 'order') {
      setTabledata(prevData => ({
        ...prevData,
        approvalList: userApprovalData?.orderUserRoles?.approvalList,
        divisionId: userApprovalData?.orderUserRoles?.divisionId,
      }));
    } else {
      setTabledata(prevData => ({
        ...prevData,
        approvalList: userApprovalData?.requestFormUserRoles?.approvalList,
        divisionId: userApprovalData?.requestFormUserRoles?.divisionId,
      }));
    }
  }, [userApprovalData]);

  return (
    <TableContainer sx={{boxShadow: 'none !important'}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.header}>
              {strings.zho.fcAdmin.manageUserRole.user}
            </TableCell>
            <TableCell sx={styles.header}>
              {strings.zho.fcAdmin.manageUserRole.currentStatus}
            </TableCell>
            <TableCell sx={styles.header}>
              {strings.zho.fcAdmin.manageUserRole.proposedStatus}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.approvalList?.length > 0 ? (
            tableData?.approvalList?.map((row: any, index: any) => (
              <TableRow key={index}>
                <StyledNameCell isOdd={index % 2 === 0}>
                  <Typography sx={{fontSize:12.7}}>{(row?.roleName==="TLM"&&tableType==="order")?"TLM / 4TH / 5TH / STATEHEAD / TopLM":row?.roleName}</Typography>
                </StyledNameCell>
                <StyledStatusCell
                  isOdd={index % 2 === 0}
                  isActive={row?.status}>
                  {row?.currentStatus}
                </StyledStatusCell>
                <TableCell align="center" sx={styles.actionTableCell}>
                  {/* <FormGroup> */}
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      sx={[
                        styles.toggleTitle,
                        ((row?.roleId === 1)||(row?.roleId === 5 && tableType === "order")||(row?.roleId === 3 && tableType === "request")) && styles.disabledToggle,
                      ]}>
                      {strings.zho.inActive}
                    </Typography>
                    <AntSwitch
                      sx={[((row?.roleId === 1)||(row?.roleId === 5 && tableType === "order")||(row?.roleId === 3 && tableType === "request")) && row.status && styles.disabledToggle]}
                      data-testid="status-toggle"
                      onChange={() => handleToggleChange(row?.roleId)}
                      checked={row.status}
                      inputProps={{'aria-label': 'ant design'}}
                      disabled={(row?.roleId === 1)||(row?.roleId === 5 && tableType === "order")||(row?.roleId === 3 && tableType === "request")}
                    />
                    <Typography
                      sx={[
                        styles.toggleTitle,
                        ((row?.roleId === 1)||(row?.roleId === 5 && tableType === "order")||(row?.roleId === 3 && tableType === "request")) && styles.disabledToggle,
                      ]}>
                      {strings.zho.active}
                    </Typography>
                  </Stack>
                  {/* </FormGroup> */}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Box
              sx={[
                {display: 'flex', justifyContent: 'center', mt: 3},
                styles.loader,
              ]}>
              <CircularProgress />
            </Box>
          )}
          <TableRow>
            <TableCell sx={styles.actionTableCell}></TableCell>
            <TableCell sx={styles.actionTableCell}></TableCell>
            <TableCell sx={styles.actionTableCell} colSpan={3}>
              <Button
                disabled={false}
                onClick={updateUserApproval}
                data-testid="test-update-roles"
                sx={{
                  width: 140,
                  height: 40,
                  borderRadius: 1,
                  fontSize: '12.7px',
                  textTransform: 'none',
                }}
                variant="contained">
                {strings.zho.update}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActionTable;
