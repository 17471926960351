import {useCallback, useState} from 'react';
import {Search} from '@mui/icons-material';
import {useStyles} from './styles';
import {useSelector} from 'react-redux';
import {
  Autocomplete,
  FormControl,
  InputAdornment,
  TextField,
  Toolbar,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@mui/material';

import {navbarStyles} from '@app/components/widgets/AntSwitch/AntSwitch';
import {appSelector} from '@app/store/selectors';
import {filterSelector} from '../../components/right-sidebar/redux/filterHandlerSelector';

type EmployeeType = any;

type SearchBarProps = {
  open: boolean;
  setOpen: (e: boolean) => void;
  autocompleteOnChangeHandler: (e: any) => void;
  textBoxOnChangeHandler: (e: any) => void;
  autoSuggestData: any;
  selectedAutoSuggestValue: any;
  suggestionsLoading: boolean;
  handleTypeChange: (value: EmployeeType) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 165,
    },
  },
};

export const SearchBar: React.FC<SearchBarProps> = ({
  open,
  setOpen,
  autocompleteOnChangeHandler,
  textBoxOnChangeHandler,
  autoSuggestData,
  suggestionsLoading,
  handleTypeChange,
  selectedAutoSuggestValue,
}) => {
  const [dropdownValue, setDropdownValue] = useState<any[]>([null]);
  const [searchEmployee, setSearchEmployee] = useState<any[]>([null]);
  const rolesDropdownData = useSelector(appSelector.getRolesDropDown());
  const request = useSelector(filterSelector.getFilterOptions());
  const roleId = request?.roleIds == null ? null : request?.roleIds;
  const classes = useStyles();
  const rolesDropdownDataAll = [
    ...rolesDropdownData,
    {
      id: null,
      name: 'All',
      rank: 0,
      shortName: 'ALL',
      staffPositionId: 0,
    },
  ];
  rolesDropdownDataAll.sort((a, b) => a.rank - b.rank);
  const handleDropdownChange = (event: any) => {
    const selectedValues = event.target.value as any[];
    let values: number[] = [];
    if (selectedValues?.length > 0) {
      if (selectedValues.includes(null)) {
        values = selectedValues.filter(item => item !== null);
        return handleTypeChange(values as EmployeeType);
      }
      return handleTypeChange(selectedValues as EmployeeType);
    } else if (selectedValues?.length === 0) {
      handleTypeChange(null as EmployeeType);
    }
  };

  const onOpenAutocomplete = useCallback(
    function () {
      if (request?.searchData?.length > 0) {
        setOpen(true);
      } else if (request?.searchData?.length === 0) {
        setOpen(false);
      }
    },
    [request],
  );

  const onCloseAutocomplete = useCallback(function () {
    setOpen(false);
  }, []);

  const onChangeHandler = useCallback(function (_event: any, value: any) {
    autocompleteOnChangeHandler(value?.employeeId);
    textBoxOnChangeHandler(value?.employeeId);
  }, []);

  const isOptionEqualHandler = useCallback(function (option: any, value: any) {
    return option?.employeeName === value?.employeeName;
  }, []);

  const getOptionLabelHandler = useCallback(function (option: any) {
    if (option?.employeeId?.length > 0 || option?.employeeName?.length > 0)
      return `${option?.employeeId}-${option?.employeeName || option?.name}`;
    else return '';
  }, []);
  const onChangeAutoComplete = useCallback(
    function (event: any) {
      setSearchEmployee(event.target.value)
      textBoxOnChangeHandler(event.target.value);
    },
    [request],
  );

  const handleKeyUp = useCallback(
    function (event: any) {
      if (event.key === 'Enter') {
        textBoxOnChangeHandler(searchEmployee);
      }
    },
    [request],
  );
  const renderInput = useCallback(
    function (params: any) {
      return (
        <TextField
          onChange={onChangeAutoComplete}
          onKeyUp={handleKeyUp}
          {...params}
          value={request.searchData}
          placeholder={'Search by name or employee code'}
          sx={navbarStyles.textField}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            onKeyDown: e => {
              if (e.key === 'Enter') {
                setOpen(false);
                e.stopPropagation();
              }
            },
            startAdornment: (
              <InputAdornment position="start" sx={navbarStyles.padding5}>
                <Search />
              </InputAdornment>
            ),
            endAdornment: <></>,
          }}
        />
      );
    },
    [request],
  );

  return (
    <div>
      <Toolbar sx={navbarStyles.toolbar}>
        <FormControl sx={{width: '100%'}}>
          <Autocomplete
            data-testid={'search-autocomplete'}
            open={open}
            freeSolo
            disableClearable
            value={selectedAutoSuggestValue}
            sx={{width: '95%',marginTop: '2.6%', borderRadius: 5}}
            onChange={onChangeHandler}
            onOpen={onOpenAutocomplete}
            onClose={onCloseAutocomplete}
            isOptionEqualToValue={isOptionEqualHandler}
            getOptionLabel={getOptionLabelHandler}
            options={
              autoSuggestData && autoSuggestData.length > 0
                ? autoSuggestData
                : []
            }
            loading={suggestionsLoading}
            renderInput={renderInput}
          />
        </FormControl>

        <div style={{width: 250, display: 'flex', flexDirection: 'column'}}>
          <span id="demo-multiple-name-label">Roles </span>
          <Select
            id="roleDropdown"
            multiple
            className={classes.dropdown}
            defaultValue={[null]}
            value={roleId == null ? [null] : roleId}
            onChange={handleDropdownChange}
            renderValue={selected => {
              const selectedRoles = rolesDropdownDataAll.filter(role =>
                selected.includes(role.id),
              );
              return selectedRoles.map(role => role.shortName).join(', ');
            }}
            onClose={() => {
              !dropdownValue?.length && setDropdownValue([null]);
            }}
            MenuProps={MenuProps}>
            {rolesDropdownDataAll.map(role => (
              <MenuItem
                key={role.id}
                value={role.id}
                disabled={role.id === null}
                style={{
                  fontWeight: 'bold',
                  height:30,
                  fontFamily: 'Poppins',
                  fontSize: '0.928571rem',
                  lineHeight: 1.5,
                  color: 'rgb(50, 43, 124)',
                }}>
                <Checkbox
                  checked={
                    roleId == null
                      ? roleId === role.id
                      : roleId.includes(role.id)
                  }
                />
                <ListItemText primary={role.shortName} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </Toolbar>
    </div>
  );
};
