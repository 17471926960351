import {createSelector} from '@reduxjs/toolkit';

export const staffHoldStateSelector = {
  getStaffHolds: () => {
    return createSelector(
      [(state: any) => state.staffHold.staffHold],
      data => data,
    );
  },
  getStaffHoldState: () => {
    return createSelector([(state: any) => state.staffHold], data => data);
  },
  getUpdatedStaffHoldRow: () => {
    return createSelector(
      [(state: any) => state.staffHold.updatedStaffHoldRow],
      data => data,
    );
  },

  getEmployeesOnHold: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHold],
      data => data,
    );
  },
  getEmployeesOnHoldPageNo: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHoldPageNo],
      data => data,
    );
  },
  getEmployeesOnHoldrowsPerPage: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHoldrowsPerPage],
      data => data,
    );
  },
  getEmployeesOnHoldTotalCount: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHoldTotalCount],
      data => data,
    );
  },
  getEmployeesOnHoldLoading: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHoldLoading],
      loading => loading,
    );
  },
  getStaffHoldsLoading: () => {
    return createSelector(
      [(state: any) => state.staffHold.staffHoldLoading],
      loading => loading,
    );
  },

  getStaffHoldsPageNo: () => {
    return createSelector(
      [(state: any) => state.staffHold.pageNo],
      staffHold => staffHold,
    );
  },
  getStaffHoldsPerPage: () => {
    return createSelector(
      [(state: any) => state.staffHold.rowsPerPage],
      staffHold => staffHold,
    );
  },
  getStaffHoldsTotalCount: () => {
    return createSelector(
      [(state: any) => state.staffHold.totalCount],
      staffHold => staffHold,
    );
  },
  getHistory: () => {
    return createSelector(
      [(state: any) => state.staffHold.history],
      data => data,
    );
  },
  getHistoryLoading: () => {
    return createSelector(
      [(state: any) => state.staffHold.historyLoading],
      data => data,
    );
  },
  getStaffHoldSuggestionsLoading: () => {
    return createSelector(
      [(state: any) => state.staffHold.staffHoldSuggestLoading],
      loading => loading,
    );
  },
  getStaffHoldEmployeeType: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeeType],
      text => text,
    );
  },
  getStaffHoldSearchText: () => {
    return createSelector(
      [(state: any) => state.staffHold.searchText],
      text => text,
    );
  },
  getHoldEmployeeSearchText: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHoldSearchText],
      text => text,
    );
  },
  getStaffHoldAutoSuggestData: () => {
    return createSelector(
      [(state: any) => state.staffHold.autoSuggestData],
      text => text,
    );
  },
  getEmployeesOnHoldAutoSuggestData: () => {
    return createSelector(
      [(state: any) => state.staffHold.employeesOnHold],
      text => text,
    );
  },
  getStaffHoldAutoSuggestValue: () => {
    return createSelector(
      [(state: any) => state.staffHold.selectedAutoSuggestValue],
      text => text,
    );
  },
  getStaffHoldsError: () => {
    return createSelector([(state: any) => state.staffHold], error => ({
      error: error?.staffHoldError,
      message: error?.staffHoldErrorMessage,
    }));
  },
  getDeepdive: () => {
    return createSelector(
      [(state: any) => state.staffHold.deepdive],
      data => data,
    );
  },
  getDeepdiveLoading: () => {
    return createSelector(
      [(state: any) => state.staffHold.deepdiveLoading],
      data => data,
    );
  },
  getResignedStaffHold: () => {
    return createSelector(
      [(state: any) => state.staffHold.resignedStaffHold],
      data => data,
    );
  },
};
