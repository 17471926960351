import {createSelector} from '@reduxjs/toolkit';

export const welcomeKitStateSelector = {
  getWelcomeKit: () => {
    return createSelector(
      [(state: any) => state.welcomeKit],
      welcomeKit => welcomeKit,
    );
  },
  getWelcomeKitData: () => {
    return createSelector(
      [(state: any) => state.welcomeKit.welcomeKit],
      welcomeKit => welcomeKit,
    );
  },
  getWelcomeKitLoading: () => {
    return createSelector(
      [(state: any) => state.welcomeKit.loading],
      welcomeKit => welcomeKit,
    );
  },

  getWelcomeKitPageNo: () => {  
    return createSelector(
      [(state: any) => state.welcomeKit.pageNo],
      welcomeKit => welcomeKit,
    ); 
  },
  getWelcomeKitPerPage: () => {
    return createSelector(
      [(state: any) => state.welcomeKit.rowsPerPage],
      welcomeKit => welcomeKit,
    );
  
  },
  getWelcomeKitTotalCount: () => {
    return createSelector(
      [(state: any) => state.welcomeKit.totalCount],
      welcomeKit => welcomeKit,
    );
  
  },
};
