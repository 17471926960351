import React from 'react';
import {Typography, TypographyProps} from '@mui/material';

interface CustomTitleProps {
  title: string;
  variant: TypographyProps['variant'];
}

const CustomTitle: React.FC<CustomTitleProps> = ({title, variant}) => {
  return (
    <Typography
      color="#1c1939"
      variant={variant}
      fontFamily="Poppins"
      style={{
        marginBottom: 5,
      }}>
      {title}
    </Typography>
  );
};

export default CustomTitle;
