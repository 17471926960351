import {createAction, createSlice} from '@reduxjs/toolkit';

export const feedbackInitialState = {
  data: [],
  totalCount: 0,
  snapShot: [],
  loading: false,
  success: false,
  error: false,
  history: [],
  historyLoading: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
};

export const feedbackDatagridInitialState = {
  pageNo: 0,
  rowsPerPage: 20,
  deleteFeedbackResponse: false,
  addFeedbackResponse: '',
  selectedFeedback: null,
};

const feedbackDatagridStateHandler = createSlice({
  name: 'FEEDBACKDATAGRID',
  initialState: feedbackDatagridInitialState,
  reducers: {
    setFeedbacksLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
    setFeedbackDeleteResponse: (prevState, action) => {
      return {
        ...prevState,
        deleteFeedbackResponse: true,
      };
    },
    setAddFeedbackResponse: (prevState, action) => {
      return {
        ...prevState,
        addFeedbackResponse: action?.payload,
      };
    },
    setSelectedFeedback: (prevState, action) => {
      return {
        ...prevState,
        selectedFeedback: action?.payload,
      };
    },
  },
});

export const fetchFeedbacksCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_FEEDBACKS',
);
export const fetchFeedbacksCreatorTypeName = fetchFeedbacksCreator().type;

export const updateFeedbacksCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/UPDATE_FEEDBACKS',
);
export const updateFeedbacksCreatorTypeName = updateFeedbacksCreator({}).type;

export const deleteFeedbacksCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/DELETE_FEEDBACKS',
);
export const deleteFeedbacksCreatorTypeName = deleteFeedbacksCreator(null).type;

export const fetchFeedbacksSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_FEEDBACKS_SUGGESTIONS',
);
export const fetchFeedbacksSuggestionsCreatorTypeName =
  fetchFeedbacksSuggestionsCreator(null).type;

export const fetchFeedbacksHistoryCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_FEEDBACKS_HISTORY',
);
export const fetchFeedbacksHistoryCreatorTypeName =
  fetchFeedbacksHistoryCreator(null).type;

const feedbacksStateHandler = createSlice({
  name: 'FEEDBACKS',
  initialState: feedbackInitialState,
  reducers: {
    setFeedbacksLoading: (prevState, action) => {
      return {
        ...prevState,
        loading: action?.payload,
      };
    },
    setFeedbacksSuccess: (prevState, action) => {
      return {
        ...prevState,
        success: action?.payload,
      };
    },
    setFeedbacks: (prevState, action) => {
      return {
        ...prevState,
        data: action?.payload,
      };
    },

    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setFeedbacksSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapShot: action?.payload,
      };
    },
    setFeedbacksError: (prevState, action) => {
      return {
        ...prevState,
        error: true,
        errorMessage: action?.payload,
      };
    },
    clearFeedbacksError: prevState => {
      return {
        ...prevState,
        error: false,
        errorMessage: '',
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
  },
});

export const feedbacksStateActions = feedbacksStateHandler.actions;
export const feedbacksStateReducer = feedbacksStateHandler.reducer;
export const feedbackDataGridStateActions = feedbackDatagridStateHandler.actions;
export const feedbackDataGridReducer = feedbackDatagridStateHandler.reducer;
