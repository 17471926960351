import {strings} from '@app/common/strings';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {useStyles} from '../style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const OrderSupplyPod = () => {
  const classes = useStyles();
  return (
    <>
      <Typography
        sx={{
          marginBottom: '16px !important',
          fontSize: '15.7px !important',
        }}
        className={`${classes.RfHeading}  `}
        variant="h6">
        {strings.zho.hoAdmin.dashboard.modalPopup.supplyPod}
      </Typography>
      <Table
        sx={{
          borderCollapse: 'separate',
          marginBottom: '16px !important',
        }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding} `}>
              {strings.zho.hoAdmin.dashboard.tableHeading.InvoiceNo}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.product}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.podNo}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.invoiceQty}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.podQty}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.podCopy}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                borderLeft: '1px solid #e2e2e2 !important',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
              className={`${classes.priDefaultFontSize} ${classes.supplyPodTable} `}>
              INV0123
            </TableCell>
            <TableCell className={classes.supplyPodTable}>
              <Stack
                direction="row"
                gap={0.5}
                justifyContent="flex-start"
                alignItems="center">
                <Typography className={`${classes.priDefaultFontSize}  `}>
                  ZADY
                </Typography>
                <InfoOutlinedIcon
                  sx={{color: '#da3131', height: '15px', width: '15px'}}
                />
              </Stack>
            </TableCell>
            <TableCell
              className={`${classes.priDefaultFontSize} ${classes.supplyPodTable} `}>
              6455666
            </TableCell>
            <TableCell
              className={`${classes.priDefaultFontSize} ${classes.supplyPodTable} `}>
              10
            </TableCell>
            <TableCell
              className={`${classes.priDefaultFontSize} ${classes.noWrap} ${classes.supplyPodTable} `}>
              100
            </TableCell>
            <TableCell
              sx={{
                color: '#322b7c !important',
                fontSize: '12.7px',
                borderRight: '1px solid #e2e2e2 !important',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
              className={classes.supplyPodTable}>
              Mankind_bil7_billcopy009.jpeg
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default OrderSupplyPod;
