import {createAction, createSlice} from '@reduxjs/toolkit';

export const cdcCandidatesInitialState = {
  data: [],
  totalCount: 0,
  snapShot: [],
  count: 0,
  loading: false,
  success: false,
  error: false,
  history: [],
  historyLoading: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
  pageNo: 0,
  rowsPerPage: 20,
  cdcCandidatesPopupData: [],
  preExcelData: [],
  finalPreExcelData: [],
  uploadedExcel: false,
  uploadedExcelLoading: false,
  uploadedExcelPath: '',
  cdcEmailTemplateData: {},
  sendCdcEmailSuccess: false,
};

export const fetchCdcCandidatesCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_CDC_CANDIDATES',
);

export const fetchCdcCandidatesCreatorTypeName =
  fetchCdcCandidatesCreator().type;

export const fetchCdcCandidatesFilterCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_CDC_CANDIDATES_FILTER',
);
export const fetchCdcCandidatesFilterCreatorTypeName =
  fetchCdcCandidatesFilterCreator().type;

export const fetchCdcCandidatesSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_CDC_CANDIDATES_SUGGESTIONS',
);
export const fetchCdcCandidatesSuggestionsCreatorTypeName =
  fetchCdcCandidatesSuggestionsCreator(null).type;

export const sendCdcCandidatesNotificationCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/SEND_CDC_CANDIDATES_NOTIFICATION',
);
export const sendCdcCandidatesNotificationCreatorTypeName =
  sendCdcCandidatesNotificationCreator(null).type;

export const fetchCdcCandidatesHistoryCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_CDC_CANDIDATES_HISTORY',
);
export const fetchCdcCandidatesHistoryCreatorTypeName =
  fetchCdcCandidatesHistoryCreator(null).type;

export const uploadCDCCandidatespopUpTableData = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_CDC_CANDIDATES_POPUP',
);
export const uploadCDCCandidatespopUpTableDataType = createAction<any>(
  uploadCDCCandidatespopUpTableData(null).type,
);
export const fetchCdcEmailTemplateDataCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/SEND_EMAIL_TEMPLATE',
);
export const fetchCdcEmailTemplateDataCreatorTypeName =
  fetchCdcEmailTemplateDataCreator(null).type;

export const fetchSendEmailCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/SEND_EMAIL',
);
export const fetchSendEmailCreatorTypeName = fetchSendEmailCreator(null).type;

const cdcCandidatesStateHandler = createSlice({
  name: 'CDC_CANDIDATES',
  initialState: cdcCandidatesInitialState,
  reducers: {
    setCdcCandidatesLoading: (prevState, action) => {
      return {
        ...prevState,
        loading: action?.payload,
      };
    },
    setCdcCandidatesSuccess: (prevState, action) => {
      return {
        ...prevState,
        success: action?.payload,
      };
    },
    setCdcCandidates: (prevState, action) => {
      return {
        ...prevState,
        data: action?.payload,
      };
    },
    setCdcCandidatesLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setCdcCandidatesSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapShot: action?.payload,
      };
    },
    setCdcCandidatesError: (prevState, action) => {
      return {
        ...prevState,
        error: true,
        errorMessage: action?.payload,
      };
    },
    clearCdcCandidatesError: prevState => {
      return {
        ...prevState,
        error: false,
        errorMessage: '',
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setCDCCandidatesPopUpData: (prevState, action) => {
      return {
        ...prevState,
        cdcCandidatesPopupData: action?.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
    setPreExcelData: (prevState, action) => {
      return {
        ...prevState,
        preExcelData: action?.payload,
      };
    },
    resetPreExcelData: (prevState, action) => {
      return {
        ...prevState,
        preExcelData: [],
      };
    },
    setFinalPreExcelData: (prevState, action) => {
      return {
        ...prevState,
        finalPreExcelData: action?.payload,
      };
    },
    resetFinalPreExcelData: (prevState, action) => {
      return {
        ...prevState,
        finalPreExcelData: [],
      };
    },
    setUploadedExcel: (prevState, action) => {
      return {
        ...prevState,
        uploadedExcel: action?.payload,
      };
    },
    setUploadedExcelLoading: (prevState, action) => {
      return {
        ...prevState,
        uploadedExcelLoading: action?.payload,
      };
    },
    setUploadedExcelPath: (prevState, action) => {
      return {
        ...prevState,
        uploadedExcelPath: action?.payload,
      };
    },
    resetUploadedExcel: prevState => {
      return {
        ...prevState,
        uploadedExcel: false,
        uploadedExcelPath: '',
      };
    },
    setCdcEmailTemplateData: (prevState, action) => {
      return {
        ...prevState,
        cdcEmailTemplateData: action?.payload,
      };
    },
    resetCdcEmailTemplateData: prevState => {
      return {
        ...prevState,
        cdcEmailTemplateData: {},
      };
    },
    setSendCdcEmailSuccess: (prevState, action) => {
      return {
        ...prevState,
        sendCdcEmailSuccess: action?.payload,
      };
    },
  },
});

export const cdcCandidatesStateActions = cdcCandidatesStateHandler.actions;
export const cdcCandidatesStateReducer = cdcCandidatesStateHandler.reducer;
