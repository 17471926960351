import { createSlice, createAction } from '@reduxjs/toolkit';

export const appState = {
  userInfo: {},
  isEditAccessAvailable: false,
  isBulkAccessAvailable: false,
  appLoading: false,
  userInfoError: false,
  userInfoErrorMessage: '',
  showCannotAccessModal: false,
  showCannotAccessModalLoading: false,
  cannotAccessModalUsers: [],
  notifications: [],
  appSettings: [],
  excelDataLoading: false,
  excel: {
    data: [],
    headers: null,
  },
  divisionDropDown: [],
  stateDropDown: [],
  stateIds: [],
  divisionIds: [],
  rolesDropDown: [],
  staffholdconfigmasterdetails: [],
};

export const fetchUserInfoCreator = createAction(
  'ROUTE_HANDLER/FETCH_USER_INFO',
);
export const fetchAppSettingsCreator = createAction(
  'ROUTE_HANDLER/FETCH_USER_INFO',
);
export const fetchUserInfoCreatorTypeName = fetchUserInfoCreator().type;
export const fetchUserAppSettingsCreatorTypeName =
  fetchAppSettingsCreator().type;

export const fetchCannotAccessModalUsersCreator = createAction(
  'ROUTE_HANDLER/FETCH_CANNOT_ACCESS_MODAL_USERS',
);
export const fetchCannotAccessModalUsersCreatorTypeName =
  fetchCannotAccessModalUsersCreator().type;

export const fetchNotificationsCreator = createAction(
  'ROUTE_HANDLER/FETCH_NOTIFICATION',
);
export const fetchNotificationsCreatorTypeName =
  fetchNotificationsCreator().type;

export const markNotificationReadCreator = createAction<any>(
  'MARK_NOTIFICATION_READ',
);
export const markNotificationTypeName = markNotificationReadCreator({}).type;

export const fetchStateAdminDropdownCreator = createAction(
  'APP/FETCH_STATE_DROPDOWN',
);
export const fetchStateAdminDropdownCreatorTypeName =
  fetchStateAdminDropdownCreator().type;

export const fetchDivisionAdminDropdownCreator = createAction(
  'APP/FETCH_DIVISION_DROPDOWN',
);
export const fetchDivisionAdminDropdownCreatorTypeName =
  fetchDivisionAdminDropdownCreator().type;

export const fetchRolesDropDownDataCreator = createAction(
  'APP/FETCH_ROLES_DROPDOWN',
);

export const fetchRolesDropDownDataCreatorTypeName =
  fetchRolesDropDownDataCreator().type;

export const fetchStaffHoldConfigMasterDetailsCreator = createAction(
  'APP/FETCH_STAFFHOLD_CONFIG_MASTERDETAIL',
);
export const fetchStaffHoldConfigMasterDetailsCreatorTypeName =
  fetchStaffHoldConfigMasterDetailsCreator().type;

export const appSlice = createSlice({
  name: 'app/appSlice',
  initialState: appState,
  reducers: {
    setUserInfo: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        userInfo: payload,
      };
    },
    setAppLoading: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        appLoading: payload,
      };
    },
    setUserInfoError: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        userInfoError: payload,
      };
    },
    setUserInfoErrorMessage: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        userInfoErrorMessage: payload,
      };
    },
    clearError: prevState => {
      return {
        ...prevState,
        userInfoError: false,
        userInfoErrorMessage: '',
      };
    },
    setShowCannotAccessModal: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        showCannotAccessModal: payload,
      };
    },
    setShowCannotAccessModalLoading: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        showCannotAccessModalLoading: payload,
      };
    },
    setCannotAccessModalUsers: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        cannotAccessModalUsers: payload,
      };
    },
    setIsEditAccessAvailable: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        isEditAccessAvailable: payload,
      };
    },
    setIsBulkAccessAvailable: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isBulkAccessAvailable: payload,
      };
    },
    setNotifications: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        notifications: payload,
      };
    },
    updateNotificationReadStatus: (prevState: any, action) => {
      const { payload } = action;
      return {
        ...prevState,
        notifications: prevState.notifications.map(
          (notification: { id: any }) => {
            if (notification?.id === payload) {
              return { ...notification, isRead: true };
            }
            return notification;
          },
        ),
      };
    },
    setAppSettings: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        appSettings: payload,
      };
    },
    setExcelDataLoading: (prevState, action) => {
      return {
        ...prevState,
        excelDataLoading: action?.payload,
      };
    },

    setExcelData: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        excel: {
          data: payload.data,
          headers: payload.headers,
        },
      };
    },
    setDivisionDropDown: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        divisionDropDown: payload.divisionDropdownData,
        divisionIds: payload.divisionIds,
      };
    },
    setStateDropDown: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        stateDropDown: payload.stateDropdownData,
        stateIds: payload.stateIds,
      };
    },
    setRolesDropDownData: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        rolesDropDown: payload,
      };
    },
    setStaffHoldConfigMasterDetails: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        staffholdconfigmasterdetails: payload,
      };
    },
  },
});

export const appSliceReducer = appSlice.reducer;
export const appSliceActions = appSlice.actions;
