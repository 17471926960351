import {strings} from '@app/common/strings';
import {RightSidebar} from '@app/components/right-sidebar';
import {createSlice} from '@reduxjs/toolkit';

const Names = [
  strings.zho.fcAdmin.dashboard.requestForm,
  strings.zho.fcAdmin.dashboard.summary,
  strings.zho.fcAdmin.dashboard.updateTotalTradeDiscount,
  strings.zho.fcAdmin.dashboard.manageUserRole,
  strings.zho.fcAdmin.dashboard.accessControl,
];
const initialStateValue = {
  names: [],
  activeTabIndex: 0,
  screenNo: 0,
  RightSidebar: true,
  statusToggleNo: 1,
  stateSelect: null,
  rejectRemarks: '',
  stateHqMulti: [],
  stateMrMulti: [],
  subTabSwitch: 1,
  requestPayload: {
    division: [],
    currentStaffPositionId: 0,
    pageSize: 10,
    pageNumber: 0,
    requestFormNo: '',
    mrStaffIds: [],
    state: '',
    hq: [],
    startDate: '',
    endDate: '',
  },
};

export const TabNames = createSlice({
  name: 'subTabName',
  initialState: {value: initialStateValue},
  reducers: {
    SwitchingName: (state, action) => {
      state.value.names = action.payload;
    },
    SwitchingNo: (state, action) => {
      state.value.activeTabIndex = action.payload;
    },
    setScreenNo: (state, action) => {
      state.value.screenNo = action.payload;
    },
    setRightSidebarToggle: (state, action) => {
      if (action.payload === false) {
        state.value.RightSidebar = false;
      } else {
        state.value.RightSidebar = !state.value.RightSidebar;
      }
    },
    setStatusToggle: (state, action) => {
      state.value.statusToggleNo = action.payload;
    },
    setStateSelect: (state, action) => {
      state.value.stateSelect = action.payload;
    },
    setHqMulti: (state, action) => {
      state.value.stateHqMulti = action.payload;
    },
    setStateMrMulti: (state, action) => {
      state.value.stateMrMulti = action.payload;
    },
    setSubTabSwitch: (state, action) => {
      state.value.subTabSwitch = action.payload;
    },
    updateRequestPayload: (state, action) => {
      state.value.requestPayload = {
        ...state.value.requestPayload,
        ...action.payload,
      };
    },
    setRejectValue: (state, action) => {
      state.value.rejectRemarks = action.payload;
    },

    clearRequestPayload: state => {
      const {pageSize, pageNumber} = state.value.requestPayload;

      state.value.requestPayload = {
        currentStaffPositionId: 0,
        pageSize: pageSize, // retain the current pageSize
        pageNumber: pageNumber, // retain the current pageNumber
        requestFormNo: '',
        mrStaffIds: [],
        state: '',
        hq: [],
        startDate: '',
        endDate: '',
        division: [],
      };
    },
  },
});

export const {
  SwitchingName,
  SwitchingNo,
  setScreenNo,
  setRightSidebarToggle,
  setStatusToggle,
  setStateSelect,
  setHqMulti,
  setStateMrMulti,
  updateRequestPayload,
  clearRequestPayload,
  setSubTabSwitch,
  setRejectValue,
} = TabNames.actions;

export const TabNamesReducer = TabNames.reducer;
