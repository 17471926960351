import theme from "@app/themes";
import { gridStyles } from "@app/screens/zho/components/widgets/DataGrid/styles";
import { borderRadius, padding } from "@mui/system";

export const updateTotalTradeDiscountStyles={
  wrapper: {
    maxHeight: "calc(100vh - 213px)",
    overflowY: 'auto'
  },
  goButton: {
    backgroundColor: '#322b7c',
    borderRadius: '5px',
    width: 'auto',
    color: '#fff',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12.7,
    marginTop: '0px',
    minWidth: '40px',
    height: '35px',
    textTransform:"capitalize",
    marginLeft: '2px',
    '&:hover': {
      background: '#322b7c',
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    backgroundColor: 'white',
    padding : '20px'
  },
  section:{
    marginBottom: '15px',
  },
  textField: {
    border: 'transparent',
    background: '#f2f2f2',
    color: '#322B7C',
    borderRadius: '5px',
    width: '100%',
    padding: '0px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: "12.7px",
    "& input::placeholder": {
      fontSize: "12.7px"
    },
    "& input": {
      fontSize: "12.7px",
    }
  },
  padding5: {
    padding: '5px',
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiIconButton-root ': {
      background: 'transparent',
      gap: 4,
    },
    '& .MuiSvgIcon-root': {
      width: 15,
      color: '#332b77',
    },
  },
  productListingDropDown: {
    minWidth: '260px',
    maxWidth: '260px',
    fontSize:'12.7px',
    color: theme.colors.grey[200],
  },
  font14: {
    fontSize: '14px',
  },
  font12: {
    fontSize: '12px',
  },
  dropdownBgWhite: {
    backgroundColor: '#fff',
  },
  tableCell:{
    border:'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize:12.7,
    color:theme.colors.grey[200]
  },
  tableHeader:{
    fontWeight: 600,
    color: theme.colors.blue[1400],
  },
  actionCell:{
    paddingTop:'0px',
    paddingBottom: '0px',
  },
  centeredCell:{
    textAlign:"center"
  },
  cellInput:{
    color: theme.colors.blue[1400],
    borderRadius:'6px',
    backgroundColor:"#322B7C08",
    borderColor: theme.colors.primary,
    width: '80px',
    fontSize:"12.7px",
    '& .MuiInputBase-input': {
      padding: '16px 10px !important',
    },
  },
  disabledCellInput:{
    backgroundColor:theme.colors.grey[1300],
  },
  cellPropInput:{
    width: '80px',
    height: '35px',
    fontSize: '12.7px',
  },
  dateInput:{
    backgroundColor:"#322B7C08",
    borderRadius:'6px',
    color:theme.colors.grey[200],
    width: 120,
    maxWidth: 140,
    '& .MuiInputBase-input': {
      fontSize: 12.7,
    },
    '& .MuiInputLabel-root': {
      fontSize: 12.7,
    },
    '& .MuiSvgIcon-root': {
      fill: '#322b7c',
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '0px',
    },
    '& .MuiIconButton-edgeEnd': {
      paddingLeft: '0px',
      marginRight: '-15px'
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: `${theme.colors.primary} !important`
    // }
  },
  actionButton:{
    width: 150,
    height: 40,
    borderRadius: 1,
    textTransform: 'none',
  },
  uploadFileSection:{
    paddingTop: 3,
    marginTop:3,
    backgroundImage: 'linear-gradient(to right, rgb(148, 148, 148) 33% , rgba(255,255,255,0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '25px 1.5px',
    backgroundRepeat: 'repeat-x',
    // borderTop: '4px dashed #949494',
  },
  downloadTemplateSection:{
    display: 'flex',
    gap:1,
    marginTop: "15px",
    marginBottom: 5
  },
  bulkUpdloadText:{
    fontWeight: 400,
    fontSize: "12.7px",
    color: theme.colors.grey[200]
  },
  downloadTemplateText:{
    color: '#3273F2',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '12.7px'
  },
  modalSection:{
    padding: '20px'
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
  },
  modalHeader:{
    borderBottom: '1px solid #E3E3E3',
    color: theme.colors.grey[200],
    fontSize:15.7
  },
  modalFooter:{
    borderTop: '1px solid #E3E3E3',
    display: 'flex',
    gap: 1
  },
  dragFileBox:{
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #D5D5D5',
    borderRadius: '8px',
    height: '150px'
  },
  supportedFormatText:{
    color: '#9D9D9D',
    fontWeight: 400,
    fontSize: '12.7px',
    paddingBottom: '10px',
    paddingTop: '10px',
    borderBottom: '1px solid #E3E3E3'
  },
  dragFileText:{
    display: 'flex',
    gap:1,
  },
  hideSpinButton: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  fileDetailsWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "15px",
    backgroundColor: '#EAEAEA8C',
    borderRadius: '8px',
    marginTop: "10px",

  },
  fileDetails:{
    borderLeft: '0.46px solid #B8B8B8',
    paddingLeft: '5px',
    marginLeft: '5px'
  },
  fileName:{
    fontWeight: 500,
    color: '#484848',
    fontSize: '12.7px',
    lineHeight: '15px'
  },
  fileSize:{
    fontWeight: 400,
    color: '#707070',
    fontSize: '12.7px',
    lineHeight: '15px'
  },
  fileUploadDate:{
    fontSize: "12.7px",
    fontWeight: 400,
    color: theme.colors.primary,
    marginTop: '10px'
  },
  listingInput:{
    paddingLeft: '0px !important',
    textAlign: 'center',
    '& .MuiInputBase-input': {
      padding: '0px !important',
      textAlign: 'center',
    },
  },
  listingTableHeader:{
     whiteSpace: 'normal', wordWrap: 'break-word',
     

     fontFamily: 'Poppins',
     fontSize: 12.7,
     fontStyle: 'normal',
     lineHeight: '21.3px',
     letterSpacing: 0,
     textAlign: 'left',
     color: '#322b7c',
     '& .MuiDataGrid-columnSeparator': {
       display: 'none',
     },
     outline: 'none !important',
  },
  customTooltipIcon:{
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '100%',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '-25px',
    right: '-25px',
  },

  rejectionButtonRej: {
    backgroundColor: '#da3131',
    fontSize:"12.7px",
    border: 'none !important',
    textTransform:"capitalize",
    '&:hover': {
      background: '#DA3131E6',
    },
  },
  errorTable:{
    border:"none",
    '& .MuiDataGrid-columnHeaderTitle':{
      fontSize:"12.7px",
      color:theme.colors.primary,
      fontWeight:600
    },
    '& .MuiDataGrid-cellContent':{
      fontSize:"12.7px !important",
      color:theme.colors.grey[200]
    },
    ...gridStyles
  },
  selectedProductName:{
    padding:"10px",
    borderRadius:"4px",
    boxShadow: "0px 2px 17.3px 0px #0000000F"
  },
  requiredHeader: {
    "::after": {
      color: "#da3131",
      content: '"*"'
    }
  }
};
