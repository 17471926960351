import axios from 'axios';
import env from '@app/env.json';
import {getAccessToken} from '@app/helper/login';
import { generateUUID } from '@app/utils/applicationInsight';

export const MarkNotification = (NotificationId: any) => {
    void (async () => {
      try {
        const accessToken = await getAccessToken();
        axios
          .put(`${env.API_HOST}notification/markasread`, [NotificationId], {
            headers: {Authorization: `Bearer ${accessToken}`, CorrelationId: generateUUID(),          },
          })

          .catch(err => {});
      } catch (error) {}
    })();
  };