import {createSelector} from '@reduxjs/toolkit';

const getState = (state: any) => state.doctorApprovalMaster;

const getMasterDataSelector = createSelector([getState], data => data?.masterData);
const getDoctorApprovalsSelector = createSelector([getState], data => data?.approvalList);
const getLoaderSelector = createSelector([getState], data => data?.loader);
const getDoctorInfoSelector = createSelector([getState], data => data?.doctorInfo);
const getProductListSelector = createSelector([getState], data => data?.products);
const getChemistsListSelector = createSelector([getState], data => data?.chemists);
const approveAndRejectionSelector = createSelector([getState], data => data?.approveAndRejectRes);
const downloadFileSelector = createSelector([getState], data => data);
const getApproveRequestsSelector = createSelector([getState], data => data?.approveRequests);
const searchDoctorListSelector = createSelector([getState], data => data?.doctorList);
const detailReportsSelector = createSelector([getState], data => data?.detailReports);
const getPatchesSelector = createSelector([getState], data => data?.patches);
const getAreasSelector = createSelector([getState], data => data?.allAreas);
const getWorkingDaySelector = createSelector([getState], data => data?.allAreas);
const approveDoc = createSelector([getState], data => data?.approve);
const rejectDoc = createSelector([getState], data => data?.reject);
const doctorInfoError = createSelector([getState], data => data?.doctorInfoError);
const docRequestStateSelector = createSelector([getState], data => data?.docRequestState);
const getDoctorRequestsSelector = createSelector([getState], data => data?.doctorRequests);
const getChemistRequestsSelector = createSelector([getState], data => data?.chemistRequests);
const getDoctorExistsRequestsSelector = createSelector([getState], data => data?.doctorExistsData);
const getPreviewDoctorSelector = createSelector([getState], data => data?.previewDoctor);
const getDoctorRemoveLoadingSelector = createSelector([getState], data => data?.doctorRemoveLoading);
const getDoctorRemoveDoneSelector = createSelector([getState], data => data?.doctorRemoveDone);
const getChemistRemoveLoadingSelector = createSelector([getState], data => data?.chemistRemoveLoading);
const getChemistRemoveDoneSelector = createSelector([getState], data => data?.chemistRemoveDone);


export const doctorApprovalSelector = {
  getPatches: () => getPatchesSelector,
  getAllWorkingDay: () => getWorkingDaySelector,
  getAreas: () => getAreasSelector,
  getMasterData: () => getMasterDataSelector,
  getLoader: () => getLoaderSelector,
  getDoctorInfo: () => getDoctorInfoSelector,
  getProductList: () => getProductListSelector,
  getChemistList: () => getChemistsListSelector,
  getApproveAndReject: () => approveAndRejectionSelector,
  getDoctorApprovals: () => getDoctorApprovalsSelector,
  downloadFile: () => downloadFileSelector,
  getApproveRequests: () => getApproveRequestsSelector,
  getDocRequestStateSelector: () => docRequestStateSelector,
  searchDoctorRequests: () => searchDoctorListSelector,
  detailReports: () => detailReportsSelector,
  docApproveState: () => approveDoc,
  docRejectState: () => rejectDoc,
  getInfoError: () => doctorInfoError,
  getDoctorRequests:()=> getDoctorRequestsSelector,
  getChemistRequests:()=> getChemistRequestsSelector,
  getDoctorExistsRequests: ()=> getDoctorExistsRequestsSelector,
  getPreviewDoctor: () => getPreviewDoctorSelector,
  getDoctorRemoveLoading: () => getDoctorRemoveLoadingSelector,
  getDoctorRemoveDone: () => getDoctorRemoveDoneSelector,
  getChemistRemoveLoading: () => getChemistRemoveLoadingSelector,
  getChemistRemoveDone: () => getChemistRemoveDoneSelector
};
