import {createSelector} from '@reduxjs/toolkit';
export const cdcCandidatesStateSelector = {
  getCdcCandidates: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.data],
      data => data,
    );
  },
  getCdcCandidatesState: () => {
    return createSelector([(state: any) => state.cdcCandidates], data => data);
  },
  getCdcCandidatesLoading: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.loading],
      loading => loading,
    );
  },
  getCDCCandidatesPageNo: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.pageNo],
      cdcCandidates => cdcCandidates,
    );
  },
  getCDCCandidatesPopUpData: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.cdcCandidatesPopupData],
      cdcCandidates => cdcCandidates,
    );
  },
  getCDCCandidatesPerPage: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.rowsPerPage],
      cdcCandidates => cdcCandidates,
    );
  },
  getCDCCandidatesTotalCount: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.totalCount],
      cdcCandidates => cdcCandidates,
    );
  },
  getCdcCandidatesError: () => {
    return createSelector([(state: any) => state.cdcCandidates], error => ({
      error: error?.cdcCandidatesError,
      message: error?.cdcCandidatesMessage,
    }));
  },
  getPreExceldata: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.preExcelData],
      cdcCandidates => cdcCandidates,
    );
  },
  getFinalPreExceldata: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.finalPreExcelData],
      cdcCandidates => cdcCandidates,
    );
  },
  getUploadedExcel: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.uploadedExcel],
      cdcCandidates => cdcCandidates,
    );
  },
  getUploadedExcelLoading: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.uploadedExcelLoading],
      cdcCandidates => cdcCandidates,
    );
  },
  getUploadedExcelPath: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.uploadedExcelPath],
      cdcCandidates => cdcCandidates,
    );
  },
  getCdcEmailTemplateData: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.cdcEmailTemplateData],
      cdcCandidates => cdcCandidates,
    );
  },
  getSendCdcEmailSuccess: () => {
    return createSelector(
      [(state: any) => state.cdcCandidates.sendCdcEmailSuccess],
      cdcCandidates => cdcCandidates,
    );
  },
};
