import { useEffect, useState, useCallback } from 'react';
import {
  debounce,
} from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { selectedEmployeeStateSelector } from '@app/screens/employee-specific-utilities/pages/selected-employees/redux/selectors';
import {
  fetchSelectedEmployeesCreator,
  fetchSelectedEmployeesSuggestionsCreator,
  selectedEmployeesStateActions,
} from '@app/screens/employee-specific-utilities/pages/selected-employees/redux/slice';
import { SearchBar } from '../search-bar';


type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarSelectedEmployees = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [type, setType] = useState<EmployeeType>('ALL');

  const handleTypeChange = useCallback(function(value: EmployeeType){
    setType(value)
    dispatch(selectedEmployeesStateActions.setSearchText(""));
    dispatch(selectedEmployeesStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}));
  },[]);

  const selectedEmployeeAutosuggestSearchText = useSelector(
    selectedEmployeeStateSelector.getSelectedEmployeesSearchText(),
    shallowEqual,
  );
  const selectedEmployeeAutosuggestLoading = useSelector(
    selectedEmployeeStateSelector.getSelectedEmployeesSuggestionsLoading(),
    shallowEqual,
  );
  const selectedEmployeeAutosuggestData = useSelector(
    selectedEmployeeStateSelector.getSelectedEmployeesAutoSuggestData(),
    shallowEqual,
  );
  const selectedEmployeeInAutoSuggest = useSelector(
    selectedEmployeeStateSelector.getSelectedEmployeesAutoSuggestValue(),
    shallowEqual,
  );
  const { selectedEmployeesSnapShot } = useSelector(
    selectedEmployeeStateSelector.getSelectedEmployeesState(),
    shallowEqual,
  );

  const handleSearch = useCallback(
    debounce((searchText: string) => dispatch(fetchSelectedEmployeesSuggestionsCreator(searchText)), 600),
    []
  )

  const autocompleteOnChangeHandler = useCallback(function(value:any){
    if (!value) {
      dispatch(fetchSelectedEmployeesCreator());
    }

    dispatch(
      selectedEmployeesStateActions.setSelectedAutoSuggest(
        value,
      ),
    );
  },[])

  const textBoxOnChangeHandler = useCallback(function(event:any){
    dispatch(
      selectedEmployeesStateActions.setSearchText(
        event.target.value,
      ),
    );
  },[])

  useEffect(()=>{
    dispatch(selectedEmployeesStateActions.setSearchText(""));
    dispatch(selectedEmployeesStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}));
  },[])
  useEffect(() => {
    selectedEmployeeAutosuggestSearchText.length > 0 && handleSearch(selectedEmployeeAutosuggestSearchText);
  }, [selectedEmployeeAutosuggestSearchText]);

  useEffect(() => {
    selectedEmployeeInAutoSuggest &&
      Object.keys(selectedEmployeeInAutoSuggest).length > 0 &&
      dispatch(
        selectedEmployeesStateActions.setSelectedEmployees(
          selectedEmployeesSnapShot?.length>0 && selectedEmployeesSnapShot.filter(
            (emp: any) => emp?.name === selectedEmployeeInAutoSuggest?.name
          )
        )
      )
  }, [selectedEmployeeInAutoSuggest]);

  useEffect(() => {
    if (type !== "ALL") {
      const newSnap = selectedEmployeesSnapShot?.length>0 && selectedEmployeesSnapShot.filter(
        (emp: any) => emp?.role === type
      )
      dispatch(
        selectedEmployeesStateActions.setSelectedEmployees(newSnap)
      )
    } else {
      dispatch(selectedEmployeesStateActions.setSelectedEmployees(selectedEmployeesSnapShot))
    }
  }, [type])

  return (
    <SearchBar 
    open={open} 
    setOpen={setOpen} 
    autocompleteOnChangeHandler={autocompleteOnChangeHandler}
    textBoxOnChangeHandler={textBoxOnChangeHandler}
    autoSuggestData={selectedEmployeeAutosuggestData}
    selectedAutoSuggestValue={selectedEmployeeInAutoSuggest}
    suggestionsLoading={selectedEmployeeAutosuggestLoading}
    handleTypeChange={handleTypeChange}     
    />
  );
};
