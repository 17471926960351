import {CustomModal} from '@app/components/widgets/CustomModal';
import {TextField, Typography} from '@mui/material';
import {useState} from 'react';
import CustomTitle from './CustomTitle';
import {useStyles} from '../view-doctor-remove-request/styles';
import {FooterButtonTP} from './footerButtonTP';

interface RejectWithReasonModalTPProps {
  viewRejectModal: boolean;
  closeModal: () => void;
  reasonText?: (reason: string) => void;
}

export const RejectWithReasonModalTP: React.FC<
  RejectWithReasonModalTPProps
> = ({viewRejectModal, closeModal, reasonText}) => {
  const styleClass = useStyles();
  const [rejection, setRejection] = useState<string>('');

  const isValidRejectionLength = () => {
    return rejection.replace(/[^A-Za-z]/g, '').length >= 5;
  };

  const closeRejectModal = () => {
    if (reasonText) {
      reasonText(rejection);
    }
    setRejection('');
  };

  const rejectTextBox = () => {
    return (
      <div style={{height: 200}}>
        <TextField
          data-testId="reject-text"
          multiline
          rows={6}
          value={rejection}
          placeholder="Reject Message"
          className={styleClass.rejectModalTextField}
          onChange={(val: any) => {
            setRejection(
              val.target.value.trimStart().replace(/[^A-Za-z ]/g, ''),
            );
          }}
          inputProps={{
            maxLength: 100,
          }}
        />

        {rejection.length > 0 && !isValidRejectionLength() && (
          <div style={{marginTop: '10px'}}>
            <Typography color="error" variant="body2">
              (At least 5 characters are mandatory.)
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <CustomModal
        maxWidth={'xs'}
        open={viewRejectModal}
        onClose={closeModal}
        header={
          <CustomTitle
            title={'Reason for rejecting the request'}
            variant={'h5'}
          />
        }
        children={rejectTextBox()}
        footer={
          <FooterButtonTP
            firstLabel={'Go Back'}
            secondLabel={'Submit'}
            firstOnClick={closeModal}
            secondOnClick={() => {
              closeRejectModal();
              closeModal();
            }}
            disabledsecondBtn={!isValidRejectionLength()}
          />
        }
      />
    </div>
  );
};
