import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {formatFilters} from '@app/utils/util';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  fetchRecentUpdatesCreatorTypeName,
  fetchRecentUpdatesHistoryCreatorTypeName,
  fetchRecentUpdatesSuggestionsCreatorTypeName,
  recentUpdatesStateActions,
} from './slice';

import {recentUpdatesStateSelector} from './selector';

/**
 * Recent updates data watcher
 */
export function* fetchRecentUpdatesDataWatcher() {
  yield takeLatest(
    fetchRecentUpdatesCreatorTypeName,
    fetchRecentUpdatesDataWatcherWorker,
  );
}

/**
 * Recent Updates data Worker
 */
export function* fetchRecentUpdatesDataWatcherWorker(): any {
  try {
    yield put(recentUpdatesStateActions.setRecentUpdatesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.recentUpdates.getAll}`;
    let pageNo = yield select(
      recentUpdatesStateSelector.getRecentUpdatesPageNo(),
    );
    let perPage = yield select(
      recentUpdatesStateSelector.getRecentUpdatesPerPage(),
    );
    const response = yield call(
      NetworkService.post,
      url,
      {pageNo: pageNo + 1, rowPerPage: perPage},
      {},
    );
    // const response = yield call(NetworkService.post, url, {'pageNo':1,'rowPerPage':50}, {});
    yield put(
      recentUpdatesStateActions.setRecentUpdates(
        response.data.recentupdatesList,
      ),
    );
    yield put(
      recentUpdatesStateActions.setRecentUpdatesSnapshot(
        response.data.recentupdatesList,
      ),
    );
    yield put(
      recentUpdatesStateActions.setTotalCount(response.data?.totalCount),
    );
    const filter = yield select(filterSelector.getFilterOptions());
    yield put(recentUpdatesStateActions.setRecentUpdatesLoading(false));
    let requestExcel = {
      ...filter,
      pageNo: 1,
      rowPerPage: response.data?.totalCount,
    };
    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );
    yield put(
      appSliceActions.setExcelData({
        data: responseExcel?.data.recentupdatesList,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'employeeName'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managersName'},
          {label: 'Updates', key: 'updates'},
        ],
      }),
    );
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        ...filters,
        Division: {
          completeApplied: false,
          collapsed: true,
          options: formatFilters(response.data.recentupdatesList, 'division'),
        },
        States: {
          completeApplied: false,
          collapsed: true,
          options: formatFilters(response.data.recentupdatesList, 'state'),
        },
      }),
    );
    yield put(recentUpdatesStateActions.setRecentUpdatesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching recent updates!',
      ToastType.ERROR,
    );
    yield put(recentUpdatesStateActions.setRecentUpdatesError(error));
    setTimeout(function* () {
      yield put(recentUpdatesStateActions.clearRecentUpdatesError());
    }, 5000);
  } finally {
    yield put(recentUpdatesStateActions.setRecentUpdatesLoading(false));
  }
}

/**
 * Recent updates suggestions watcher
 */
export function* fetchRecentUpdatesSuggestionsWatcher() {
  yield takeLatest(
    fetchRecentUpdatesSuggestionsCreatorTypeName,
    fetchRecentUpdatesSuggestionsWatcherWorker,
  );
}

/**
 * Recent Updates suggestions Worker
 */
export function* fetchRecentUpdatesSuggestionsWatcherWorker(action: any): any {
  try {
    yield put(recentUpdatesStateActions.setAutoSuggestLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.recentUpdates.suggestions}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(recentUpdatesStateActions.setAutoSuggestData(response.data));
    yield put(recentUpdatesStateActions.setAutoSuggestLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching recent updates suggestions!',
      ToastType.ERROR,
    );
    yield put(recentUpdatesStateActions.setRecentUpdatesError(error));
    setTimeout(function* () {
      yield put(recentUpdatesStateActions.clearRecentUpdatesError());
    }, 5000);
  }
}

/**
 * Recent updates history watcher
 */
export function* fetchRecentUpdatesHistoryWatcher() {
  yield takeLatest(
    fetchRecentUpdatesHistoryCreatorTypeName,
    fetchRecentUpdatesHistoryWatcherWorker,
  );
}

/**
 * Recent Updates history Worker
 */
export function* fetchRecentUpdatesHistoryWatcherWorker(action: any): any {
  try {
    yield put(recentUpdatesStateActions.setHistoryLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.recentUpdates.history}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        employeeId: action?.payload,
      },
      {},
    );
    yield put(recentUpdatesStateActions.setHistory(response.data));
    yield put(recentUpdatesStateActions.setHistoryLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching recent updates history!',
      ToastType.ERROR,
    );
    yield put(recentUpdatesStateActions.setRecentUpdatesError(error));
    setTimeout(function* () {
      yield put(recentUpdatesStateActions.clearRecentUpdatesError());
    }, 5000);
  }
}
