import React, {useCallback, useState} from 'react';
import {
  TableRow,
  TableCell,
  Stack,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  CircularProgress,
  Paper,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {DataGrid} from '@mui/x-data-grid';
import Colors from '@app/themes/colors';
import {useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';

export type CustomTableProps = {
  rows: any[];
  loading?: boolean;
  columns: {
    headerName: string;
    field: string;
    renderCell?: (row?: any) => React.ReactNode;
    width?: any;
  }[];
  nestedColumns: {
    headerName: string;
    field: string;
    renderCell?: (row?: any) => React.ReactNode;
  }[];
  uniqueProperty: string;
  nestedData?: any[];
  pagination?: boolean;
  nestedPagination?: boolean;
  onPageChange?: (page: number) => void;
  onDeepDive?: (userId?: number, rowData?: []) => void;
  deepDiveIsLoading?: boolean;
  columnBuffer?: number;
  isDeepDive?: boolean;
  tablePagination?: {pageNo: number; perPage: number};
  totalcount?: number;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  width?: any;
  deepdiveShouldNotOpen?:boolean;
};

type CustomTableRowProps = CustomTableProps & {
  row: any;
  openId: number;
  setOpenId: (id: any) => void;
  deepdiveShouldNotOpen:boolean;
};

const useStyles = makeStyles({
  gridcss: {
    '& .css-11kxpxz-MuiDataGrid-root': {
      backgroundColor: '#fff',
    },
    '& .text-align-center': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },

    '& .css-vjchl4-MuiTableCell-root': {
      borderBottom: '0px solid #fff !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal !important',
      color: '#1c1939',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: 12,
      backgroundColor: '#fff',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: 14.7,
      color: '#747379',
      textTransform: 'uppercase',
    },
    '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: 14.7,
      color: '#747379',
      textTransform: 'uppercase',
    },
    '& .css-16r9fgn-MuiButtonBase-root-MuiButton-root ': {
      minWidth: '40px !important',
    },
  },
  collapsible: {
    backgroundColor: '#f4f4f4',
  },
  tableRow: {
    boxShadow: 'none !important',
  },
  tableCell: {
    padding: '10px',
    fontSize: '12px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    minWidth: '100px',
  },
  tableHead: {
    color: '#747379',
    fontSize: '10.7px',
    fontStyle: 'normal',
    fontWeight: '600',
    textTransform: 'uppercase',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '10px',
    width: '180px',
  },
  styledTableHead: {
    backgroundColor: Colors.grayishBlue,
    fontSize: '12px',
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#747379',
    textTransform: 'uppercase',
  },
  body: {
    height: '300px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deepDiveLoader: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    height: '10vh',
    paddingTop: '20px',
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  nestedTable: {
    maxHeight: '400px',
    overflowY: 'scroll',
    margin: 10,
  },
  noRecordsCell: {
    padding: '10px',
    backgroundColor: '#f2f2f2',
    color: '#555',
    fontStyle: 'normal',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
});

const Row: React.FC<CustomTableRowProps> = ({
  row,
  columns,
  nestedColumns,
  uniqueProperty,
  nestedData = [],
  onDeepDive = () => null,
  deepDiveIsLoading = false,
  nestedPagination = true,
  openId,
  setOpenId,
  columnBuffer = 8,
  isDeepDive,
  width,
  deepdiveShouldNotOpen
}) => {
  console.log('row', row);
  const classes = useStyles();
  const userId = row?.userid || row?.userId;
  const rowData = row
  const open = userId === openId;
  const componentName = useSelector(routeSelector.getNavbarComponentName());
  const handleOpenId = () => {
    if (openId === -1) {
      return userId;
    } else {
      if (open) {
        return -1;
      } else {
        return userId;
      }
    }
  };
  const onClickHandleDeepDive = useCallback(
    function (e) {
      if (typeof isDeepDive == 'undefined' || isDeepDive) {
        if (openId !== userId) {
          setOpenId(() => handleOpenId());
          if (openId !== userId) {
            onDeepDive(userId, rowData);
          }
        } else {
          onDeepDive(userId);
        }
        setOpenId(() => handleOpenId());
        if (openId !== userId) {
          onDeepDive(userId, rowData);
        }
      }
    },
    [openId, userId],
  );

  const getRowIdHandler = useCallback(function (row: any) {
    return Math.random().toString();
  }, []);

  const noRowsOverLay = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No Records Found
      </Stack>
    ),
    [],
  );

  return (
    <React.Fragment>
      <TableRow
        sx={{'& > *': {borderBottom: 'unset'}, overflowX: 'auto'}}
        className={classes.tableRow}
        onClick={()=>{
          deepdiveShouldNotOpen && onClickHandleDeepDive(userId)
        } }>
        {columns?.map(column =>
          column?.renderCell ? (
            <TableCell
              key={column.headerName}
              sx={{width: '600px'}}
              className={classes.tableCell}
              style={{cursor: 'pointer', minWidth: column.width || '100px'}}>
              {column?.renderCell(row)}
            </TableCell>
          ) : (
            <TableCell
              key={column.headerName}
              sx={{width: '600px'}}
              className={classes.tableCell}
              style={{cursor: 'pointer', minWidth: column.width || '100px'}}>
              {row[column?.field]}
            </TableCell>
          ),
        )}
      </TableRow>
      <TableRow className={classes.tableRow}>
        {deepDiveIsLoading && openId === userId && (
          <Box className={classes.deepDiveLoader}>
            <CircularProgress
              size={20}
              data-testid="Nested-Circular-progress"
            />
          </Box>
        )}
        {nestedData?.length > 0 ? (
          <TableCell
            colSpan={10}
            className={`${classes.collapsible} ${!open && classes.noPadding}`}>
            <Collapse
              in={openId === userId}
              timeout="auto"
              unmountOnExit
              className={classes.collapsible}>
              <div
                style={{
                  height: nestedData.length >= 5 ? 300 : 'auto',
                  width: width ? width : '100%',
                }}>
                <DataGrid
                  columnBuffer={columnBuffer}
                  pagination={nestedPagination ? true : undefined}
                  autoPageSize
                  autoHeight={nestedData.length >= 5 ? undefined : true}
                  hideFooter={!nestedPagination}
                  paginationMode="client"
                  rowsPerPageOptions={nestedPagination ? [] : [5]}
                  rowCount={nestedData.length}
                  rows={nestedData}
                  columns={nestedColumns}
                  pageSize={nestedPagination ? 100 : nestedData.length}
                  loading={deepDiveIsLoading}
                  getRowId={getRowIdHandler}
                  className={classes.gridcss}
                  components={{
                    NoRowsOverlay: noRowsOverLay,
                  }}
                />
              </div>
            </Collapse>
          </TableCell>
        ) : (
          open &&
          !deepDiveIsLoading && (
            <TableCell colSpan={12} className={classes.noRecordsCell}>
              No Records found
            </TableCell>
          )
          // <TableCell colSpan={columns.length} className={classes.collapsible}>
          //   {noRowsOverLay()}
          // </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  columns,
  nestedColumns,
  uniqueProperty,
  nestedData,
  loading = false,
  onDeepDive,
  deepDiveIsLoading = false,
  nestedPagination = true,
  isDeepDive,
  onPageChange = () => {},
  tablePagination = {pageNo: 0, perPage: 0},
  totalcount = 0,
  onRowsPerPageChange = () => {},
  width = '100%',
  deepdiveShouldNotOpen = true
}) => {
  const classes = useStyles();
  const labels = columns.map(column => column?.headerName);

  const [openId, setOpenId] = useState<any>(-1);
  const componentName = useSelector(routeSelector.getNavbarComponentName());

  return (
    <Paper variant="outlined">
      <TableContainer
        sx={{
          maxHeight:
            componentName === navbarComponentName.cdcCandidates ? 400 : 460,
        }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {labels.map(label => (
                <TableCell className={classes.tableHead} key={label}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <Box className={classes.body}>
                    <CircularProgress data-testid="Circular-progress" />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Records found
                  </TableCell>
                </TableRow>
              )}
              {rows?.length > 0 &&
                rows.map(row => (
                  <Row
                    {...{
                      key: row?.employeeName,
                      row,
                      rows,
                      columns,
                      nestedColumns,
                      uniqueProperty,
                      nestedData,
                      onDeepDive,
                      deepDiveIsLoading,
                      nestedPagination,
                      openId,
                      setOpenId,
                      isDeepDive,
                      width,
                      deepdiveShouldNotOpen
                    }}
                  />
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        page={tablePagination.pageNo}
        rowsPerPage={tablePagination.perPage}
        rowsPerPageOptions={[10, 20, 50]}
        count={totalcount || 0}
        component={Paper}
        elevation={0}
        onPageChange={(_event, newPage: number) => {
          onPageChange(newPage);
        }}
        onRowsPerPageChange={_event => {
          onRowsPerPageChange(parseInt(_event.target.value));
        }}
      />
    </Paper>
  );
};
