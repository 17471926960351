import {createSelector} from '@reduxjs/toolkit';

export const feedbacksStateSelector = {
  getFeedbacks: () => {
    return createSelector([(state: any) => state.feedback.data], data => data);
  },
  getFeedbacksState: () => {
    return createSelector([(state: any) => state.feedback], data => data);
  },
  getFeedbacksSnapshot: () => {
    return createSelector(
      [(state: any) => state.feedback.snapShot],
      data => data,
    );
  },
  getFeedbacksLoading: () => {
    return createSelector(
      [(state: any) => state.feedback.loading],
      loading => loading,
    );
  },
  getFeedbacksTotalCount: () => {
    return createSelector(
      [(state: any) => state.feedback.totalCount],
      feedback => feedback,
    );
  },
  getFeedbacksError: () => {
    return createSelector([(state: any) => state.feedback], error => ({
      error: error?.feedbacksError,
      message: error?.feedbacksMessage,
    }));
  },

  getDeletedFeedbackResponse: () => {
    return createSelector(
      [(state: any) => state.feedback],
      feedback => feedback,
    );
  },
};

export const getFeedbackDataGridSeletor = {
  getFeedbacksPageNo: () => {
    return createSelector(
      [(state: any) => state.feedbackDataGrid.pageNo],
      feedback => feedback,
    );
  },

  getFeedbacksPerPage: () => {
    return createSelector(
      [(state: any) => state.feedbackDataGrid.rowsPerPage],
      feedback => feedback,
    );
  },
  getAddFeedbackResponse: () => {
    return createSelector(
      [(state: any) => state.feedbackDataGrid],
      feedback => feedback,
    );
  },
};
