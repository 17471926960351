import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useStyles} from '../styles';
import {CircularProgress, Grid, SvgIcon} from '@mui/material';
import {EditIcon} from '@app/screens/Styles/style';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {useState, useEffect} from 'react';
import {Stack} from '@mui/system';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {fetchReviewUpdateApprovalPeriodCreator} from '../../redux-api/slice';
import {strings} from '@app/common/strings';
import dateUtils from '@app/screens/zho/utils/dateFormat';
function Details({
  productData,
  requestFormId,
  userLoginInfo,
  approvalStatus,
  isEditDetails,
  setIsEditDetails,
  setIsEditProducts,
}: any) {
  const classes = useStyles();
  const [tempPeriod, setTempPeriod] = useState(productData?.approvalPeriod);
  const dispatch = useDispatch();
  const {DateFormat, IstFormat} = dateUtils;

  const CustomArrowIcon = (props: any) => {
    return (
      <SvgIcon {...props}>
        <path d="M7 10l5 5 5-5z" fill="#322b7c" />
      </SvgIcon>
    );
  };

  const handleEditClick = () => {
    setIsEditDetails(true);
    setIsEditProducts(false);
    setTempPeriod(productData?.approvalPeriod);
  };

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const detailsLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewFormProductLoading,
  );

  const handleSaveClick = () => {
    setIsEditDetails(false);
    dispatch(
      fetchReviewUpdateApprovalPeriodCreator({
        requestId: requestFormId,
        days: parseInt(tempPeriod),
        currentUserId: userLoginInfo?.userId,
      }),
    );
  };

  const handleCancelClick = () => {
    setIsEditDetails(false);
    setTempPeriod(productData?.approvalPeriod);
  };

  const handleChange = (event: number) => {
    setTempPeriod(event);
  };

  useEffect(() => {
    handleChange(productData?.approvalPeriod);
  }, [productData]);

  return (
    <Box
      sx={{marginTop: '20px'}}
      className={`${classes.headingMarginBottom}  `}>
      <Typography
        className={`${classes.headingMarginBottom} ${classes.RfHeading} `}
        fontSize="15.7px"
        variant="h6">
        {strings.details}
      </Typography>

      <Box className={`${classes.detailsBox}`}>
        {!detailsLoader ? (
          <Grid className={classes.headingMarginBottom} container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.createdBy}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.createdBy}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  sx={{textTransform: 'uppercase'}}
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.fcAdmin.dashboard.tableHeading.requestFormNo}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.requestFormNo}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.date}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.date !== null
                    ? DateFormat(productData?.date)
                    : '---'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.candf}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.candF}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box>
                <Typography
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.hq}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.hq}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.chemistName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.mrName}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.mrName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction={isEditDetails ? 'row' : 'column'}
                alignItems={isEditDetails ? 'center' : 'flex-start'}
                spacing={isEditDetails ? '' : '2'}>
                {approvalStatus === 0 && !isEditDetails && (
                  <Box className={classes.flexCenter}>
                    <Typography
                      className={classes.defaultLightText}
                      variant="subtitle1">
                      {strings.zho.approvalPeriod}
                    </Typography>
                    {statusToggleNo === 1 && (
                      <EditIcon
                        data-testid="edit-approval-period"
                        onClick={handleEditClick}
                        className={classes.editIcon}
                        color="primary"
                      />
                    )}
                  </Box>
                )}
                {approvalStatus === 0 && isEditDetails ? (
                  <FormControl
                    sx={{
                      marginTop: '10px',
                      minWidth: '150px',
                    }}>
                    <InputLabel
                      sx={{fontSize: '12.7px', color: '#1c1939B3'}}
                      size="small"
                      id="ApprovalPeriod">
                      {strings.zho.approvalPeriod}
                    </InputLabel>

                    <Select
                      labelId="ApprovalPeriod"
                      id="ApprovalPeriod"
                      sx={{fontSize: '12.7px'}}
                      value={tempPeriod}
                      label={'Approval Period'}
                      size="small"
                      IconComponent={props => (
                        <CustomArrowIcon {...props} sx={{color: '#'}} />
                      )}
                      onChange={e => handleChange(parseInt(e.target.value))}>
                      <MenuItem value={90}>90 Days</MenuItem>
                      <MenuItem value={180}>180 Days</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Box>
                    {approvalStatus !== 0 && (
                      <Typography
                        className={classes.defaultLightText}
                        variant="subtitle1">
                        {strings.zho.approvalPeriod}
                      </Typography>
                    )}
                    <Typography
                      className={classes.priDefaultFontSize}
                      variant="subtitle1">
                      {productData?.approvalPeriod} Days
                    </Typography>
                  </Box>
                )}
                {isEditDetails && (
                  <Box sx={{marginLeft: '10px'}}>
                    <DoneIcon
                      data-testid="save-edit"
                      onClick={handleSaveClick}
                      color="primary"
                    />
                    <CloseIcon
                      data-testid="cancel-edit"
                      onClick={handleCancelClick}
                      color="primary"
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Typography
                textTransform="uppercase"
                className={classes.defaultLightText}
                variant="subtitle1">
                {strings.zho.customer}
              </Typography>
              <Box sx={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
                {productData?.stockists?.map((name: any, index: number) => {
                  return (
                    <Typography
                      className={classes.detailsCustomerNames}
                      variant="body1">
                      {name?.stockistName}
                    </Typography>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box
            className={classes.loader}
            sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Details;
