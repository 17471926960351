import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {formatFilters} from '@app/utils/util';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {appSelector} from '@app/store/selectors';

import {
  fetchFeedbacksCreatorTypeName,
  updateFeedbacksCreatorTypeName,
  deleteFeedbacksCreatorTypeName,
  fetchFeedbacksSuggestionsCreatorTypeName,
  fetchFeedbacksHistoryCreatorTypeName,
  feedbacksStateActions,
  feedbackDataGridStateActions,
} from './slice';

import {feedbacksStateSelector, getFeedbackDataGridSeletor} from './selectors';

/**
 * Feedbacks watcher
 */
export function* fetchFeedbacksDataWatcher() {
  yield takeLatest(
    fetchFeedbacksCreatorTypeName,
    fetchFeedbacksDataWatcherWorker,
  );
}

/**
 * Feedbacks update Worker
 */
export function* fetchFeedbacksDataWatcherWorker(): any {
  try {
    yield put(feedbacksStateActions.setFeedbacksLoading(true));
    yield put(appSliceActions.setExcelDataLoading(true));
    const stateByAdminData = yield select(appSelector.getStateDropDown());
    const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
    const divisionIds = yield select(appSelector.getDivisionDropDownIds());
    const stateIds = yield select(appSelector.getStateDropDownIds());
    const filters = yield select(filterSelector.getFilterState());
    const filterApplied = yield select(filterSelector.getFilterApplied());
    const filter = yield select(filterSelector.getFilterOptions());
    if (!filterApplied) {
      // if (divisionByAdminData.length > 0) {
        const options: any = {};
        const optionsState: any = {};
        divisionByAdminData?.map((item: any) => {
          options[item.label] = false;
          return null;
        });

        stateByAdminData?.map((item: any) => {
          optionsState[item.label] = false;
          return null;
        });
        yield put(
          filterStateActions.setFilterState({
            ...filters,
            Division: {
              completeApplied: false,
              collapsed: true,
              options,
            },
            States: {
              completeApplied: false,
              collapsed: true,
              options: optionsState,
            },
          }),
        );
      // }
    }
    const url = `${API_PATH.employeeSpecificUtilities.feedbacks.getAll}`;
    let pageNo = yield select(getFeedbackDataGridSeletor.getFeedbacksPageNo());
    let perPage = yield select(getFeedbackDataGridSeletor.getFeedbacksPerPage());
    let request = {
      pageNo: pageNo + 1,
      rowPerPage: perPage,
      stateIds: stateIds,
      divisionIds: divisionIds,
      roleIds: null,
      searchData: '',
    };
    if (filterApplied) {
      request = {
        ...filter,
        pageNo: pageNo + 1,
        rowPerPage: perPage,
      };
      if (filter.stateIds.length == 0) {
        request.stateIds = stateIds;
      }
      if (filter.divisionIds.length == 0) {
        request.divisionIds = divisionIds;
      }
    }
    const response = yield call(NetworkService.post, url, request, {});
    yield put(feedbacksStateActions.setFeedbacks(response.data.feedbackList));
    yield put(
      feedbacksStateActions.setFeedbacksSnapshot(response.data.feedbackList),
    );
    yield put(feedbacksStateActions.setTotalCount(response.data?.totalCount));

    yield put(feedbacksStateActions.setFeedbacksLoading(false));

    let requestExcel = {
      ...filter,
      pageNo: 1,
      rowPerPage: response?.data?.totalCount,
    };
    if (filter.stateIds.length == 0) {
      requestExcel.stateIds = stateIds;
    }
    if (filter.divisionIds.length == 0) {
      requestExcel.divisionIds = divisionIds;
    }

    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );

    yield put(
      appSliceActions.setExcelData({
        data: responseExcel?.data?.feedbackList,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'name'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managerName'},
          {label: 'Feedback', key: 'feedback'},
        ],
      }),
    );
    yield put(feedbacksStateActions.setFeedbacksLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(feedbacksStateActions.setFeedbacksError(error));
    setTimeout(function* () {
      yield put(feedbacksStateActions.clearFeedbacksError());
    }, 5000);
  } finally {
    yield put(feedbacksStateActions.setFeedbacksLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  }
}

/**
 * Feedbacks update watcher
 */
export function* updateFeedbacksDataWatcher() {
  yield takeLatest(
    updateFeedbacksCreatorTypeName,
    updateFeedbacksDataWatcherWorker,
  );
}

/**
 * Feedbacks Worker
 */

export function* updateFeedbacksDataWatcherWorker(action: any): any {
  try {
    const payload = {
      userId: action?.payload?.userId,
      feedback: action?.payload?.feedback,
    };
    yield put(feedbacksStateActions.setFeedbacksLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.feedbacks.update}`;
    const response = yield call(NetworkService.post, url, payload);
    const statusCode = response.status;
    yield put(feedbackDataGridStateActions.setAddFeedbackResponse(statusCode));
    ToasterService.showToaster(
      'Successfully added the feedback for' + ' ' + action?.payload?.name,
      ToastType.SUCCESS,
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while updating selected employees!',
      ToastType.ERROR,
    );
    yield put(feedbacksStateActions.setFeedbacksError(error));
    setTimeout(function* () {
      yield put(feedbacksStateActions.clearFeedbacksError());
    }, 5000);
  } finally {
    yield put(feedbacksStateActions.setFeedbacksLoading(false));
  }
}

/**
 * Feedbacks update watcher
 */
export function* deleteFeedbacksDataWatcher() {
  yield takeLatest(
    deleteFeedbacksCreatorTypeName,
    deleteFeedbacksDataWatcherWorker,
  );
}

/**
 * Feedbacks Worker
 */
export function* deleteFeedbacksDataWatcherWorker(action: any): any {
  try {
    const payload = {
      feedbackId: action?.payload?.feedbackId,
    };
    yield put(feedbacksStateActions.setFeedbacksLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.feedbacks.delete}`;
    const response = yield call(NetworkService.post, url, payload, {});
    yield put(feedbackDataGridStateActions.setFeedbackDeleteResponse(true));
    ToasterService.showToaster(
      'Successfully deleted the feedback',
      ToastType.SUCCESS,
    );
    return response;
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while deleting selected employees!',
      ToastType.ERROR,
    );
    yield put(feedbacksStateActions.setFeedbacksError(error));
    setTimeout(function* () {
      yield put(feedbacksStateActions.clearFeedbacksError());
    }, 5000);
  } finally {
    yield put(feedbacksStateActions.setFeedbacksLoading(false));
  }
}

/**
 * Feedbacks suggestions watcher
 */
export function* fetchFeedbacksSuggestionsWatcher() {
  yield takeLatest(
    fetchFeedbacksSuggestionsCreatorTypeName,
    fetchFeedbacksSuggestionsWatcherWorker,
  );
}

/**
 * Feedbacks suggestions Worker
 */
export function* fetchFeedbacksSuggestionsWatcherWorker(action: any): any {
  try {
    yield put(feedbacksStateActions.setAutoSuggestLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.feedbacks.suggestions}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(feedbacksStateActions.setAutoSuggestData(response.data));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(feedbacksStateActions.setFeedbacksError(error));
    setTimeout(function* () {
      yield put(feedbacksStateActions.clearFeedbacksError());
    }, 5000);
  } finally {
    yield put(feedbacksStateActions.setAutoSuggestLoading(false));
  }
}

/**
 * Feedbacks history watcher
 */
export function* fetchFeedbacksHistoryWatcher() {
  yield takeLatest(
    fetchFeedbacksHistoryCreatorTypeName,
    fetchFeedbacksHistoryWatcherWorker,
  );
}

/**
 * Feedbacks history Worker
 */
export function* fetchFeedbacksHistoryWatcherWorker(action: any): any {
  try {
    yield put(feedbacksStateActions.setHistory(''));
    yield put(feedbacksStateActions.setHistoryLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.feedbacks.history}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        userId: action?.payload,
      },
      {},
    );
    const data = response.data.map((data: any) => {
      return {...data, id: data.userId};
    });
    yield put(feedbacksStateActions.setHistory(data));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees history!',
    //   ToastType.ERROR,
    //   );
    yield put(feedbacksStateActions.setFeedbacksError(error));
    setTimeout(function* () {
      yield put(feedbacksStateActions.clearFeedbacksError());
    }, 5000);
  } finally {
    yield put(feedbacksStateActions.setHistoryLoading(false));
  }
}
