import {createSlice, createAction} from '@reduxjs/toolkit';
import {EMP360_DEFAULT_OBJECTS} from '@app/common/constants';

export const employee360InitialState = {
  emp360IsProfileActive: false,
  emp360ActiveProfile: {},

  emp360Divisions: [],
  emp360DivisionsLoading: false,
  emp360DivisionsError: '',
  emp360SelectedDivisions: [],

  emp360States: [],
  emp360StatesLoading: false,
  emp360StatesError: '',

  emp360HQ: [],
  emp360HQLoading: false,
  emp360HQError: '',

  emp360Hierarchy: [],
  emp360HierarchyLoading: false,
  emp360HierarchyError: '',

  emp360SearchUser: [],
  emp360SearchUserLoading: false,
  emp360SearchUserError: '',

  emp360GenderAndReligion: {
    Gender: [],
    Religion: [],
    genderError: false,
    religionError: false,
  },
  emp360GenderAndReligionLoading:{
    genderLoading:false,
    religionLoading:false,
  },
  emp360EmpList: [],
  emp360EmpListLoading: false,
  emp360EmpListError: '',
  emp360EmpListLength: -1,
  emp360EmpListActivePage: 0,
  emp360EmpListPageLimit: 10,
  emp360EmpListSortModel: {},

  emp360EmpFilters: {...EMP360_DEFAULT_OBJECTS.emp360EmpFilters},
  emp360EmpFiltersLoading: false,
  emp360EmpFiltersError: '',
  emp360EmpFiltersApplied: {...EMP360_DEFAULT_OBJECTS.emp360EmpFiltersApplied},

  emp360EmpExcel: [],
  emp360EmpExcelLoading: false,
  emp360EmpExcelError: '',
  emp360EmpExcelLatestAction: {},

  emp360Profile: {},
  emp360ProfileLoading: false,
  emp360ProfileError: '',

  emp360ProfilePersonalInformation: [],
  emp360ProfilePersonalInformationLoading: false,
  emp360ProfilePersonalInformationError: '',

  employeesOnHoldDetails: [],
  employeesOnHoldError: false,
  employeesOnHoldLoading: false,

  employeesWorkExperience: {},
  employeesWorkExperienceError: false,
  employeesWorkExperienceLoading: false,

  emp360ProfileEducation: [],
  emp360ProfileEducationLoading: false,
  emp360ProfileEducationError: '',

  emp360ProfileCourseDetails: [],
  emp360ProfileCourseLoading: false,
  emp360ProfileCourseError: '',

  emp360ProfileAwardDetails: [],
  emp360ProfileAwardLoading: false,
  emp360ProfileAwardError: '',

  emp360ProfileForeignTrips: [],
  emp360ProfileForeignTripsLoading: false,
  emp360ProfileForeignTripsError: '',

  emp360ProfileContactDetails: {},
  emp360ProfileContactDetailsLoading: false,
  emp360ProfileContactDetailsError: '',

  emp360ProfileFamilyDetails: [],
  emp360ProfileFamilyDetailsLoading: false,
  emp360ProfileFamilyDetailsError: '',

  emp360ProfileCompanyAssets: [],
  emp360ProfileCompanyAssetsLoading: false,
  emp360ProfileCompanyAssetsError: '',

  emp360ProfileFeedbacks: [],
  emp360ProfileFeedbacksLoading: false,
  emp360ProfileFeedbacksError: '',

  emp360ProfileAssessments: [],
  emp360ProfileAssessmentsLoading: false,
  emp360ProfileAssessmentsError: '',
};

export const fetchEmp360DivisionsCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_DIVISIONS',
);
export const fetchEmp360DivisionsCreatorTypeName =
  fetchEmp360DivisionsCreator().type;

export const fetchEmp360StatesCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_STATES',
);
export const fetchEmp360StatesCreatorTypeName = fetchEmp360StatesCreator().type;

export const fetchEmp360HQCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_HQ',
);
export const fetchEmp360HQCreatorTypeName = fetchEmp360HQCreator({
  stateList: [],
}).type;

export const fetchEmp360HierarchyCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_HIERARCHY',
);
export const fetchEmp360HierarchyCreatorTypeName = fetchEmp360HierarchyCreator({
  divisions: [],
}).type;

export const fetchEmp360SearchUserCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_SEARCH_USER',
);
export const fetchEmp360SearchUserCreatorTypeName =
  fetchEmp360SearchUserCreator({
    divisions: [],
  }).type;

export const fetchEmp360EmpListCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_EMP_LIST',
);
export const fetchEmp360EmpListCreatorTypeName = fetchEmp360EmpListCreator({
  divisions: [],
}).type;

export const fetchEmp360EmpExcelCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_EXCEL_EMP_LIST',
);
export const fetchEmp360EmpExcelCreatorTypeName = fetchEmp360EmpExcelCreator({
  divisions: [],
}).type;

export const fetchEmp360ProfileCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_EMP_PROFILE',
);

export const fetchEmp360ProfileCreatorTypeName = fetchEmp360ProfileCreator({
  user: {},
}).type;

export const fetchEmp360ProfilePersonalInformationCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_PERSONAL_INFORMATION',
);
export const fetchEmp360ProfilePersonalInformationCreatorTypeName =
  fetchEmp360ProfilePersonalInformationCreator({user: {}}).type;

export const fetchEmployeesOnHoldDetailsCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_EMPLOYEES_ONHOLD_DETAILS',
  // function prepare(userId: number, additionalInfo: any | null) {
  //   return {
  //     payload: {
  //       userId: userId,
  //       additionalInfo: additionalInfo,
  //     },
  //   };
  // },
);
export const fetchEmployeesOnHoldDetailsTypeName =
  fetchEmployeesOnHoldDetailsCreator({}).type;

export const fetchEmployeesWorkExperienceDetailsCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_EMPLOYEES_WORK_EXPERIENCE',
);

export const fetchEmployeesWorkExperienceDetailsTypeName =
  fetchEmployeesWorkExperienceDetailsCreator({}).type;

export const fetchEmp360ProfileEducationCreator = createAction<any>(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_EDUCATION',
);
export const fetchEmp360ProfileEducationCreatorTypeName =
  fetchEmp360ProfileEducationCreator({user: {}}).type;

export const fetchEmp360ProfileCourseDetailsCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_COURSE_DETAILS',
);

export const fetchEmp360ProfileCourseDetailsCreatorTypeName =
  fetchEmp360ProfileCourseDetailsCreator().type;

export const fetchEmp360ProfileAwardDetailsCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_AWARD_DETAILS',
);

export const fetchEmp360ProfileAwardDetailsCreatorTypeName =
  fetchEmp360ProfileAwardDetailsCreator().type;

export const fetchEmp360ProfileForeignTripsCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_FOREIGN_TRIPS',
);

export const fetchEmp360ProfileForeignTripsCreatorTypeName =
  fetchEmp360ProfileForeignTripsCreator().type;

export const fetchEmp360ProfileContactDetails = createAction(
  'EMPLOYEE360_HANDLER/FETCH_CONTACT_DETAILS',
);
export const fetchEmp360ProfileContactDetailsTypeName =
  fetchEmp360ProfileContactDetails().type;

export const fetchEmp360ProfileFamilyDetails = createAction(
  'EMPLOYEE360_HANDLER/FETCH_FAMILY_DETAILS',
);
export const fetchEmp360ProfileFamilyDetailsTypeName =
  fetchEmp360ProfileFamilyDetails().type;

export const fetchEmp360ProfileCompanyAssets = createAction(
  'EMPLOYEE360_HANDLER/FETCH_COMPANY_ASSETS',
);
export const fetchEmp360ProfileCompanyAssetsTypeName =
  fetchEmp360ProfileCompanyAssets().type;

export const fetchEmp360ProfileFeedbacksCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_FEEDBACKS',
);

export const fetchEmp360ProfileFeedbacksCreatorTypeName =
  fetchEmp360ProfileFeedbacksCreator().type;

export const fetchEmp360ProfileAssessmentsCreator = createAction(
  'EMPLOYEE360_HANDLER/FETCH_PROFILE_ASSESSMENTS',
);

export const fetchEmp360ProfileAssessmentsCreatorTypeName =
  fetchEmp360ProfileAssessmentsCreator().type;

export const fetchEmp360DefaultGenderAndReligionActionCreator = createAction(
  'EMPLOYEE360_HANDLER/DEFAULTGENDERANDRELIGION',
);

export const fetchEmp360DefaultGenderAndReligionTypeName =
  fetchEmp360DefaultGenderAndReligionActionCreator().type;

const employee360StateHandler = createSlice({
  name: 'EMPLOYEE360_HANDLER',
  initialState: employee360InitialState,
  reducers: {
    setEmp360IsProfileActive: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360IsProfileActive: payload,
      };
    },
    setEmp360ActiveProfile: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ActiveProfile: payload,
      };
    },
    setEmp360Divisions: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360Divisions: payload,
      };
    },
    setEmp360DivisionsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360DivisionsLoading: payload,
      };
    },
    setEmp360DivisionsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360DivisionsError: payload,
      };
    },
    setEmp360SelectedDivisions: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360SelectedDivisions: payload,
      };
    },
    setEmp360States: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360States: payload,
      };
    },
    setEmp360StatesLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360StatesLoading: payload,
      };
    },
    setEmp360StatesError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360StatesError: payload,
      };
    },
    setEmp360HQ: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360HQ: payload,
      };
    },
    setEmp360HQLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360HQLoading: payload,
      };
    },
    setEmp360HQError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360HQError: payload,
      };
    },
    setEmp360GenderAndReligion: (prevState, action)=>{
      const {payload} = action;
      return {
        ...prevState,
        emp360GenderAndReligion:{
          ...payload
        }

      }

    },
    setemp360GenderAndReligionLoading:(prevState, action)=>{
      const {payload} = action;
      return {
        ...prevState,
        emp360GenderAndReligionLoading:{
          ...payload
        }

      }

    },
    setEmp360Hierarchy: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360Hierarchy: payload,
      };
    },
    setEmp360HierarchyLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360HierarchyLoading: payload,
      };
    },
    setEmp360HierarchyError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360HierarchyError: payload,
      };
    },
    setEmp360SearchUser: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360SearchUser: payload,
      };
    },
    setEmp360SearchUserLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360SearchUserLoading: payload,
      };
    },
    setEmp360SearchUserError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360SearchUserError: payload,
      };
    },
    setEmp360EmpList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpList: payload,
      };
    },
    setEmp360EmpListLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpListLoading: payload,
      };
    },
    setEmp360EmpListError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpListError: payload,
      };
    },
    setEmp360EmpListLength: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpListLength: payload,
      };
    },
    setEmp360EmpListActivePage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpListActivePage: payload,
      };
    },
    setEmp360EmpListPageLimit: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpListPageLimit: payload,
      };
    },
    setEmp360EmpListSortModel: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpListSortModel: payload,
      };
    },
    setEmp360EmpFilters: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpFilters: payload,
      };
    },
    setEmp360EmpFiltersLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpFiltersLoading: payload,
      };
    },
    setEmp360EmpFiltersError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpFiltersError: payload,
      };
    },
    setEmp360EmpFiltersApplied: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpFiltersApplied: payload,
      };
    },
    setEmp360EmpExcel: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpExcel: payload,
      };
    },
    setEmp360EmpExcelLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpExcelLoading: payload,
      };
    },
    setEmp360EmpExcelError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpExcelError: payload,
      };
    },
    setEmp360EmpExcelLatestAction: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360EmpExcelLatestAction: payload,
      };
    },
    setEmp360Profile: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360Profile: payload,
      };
    },
    setEmp360ProfileLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileLoading: payload,
      };
    },
    setEmp360ProfileError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileError: payload,
      };
    },
    setEmp360ProfilePersonalInformation: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfilePersonalInformation: payload,
      };
    },
    setEmp360ProfilePersonalInformationLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfilePersonalInformationLoading: payload,
      };
    },
    setEmp360ProfilePersonalInformationError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfilePersonalInformationError: payload,
      };
    },

    setEmployeesOnHoldDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeesOnHoldDetails: payload,
      };
    },

    setEmployeesOnHoldErrors: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeesOnHoldError: payload,
      };
    },
    setEmployeesOnHoldLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeesOnHoldLoading: payload,
      };
    },

    setEmployeesWorkExperience: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeesWorkExperience: payload,
      };
    },
    setEmployeesWorkExperienceError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeesWorkExperienceError: payload,
      };
    },

    setEmployeesWorkExperienceLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeesWorkExperienceLoading: payload,
      };
    },

    setEmp360ProfileEducation: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileEducation: payload,
      };
    },
    setEmp360ProfileEducationLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileEducationLoading: payload,
      };
    },
    setEmp360ProfileEducationError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileEducationError: payload,
      };
    },
    setEmp360ProfileCourseDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileCourseDetails: payload,
      };
    },
    setEmp360ProfileCourseLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileCourseLoading: payload,
      };
    },
    setEmp360ProfileCourseError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileCourseError: payload,
      };
    },
    setEmp360ProfileAwardDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileAwardDetails: payload,
      };
    },
    setEmp360ProfileAwardLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileAwardLoading: payload,
      };
    },
    setEmp360ProfileAwardError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileAwardError: payload,
      };
    },
    setEmp360ProfileForeignTrips: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileForeignTrips: payload,
      };
    },
    setEmp360ProfileForeignTripsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileForeignTripsLoading: payload,
      };
    },
    setEmp360ProfileForeignTripsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileForeignTripsError: payload,
      };
    },
    setEmp360ContactDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileContactDetails: payload,
      };
    },
    setEmp360ContactDetailsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileContactDetailsLoading: payload,
      };
    },
    setEmp360ContactDetailsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileContactDetailsError: payload,
      };
    },
    setEmp360FamilyDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileFamilyDetails: payload,
      };
    },
    setEmp360FamilyDetailsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileFamilyDetailsLoading: payload,
      };
    },
    setEmp360FamilyDetailsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileFamilyDetailsError: payload,
      };
    },
    setEmp360CompanyAssets: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileCompanyAssets: payload,
      };
    },
    setEmp360CompanyAssetsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileCompanyAssetsLoading: payload,
      };
    },
    setEmp360CompanyAssetsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileCompanyAssetsError: payload,
      };
    },
    setEmp360ProfileFeedbacks: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileFeedbacks: payload,
      };
    },

    setEmp360ProfileFeedbacksLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileFeedbacksLoading: payload,
      };
    },

    setEmp360ProfileFeedbacksError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileFeedbacksError: payload,
      };
    },
    setEmp360ProfileAssessments: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileAssessments: payload,
      };
    },
    setEmp360ProfileAssessmentsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileAssessmentsLoading: payload,
      };
    },
    setEmp360ProfileAssessmentsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        emp360ProfileAssessmentsError: payload,
      };
    },
  },
});

export const employee360StateActions = employee360StateHandler.actions;
export const employee360StateReducer = employee360StateHandler.reducer;
