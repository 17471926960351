import {useState, useEffect} from 'react';
import {Box, Slider, Typography} from '@mui/material';
import Colors from '@app/themes/colors';

interface SliderProps {
  title: string;
  min: number;
  max: number;
  clear: boolean;
  onChange: any;
}

export default function SliderComp(props: SliderProps) {
  const [sliderValue, setSliderValue] = useState<number[]>([
    props.min,
    props.max,
  ]);
  const marks = Array.from({length: props.max + 1}, (x, i) => {
    return {value: i, label: `${i}`};
  });
  const step = (Math.ceil((props.max - props.min + 1) / 10) * 10) / 10;
  const handleChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number[]);
    props.onChange(newValue as number[]);
  };

  useEffect(() => {
    setSliderValue([props.min, props.max]);
    if (props.clear) {
      props.onChange([props.min, props.max]);
    }
  }, [props.clear]);

  return (
    <Box width="200px" padding="7px 20px">
      <Typography
        variant={'caption'}
        sx={{color: Colors.blue[1600], fontSize: '12px'}}>
        {props.title}
      </Typography>

      <Slider
        sx={{
          '& .MuiSlider-thumb': {
            height: 10,
            width: 10,
          },
          '& .MuiSlider-track': {
            height: '3.5px',
            border: 'none',
          },
          '& .MuiSlider-rail': {
            height: '3px',
            backgroundColor: '#dde7eb',
          },
          '& .MuiSlider-markLabel': {
            fontSize: '12px',
          },
          '& .MuiSlider-mark': {
            height: 8,
            backgroundColor: '#dde7eb',

            '&.MuiSlider-markActive': {
              height: 8,
              opacity: 1,
              backgroundColor: '#322b7c',
            },
          },
        }}
        min={props.min ? props.min : 0}
        max={props.max ? props.max : 10}
        step={step}
        disableSwap
        marks={marks}
        value={sliderValue}
        valueLabelDisplay={'auto'}
        onChange={handleChange}
        data-testid={'testSlider'}
      />
    </Box>
  );
}
