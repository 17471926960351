import {select} from 'redux-saga/effects';

import {API_PATH, USER_ID} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {appSelector} from '@app/store/selectors';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put} from '@redux-saga/core/effects';

import {
  fetchSelectedEmployeesCreatorTypeName,
  fetchSelectedEmployeesSuggestionsCreatorTypeName,
  updateSelectedEmployeesCreatorTypeName,
  fetchSelectedEmployeesSalaryCreatorTypeName,
  updateSelectedEmployeesSalaryCreatorTypeName,
  releaseAppointmentLetterCreatorTypeName,
  fetchHqDataCreatorTypeName,
  fetchDesignationsDataCreatorTypeName,
  selectedEmployeesStateActions,
  fetchSalaryTableCreatorTypeName,
} from './slice';
import {formatFilters} from '@app/utils/util';
import {appSliceActions} from '@app/store/reducers/appSlice';
import moment from 'moment';

/**
 * Selected Employees watcher
 */
export function* fetchSelectedEmployeesDataWatcher() {
  yield takeLatest(
    fetchSelectedEmployeesCreatorTypeName,
    fetchSelectedEmployeesDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchSelectedEmployeesDataWatcherWorker(): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const stateByAdminData = yield select(appSelector.getStateDropDown());
    const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
    const divisionIds = yield select(appSelector.getDivisionDropDownIds());
    const stateIds = yield select(appSelector.getStateDropDownIds());
    const filters = yield select(filterSelector.getFilterState());
    const filterApplied = yield select(filterSelector.getFilterApplied());
    const filter = yield select(filterSelector.getFilterOptions());
    const userDetails = yield select(appSelector.getUserInfo());

    if (!filterApplied) {
      // if (divisionByAdminData.length > 0) {
      const options: any = {};
      const optionsState: any = {};
      divisionByAdminData?.map((item: any) => {
        options[item.label] = false;
        return null;
      });

      stateByAdminData?.map((item: any) => {
        optionsState[item.label] = false;
        return null;
      });
      yield put(
        filterStateActions.setFilterState({
          ...filters,
          Division: {
            completeApplied: false,
            collapsed: true,
            options,
          },
          States: {
            completeApplied: false,
            collapsed: true,
            options: optionsState,
          },
        }),
      );
      // }
    }
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.getAll}`;
    let response = yield call(
      NetworkService.post,
      url,
      {pageNo: 1, rowPerPage: 50},
      {},
    );
    yield put(
      selectedEmployeesStateActions.setSelectedEmployees(
        response.data.selectedEmployeeList,
      ),
    );
    yield put(
      selectedEmployeesStateActions.setSelectedEmployeesSnapshot(
        response.data.selectedEmployeeList,
      ),
    );
    yield put(
      selectedEmployeesStateActions.setTotalCount(response.data?.totalCount),
    );

    yield put(
      appSliceActions.setExcelData({
        data: response.data.selectedEmployeeList.map((item: any) => {
          return {
            ...item,
            ['dateOfJoining']: moment(item?.dateOfJoining).format('DD/MM/YY'),
          };
        }),
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'name'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managerName'},
          {label: 'Date of Joining', key: 'dateOfJoining'},
          {label: 'Position Code', key: 'positionCode'},
          {label: 'Reg No', key: 'regNo'},
          {label: 'Designation', key: 'designation'},
          {label: 'HQ', key: 'hq'},
          {label: 'Salary', key: 'salary'},
        ],
      }),
    );
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  } finally {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  }
}

export function* updateSelectedEmployeesDataWatcher() {
  yield takeLatest(
    updateSelectedEmployeesCreatorTypeName,
    updateSelectedEmployeesDataWatcherWorker,
  );
}

export function* updateSelectedEmployeesDataWatcherWorker(action: any): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.update}`;
    yield call(NetworkService.post, url, {
      ...action?.payload,
    });
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while updating selected employees!',
      ToastType.ERROR,
    );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

export function* fetchSelectedEmployeesSalaryDataWatcher() {
  yield takeLatest(
    fetchSelectedEmployeesSalaryCreatorTypeName,
    fetchSelectedEmployeesSalaryDataWatcherWorker,
  );
}

export function* fetchSelectedEmployeesSalaryDataWatcherWorker(): any {
  try {
    // fetch selected employee salary
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

export function* updateSelectedEmployeesSalaryDataWatcher() {
  yield takeLatest(
    updateSelectedEmployeesSalaryCreatorTypeName,
    updateSelectedEmployeesSalaryDataWatcherWorker,
  );
}

export function* updateSelectedEmployeesSalaryDataWatcherWorker(
  action: any,
): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.updateSalary}`;
    yield call(NetworkService.post, url, action.payload);
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while updateing selected employee's salary!",
      ToastType.ERROR,
    );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

export function* releaseAppointmentLetterDataWatcher() {
  yield takeLatest(
    releaseAppointmentLetterCreatorTypeName,
    releaseAppointmentLetterDataWatcherWorker,
  );
}

export function* releaseAppointmentLetterDataWatcherWorker(): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const employeeId = localStorage.getItem(USER_ID);
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.releaseOfferLetter}${employeeId}`;
    yield call(NetworkService.post, url, {});
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

/**
 * Selected Employees suggestions watcher
 */
export function* fetchSelectedEmployeesSuggestionsWatcher() {
  yield takeLatest(
    fetchSelectedEmployeesSuggestionsCreatorTypeName,
    fetchSelectedEmployeesSuggestionsWatcherWorker,
  );
}

/**
 * Selected Employees suggestions Worker
 */
export function* fetchSelectedEmployeesSuggestionsWatcherWorker(
  action: any,
): any {
  try {
    yield put(
      selectedEmployeesStateActions.setSelectedEmployeesSuggestionsLoading(
        true,
      ),
    );
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.suggestions}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(selectedEmployeesStateActions.setAutoSuggestData(response.data));
    yield put(
      selectedEmployeesStateActions.setSelectedEmployeesSuggestionsLoading(
        false,
      ),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold suggestions!',
      ToastType.ERROR,
    );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

/**
 * Selected Employees designations watcher
 */
export function* fetchDesignationsWatcher() {
  yield takeLatest(
    fetchDesignationsDataCreatorTypeName,
    fetchDesignationsWatcherWorker,
  );
}

/**
 * Selected Employees designations Worker
 */
export function* fetchDesignationsWatcherWorker(): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.designations}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(selectedEmployeesStateActions.setDesignations(response.data));
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching designations!',
      ToastType.ERROR,
    );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

/**
 * Selected Employees hqs watcher
 */
export function* fetchSelectedEmployeesHqsWatcher() {
  yield takeLatest(fetchHqDataCreatorTypeName, fetchHqsWatcherWorker);
}

/**
 * Selected Employees hqs Worker
 */
export function* fetchHqsWatcherWorker(action: any): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.hqs}${action?.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(selectedEmployeesStateActions.setHqs(response.data));
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching headquarters!',
      ToastType.ERROR,
    );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}

export function* fetchSalaryTableWatcher() {
  yield takeLatest(
    fetchSalaryTableCreatorTypeName,
    fetchSalaryTableWatcherWorker,
  );
}

export function* fetchSalaryTableWatcherWorker(action: any): any {
  try {
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.selectedEmployees.salaryTable}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        ...action?.payload,
      },
      {},
    );
    yield put(selectedEmployeesStateActions.setSalaryBreakup(response.data[0]));
    yield put(selectedEmployeesStateActions.setSelectedEmployeesLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(selectedEmployeesStateActions.setSelectedEmployeesError(error));
    setTimeout(function* () {
      yield put(selectedEmployeesStateActions.clearSelectedEmployeesError());
    }, 5000);
  }
}
