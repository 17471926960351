import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {takeLatest, call, put, takeEvery} from 'redux-saga/effects';
import {
  hoDashboardActions,
  fetchStateDataTypeName,
  fetchHqDataTypeName,
  fetchOrderFormDataTypeName,
  fetchMrDataTypeName,
  fetchOrderInnerFormDataTypeName,
  fetchOrderFormProductDataTypeName,
  fetchOrderFormApprovalDataTypeName,
  fetchOrderProductApprovalDataTypeName,
  fetchOrderProductRejectDataTypeName,
  fetchOrderSyncDataTypeName,
  fetchRejectOptionsDataTypeName,
} from './slice';
import {redirectToLogin} from '@app/screens/zho/utils/handleAuth';
import {WatchLater} from '@mui/icons-material';
// State Dropdown watcher
export function* fetchHoStateDataWatcher() {
  yield takeEvery(fetchStateDataTypeName, fetchHoStateDataWorker);
}

// State Dropdown worker
export function* fetchHoStateDataWorker(actions: any): any {
  const currentStaffPositionId = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.dropDowns.state}${currentStaffPositionId}`;
  try {
    yield put(hoDashboardActions.setStateLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(hoDashboardActions.setStateData(response?.data?.response));
    } else {
      yield put(hoDashboardActions.setStateError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setStateError());
  }
}

// Hq Dropdown watcher
export function* fetchHoHqDataWatcher() {
  yield takeEvery(fetchHqDataTypeName, fetchHoHqDataWorker);
}

// Hq Dropdown worker
export function* fetchHoHqDataWorker(actions: any): any {
  const currentstateID = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.dropDowns.hq}${currentstateID}`;
  try {
    yield put(hoDashboardActions.setHqLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(hoDashboardActions.setHqData(response?.data?.response));
    } else {
      yield put(hoDashboardActions.setHqError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setHqError());
  }
}

// Hq Dropdown watcher
export function* fetchHoMrDataWatcher() {
  yield takeEvery(fetchMrDataTypeName, fetchHoMrDataWorker);
}

// Hq Dropdown worker
export function* fetchHoMrDataWorker(actions: any): any {
  const hqIds = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.dropDowns.mr}`;
  try {
    yield put(hoDashboardActions.setMrLoading());
    const response = yield call(NetworkService.post, url, hqIds, {});
    if (response.status === HTTP_OK) {
      yield put(hoDashboardActions.setMrData(response?.data?.response));
    } else {
      yield put(hoDashboardActions.setMrError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setMrError());
  }
}

// orderForm table watcher
export function* fetchHoOrderFormDataWatcher() {
  yield takeEvery(fetchOrderFormDataTypeName, fetchHoOrderFormDataWorker);
}

// orderForm table worker
export function* fetchHoOrderFormDataWorker(actions: any): any {
  const requestformTableObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderDashboardTable}${requestformTableObj.statusToggleNo}`;
  try {
    yield put(hoDashboardActions.setOrderFormLoading());
    const response = yield call(
      NetworkService.post,
      url,
      requestformTableObj.currentRequestStatus,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(hoDashboardActions.setOrderFormData(response?.data?.response));
    } else {
      yield put(hoDashboardActions.setOrderFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormError());
  }
}

// orderForm innertable Dropdown watcher
export function* fetchHoOrderInnerFormDataWatcher() {
  yield takeEvery(
    fetchOrderInnerFormDataTypeName,
    fetchHoOrderInnerFormDataWorker,
  );
}

// orderForm innertable Dropdown worker
export function* fetchHoOrderInnerFormDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderInnerTableData}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderInnerFormLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        hoDashboardActions.setOrderInnerFormData(response?.data?.response),
      );
    } else {
      yield put(hoDashboardActions.setOrderInnerFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderInnerFormError());
  }
}

// orderFormPopup product Details watcher
export function* fetchHoOrderFormProductDataWatcher() {
  yield takeEvery(
    fetchOrderFormProductDataTypeName,
    fetchHoOrderFormProductDataWorker,
  );
}

// orderFormPopup product Details worker
export function* fetchHoOrderFormProductDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderPopup.productDetails}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderFormProductLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        hoDashboardActions.setOrderFormProductData(response?.data?.response),
      );
    } else {
      yield put(hoDashboardActions.setOrderFormProductError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormProductError());
  }
}

// orderFormPopup approval Details watcher
export function* fetchHoOrderFormApprovalDataWatcher() {
  yield takeEvery(
    fetchOrderFormApprovalDataTypeName,
    fetchHoOrderFormApprovalDataWorker,
  );
}

// orderFormPopup approval Details worker
export function* fetchHoOrderFormApprovalDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderPopup.approvalStatus}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderFormApprovalLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      console.log('this is form saga', response);
      yield put(
        hoDashboardActions.setOrderFormApprovalData(response?.data?.response),
      );
    } else {
      yield put(hoDashboardActions.setOrderFormApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormApprovalError());
  }
}

// order product approval  watcher
export function* fetchHoOrderProductApprovalDataWatcher() {
  yield takeEvery(
    fetchOrderProductApprovalDataTypeName,
    fetchHoOrderProductApprovalDataWorker,
  );
}

// order product approval Details worker
export function* fetchHoOrderProductApprovalDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.approveOrder}${payObj.staffPositionId}&currentUserId=${payObj.UserId}`;
  try {
    yield put(hoDashboardActions.setOrderProductApprovalLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payObj.currentOrderIds,
      {},
    );
    if (response.status === HTTP_OK) {
      if (
        response?.data &&
        response?.data.isError !== undefined &&
        response?.data.isError !== null &&
        response?.data.isError
      ) {
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(hoDashboardActions.setOrderProductApprovalComplete());
      }
    } else {
      yield put(hoDashboardActions.setOrderProductApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderProductApprovalError());
  }
}

// order reject  watcher
export function* fetchHoOrderProductRejectDataWatcher() {
  yield takeEvery(
    fetchOrderProductRejectDataTypeName,
    fetchHoOrderProductRejectDataWorker,
  );
}

// order Reject worker
export function* fetchHoOrderProductRejectDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderPopup.rejectOrder}${payObj.currentOrderId}&RejectionRemarks=${payObj.rejectionRemarks}&staffPosId=${payObj.staffPositionId}&currentUser=${payObj.UserId}`;
  try {
    yield put(hoDashboardActions.setOrderProductRejectLoading());
    const response = yield call(NetworkService.post, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        hoDashboardActions.setOrderProductRejectData(response?.data?.response),
      );
      yield put(hoDashboardActions.setOrderProductApprovalComplete());
    } else {
      yield put(hoDashboardActions.setOrderProductRejectError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderProductRejectError());
  }
}

// order Sync watcher
export function* fetchHoOrderSyncDataWatcher() {
  yield takeEvery(fetchOrderSyncDataTypeName, fetchHoOrderSyncDataWorker);
}

// order sync worker
export function* fetchHoOrderSyncDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.syncOrder}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderSyncLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      ToasterService.hideToaster();
      ToasterService.showToaster(response.data.response, ToastType.SUCCESS);
    } else {
      yield put(hoDashboardActions.setOrderSyncError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderSyncError());
  }
}

// order reject options watcher
export function* fetchHoOrderRejectOptionsWatcher() {
  yield takeEvery(
    fetchRejectOptionsDataTypeName,
    fetchHoOrderRejectOptionsWorker,
  );
}

// orderreject options worker
export function* fetchHoOrderRejectOptionsWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderRejectOptions}`;
  try {
    yield put(hoDashboardActions.setOrderRejectOptionsLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        hoDashboardActions.setOrderRejectOptionsData(response?.data?.response),
      );
    } else {
      yield put(hoDashboardActions.setOrderRejectOptionsError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderRejectOptionsError());
  }
}
