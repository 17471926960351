import {useState, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';

import {
  fetchStaffHoldCreator,
  staffHoldStateActions,
} from '@app/screens/employee-specific-utilities/pages/staff-hold/redux/slice';
import {staffHoldStateSelector} from '@app/screens/employee-specific-utilities/pages/staff-hold/redux/selectors';
import {filterSelector} from '../../components/right-sidebar/redux/filterHandlerSelector';
import {filterStateActions} from '../../components/right-sidebar/redux/filterHandler';
import {SearchBar} from '../search-bar';
import {store} from '../../App';

export const Utilas: any = {
  handleTypeChange:null,
  textBoxOnChangeHandler:null,
 
};
type EmployeeType = any;

export const SearchBarStaffHold = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const staffHoldAutosuggestLoading = useSelector(
    staffHoldStateSelector.getStaffHoldSuggestionsLoading(),
    shallowEqual,
  );

  const staffHoldAutosuggestData = useSelector(
    staffHoldStateSelector.getStaffHolds(),
    shallowEqual,
  );

  const selectedStaffHoldInAutoSuggest = useSelector(
    staffHoldStateSelector.getStaffHoldAutoSuggestValue(),
    shallowEqual,
  );

  const filter = useSelector(filterSelector.getFilterOptions());

  const handleTypeChange = useCallback(
    function (value: EmployeeType) {
      dispatch(
        filterStateActions.setFilterOptions({
          ...filter,
          roleIds: value === 'ALL' ? null : value,
        }),
      );
      dispatch(
        staffHoldStateActions.setStaffHoldLoadingPerPage({
          pageNo: 0,
          perPage: 20,
        }),
      );
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchStaffHoldCreator());
    },
    [filter],
  );

  const textBoxOnChangeHandler = useCallback(function (value: any) {
    let filterReq = store?.getState()?.filterState?.filterOptions;
    dispatch(
      filterStateActions.setFilterOptions({
        ...filterReq,
        searchData: value,
      }),
    );
    dispatch(
      staffHoldStateActions.setStaffHoldLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
  
    dispatch(filterStateActions.setFilterApplied(true));
    dispatch(fetchStaffHoldCreator());
  }, []);
  Utilas.handleTypeChange=handleTypeChange;
  Utilas.textBoxOnChangeHandler=textBoxOnChangeHandler;
  return (
    <SearchBar
      open={open}
      setOpen={setOpen}
      autocompleteOnChangeHandler={() => {}}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={staffHoldAutosuggestData}
      selectedAutoSuggestValue={selectedStaffHoldInAutoSuggest}
      suggestionsLoading={staffHoldAutosuggestLoading}
      handleTypeChange={handleTypeChange}
    />
  );
};
