import {IconButton, Tooltip} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomTitle from '../common-component/CustomTitle';

interface DoctorRequestHeaderProps {
  doctorData: any;
  firstFormView: boolean;
  arrowBack: () => void;
}

export const DoctorRequestHeader: React.FC<DoctorRequestHeaderProps> = ({
  doctorData,
  firstFormView,
  arrowBack,
}) => {
  const firstFormHeader = () => (
    <div>
      <CustomTitle title={'Basic Customer Information'} variant={'h6'} />

      <CustomTitle
        title={`Dr. ${doctorData ? doctorData.name : ''} | ${
          doctorData ? doctorData.cityName : ''
        }`}
        variant={'h6'}
      />
    </div>
  );

  const secondFormHeader = () => (
    <div>
      <div style={{marginBottom: 20}}>
        <Tooltip title="Back to Previous Page">
          <IconButton
            size="small"
            data-testId={'arrow-Back'}
            style={{
              backgroundColor: 'transparent',
              border: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={arrowBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </Tooltip>
      </div>

      <CustomTitle title={'Customer business information'} variant={'h6'} />
    </div>
  );
  return firstFormView ? firstFormHeader() : secondFormHeader();
};
