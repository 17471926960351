import Typography from '@mui/material/Typography';
import {Box, Tabs, Tab} from '@mui/material';
import {
  RootStateOrAny,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import {strings} from '@app/common/strings';
import {
  SwitchingNo,
  setScreenNo,
} from '../../../screens/zho/pages/fc-admin/redux/tabName';
import {useEffect, useState} from 'react';
import {Stack} from '@mui/system';
import {SwitchingName} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import Colors from '@app/themes/colors';
import {useLocation} from 'react-router-dom';

interface ReusableTabsProps {
  tabNames: string[];
}

export const RequestFormNavbar = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const tabNames = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.names,
    shallowEqual,
  );

  const headerActiveIndex = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const hoTabs = [
    strings.zho.hoAdmin.dashboard.order,
    strings.zho.hoAdmin.dashboard.invoicing,
    strings.zho.hoAdmin.dashboard.Summary,
  ];

  const adminTabs = [
    strings.zho.fcAdmin.dashboard.requestForm,
    strings.zho.fcAdmin.dashboard.summary,
    strings.zho.fcAdmin.dashboard.updateTotalTradeDiscount,
    strings.zho.fcAdmin.dashboard.manageUserRole,
    strings.zho.fcAdmin.dashboard.accessControl,
  ];

  useEffect(() => {
    if (pathname === '/fc-admin') {
      dispatch(setScreenNo(1));
      dispatch(SwitchingName(adminTabs));
    } else if (pathname === '/ho-admin') {
      dispatch(setScreenNo(2));
      dispatch(SwitchingName(hoTabs));
    }
    return () => {
      dispatch(SwitchingName([]));
    };
  }, [pathname]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        dispatch(SwitchingNo(0));
        break;
      case 1:
        dispatch(SwitchingNo(1));
        break;
      case 2:
        dispatch(SwitchingNo(2));
        break;
      case 3:
        dispatch(SwitchingNo(3));
        break;
      case 4:
        dispatch(SwitchingNo(4));
        break;
      default:
        dispatch(SwitchingNo(0));
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '0px',
      transform: 'translateY(20px)',
      marginTop: '-20px',
    },
    tab: {
      color: '#322B7C',
      fontSize: '0.7rem',
      padding: '8px 25px',
      marginRight: `${tabNames.length > 3 ? '0px' : '4rem'}`,
      minHeight: '2rem',
      height: '2.5rem',
      textTransform: 'none',
    },
    goButton: {
      border: `3px solid ${Colors.primary}`,
      backgroundColor: Colors.primary,
      borderRadius: '5px',
      width: 'auto',
      height: '35px',
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: '600',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'center',
      marginLeft: 10,
      color: Colors.white,
      cursor: 'pointer',
      '&:hover': {
        background: Colors.primary,
      },
      '&:disabled': {
        backgroundColor: Colors.grey[100],
        border: `3px solid ${Colors.grey[100]}`,
      },
      '& .css-1r6mvst-MuiToolbar-root': {
        marginRight: '0px !important',
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="h6"
          noWrap
          fontSize="22.7px"
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          ZHO
        </Typography>
      </Stack>
      <Tabs
        aria-label="request form tabs"
        indicatorColor="primary"
        textColor="primary"
        sx={{
          minHeight: '0.5rem',
          marginTop: '2rem',
        }}
        onChange={handleTabChange}
        value={headerActiveIndex}>
        {tabNames.map((name: any, index: number) => (
          <Tab
            key={index}
            sx={[
              styles.tab,
              {
                fontSize: '12.7px',
                fontWeight: `${
                  headerActiveIndex === index ? 'bold' : 'normal !important'
                }`,
              },
            ]}
            label={name}
          />
        ))}
      </Tabs>
    </Box>
  );
};
