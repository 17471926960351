import {useEffect, useState, useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  Toolbar,
  Autocomplete,
  FormControl,
  InputAdornment,
  Stack,
  debounce,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import {Close as CloseIcon, Search} from '@mui/icons-material';

import {navbarStyles} from '@app/components/widgets/AntSwitch/AntSwitch';
import {
  fetchEmployeesOnHoldCreator,
  fetchHoldEmployeeSuggestionsCreator,
  fetchStaffHoldHistoryCreator,
  staffHoldStateActions,
  fetchStaffHoldCreator,
} from '../../pages/staff-hold/redux/slice';
import {staffHoldStateSelector} from '../../pages/staff-hold/redux/selectors';
import {useStyles, DisabledIcon} from '../../helpers';
import moment from 'moment';
import {CustomTable} from '@app/components/widgets/CustomTable';
import {appSelector} from '@app/store/selectors';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {store} from '@app/App';

export const UtilList: any = {
  handleDropdownChange: null,
  handleSearch: null,
  handleTypeChange: null,
  onPageChangeHandler: null,
  onRowsPerPageChangeHandler: null,
  isEmpHoldOptionEqualToValue: null,
  onDeepDiveEmpHold: null,
  onCloseHandler: null,
  getEmpHoldDropdownOptionLabel: null,
};

type ListOfEmployeesOnHoldProps = {
  visible: boolean;
  onClose: () => void;
};

export const ListOfEmployeesOnHold: React.FC<ListOfEmployeesOnHoldProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dropdownValue, setDropdownValue] = useState<any[]>([null]);
  const [localEmployeesOnHoldTotalCount, setLocalEmployeesOnHoldTotalCount] =
    useState<number>(0);
  const [open, setOpen] = useState(false);
  const request = useSelector(filterSelector.getFilterOptions());
  const roleId = request?.roleIds == null ? null : request?.roleIds;
  const rolesDropdownData = useSelector(appSelector.getRolesDropDown());
  const rolesDropdownDataAll = [
    ...rolesDropdownData,
    {
      id: null,
      name: 'All',
      rank: 0,
      shortName: 'ALL',
      staffPositionId: 0,
    },
  ];
  rolesDropdownDataAll.sort((a, b) => a.rank - b.rank);

  const filter = useSelector(filterSelector.getFilterOptions());
  const handleTypeChange = useCallback(
    function (value: any) {
      dispatch(
        filterStateActions.setFilterOptions({
          ...filter,
          roleIds: value === 'ALL' ? null : value,
        }),
      );
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchEmployeesOnHoldCreator());
    },
    [filter],
  );

  const {
    employeesOnHold,
    employeesOnHoldSnapShot,
    employeesOnHoldLoading,
    employeesOnHoldAutoSuggestion,
    employeesOnHoldSearchText,
  } = useSelector(staffHoldStateSelector.getStaffHoldState());
  const historyLoading = useSelector(
    staffHoldStateSelector.getHistoryLoading(),
  );

  const handleSearch = useCallback(
    debounce(
      (searchText: string) =>
        dispatch(fetchHoldEmployeeSuggestionsCreator(searchText)),
      600,
    ),
    [],
  );

  const handleDropdownChange = (event: any) => {
    const selectedValues = event.target.value as any[];
    let values: number[] = [];
    if (selectedValues?.length > 0) {
      if (selectedValues.includes(null)) {
        values = selectedValues.filter(item => item !== null);
        return handleTypeChange(values as any);
      }
      return handleTypeChange(selectedValues as any);
    } else if (selectedValues?.length === 0) {
      handleTypeChange(null as any);
    }
  };
  const columns = [
    {
      field: 'employeeName',
      headerName: 'Name',
      headerClassName: 'boldHeader',
      width: 202,
      renderCell: (row?: any) => (
        <Stack>
          <Typography className={`${classes.title} ${classes.normalFont}`}>
            {row?.employeeName}
          </Typography>
          <Typography className={`${classes.subTitle} ${classes.normalFont}`}>
            Emp ID: {row?.employeeId}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'role',
      headerName: 'Role | Division',
      headerClassName: 'boldHeader',
      width: 140,
      renderCell: (row?: any) => (
        <Stack>
          <Typography
            className={`${classes.title} ${classes.normalFont}`}
            variant="body1">
            {row?.roleName}
          </Typography>
          <Typography
            className={`${classes.company} ${classes.normalFont}`}
            variant="caption">
            {row?.division}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'managerName',
      headerName: "Manager's Name",
      headerClassName: 'boldHeader',
      width: 130,
    },
    {
      field: 'holdDate',
      headerName: 'Date',
      headerClassName: 'boldHeader',
      renderCell: (row?: any) => moment(row?.holdDate).format('DD/MM/YY'),
    },
    {
      field: 'isSalaryOnHold',
      headerName: 'Salary',
      headerClassName: 'boldHeader',
      width: 100,
      renderCell: (row?: any) => row?.isSalaryOnHold == 1 && <DisabledIcon />,
    },
    {
      field: 'isExpenseOnHold',
      headerName: 'Expense',
      headerClassName: 'boldHeader',
      width: 100,
      renderCell: (row?: any) => row?.isExpenseOnHold == 1 && <DisabledIcon />,
    },
    {
      field: 'isDCROnHold',
      headerName: 'DCR',
      width: 100,
      headerClassName: 'boldHeader',
      renderCell: (row?: any) => row?.isDCROnHold == 1 && <DisabledIcon />,
    },
    {
      field: 'isIncentiveOnHold',
      headerName: 'Incentive',
      headerClassName: 'boldHeader',
      renderCell: (row?: any) =>
        row?.isIncentiveOnHold == 1 && <DisabledIcon />,
    },
    {
      field: 'isInputandsamplesOnHold',
      headerName: 'Input And Samples',
      headerClassName: 'boldHeader',
      width: 150,
      renderCell: (row?: any) =>
        row?.isInputandsamplesOnHold == 1 && <DisabledIcon />,
    },
  ];

  const EmployeesOnHoldTotalCount = useSelector(
    staffHoldStateSelector.getEmployeesOnHoldTotalCount(),
  );

  // employeesOnHold page no.
  const EmployeesOnHoldPageNo = useSelector(
    staffHoldStateSelector.getEmployeesOnHoldPageNo(),
  );

  //employeesOnHold per pages.
  const EmployeesOnHoldPerPage = useSelector(
    staffHoldStateSelector.getEmployeesOnHoldrowsPerPage(),
  );
  useEffect(() => {
    dispatch(fetchEmployeesOnHoldCreator());
  }, [EmployeesOnHoldPageNo, EmployeesOnHoldPerPage]);

  // On page change.
  const onPageChangeHandler = (newPage: any) => {
    dispatch(
      staffHoldStateActions.setEmployeesOnHoldPerPage({
        pageNo: newPage,
        perPage: EmployeesOnHoldPerPage,
      }),
    );
  };
  // On rows per page change .
  const onRowsPerPageChangeHandler = (employeesOnHoldrowsPerPage: number) => {
    dispatch(
      staffHoldStateActions.setEmployeesOnHoldPerPage({
        pageNo: 0,
        perPage: employeesOnHoldrowsPerPage,
      }),
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  useEffect(() => {
    setLocalEmployeesOnHoldTotalCount(prevCount => {
      if (prevCount === 0) {
        return EmployeesOnHoldTotalCount;
      }
      return prevCount;
    });
  }, [EmployeesOnHoldTotalCount]);

  useEffect(() => {
    dispatch(staffHoldStateActions.setEmployeesOnHoldSearchText(''));
    dispatch(
      staffHoldStateActions.setSelectedEmployeesOnHoldAutoSuggest({
        displayText: '',
        value: '',
      }),
    );
  }, []);

  useEffect(() => {
    employeesOnHoldSearchText.length > 0 &&
      handleSearch(employeesOnHoldSearchText);
  }, [employeesOnHoldSearchText]);

  useEffect(() => {
    employeesOnHoldAutoSuggestion &&
      Object.keys(employeesOnHoldAutoSuggestion).length > 0 &&
      dispatch(
        staffHoldStateActions.setEmployeesOnHold(
          employeesOnHoldSnapShot?.length > 0 &&
            employeesOnHoldSnapShot.filter(
              (emp: any) =>
                emp?.name === employeesOnHoldAutoSuggestion?.displayText,
            ),
        ),
      );
  }, [employeesOnHoldAutoSuggestion]);

  useEffect(() => {
    dispatch(fetchEmployeesOnHoldCreator());
  }, [dispatch]);

  const textBoxOnChangeHandler = useCallback(function (value: any) {
    let filterReq = store?.getState()?.filterState?.filterOptions;
    dispatch(
      filterStateActions.setFilterOptions({
        ...filterReq,
        searchData: value,
      }),
    );
    dispatch(filterStateActions.setFilterApplied(true));
    dispatch(fetchEmployeesOnHoldCreator());
  }, []);

  const onChangeAutoComplete = useCallback(
    function (event: any) {
      textBoxOnChangeHandler(event.target.value);
    },
    [request],
  );

  const onOpenEmpHoldAutoComplete = useCallback(
    function () {
      if (request.searchData.length > 0) {
        setOpen(true);
      } else if (request.searchData.length === 0) {
        setOpen(false);
      }
    },
    [request],
  );
  const onCloseEmplHoldAutoComplete = useCallback(() => setOpen(false), []);
  const isEmpHoldOptionEqualToValue = useCallback(function (
    option: any,
    value: any,
  ) {
    return option?.employeeName === value?.employeeName;
  },
  []);

  const getEmpHoldDropdownOptionLabel = useCallback(function (option: any) {
    if (option?.employeeId?.length > 0)
      return `${option?.employeeId}-${option?.employeeName || option?.name}`;
    else return '';
  }, []);
  const renderEmpHoldDropdownOption = useCallback(
    params => (
      <TextField
        onChange={onChangeAutoComplete}
        {...params}
        placeholder={'Search by name or employee code'}
        value={request.searchData}
        sx={navbarStyles.textField}
        InputProps={{
          ...params.InputProps,
          type: 'search',
          onKeyDown: e => {
            if (e.key === 'Enter') {
              setOpen(false);
              e.stopPropagation();
            }
          },
          startAdornment: (
            <InputAdornment position="start" sx={navbarStyles.padding5}>
              <Search />
            </InputAdornment>
          ),
          endAdornment: <></>,
        }}
      />
    ),
    [request],
  );
  const onDeepDiveEmpHold = useCallback(
    (id?: number) => dispatch(fetchStaffHoldHistoryCreator(id)),
    [],
  );
  const selectedStaffHoldInAutoSuggest = useSelector(
    staffHoldStateSelector.getStaffHoldAutoSuggestValue(),
    shallowEqual,
  );
  const onChangeHandler = useCallback(function (_event: any, value: any) {
    textBoxOnChangeHandler(value?.employeeId);
  }, []);

  const onCloseHandler = () => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(filterStateActions.setFilterApplied(false));
    dispatch(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchData: '',
        pageNo: 0,
        rowPerPage: 20,
        divisionIds: [],
        stateIds: [],
        roleIds: null,
      }),
    );
    dispatch(staffHoldStateActions.setEmployeesOnHold([]));
    dispatch(staffHoldStateActions.setEmployeesOnHoldTotalCount(0));
    dispatch(fetchStaffHoldCreator());
    onClose();
  };
  UtilList.handleDropdownChange = handleDropdownChange;
  UtilList.handleSearch = handleSearch;
  UtilList.handleTypeChange = handleTypeChange;
  UtilList.onPageChangeHandler = onPageChangeHandler;
  UtilList.onRowsPerPageChangeHandler = onRowsPerPageChangeHandler;
  UtilList.isEmpHoldOptionEqualToValue = isEmpHoldOptionEqualToValue;
  UtilList.onDeepDiveEmpHold = onDeepDiveEmpHold;
  UtilList.onCloseHandler = onCloseHandler;
  UtilList.getEmpHoldDropdownOptionLabel = getEmpHoldDropdownOptionLabel;
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={visible}
      PaperProps={{style: {borderRadius: 16}}}>
      <Toolbar className={classes.toolbar}>
        <Box
          className={classes.container}
          style={{width: '100%', height: 'auto'}}>
          <Box className={classes.flexSpaceBetween}>
            <Typography variant="h6" noWrap component="div">
              {`List of Employees on Hold - ${localEmployeesOnHoldTotalCount} employees`}
            </Typography>
            <Button onClick={onCloseHandler}>
              <CloseIcon sx={{color: '#1c1939'}} />
            </Button>
          </Box>

          <Box className={classes.innerToolbar}>
            <FormControl>
              <Autocomplete
                open={open}
                freeSolo
                value={selectedStaffHoldInAutoSuggest}
                sx={{width: 870}}
                data-testid={'search-autocomplete'}
                onChange={onChangeHandler}
                onOpen={onOpenEmpHoldAutoComplete}
                onClose={onCloseEmplHoldAutoComplete}
                isOptionEqualToValue={isEmpHoldOptionEqualToValue}
                getOptionLabel={getEmpHoldDropdownOptionLabel}
                options={
                  employeesOnHold && employeesOnHold?.length > 0
                    ? employeesOnHold
                    : []
                }
                loading={employeesOnHoldLoading}
                renderInput={renderEmpHoldDropdownOption}
              />
            </FormControl>

            <div style={{width: 200, display: 'flex', flexDirection: 'column'}}>
              <span id="list-of-employees-role-name-label">Roles</span>
              <Select
                data-testid={'list-of-employees-roleDropdown'}
                multiple
                defaultValue={[null]}
                value={roleId ?? [null]}
                onChange={handleDropdownChange}
                style={{
                  backgroundColor: '#f2f2f2',
                  height: 28,
                  width: 200,
                }}
                renderValue={selected => {
                  const selectedRoles = rolesDropdownDataAll.filter(role =>
                    selected.includes(role.id),
                  );
                  return selectedRoles.map(role => role.shortName).join(', ');
                }}
                onClose={() => {
                  !dropdownValue?.length && setDropdownValue([null]);
                }}
                MenuProps={MenuProps}>
                {rolesDropdownDataAll.map(role => (
                  <MenuItem
                    key={role.id}
                    value={role.id}
                    disabled={role.id === null}
                    style={{
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Poppins',
                      fontSize: '0.928571rem',
                      lineHeight: 1.5,
                      color: 'rgb(50, 43, 124)',
                    }}>
                    <Checkbox
                      checked={
                        roleId == null
                          ? roleId === role.id
                          : roleId.includes(role.id)
                      }
                    />
                    <ListItemText primary={role.shortName} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
        </Box>
      </Toolbar>
      <Box style={{height: 600, overflow: 'auto', padding: '0px 20px'}}>
        <CustomTable
          isDeepDive={false}
          rows={employeesOnHold}
          loading={employeesOnHoldLoading}
          columns={columns}
          nestedColumns={[]}
          uniqueProperty="holdDate"
          nestedData={[]}
          onDeepDive={onDeepDiveEmpHold}
          deepDiveIsLoading={historyLoading}
          nestedPagination={false}
          tablePagination={{
            pageNo: EmployeesOnHoldPageNo,
            perPage: EmployeesOnHoldPerPage,
          }}
          totalcount={EmployeesOnHoldTotalCount}
          onRowsPerPageChange={onRowsPerPageChangeHandler}
          onPageChange={onPageChangeHandler}
        />
      </Box>
    </Dialog>
  );
};
