import {createAction, createSlice} from '@reduxjs/toolkit';

export const selectedEmployeesInitialState = {
  selectedEmployees: [],
  totalCount:0,
  selectedEmployeesSnapShot: [],
  selectedEmployeesCount: 0,
  selectedEmployeesLoading: false,
  selectedEmployeesSuggestLoading: false,
  selectedEmployeesSuccess: false,
  selectedEmployeesError: false,
  selectedEmployeesErrorMessage: '',
  designations: [],
  hqs: [],
  searchText: '',
  selectedAutoSuggestValue: {displayText: '', value: ""},
  autoSuggestData: [],
  salaryBreakup: {
    grossPay: 0,
    basic: 0,
    imgi: 0,
    metro: 0,
    medical: 0,
    bonus: 0,
    splBonus: 0,
    lta: 0,
    total: 0,
  },
};

export const fetchSelectedEmployeesCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_SELECTED_EMPLOYEES',
);
export const fetchSelectedEmployeesCreatorTypeName =
  fetchSelectedEmployeesCreator().type;

export const updateSelectedEmployeesCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/UPDATE_SELECTED_EMPLOYEES',
);
export const updateSelectedEmployeesCreatorTypeName =
  updateSelectedEmployeesCreator({}).type;

export const fetchSelectedEmployeesSalaryCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_SELECTED_EMPLOYEES_SALARY',
);
export const fetchSelectedEmployeesSalaryCreatorTypeName =
  fetchSelectedEmployeesSalaryCreator().type;

export const fetchSalaryTableCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_SALARY_TABLE',
);
export const fetchSalaryTableCreatorTypeName =
  fetchSalaryTableCreator({}).type;

export const updateSelectedEmployeesSalaryCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/UPDATE_SELECTED_EMPLOYEES_SALARY',
);
export const updateSelectedEmployeesSalaryCreatorTypeName =
  updateSelectedEmployeesSalaryCreator({}).type;

export const releaseAppointmentLetterCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/RELEASE_APPOINTMENT_LETTER',
);
export const releaseAppointmentLetterCreatorTypeName =
  releaseAppointmentLetterCreator().type;

export const fetchSelectedEmployeesSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_SELECTED_EMPLOYEES_SUGGESTIONS',
);
export const fetchSelectedEmployeesSuggestionsCreatorTypeName =
  fetchSelectedEmployeesSuggestionsCreator(null).type;

export const fetchDesignationsDataCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_DIVISION_DROPDOWN',
);
export const fetchDesignationsDataCreatorTypeName =
  fetchDesignationsDataCreator().type;

export const fetchHqDataCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_HQ_DROPDOWN',
);
export const fetchHqDataCreatorTypeName =
  fetchHqDataCreator(0).type;


const selectedEmployeesStateHandler = createSlice({
  name: 'SELECTED_EMPLOYEES',
  initialState: selectedEmployeesInitialState,
  reducers: {
    setSelectedEmployeesLoading: (prevState, action) => {
      return {
        ...prevState,
        selectedEmployeesLoading: action?.payload,
      };
    },
    setSelectedEmployeesSuccess: (prevState, action) => {
      return {
        ...prevState,
        selectedEmployeesSuccess: action?.payload,
      };
    },
    setSelectedEmployees: (prevState, action) => {
      return {
        ...prevState,
        selectedEmployees: action?.payload,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setSelectedEmployeesSnapshot: (prevState, action) => {
      return {
        ...prevState,
        selectedEmployeesSnapShot: action?.payload,
      };
    },
    setSelectedEmployeesError: (prevState, action) => {
      return {
        ...prevState,
        selectedEmployeesError: true,
        selectedEmployeesErrorMessage: action?.payload,
      };
    },
    clearSelectedEmployeesError: prevState => {
      return {
        ...prevState,
        selectedEmployeesError: false,
        selectedEmployeesErrorMessage: '',
      };
    },
    setSelectedEmployeesSuggestionsLoading: (prevState, action) => {
      return {
        ...prevState,
        selectedEmployeesSuggestLoading: action?.payload,
      };
    },
    setEmployeeType: (prevState, action) => {
      return {
        ...prevState,
        employeeType: action?.payload,
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setDesignations: (prevState, action) => {
      return {
        ...prevState,
        designations: action?.payload,
      };
    },
    setHqs: (prevState, action) => {
      return {
        ...prevState,
        hqs: action?.payload,
      };
    },
    setSalaryBreakup: (prevState, action) => {
      return {
        ...prevState,
        salaryBreakup: action?.payload,
      };
    },
  },
});

export const selectedEmployeesStateActions =
  selectedEmployeesStateHandler.actions;
export const selectedEmployeesStateReducer =
  selectedEmployeesStateHandler.reducer;
