import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { companyAssetsStateSelector } from '@app/screens/employee-specific-utilities/pages/company-assets/redux/selector';
import {
  companyAssetsStateActions,
  fetchCompanyAssetsCreator,
  fetchCompanyAssetsSuggestoionsCreator,
} from '@app/screens/employee-specific-utilities/pages/company-assets/redux/slice';
import { SearchBar } from '../search-bar';

type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarCompanyAssets = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [type, setType] = useState<EmployeeType>('ALL');

  const handleTypeChange = useCallback(function(value: EmployeeType){
    setType(value);
    dispatch(companyAssetsStateActions.setSearchText(""),);
    dispatch(companyAssetsStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}))
  },[])

  const {
    snapshot, searchText, suggestionsLoading,
    selectedAutoSuggestValue, autoSuggestData,
  } = useSelector(
    companyAssetsStateSelector.getCompanyAssets(),
    shallowEqual,
  );


  const handleSearch = useCallback(
    debounce((searchText: string) => dispatch(fetchCompanyAssetsSuggestoionsCreator(searchText)), 600),
    []
  )

  const autocompleteOnChangeHandler = useCallback(function(value:any){
    if (!value) {
      dispatch(fetchCompanyAssetsCreator());
    }

    dispatch(
      companyAssetsStateActions.setSelectedAutoSuggest(
        value,
      ),
    );
  },[])

  const textBoxOnChangeHandler = useCallback(function(event:any){
    dispatch(
      companyAssetsStateActions.setSearchText(
        event.target.value,
      ),
    );
  },[])

  useEffect(()=>{
    dispatch(companyAssetsStateActions.setSearchText(""),);
    dispatch(companyAssetsStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}))
  },[])

  useEffect(() => {
    searchText.length > 0 && handleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    selectedAutoSuggestValue &&
      Object.keys(selectedAutoSuggestValue).length > 0 &&
      dispatch(
        companyAssetsStateActions.setCompanyAssets(
          snapshot?.length>0 && snapshot.filter(
            (emp: any) => emp?.name === selectedAutoSuggestValue?.name
          )
        )
      )
  }, [selectedAutoSuggestValue]);

  useEffect(() => {
    if (type !== "ALL") {
      const newSnap = snapshot?.length>0 && snapshot.filter(
        (emp: any) => emp?.role === type
      )
      dispatch(companyAssetsStateActions.setCompanyAssets(newSnap))
    } else {
      dispatch(companyAssetsStateActions.setCompanyAssets(snapshot));
    }
  }, [type])

  return (
    <SearchBar 
      open={open} 
      setOpen={setOpen} 
      autocompleteOnChangeHandler={autocompleteOnChangeHandler}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={autoSuggestData}
      selectedAutoSuggestValue={selectedAutoSuggestValue}
      suggestionsLoading={suggestionsLoading}
      handleTypeChange={handleTypeChange}     
      />
  );
};
