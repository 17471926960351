import {createAction, createSlice} from '@reduxjs/toolkit';

export const hoDashboardInitialState = {
  // Dropdown for state
  stateLoading: false,
  stateData: [],
  stateError: false,
  stateMsg: '',

  // Dropdown for hq
  hqLoading: false,
  hqData: [],
  hqError: false,
  hqMsg: '',

  // Dropdown for mr
  mrLoading: false,
  mrData: [],
  mrError: false,
  mrMsg: '',

  // Main request fomm table
  orderFormLoading: false,
  orderFormData: [],
  orderFormError: false,
  orderFormMsg: '',

  // orderForm Inner Table toggle
  orderInnerFormLoading: false,
  orderInnerFormData: [],
  orderInnerformError: false,
  orderInnerformMsg: '',

  // orderProduct form details
  orderFormProductLoading: false,
  orderFormProductData: [],
  orderFormProductError: false,
  orderFormProductMsg: '',

  // orderform popup approval status
  orderFormApprovalLoading: false,
  orderFormApprovalData: [],
  orderFormApprovalError: false,
  orderFormApprovalMsg: '',

  // order Approval
  orderProductApprovalLoading: false,
  orderProductApprovalError: false,
  orderProductApprovalMsg: '',
  orderProductApprovalComplete: false,

  //order Reject
  orderProductRejectLoading: false,
  orderProductRejectError: false,
  orderProductRejectMsg: '',

  //Order Sync
  orderSyncLoading: false,
  orderSyncError: false,
  orderSyncMsg: '',

  //order reject options
  orderRejectOptionsLoading: false,
  orderRejectOptionsData: [],
  orderRejectOptionsError: false,
  orderRejectOptionsMsg: '',
};

//  for state Dropdown
export const fetchStateDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_STATE_DATA',
  currentStaffPositionId => ({payload: currentStaffPositionId}),
);

export const fetchStateDataTypeName = fetchStateDataCreator.type;

//for ho Dropdown

export const fetchHqDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_HQ_DATA',
  currentStateId => ({payload: currentStateId}),
);

export const fetchHqDataTypeName = fetchHqDataCreator.type;

//for Mr Dropdown

export const fetchMrDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_MR_DATA',
  hqIds => ({payload: hqIds}),
);

export const fetchMrDataTypeName = fetchMrDataCreator.type;

// for Order form table Ho
export const fetchOrderFormDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderTableData',
  (currentRequestStatus, statusToggleNo) => ({
    payload: {currentRequestStatus, statusToggleNo},
  }),
);

export const fetchOrderFormDataTypeName = fetchOrderFormDataCreator.type;

// for Order innerTable
export const fetchOrderInnerFormDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderInnerTableData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderInnerFormDataTypeName =
  fetchOrderInnerFormDataCreator.type;

// for OrderForm Product Details
export const fetchOrderFormProductDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderFormProductData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderFormProductDataTypeName =
  fetchOrderFormProductDataCreator.type;

// for OrderForm Approval
export const fetchOrderFormApprovalDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderFormApprovalData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderFormApprovalDataTypeName =
  fetchOrderFormApprovalDataCreator.type;

// for OrderForm product Approval
export const fetchOrderProductApprovalCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderProductApprovalData',
  (staffPositionId, UserId, currentOrderIds) => ({
    payload: {staffPositionId, UserId, currentOrderIds},
  }),
);

export const fetchOrderProductApprovalDataTypeName =
  fetchOrderProductApprovalCreator.type;

// for OrderForm Reject
export const fetchOrderProductRejectCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderProductRejectData',
  (staffPositionId, rejectionRemarks, UserId, currentOrderId) => ({
    payload: {staffPositionId, rejectionRemarks, UserId, currentOrderId},
  }),
);

export const fetchOrderProductRejectDataTypeName =
  fetchOrderProductRejectCreator.type;

// for OrderSync
export const fetchOrderSyncCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderSyncData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderSyncDataTypeName = fetchOrderSyncCreator.type;

// for reject order options
export const fetchRejectOptionsCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_RejectOptionsData',
);

export const fetchRejectOptionsDataTypeName = fetchRejectOptionsCreator.type;

const hoDashboardStatehandler = createSlice({
  name: 'HO_DASHBOARD_HANDLER',
  initialState: hoDashboardInitialState,
  reducers: {
    // filter selected data
    setStateLoading: prevState => {
      return {
        ...prevState,
        stateLoading: true,
        stateData: [],
        stateError: false,
        stateMsg: '',
      };
    },

    setStateData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        stateLoading: false,
        stateData: payload,
        stateError: false,
      };
    },

    setStateError: prevState => {
      return {
        ...prevState,
        stateLoading: false,
        stateData: [],
        stateError: true,
        stateMsg: '',
      };
    },

    // filter Hq data
    setHqLoading: prevState => {
      return {
        ...prevState,
        hqLoading: true,
        hqData: [],
        hqError: false,
        hqMsg: '',
      };
    },

    setHqData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqLoading: false,
        hqData: payload,
        hqError: false,
      };
    },

    setHqError: prevState => {
      return {
        ...prevState,
        hqLoading: false,
        hqData: [],
        hqError: true,
        hqMsg: '',
      };
    },

    // filter Mr data
    setMrLoading: prevState => {
      return {
        ...prevState,
        mrLoading: true,
        mrData: [],
        mrError: false,
        mrMsg: '',
      };
    },

    setMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        mrLoading: false,
        mrData: payload,
        mrError: false,
      };
    },

    setMrError: prevState => {
      return {
        ...prevState,
        mrLoading: false,
        mrData: [],
        mrError: true,
        mrMsg: '',
      };
    },

    // orderForm main table Data
    setOrderFormLoading: prevState => {
      return {
        ...prevState,
        orderFormLoading: true,
        orderFormData: [],
        orderFormError: false,
        orderFormMsg: '',
      };
    },

    setOrderFormData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderFormLoading: false,
        orderFormData: payload,
        orderFormError: false,
      };
    },

    setOrderFormError: prevState => {
      return {
        ...prevState,
        orderFormLoading: false,
        orderFormData: [],
        orderFormError: true,
        orderFormMsg: '',
      };
    },

    // orderForm innerTable
    setOrderInnerFormLoading: prevState => {
      return {
        ...prevState,
        orderInnerFormLoading: true,
        orderInnerFormData: [],
        orderInnerformError: false,
        orderInnerformMsg: '',
      };
    },

    setOrderInnerFormData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderInnerFormLoading: false,
        orderInnerFormData: payload,
        orderInnerformError: false,
      };
    },

    setOrderInnerFormError: prevState => {
      return {
        ...prevState,
        orderInnerFormLoading: false,
        orderInnerFormData: [],
        orderInnerformError: true,
        orderInnerformMsg: '',
      };
    },

    // orderPopup product Details
    setOrderFormProductLoading: prevState => {
      return {
        ...prevState,
        orderFormProductLoading: true,
        orderFormProductData: [],
        orderFormProductError: false,
        orderFormProductMsg: '',
      };
    },

    setOrderFormProductData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderFormProductLoading: false,
        orderFormProductData: payload,
        orderFormProductError: false,
      };
    },

    setOrderFormProductError: prevState => {
      return {
        ...prevState,
        orderFormProductLoading: false,
        orderFormProductData: [],
        orderFormProductError: true,
        orderFormProductMsg: '',
      };
    },

    // orderPopup Approval Details
    setOrderFormApprovalLoading: prevState => {
      return {
        ...prevState,
        orderFormApprovalLoading: true,
        orderFormApprovalData: [],
        orderFormApprovalError: false,
        orderFormApprovalMsg: '',
      };
    },

    setOrderFormApprovalData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderFormApprovalLoading: false,
        orderFormApprovalData: payload,
        orderFormApprovalError: false,
      };
    },

    setOrderFormApprovalError: prevState => {
      return {
        ...prevState,
        orderFormApprovalLoading: false,
        orderFormApprovalData: [],
        orderFormApprovalError: true,
        orderFormApprovalMsg: '',
      };
    },

    // order Product Approval Details
    setOrderProductApprovalLoading: prevState => {
      return {
        ...prevState,
        orderProductApprovalLoading: true,
        orderProductApprovalData: [],
        orderProductApprovalError: false,
        orderProductApprovalMsg: '',
      };
    },

    setOrderProductApprovalComplete: prevState => {
      return {
        ...prevState,
        orderProductApprovalComplete: !prevState.orderProductApprovalComplete,
      };
    },

    setOrderProductApprovalError: prevState => {
      return {
        ...prevState,
        orderProductApprovalLoading: false,
        orderProductApprovalData: [],
        orderProductApprovalError: true,
        orderProductApprovalMsg: '',
      };
    },

    // order Reject
    setOrderProductRejectLoading: prevState => {
      return {
        ...prevState,
        orderProductRejectLoading: true,
        orderProductRejectData: [],
        orderProductRejectError: false,
        orderProductRejectMsg: '',
      };
    },

    setOrderProductRejectData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderProductRejectLoading: false,
        orderProductRejectData: payload,
        orderProductRejectError: false,
      };
    },

    setOrderProductRejectComplete: prevState => {
      return {
        ...prevState,
        orderProductApprovalComplete: !prevState.orderProductApprovalComplete,
      };
    },

    setOrderProductRejectError: prevState => {
      return {
        ...prevState,
        orderProductRejectLoading: false,
        orderProductRejectData: [],
        orderProductRejectError: true,
        orderProductRejectMsg: '',
      };
    },

    // orderSync
    setOrderSyncLoading: prevState => {
      return {
        ...prevState,
        orderSyncLoading: true,
        orderSyncData: [],
        orderSyncError: false,
        orderSyncMsg: '',
      };
    },

    setOrderSyncError: prevState => {
      return {
        ...prevState,
        orderSyncLoading: false,
        orderSyncData: [],
        orderSyncError: true,
        orderSyncMsg: '',
      };
    },

    // orderreject options
    setOrderRejectOptionsLoading: prevState => {
      return {
        ...prevState,
        orderRejectOptionsLoading: true,
        orderRejectOptionsData: [],
        orderRejectOptionsError: false,
        orderRejectOptionsMsg: '',
      };
    },

    setOrderRejectOptionsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderRejectOptionsLoading: false,
        orderRejectOptionsData: payload,
        orderRejectOptionsError: false,
      };
    },

    setOrderRejectOptionsError: prevState => {
      return {
        ...prevState,
        orderRejectOptionsLoading: false,
        orderRejectOptionsData: [],
        orderRejectOptionsError: true,
        orderRejectOptionsMsg: '',
      };
    },
  },
});

export const hoDashboardActions = hoDashboardStatehandler.actions;
export const hoDashboardReducer = hoDashboardStatehandler.reducer;
