import {makeStyles} from '@mui/styles';
import {Delete, Edit, DisabledByDefaultRounded} from '@mui/icons-material';
import {Button, Typography, styled, ListItemText} from '@mui/material';

export const useStyles = makeStyles({
  container: {
    padding: '10px',
    height: '70vh',
    '& .boldHeader': {
      width: 46.7,
      height: 15.3,
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: 21.3,
      letterSpacing: 0,
      textAlign: 'left',
      color: '#747379',
    },
  },
  title: {
    color: '#1c1939',
    fontSize: 11,
    lineHeight: '21.3px',
    fontWeight: 'normal',
  },
  subTitle: {
    color: '#1a1c19',
    fontSize: 10.7,
    opacity: 0.6,
    fontWeight: 'normal',
  },
  normalFont: {
    fontWeight: 'normal',
  },
  downloadExcelLogo: {
    width: 25,
    marginRight: 5,
  },
  company: {
    color: '#1c1939',
    fontSize: 10.7,
    fontWeight: 'normal',
  },
  navbarComponentHeading: {
    fontWeight: '600',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#1c1939',
  },
  checkboxInput: {
    margin: '5px 0',
  },
  modalBox: {
    display: 'flex',
    flexDirection: 'column',
    // m: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '10px',
    marginBottom: '10px',
    width: '500',
    height: '400',
    border: 0.5,
    borderBlockColor: 'GrayText',
    padding: '5px',
    color: '#1e1c3b',
  },
  headerClass: {
    background: '#9FBEFF',
    borderRadius: 2,
  },
  dropdown: {
    height: 40,
  },

  datepicker: {
    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '10px',
      fontSize: '12.7px',
    },
    '& .css-1loy7j4-MuiInputBase-root-MuiOutlinedInput-root': {
      background: '#fff',
      zIndex: 1,
    },
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      fontSize: '12.7px',
      fontStyle: 'normal',
      borderColor: '#caccd1',
      color: '#caccd1',
      borderWidth: '1px',
      borderRadius: '4px',
    },
    '& .MuiInputBase-input': {
      fontSize: '12.7px',
      padding: '5.5px 14px',
      height: '25px',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '12.7px',
    },
  },
  multiselect: {
    '& .MuiSelect-select': {
      padding: '7px 14px !important',
    },
    marginBottom: '21.3px',
  },
  errorBorder: {
    border: '2px solid red',
    padding: '10px',
  },

  gridcss: {
    '& .css-11kxpxz-MuiDataGrid-root': {
      backgroundColor: '#fff',
    },
    '& .text-align-center': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },

    '& .css-vjchl4-MuiTableCell-root': {
      borderBottom: '0px solid #fff !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal !important',
      color: '#1c1939',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: 12,
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: 14.7,
      color: '#747379',
      textTransform: 'uppercase',
    },
    '& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: 14.7,
      color: '#747379',
      textTransform: 'uppercase',
    },
    '& .css-16r9fgn-MuiButtonBase-root-MuiButton-root ': {
      minWidth: '40px !important',
    },
  },
  textInput: {
    '& .MuiInputBase-input': {
      padding: '6px 12px',
      fontSize: '10.7px',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '10.7px',
    },
  },
  textInputTotal: {
    '& .MuiInputBase-input': {
      padding: '6px 12px',
      fontSize: '14.7px',
      color: '#1c1939',
      fontweight: 'bold',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '10.7px',
      fontweight: 'bold',
    },
   
  },
  salaryColumn: {
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 'normal',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  actionColumn: {
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 'normal',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: '8px',
  },
  releaseBtn: {
    fontSize: '12.7px',
    textTransform: 'capitalize',
    minWidth: 0,
    padding: '5px 15px',
    outline: '1px solid #322b7c',
  },
  staffHoldBtn: {
    fontSize: '12.7px',
    textTransform: 'capitalize',
    minWidth: 0,
    padding: '5px 15px',
    outline: '1px solid #322b7c',
  },
  staffHoldBtnDisable: {
    fontSize: '12.7px',
    textTransform: 'capitalize',
    minWidth: 0,
    padding: '5px 15px',
  },
  autocompleteInput: {
    '& .MuiAutocomplete-input': {
      padding: '0 12px !important',
      fontSize: '12.7px',
    },
    '& .MuiAutocomplete-input::placeholder': {
      fontSize: '12.7px',
    },
    '& .MuiAutocomplete-option': {
      fontSize: '2.7px !important',
    },
  },
  ratingColumn: {
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: 'normal',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  toolbar: {
    backgroundColor: '#dde8ec',
    color: '#1e1c3b',
    padding: '20px',
    borderRadius: '20px',
  },
  innerToolbar: {
    color: '#1e1c3b',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px !important',
    marginTop: '5px',
    gap: '8px',
  },

  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    padding: '5px',
    borderRadius: '5px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 12.7,
    minWidth: '0px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  recentRole: {
    textTransform: 'capitalize',
  },
  recentDivision: {
    textTransform: 'capitalize',
  },
  recentEmpName: {
    textTransform: 'capitalize',
  },
  recentManagerName: {
    textTransform: 'capitalize',
  },
  feedbackSeeMore: {
    width: '500px',
    minHeight: '300px',
    overflowY: 'auto',
    wordWrap: 'break-word',
    border: '0.5px solid #E0E0E0',
  },
});

export const DeleteIconEnabled = styled(Delete)({
  color: '#042875',
  cursor: 'pointer',
});

export const DeleteIconDisabled = styled(Delete)({
  color: '#ddd',
  cursor: 'pointer',
});

export const EditIcon = styled(Edit)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
}));

export const DisabledIcon = styled(DisabledByDefaultRounded)({
  color: '#c43500',
  fontSize: '16px',
});

export const CancelButton = styled(Button)({
  borderRadius: '5px',
  width: '150px',
  boxShadow: 'none',
  marginLeft: '10px',
});

export const ModalTitle = styled(Typography)({
  fontSize: '18px',
  lineHeight: '26px',
});

export const Label = styled(Typography)({
  fontSize: 12.7,
  lineHeight: '20px',
  color: '#1c1939',
  marginBottom: 10.7,
});

export const Text = styled(ListItemText)({
  fontSize: '12px',
  lineHeight: '20px',
  cursor: 'pointer',
});
