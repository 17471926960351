import {Grid, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import {useStyles} from './styles';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {FilePreviewTP} from './FilePreviewTP';
import React, {useState} from 'react';

type Props = {
  label?: string;
  fileName: string;
  url: string;
  blobFolderName: string;
  handleDownload?: () => void;
  customStyle?: React.CSSProperties;
};
export const FileDownloadBoxTP = ({
  label,
  fileName,
  url,
  blobFolderName,
  handleDownload,
  customStyle,
}: Props) => {
  const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);

  const openViewer = () => {
    setViewerIsOpen(true);
  };

  const closeViewer = () => {
    setViewerIsOpen(false);
  };
  const classes = useStyles();

  const trimFileName = () => {
    if (!fileName) {
      return '';
    }

    const fileExt = fileName.split('.').pop();
    if (fileExt !== undefined && fileName.length > 19) {
      return fileName.substring(0, 20) + '...' + '.' + fileExt;
    }

    return fileName;
  };

  const downloadIconFunction = () => {
    return (
      <>
        <Tooltip title="Preview File">
          <IconButton
            onClick={openViewer}
            style={{color: '#322b7c'}}
            data-testid="preview-button">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download File">
          <IconButton
            onClick={handleDownload}
            style={{color: '#322b7c'}}
            data-testid="download-button">
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      className={classes.textFieldcontainer}
      style={customStyle}>
      {label && (
        <Grid item>
          <Typography className={classes.textFieldLabel}>{label}</Typography>
        </Grid>
      )}
      <Grid item>
        <TextField
          value={trimFileName()}
          InputProps={{
            readOnly: true,
            className: classes.donwnloadBox,
            endAdornment: fileName && downloadIconFunction(),
          }}
        />
      </Grid>
      {viewerIsOpen && (
        <FilePreviewTP
          openModal={viewerIsOpen}
          closeModal={closeViewer}
          fileName={fileName.toString()}
          blobFolderName={blobFolderName.toString()}
          url={url.toString()}
        />
      )}
    </Grid>
  );
};
