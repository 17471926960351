import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {formatFilters} from '@app/utils/util';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {appSelector} from '@app/store/selectors';

import {
  fetchHrApprovalsCreatorTypeName,
  fetchHrApprovalsSuggestionsCreatorTypeName,
  fetchHrApprovalsHistoryCreatorTypeName,
  hrApprovalStateActions,
  hrApprovalDataGridStateActions,
  fetchHrPreviewCreatorTypeName,
  fetchHrRejectTypeName,
  fetchHrChangesTypeName,
  fetchHrAminRejectactionCreatorTypeName,
  fetchHrAminApproveCreatorTypeName,
  fetchHrAutoSuggestTypeName,
} from './slice';

import {
  hrApprovalStateSelector,
  getHrApprovalsDataGridSeletor,
} from './selectors';

/**
 * Feedbacks watcher
 */
export function* fetchHrApprovalDataWatcher() {
  yield takeLatest(fetchHrApprovalsCreatorTypeName, fetchHrApprovalsWorker);
}

/**
 * Feedbacks update Worker
 */
export function* fetchHrApprovalsWorker(): any {
  try {
    yield put(hrApprovalStateActions.setHrApprovalLoading(true));
    yield put(appSliceActions.setExcelDataLoading(true));
    const stateByAdminData = yield select(appSelector.getStateDropDown());
    const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
    const divisionIds = yield select(appSelector.getDivisionDropDownIds());
    const stateIds = yield select(appSelector.getStateDropDownIds());
    const filters = yield select(filterSelector.getFilterState());
    const filterApplied = yield select(filterSelector.getFilterApplied());
    const filter = yield select(filterSelector.getFilterOptions());
    const userDetails = yield select(appSelector.getUserInfo());
    if (!filterApplied) {
      if (divisionByAdminData.length > 0) {
        const options: any = {};
        const optionsState: any = {};
        divisionByAdminData?.map((item: any) => {
          options[item.label] = false;
          return null;
        });

        stateByAdminData?.map((item: any) => {
          optionsState[item.label] = false;
          return null;
        });
        yield put(
          filterStateActions.setFilterState({
            ...filters,
            Division: {
              completeApplied: false,
              collapsed: true,
              options,
            },
            States: {
              completeApplied: false,
              collapsed: true,
              options: optionsState,
            },
          }),
        );
      }
    }
    const url = `${API_PATH.employeeSpecificUtilities.hrApprovals.getAll}`;
    let pageNo = yield select(
      getHrApprovalsDataGridSeletor.getHrApprovalsPageNo(),
    );
    let perPage = yield select(
      getHrApprovalsDataGridSeletor.getHrApprovalsPerPage(),
    );
    let request = {
      pageNo: pageNo + 1,
      rowPerPage: perPage,
      stateIds: stateIds,
      divisionIds: divisionIds,
      roleIds: null,
      searchData: '',
      staffpositionId: userDetails?.staffPositionId,
    };
    if (filterApplied) {
      request = {
        stateIds: filter?.stateIds,
        divisionIds: filter?.divisionIds,
        roleIds: filter?.roleIds,
        searchData: filter?.searchData,
        staffpositionId: userDetails?.staffPositionId,
        pageNo: pageNo + 1,
        rowPerPage: perPage,
      };
      if (filter.stateIds.length == 0) {
        request.stateIds = stateIds;
      }
      if (filter.divisionIds.length == 0) {
        request.divisionIds = divisionIds;
      }
    }
    const response = yield call(NetworkService.post, url, request, {});
    yield put(
      hrApprovalStateActions.setHrApprovals(response.data.hradminApprovals),
    );
    yield put(
      hrApprovalStateActions.setHrApprovalsSnapshot(
        response.data.hradminApprovals,
      ),
    );
    yield put(hrApprovalStateActions.setTotalCount(response.data?.totalCount));

    yield put(hrApprovalStateActions.setHrApprovalLoading(false));

    let requestExcel = {
      ...request,
      rowPerPage: response?.data?.totalCount,
    };

    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );

    yield put(
      appSliceActions.setExcelData({
        data: responseExcel?.data?.hradminApprovals,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'employeeName'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
        ],
      }),
    );
    yield put(hrApprovalStateActions.setHrApprovalLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching Hr approvals!',
      ToastType.ERROR,
    );
    yield put(hrApprovalStateActions.setHrApprovalsError(error));
    setTimeout(function* () {
      yield put(hrApprovalStateActions.clearHrApprovalsError());
    }, 5000);
  } finally {
    yield put(hrApprovalStateActions.setHrApprovalLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  }
}

/**
 * Feedbacks update watcher
 */
// export function* updateFeedbacksDataWatcher() {
//   yield takeLatest(
//     updateFeedbacksCreatorTypeName,
//     updateFeedbacksDataWatcherWorker,
//   );
// }

// /**
//  * Feedbacks Worker
//  */

// export function* updateFeedbacksDataWatcherWorker(action: any): any {
//   try {
//     const payload = {
//       userId: action?.payload?.userId,
//       feedback: action?.payload?.feedback,
//     };
//     yield put(feedbacksStateActions.setFeedbacksLoading(true));
//     const url = `${API_PATH.employeeSpecificUtilities.feedbacks.update}`;
//     const response = yield call(NetworkService.post, url, payload);
//     const statusCode = response.status;
//     yield put(feedbackDataGridStateActions.setAddFeedbackResponse(statusCode));
//     ToasterService.showToaster(
//       'Successfully added the feedback for' + ' ' + action?.payload?.name,
//       ToastType.SUCCESS,
//     );
//   } catch (error) {
//     ToasterService.showToaster(
//       'Something went wrong while updating selected employees!',
//       ToastType.ERROR,
//     );
//     yield put(feedbacksStateActions.setFeedbacksError(error));
//     setTimeout(function* () {
//       yield put(feedbacksStateActions.clearFeedbacksError());
//     }, 5000);
//   } finally {
//     yield put(feedbacksStateActions.setFeedbacksLoading(false));
//   }
// }

// /**
//  * Feedbacks update watcher
//  */
// export function* deleteFeedbacksDataWatcher() {
//   yield takeLatest(
//     deleteFeedbacksCreatorTypeName,
//     deleteFeedbacksDataWatcherWorker,
//   );
// }

// /**
//  * Feedbacks Worker
//  */
// export function* deleteFeedbacksDataWatcherWorker(action: any): any {
//   try {
//     const payload = {
//       feedbackId: action?.payload?.feedbackId,
//     };
//     yield put(feedbacksStateActions.setFeedbacksLoading(true));
//     const url = `${API_PATH.employeeSpecificUtilities.feedbacks.delete}`;
//     const response = yield call(NetworkService.post, url, payload, {});
//     yield put(feedbackDataGridStateActions.setFeedbackDeleteResponse(true));
//     ToasterService.showToaster(
//       'Successfully deleted the feedback',
//       ToastType.SUCCESS,
//     );
//     return response;
//   } catch (error) {
//     ToasterService.showToaster(
//       'Something went wrong while deleting selected employees!',
//       ToastType.ERROR,
//     );
//     yield put(feedbacksStateActions.setFeedbacksError(error));
//     setTimeout(function* () {
//       yield put(feedbacksStateActions.clearFeedbacksError());
//     }, 5000);
//   } finally {
//     yield put(feedbacksStateActions.setFeedbacksLoading(false));
//   }
// }

// /**
//  * Feedbacks suggestions watcher
//  */
// export function* fetchFeedbacksSuggestionsWatcher() {
//   yield takeLatest(
//     fetchFeedbacksSuggestionsCreatorTypeName,
//     fetchFeedbacksSuggestionsWatcherWorker,
//   );
// }

// /**
//  * Feedbacks suggestions Worker
//  */
// export function* fetchFeedbacksSuggestionsWatcherWorker(action: any): any {
//   try {
//     yield put(feedbacksStateActions.setAutoSuggestLoading(true));
//     const url = `${API_PATH.employeeSpecificUtilities.feedbacks.suggestions}`;
//     const response = yield call(
//       NetworkService.get,
//       url,
//       {
//         searchData: action?.payload,
//       },
//       {},
//     );
//     yield put(feedbacksStateActions.setAutoSuggestData(response.data));
//   } catch (error) {
//     ToasterService.showToaster(
//       'Something went wrong while fetching selected employees!',
//       ToastType.ERROR,
//     );
//     yield put(feedbacksStateActions.setFeedbacksError(error));
//     setTimeout(function* () {
//       yield put(feedbacksStateActions.clearFeedbacksError());
//     }, 5000);
//   } finally {
//     yield put(feedbacksStateActions.setAutoSuggestLoading(false));
//   }
// }

// /**
//  * Feedbacks history watcher
//  */

export function* fetchHrApprovalsHistoryWatcher() {
  yield takeLatest(
    fetchHrApprovalsHistoryCreatorTypeName,
    fetchHrApprovalsHistoryWorker,
  );
}

export function* fetchHrApprovalsHistoryWorker(action: any): any {
  try {
    let payload = {
      userId: action?.payload,
    };
    yield put(hrApprovalStateActions.setHistoryLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.hrApprovals.getuserdeepdive}`;
    const response = yield call(NetworkService.get, url, payload, {});
    // yield put(hrApprovalStateActions.setHrApprovalDeepdiveData(response?.data));
    if (response.status == 200) {
      yield put(hrApprovalStateActions.setHistory(response?.data));
      yield put(hrApprovalStateActions.setHistoryLoading(false));
    } else {
      yield put(hrApprovalStateActions.setHistoryLoading(false));
    }
  } catch (error) {
    setTimeout(function* () {}, 5000);
    yield put(hrApprovalStateActions.setHistoryLoading(false));
  } finally {
  }
}

export function* fetchHrPreviewWatcher() {
  yield takeLatest(fetchHrPreviewCreatorTypeName, fetchHrPreviewWorker);
}

export function* fetchHrPreviewWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.hrApprovals.hrpreview}?approvalId=${action?.payload?.approvalId}`;
    const response = yield call(
      NetworkService.post,
      url,
      {},
      {},
    );
    if (response.status == 200) {
      yield put(hrApprovalStateActions.setDocumentData(response?.data));
    } else {
    }
  } catch (error) {
    setTimeout(function* () {}, 5000);
  } finally {
  }
}

export function* fetchHrChangesWatcher() {
  yield takeLatest(fetchHrChangesTypeName, fetchHrChangesWorker);
}

export function* fetchHrChangesWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.hrApprovals.getuserdeepdivecomparisondata}?empDocumentApprovalId=${action?.payload}`;
    yield put(hrApprovalStateActions.setChangesLoading(true));
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status == 200) {
      yield put(
        hrApprovalStateActions.setUserDeepdiveComparisonData(response?.data),
      );
      yield put(hrApprovalStateActions.setChangesLoading(false));
    } else {
      yield put(hrApprovalStateActions.setChangesLoading(false));
    }
  } catch (error) {
    yield put(hrApprovalStateActions.setChangesLoading(false));
  }
}

export function* fetchHrAdminRejectionWatcher() {
  yield takeLatest(
    fetchHrAminRejectactionCreatorTypeName,
    fetchHrAdminRejectionWorker,
  );
}

export function* fetchHrAdminRejectionWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.hrApprovals.hradminreject}/${action.payload}`;

    const response = yield call(NetworkService.post, url, {}, {});
    if (response.status === 200) {
      yield put(hrApprovalStateActions.setHrAdminRejectSuccess(true));
      yield put(hrApprovalStateActions.setHrAdminRejection(response));
      yield put(hrApprovalStateActions.setHrAdminApprOrRejectFail(false));
    }
  } catch (error) {
    yield put(hrApprovalStateActions.setHrAdminRejectSuccess(false));
    yield put(hrApprovalStateActions.setHrAdminApprOrRejectFail(true));
  } finally {
  }
}

export function* fetchHrAdminApproveWatcher() {
  yield takeLatest(
    fetchHrAminApproveCreatorTypeName,
    fetchHrAdminApproveWorker,
  );
}

export function* fetchHrAdminApproveWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.hrApprovals.hradminapprove}`;

    const response = yield call(NetworkService.post, url, action?.payload, {});
    if (response.status === 200) {
      yield put(hrApprovalStateActions.setHrAdminApproveSuccess(true));
      yield put(hrApprovalStateActions.setHrAdminApprove(response));
      yield put(hrApprovalStateActions.setHrAdminApprOrRejectFail(false));
    }
  } catch (error) {
    yield put(hrApprovalStateActions.setHrAdminApproveSuccess(false));
    yield put(hrApprovalStateActions.setHrAdminApprOrRejectFail(true));
  } finally {
  }
}

export function* fetchHrAutoSuggestWatcher() {
  yield takeLatest(fetchHrAutoSuggestTypeName, fetchHrAutoSuggestWorker);
}
export function* fetchHrAutoSuggestWorker(action: any): any {
  // try {
  //   const url =  `${API_PATH.employeeSpecificUtilities.staffHold.suggest}`;
  //   const response = yield call(NetworkService.post, url, action?.payload, {});
  //   console.log(response, "response of auto suggest")
  //   if (response.status === 200) {
  //     yield put(hrApprovalStateActions.setHrAdminApproveSuccess(true));
  //     yield put(hrApprovalStateActions.setHrAdminApprove(response));
  //   }
  // } catch (error) {
  //   yield put(hrApprovalStateActions.setHrAdminApprOrRejectFail(true));
  // } finally {
  // }
}
