import {Button, Grid} from '@mui/material';
import {useStyles} from './styles';

interface FooterButtonTPProps {
  firstLabel?: string;
  secondLabel?: string;
  firstOnClick?: () => void;
  secondOnClick?: () => void;
  disabledsecondBtn?: boolean;
  customStyle?: React.CSSProperties;
  firstButtonStyle?: React.CSSProperties;
  secondButtonStyle?: React.CSSProperties;
}

export const FooterButtonTP = ({
  firstLabel,
  secondLabel,
  firstOnClick,
  secondOnClick,
  disabledsecondBtn,
  customStyle,
  firstButtonStyle,
  secondButtonStyle,
}: FooterButtonTPProps) => {
  const classes = useStyles();
  return (
    <Grid container style={{justifyContent: 'center'}}>
      <Grid
        container
        style={{
          justifyContent: 'space-around',
          width: 500,
          marginBottom: 20,
          ...customStyle,
        }}>
        <Button
          variant="outlined"
          size="medium"
          data-testId={`first-button-${firstLabel}`}
          className={classes.approveBtn}
          onClick={firstOnClick}
          style={firstButtonStyle}>
          {firstLabel}
        </Button>
        <Button
          variant="contained"
          size="medium"
          data-testId={`second-button-${secondLabel}`}
          disabled={disabledsecondBtn}
          className={classes.rejectBtn}
          onClick={secondOnClick}
          style={secondButtonStyle}>
          {secondLabel}
        </Button>
      </Grid>
    </Grid>
  );
};
