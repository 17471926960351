import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {formatFilters} from '@app/utils/util';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {companyAssetsStateSelector} from './selector';
import {
  companyAssetsStateActions,
  fetchCompanyAssetsCreatorTypeName,
  fetchCompanyAssetsHistoryCreatorTypeName,
  fetchCompanyAssetsSuggestoionsCreatorTypeName,
} from './slice';

/**
 * Company Assets data watcher
 */
export function* fetchCompanyAssetsDataWatcher() {
  yield takeLatest(
    fetchCompanyAssetsCreatorTypeName,
    fetchCompanyAssetsDataWatcherWorker,
  );
}

/**
 * Company Assets data Worker
 */
export function* fetchCompanyAssetsDataWatcherWorker(): any {
  try {
    yield put(companyAssetsStateActions.setLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.companyAssets.getAll}`;
    const {perPage, pageNo} = yield select(
      companyAssetsStateSelector.getCompanyAssets(),
    );
    const response = yield call(
      NetworkService.post,
      url,
      {pageNo: pageNo + 1, rowPerPage: perPage},
      {},
    );
    yield put(
      companyAssetsStateActions.setCompanyAssets(response?.data?.companyAssets),
    );
    yield put(
      companyAssetsStateActions.setCompanyAssetsSnapshot(
        response?.data?.companyAssets,
      ),
    );
    yield put(
      companyAssetsStateActions.setTotalCount(response.data?.totalCount),
    );
    const filter = yield select(filterSelector.getFilterOptions());

    yield put(companyAssetsStateActions.setLoading(false));

    let requestExcel = {
      ...filter,
      pageNo: 1,
      rowPerPage: response?.data?.totalCount,
    };
    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );
    yield put(
      appSliceActions.setExcelData({
        data: responseExcel?.data?.companyAssets,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'name'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managersName'},
          {label: 'Tab', key: 'tab'},
          {label: 'Mobile', key: 'mobile'},
          {label: 'Laptop', key: 'laptop'},
          {label: 'Bag', key: 'bag'},
          {label: 'Helmets', key: 'helmet'},
        ],
      }),
    );
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        ...filters,
        Division: {
          completeApplied: false,
          collapsed: true,
          options: formatFilters(response?.data?.companyAssets, 'division'),
        },
        States: {
          completeApplied: false,
          collapsed: true,
          options: formatFilters(response?.data?.companyAssets, 'state'),
        },
      }),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching company assets!',
      ToastType.ERROR,
    );
  } finally {
    yield put(companyAssetsStateActions.setLoading(false));
  }
}

export function* fetchCompanyAssetsHistoryWatcher() {
  yield takeLatest(
    fetchCompanyAssetsHistoryCreatorTypeName,
    fetchCompanyAssetsHistoryWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchCompanyAssetsHistoryWatcherWorker(action: any): any {
  try {
    yield put(companyAssetsStateActions.setHistoryLoading(true));
    console.log(action.payload, 'palo....');
    const url = `${API_PATH.employeeSpecificUtilities.companyAssets.history}/${action.payload}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        userId: action.payload,
      },
      {},
    );
    yield put(companyAssetsStateActions.setHistory(response?.data));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching company assets history!',
      ToastType.ERROR,
    );
  } finally {
    yield put(companyAssetsStateActions.setHistoryLoading(false));
  }
}

export function* fetchCompanyAssetsSuggestionsDataWatcher() {
  yield takeLatest(
    fetchCompanyAssetsSuggestoionsCreatorTypeName,
    fetchCompanyAssetsSuggestionsDataWatcherWorker,
  );
}

/**
 * Company assets suggestions Worker
 */
export function* fetchCompanyAssetsSuggestionsDataWatcherWorker(
  action: any,
): any {
  try {
    yield put(companyAssetsStateActions.setAutoSuggestLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.companyAssets.suggest}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(companyAssetsStateActions.setAutoSuggestData(response?.data));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching company assets suggestions!',
      ToastType.ERROR,
    );
  } finally {
    yield put(companyAssetsStateActions.setAutoSuggestLoading(false));
  }
}
