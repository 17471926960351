import {createAction, createSlice} from '@reduxjs/toolkit';

export const welcomeKitInitialState = {
  welcomeKit: [],
  totalCount:0,
  snapshot: [],
  count: 0,
  loading: false,
  success: false,
  error: false,
  history: [],
  historyLoading: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
  pageNo: 0,
  rowsPerPage: 10,
  
};

export const fetchWelcomeKitCreator = createAction(
  'WELCOME_KIT/FETCH_WELCOME_KIT',
);
export const fetchWelcomeKitCreatorTypeName = fetchWelcomeKitCreator().type;

export const updateWelcomeKitCreator = createAction<any>(
  'WELCOME_KIT/UPDATE_WELCOME_KIT',
);
export const updateWelcomeKitCreatorTypeName = updateWelcomeKitCreator({}).type;

export const fetchWelcomeKitSuggestionsCreator = createAction<any>(
  'WELCOME_KIT/FETCH_SUGGESTIONS',
);
export const fetchWelcomeKitSuggestionsCreatorTypeName = fetchWelcomeKitSuggestionsCreator(null).type;

export const fetchWelcomeKitHistoryCreator = createAction<any>(
  'WELCOME_KIT/FETCH_WELCOME_KIT_HISTORY',
);
export const fetchWelcomeKitHistoryCreatorTypeName = fetchWelcomeKitHistoryCreator(null).type;

const welcomeKitStateHandler = createSlice({
  name: 'WELCOME_KIT',
  initialState: welcomeKitInitialState,
  reducers: {
    setWelcomeKitLoading: (prevState, action) => {
      return {
        ...prevState,
        loading: action.payload,
      };
    },
    setWelcomeKit: (prevState, action) => {
      return {
        ...prevState,
        welcomeKit: action.payload,
      };
    },
    setWelcomeKitLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setWelcomeKitSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapshot: action.payload,
      };
    },
    setWelcomeKitError: (prevState, action) => {
      return {
        ...prevState,
        error: true,
        errorMessage: action?.payload,
      };
    },
    clearWelcomeKitError: prevState => {
      return {
        ...prevState,
        error: false,
        errorMessage: '',
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
  },
});

export const welcomeKitStateActions = welcomeKitStateHandler.actions;
export const welcomeKitStateReducer = welcomeKitStateHandler.reducer;
