import {createSelector} from '@reduxjs/toolkit';

export const companyAssetsStateSelector = {
  getCompanyAssets: () => {
    return createSelector(
      [(state: any) => state.companyAssets],
      companyAssets => companyAssets,
    );
  },
};
