import { useSelector } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { useStyles } from './styles';
import { getMonthsArray } from '@app/helper/plan-and-meet';
import { Label } from '@app/components/elements/Label';
import { Button } from '@mui/material';
import excelLogo from '@app/assets/images/excelLogo.png';

const reportMonths = getMonthsArray();

export const DetailingReportNavBar = () => {
  const reportsmNavMonth: any = {checked: false, months: reportMonths};

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles(reportsmNavMonth); 

  const downloadFile = () => {
    return null;
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        <div>
          <Button onClick={downloadFile}>
            <img
              src={excelLogo}
              alt="Logo"
              className={classes.downloadExcelLogo}
            />
            <Label
              title={'Download Excel'}
              variant="h6"
              textColor={'#322b7c'}
              fontWeight={'800'}
              hideToolTip
            />
          </Button>
        </div>
      </div> 
    </div>
  );
};
