import {createSelector} from '@reduxjs/toolkit';

export const employee360StateSelector = {
  getEmp360IsProfileActive: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360IsProfileActive],
      emp360IsProfileActive => emp360IsProfileActive,
    );
  },
  getEmp360ActiveProfile: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ActiveProfile],
      emp360ActiveProfile => emp360ActiveProfile,
    );
  },
  getEmp360Divisions: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360Divisions],
      emp360Divisions => emp360Divisions,
    );
  },
  getEmp360DivisionsLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360DivisionsLoading],
      emp360DivisionsLoading => emp360DivisionsLoading,
    );
  },
  getEmp360DivisionsError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360DivisionsError],
      emp360DivisionsError => emp360DivisionsError,
    );
  },
  getEmp360SelectedDivisions: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360SelectedDivisions],
      emp360SelectedDivisions => emp360SelectedDivisions,
    );
  },
  getEmp360States: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360States],
      emp360States => emp360States,
    );
  },
  getEmp360StatesLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360StatesLoading],
      emp360StatesLoading => emp360StatesLoading,
    );
  },
  getEmp360StatesError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360StatesError],
      emp360StatesError => emp360StatesError,
    );
  },
  getEmp360HQ: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360HQ],
      emp360HQ => emp360HQ,
    );
  },
  getEmp360HQLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360HQLoading],
      emp360HQLoading => emp360HQLoading,
    );
  },
  getEmp360HQError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360HQError],
      emp360HQError => emp360HQError,
    );
  },
  getEmp360Hierarchy: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360Hierarchy],
      emp360Hierarchy => emp360Hierarchy,
    );
  },
  getEmp360HierarchyLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360HierarchyLoading],
      emp360HierarchyLoading => emp360HierarchyLoading,
    );
  },
  getEmp360HierarchyError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360HierarchyError],
      emp360HierarchyError => emp360HierarchyError,
    );
  },
  getEmp360SearchUser: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360SearchUser],
      emp360SearchUser => emp360SearchUser,
    );
  },
  getEmp360SearchUserLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360SearchUserLoading],
      emp360SearchUserLoading => emp360SearchUserLoading,
    );
  },
  getEmp360SearchUserError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360SearchUserError],
      emp360SearchUserError => emp360SearchUserError,
    );
  },
  getEmp360EmpList: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpList],
      emp360EmpList => emp360EmpList,
    );
  },
  getEmp360EmpListLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpListLoading],
      emp360EmpListLoading => emp360EmpListLoading,
    );
  },
  getEmp360EmpListError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpListError],
      emp360EmpListError => emp360EmpListError,
    );
  },
  getEmp360EmpListLength: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpListLength],
      emp360EmpListLength => emp360EmpListLength,
    );
  },
  getEmp360EmpListActivePage: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpListActivePage],
      emp360EmpListActivePage => emp360EmpListActivePage,
    );
  },
  getEmp360EmpListPageLimit: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpListPageLimit],
      emp360EmpListPageLimit => emp360EmpListPageLimit,
    );
  },
  getEmp360EmpListSortModel: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpListSortModel],
      emp360EmpListSortModel => emp360EmpListSortModel,
    );
  },

  getEmp360EmpFilters: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpFilters],
      emp360EmpFilters => emp360EmpFilters,
    );
  },
  getEmp360EmpFiltersLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpFiltersLoading],
      emp360EmpFiltersLoading => emp360EmpFiltersLoading,
    );
  },
  getEmp360EmpFiltersError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpFiltersError],
      emp360EmpFiltersError => emp360EmpFiltersError,
    );
  },
  getEmp360EmpFiltersApplied: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpFiltersApplied],
      emp360EmpFiltersApplied => emp360EmpFiltersApplied,
    );
  },
  getEmp360EmpExcel: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpExcel],
      emp360EmpExcel => emp360EmpExcel,
    );
  },
  getEmp360EmpExcelLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpExcelLoading],
      emp360EmpExcelLoading => emp360EmpExcelLoading,
    );
  },
  getEmp360EmpExcelError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpExcelError],
      emp360EmpExcelError => emp360EmpExcelError,
    );
  },

  getEmp360EmpExcelLatestAction: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360EmpExcelLatestAction],
      emp360EmpExcelLatestAction => emp360EmpExcelLatestAction,
    );
  },
  getEmp360Profile: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360Profile],
      emp360Profile => emp360Profile,
    );
  },
  getEmp360ProfileLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileLoading],
      emp360ProfileLoading => emp360ProfileLoading,
    );
  },
  getEmp360ProfileError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileError],
      emp360ProfileError => emp360ProfileError,
    );
  },
  getEmp360ProfilePersonalInformation: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfilePersonalInformation],
      emp360ProfilePersonalInformation => emp360ProfilePersonalInformation,
    );
  },
  getEmp360ProfilePersonalInformationLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.employee360.emp360ProfilePersonalInformationLoading,
      ],
      emp360ProfilePersonalInformationLoading =>
        emp360ProfilePersonalInformationLoading,
    );
  },
  getEmp360ProfilePersonalInformationError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfilePersonalInformationError],
      emp360ProfilePersonalInformationError =>
        emp360ProfilePersonalInformationError,
    );
  },
  getEmployeesOnHoldDetails: () => {
    return createSelector(
      [(state: any) => state.employee360.employeesOnHoldDetails],
      empOnHold => empOnHold,
    );
  },
  getEmployeesOnHoldErrors: () => {
    return createSelector(
      [(state: any) => state.employee360.employeesOnHoldError],
      empOnHoldError => empOnHoldError,
    );
  },
  getEmployeesOnHoldLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.employeesOnHoldLoading],
      empOnHoldLoading => empOnHoldLoading,
    );
  },
  getEmployeesworkExperience:()=>{

    return createSelector(
      [(state:any)=> state.employee360.employeesWorkExperience],
      employeesWorkExperience=>employeesWorkExperience

    )
  },

  getEmployeesWorkExperienceError:()=>{
    return createSelector(
      [(state:any)=> state.employee360.employeesWorkExperienceError],
      employeesWorkExperienceError=>employeesWorkExperienceError

    )
  },

  getEmployeesWorkExperienceLoading:()=>{
    return createSelector(
      [(state:any)=> state.employee360.employeesWorkExperienceLoading],
      employeesWorkExperienceLoading=>employeesWorkExperienceLoading

    )
  },

  getEmployee360DefaultGenderandReligion :()=>{
    return createSelector(
      [(state:any)=> state.employee360.emp360GenderAndReligion],
      emp360GenderAndReligion=>emp360GenderAndReligion

    )
  },

  getEmployee360DefaultGenderandReligionLoading :()=>{
    return createSelector(
      [(state:any)=> state.employee360.mp360GenderAndReligionLoading],
      mp360GenderAndReligionLoading=>mp360GenderAndReligionLoading
    )
  },
  getEmp360ProfileEducation: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileEducation],
      emp360ProfileEducation => emp360ProfileEducation,
    );
  },
  getEmp360ProfileEducationLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileEducationLoading],
      emp360ProfileEducationLoading => emp360ProfileEducationLoading,
    );
  },
  getEmp360ProfileEducationError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileEducationError],
      emp360ProfileEducationError => emp360ProfileEducationError,
    );
  },
  getEmp360ProfileCourseDetails: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileCourseDetails],
      emp360ProfileCourseDetails => emp360ProfileCourseDetails,
    );
  },
  getEmp360ProfileCourseLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileCourseLoading],
      emp360ProfileCourseLoading => emp360ProfileCourseLoading,
    );
  },
  getEmp360ProfileCourseError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileCourseError],
      emp360ProfileCourseError => emp360ProfileCourseError,
    );
  },
  getEmp360ProfileAwardDetails: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileAwardDetails],
      emp360ProfileAwardDetails => emp360ProfileAwardDetails,
    );
  },
  getEmp360ProfileAwardLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileAwardLoading],
      emp360ProfileAwardLoading => emp360ProfileAwardLoading,
    );
  },
  getEmp360ProfileAwardError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileAwardError],
      emp360ProfileAwardError => emp360ProfileAwardError,
    );
  },
  getEmp360ProfileForeignTrips: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileForeignTrips],
      emp360ProfileForeignTrips => emp360ProfileForeignTrips,
    );
  },
  getEmp360ProfileForeignTripsLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileForeignTripsLoading],
      emp360ProfileForeignTripsLoading => emp360ProfileForeignTripsLoading,
    );
  },
  getEmp360ProfileForeignTripsError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileForeignTripsError],
      emp360ProfileForeignTripsError => emp360ProfileForeignTripsError,
    );
  },
  getEmp360ContactDetails: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileContactDetails],
      emp360ProfileContactDetails => emp360ProfileContactDetails,
    );
  },
  getEmp360ContactDetailsLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileContactDetailsLoading],
      emp360ProfileContactDetailsLoading => emp360ProfileContactDetailsLoading,
    );
  },
  getEmp360ContactDetailsError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileContactDetailsError],
      emp360ProfileContactDetailsError => emp360ProfileContactDetailsError,
    );
  },
  getEmp360FamilyDetails: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileFamilyDetails],
      emp360ProfileFamilyDetails => emp360ProfileFamilyDetails,
    );
  },
  getEmp360FamilyDetailsLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileFamilyDetailsLoading],
      emp360ProfileFamilyDetailsLoading => emp360ProfileFamilyDetailsLoading,
    );
  },
  getEmp360FamilyDetailsError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileFamilyDetailsError],
      emp360ProfileFamilyDetailsError => emp360ProfileFamilyDetailsError,
    );
  },
  getEmp360CompanyAssets: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileCompanyAssets],
      emp360ProfileCompanyAssets => emp360ProfileCompanyAssets,
    );
  },
  getEmp360CompanyAssetsLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileCompanyAssetsLoading],
      emp360ProfileCompanyAssetsLoading => emp360ProfileCompanyAssetsLoading,
    );
  },
  getEmp360CompanyAssetsError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileCompanyAssetsError],
      emp360ProfileCompanyAssetsError => emp360ProfileCompanyAssetsError,
    );
  },
  getEmp360ProfileFeedbacks: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileFeedbacks],
      emp360ProfileFeedbacks => emp360ProfileFeedbacks,
    );
  },
  getEmp360ProfileFeedbacksLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileFeedbacksLoading],
      emp360ProfileFeedbacksLoading => emp360ProfileFeedbacksLoading,
    );
  },
  getEmp360ProfileFeedbacksError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileFeedbacksError],
      emp360ProfileFeedbacksError => emp360ProfileFeedbacksError,
    );
  },
  getEmp360ProfileAssessments: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileAssessments],
      emp360ProfileAssessments => emp360ProfileAssessments,
    );
  },
  getEmp360ProfileAssessmentsLoading: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileAssessmentsLoading],
      emp360ProfileAssessmentsLoading => emp360ProfileAssessmentsLoading,
    );
  },
  getEmp360ProfileAssessmentsError: () => {
    return createSelector(
      [(state: any) => state.employee360.emp360ProfileAssessmentsError],
      emp360ProfileAssessmentsError => emp360ProfileAssessmentsError,
    );
  },
};
