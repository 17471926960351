import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {Box, Divider, Avatar, Typography} from '@mui/material';
import moment from 'moment';
import Colors from '@app/themes/colors';
import avatar from '@app/assets/images/avatar.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';

import {employee360StateActions} from '@app/screens/not-assigned/pages/employee360/redux';
import {fetchEmp360ProfileCreator} from '@app/screens/not-assigned/pages/employee360/redux/slice';
import {employee360StateSelector} from '@app/screens/not-assigned/pages/employee360/redux/selectors';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    borderRadius: '10px',
    color: 'black',
    backgroundColor: '#dde8ec',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '25px',
  },
  details: {
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  detailSection: {
    height: '45px',
    maxHeight: '45px',
    width: '350px',
    maxWidth: '400px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
  },
  boxIconStyle: {
    dispaly: 'flex',
    alignItems: 'center',
  },
  avatarIconStyle: {
    width: '25px',
    height: '25px',
    marginBottom: '3px',
    marginRight: '5px',
    background: 'white',
  },
};

export default function Emp360ProfileHeader() {
  const dispatch = useDispatch();
  const profile = useSelector(
    employee360StateSelector.getEmp360Profile(),
    shallowEqual,
  );

  const employee = useSelector(
    employee360StateSelector.getEmp360ActiveProfile(),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchEmp360ProfileCreator(employee?.userId));
  }, []);

  const handleBackBtn = () => {
    dispatch(employee360StateActions.setEmp360ActiveProfile({}));
    dispatch(employee360StateActions.setEmp360Profile({}));
    dispatch(employee360StateActions.setEmp360ProfileLoading(false));
    dispatch(employee360StateActions.setEmp360ProfileError(false));
    dispatch(employee360StateActions.setEmp360IsProfileActive(false));
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.title}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={Colors.blue[1400]}>
          <IconButton
            onClick={handleBackBtn}
            sx={{
              width: '27px',
              height: '27px',
              marginRight: '10px',
              border: '2px solid #322b7c',
            }}
            data-testid="emp360-backbtn">
            <ArrowBackIosNewIcon
              fontSize="small"
              sx={{
                color: '#322b7c',
                borderRadius: '10px',
              }}
            />
          </IconButton>
          Employee Profile
        </Typography>

        <Typography variant="caption">Correspondence File</Typography>
      </Box>
      <Box sx={styles.details}>
        <Avatar
          alt="profile picture"
          sx={{
            width: '45px',
            height: '45px',
            marginTop: '5px',
            marginRight: '10px',
          }}
          src={profile.photourl ? profile.photourl : avatar}
        />

        <Box sx={styles.detailSection}>
          <Typography
            variant="subtitle1"
            fontWeight="fontWeightLight"
            color={Colors.blue[1400]}
            sx={{
              textTransform: 'capitalize',
            }}>
            {profile.name ? profile.name.toLowerCase() : 'NA'}
          </Typography>
          <Typography
            variant="caption"
            fontWeight="fontWeightLight"
            color={Colors.blue[1400]}>
            ID: {profile.employeeId ? profile.employeeId : 'NA'}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: '45px',
            marginTop: '3px',
            marginRight: '10px',
          }}
        />

        <Box sx={styles.detailSection}>
          <Typography
            variant="subtitle1"
            fontWeight="fontWeightRegular"
            color={Colors.blue[1400]}>
            {profile.designation ? profile.designation : 'NA'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}>
            <Typography
              variant="caption"
              fontWeight="fontWeightRegular"
              color={Colors.blue[1400]}>
              {profile.hq ? profile.hq : 'NA'}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: '11px',
                marginTop: '4px',
                marginRight: '5px',
                marginLeft: '5px',
              }}
            />
            <Typography
              variant="caption"
              fontWeight="fontWeightRegular"
              color={Colors.blue[1400]}>
              {profile.division ? profile.division : 'NA'}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: '45px',
            marginTop: '3px',
            marginRight: '10px',
          }}
        />
        <Box sx={{...styles.detailSection, width: '180px', maxWidth: '200px'}}>
          <Box display="flex" alignItems="center">
            <Avatar sx={styles.avatarIconStyle}>
              <svg
                data-name="icon/24/Birthday"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  data-name="Path 3696"
                  d="M240.5 111.21h1.065v1.952H240.5z"
                  transform="translate(-229.032 -104.297)"
                  fill="#00465f"
                />
                <path
                  data-name="Path 3697"
                  d="M255.488 111.21h.533v1.952h-.533z"
                  transform="translate(-243.471 -104.297)"
                  fill="#002c3c"
                />
                <path
                  data-name="Path 3698"
                  d="M213.692 2.882a1.6 1.6 0 0 1-1.6 1.6 1.6 1.6 0 0 1-1.6-1.6 4.129 4.129 0 0 1 1.22-2.506l.383-.376.377.376a4.129 4.129 0 0 1 1.22 2.506z"
                  transform="translate(-200.096 3)"
                  fill="#ff3636"
                />
                <path
                  data-name="Path 3699"
                  d="M255.488 4.479V0l.377.376a4.129 4.129 0 0 1 1.221 2.506 1.6 1.6 0 0 1-1.598 1.597z"
                  transform="translate(-243.43 3)"
                  fill="#e10000"
                />
                <path
                  data-name="Path 3700"
                  d="M107.867 360.851h-3.081l-.734-5.525h3.448z"
                  transform="translate(-97.86 -339.852)"
                  fill="#ff8700"
                />
                <path
                  data-name="Path 3701"
                  d="M173.7 355.326v5.525h-3.16l-.4-5.525z"
                  transform="translate(-161.325 -339.852)"
                  fill="#f03800"
                />
                <path
                  data-name="Path 3702"
                  d="m303.277 355.326-.734 5.525h-3.081l.367-5.525z"
                  transform="translate(-285.475 -339.852)"
                  fill="#ff712f"
                />
                <path
                  data-name="Path 3703"
                  d="m258.488 355.326-.367 5.525h-2.628v-5.525z"
                  transform="translate(-243.329 -339.852)"
                  fill="#c92e00"
                />
                <path
                  data-name="Path 3704"
                  d="M121.576 153.332a2.218 2.218 0 0 1-2.3 2.122h-6.56a2.128 2.128 0 1 1 0-4.243h6.56a2.218 2.218 0 0 1 2.3 2.121z"
                  transform="translate(-103.997 -142.912)"
                  fill="#f4e3df"
                />
                <path
                  data-name="Path 3705"
                  d="M260.637 153.332a2.124 2.124 0 0 1-2.122 2.122h-3.028v-4.244h3.028a2.124 2.124 0 0 1 2.122 2.122z"
                  transform="translate(-243.059 -142.912)"
                  fill="#d6c5c5"
                />
                <path
                  data-name="Path 3706"
                  d="M84.378 242.892v.036a2.2 2.2 0 0 1-2.26 2.122h-9.479a2.2 2.2 0 0 1-2.26-2.122v-.036a2.2 2.2 0 0 1 2.26-2.122h9.479a2.2 2.2 0 0 1 2.26 2.122z"
                  transform="translate(-65.379 -229.293)"
                  fill="#fff5f6"
                />
                <path
                  data-name="Path 3707"
                  d="M262.058 242.892v.036a2.124 2.124 0 0 1-2.122 2.122h-4.449v-4.28h4.449a2.124 2.124 0 0 1 2.122 2.122z"
                  transform="translate(-243.059 -229.293)"
                  fill="#f4e3df"
                />
              </svg>
            </Avatar>
            <Typography
              variant="caption"
              fontWeight="fontWeightLight"
              color={Colors.blue[1400]}>
              {profile.birthday
                ? moment(profile.birthday).format('DD MMMM')
                : 'NA'}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Avatar sx={styles.avatarIconStyle}>
              <svg
                data-name="icon/24/Anniversary"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  data-name="Path 3712"
                  d="m67.895 305.236 1.262-4.407-1.2-.344-1.269 4.43a1.129 1.129 0 0 1-.878.8 1.129 1.129 0 0 0-.878.8l-.066.229 3.7 1.058.059-.207a1.129 1.129 0 0 0-.347-1.165l-.032-.027a1.129 1.129 0 0 1-.351-1.167z"
                  transform="translate(-59.865 -286.8)"
                  fill="#cbcbcb"
                />
                <path
                  data-name="Path 3715"
                  d="m339.271 305.239-1.262-4.407 1.2-.344 1.269 4.429a1.129 1.129 0 0 0 .878.8 1.129 1.129 0 0 1 .878.8l.065.229-3.7 1.058-.059-.207a1.129 1.129 0 0 1 .347-1.165l.032-.028a1.129 1.129 0 0 0 .352-1.165z"
                  transform="translate(-322.299 -286.803)"
                  fill="#eaeae8"
                />
                <path
                  data-name="Union 7"
                  d="M21351.34 17915.859a3.51 3.51 0 0 1-1.811-3.008l-.049-2.818 3.6-1.035 1.453 2.424a3.514 3.514 0 0 1 .059 3.5l-.734 1.318-1.2.344zm-6.3.385-.729-1.312a3.5 3.5 0 0 1 .059-3.51l1.447-2.418 3.6 1.029-.053 2.818a3.507 3.507 0 0 1-1.807 3.008l-1.318.729z"
                  transform="translate(-21336.945 -17902.494)"
                  fill="#eaeae8"
                />
                <path
                  data-name="Union 9"
                  d="M21351.338 17913.223a3.51 3.51 0 0 1-1.807-3.008l-.012-.525h1.084a12.15 12.15 0 0 0 3.848-.627l.193-.064a3.5 3.5 0 0 1-.059 3.291l-.73 1.318-1.2.344zm-6.3.385-.729-1.318a3.491 3.491 0 0 1-.07-3.27l.172.043a12.159 12.159 0 0 0 3.848.627h1.115l-.012.525a3.5 3.5 0 0 1-1.807 3.008l-1.318.729z"
                  transform="translate(-21336.945 -17899.871)"
                  fill="#070fdb"
                />
                <path
                  data-name="Union 8"
                  d="M21343.879 17911.541a.307.307 0 0 1 .135-.354l.316-.187a.319.319 0 0 1 .428.107.315.315 0 0 1-.107.428l-.311.189a.315.315 0 0 1-.16.047.31.31 0 0 1-.301-.23zm6.773.043a.325.325 0 0 1 0-.311l.182-.316a.312.312 0 1 1 .541.311l-.182.316a.3.3 0 0 1-.268.156.316.316 0 0 1-.273-.156zm-5.744-.654a.312.312 0 0 1 .139-.348.816.816 0 0 0 .387-.68.311.311 0 0 1 .621 0 1.416 1.416 0 0 1-.686 1.211.29.29 0 0 1-.16.047.314.314 0 0 1-.301-.23zm6.344-.375a.3.3 0 0 1 0-.311.805.805 0 0 0 .006-.781.31.31 0 1 1 .541-.3 1.411 1.411 0 0 1-.006 1.393.316.316 0 0 1-.271.156.305.305 0 0 1-.27-.157z"
                  transform="translate(-21334.826 -17898.836)"
                  fill="#6e7dff"
                />
                <path
                  data-name="Union 6"
                  d="m21307.064 17889.611-.689-.076a.314.314 0 1 1 .07-.625l.7.08a.315.315 0 0 1 .271.332.3.3 0 0 1-.309.293.154.154 0 0 1-.043-.004zm-10.967-.289a.32.32 0 0 1 .277-.332l.7-.08a.315.315 0 0 1 .07.625l-.7.076a.108.108 0 0 1-.037 0 .309.309 0 0 1-.307-.289zm8.705-.846-.213-.588a.308.308 0 0 1 .182-.4.315.315 0 0 1 .4.188l.215.588a.3.3 0 0 1-.037.283.312.312 0 0 1-.258.133.305.305 0 0 1-.291-.203zm-6.635.07a.294.294 0 0 1-.039-.283l.215-.588a.307.307 0 0 1 .395-.187.311.311 0 0 1 .188.4l-.213.588a.31.31 0 0 1-.545.07zm7.635-.08a.32.32 0 0 1 .059-.338l.482-.508a.316.316 0 0 1 .443-.01.309.309 0 0 1 .01.438l-.48.508a.3.3 0 0 1-.225.1.309.309 0 0 1-.291-.189zm-8.6.09-.48-.508a.309.309 0 0 1 .01-.437.316.316 0 0 1 .443.01l.477.508a.312.312 0 0 1-.225.529.3.3 0 0 1-.227-.101z"
                  transform="translate(-21289.287 -17882.891)"
                  fill="#0750db"
                />
                <path
                  data-name="Path 3709"
                  d="m204.1 25.582-1.915 1.918-1.913-1.913a.927.927 0 1 1 1.311-1.311l.6.6.6-.6a.927.927 0 1 1 1.311 1.311z"
                  transform="translate(-189.701 -21)"
                  fill="#f16465"
                />
              </svg>
            </Avatar>

            <Typography
              variant="caption"
              fontWeight="fontWeightLight"
              color={Colors.blue[1400]}>
              {profile.anniversary
                ? moment(profile.anniversary).format('DD MMMM')
                : 'NA'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
