import {filterStateActions} from '@app/components/right-sidebar/redux';
import {
  navbarComponentName,
  routeStateActions,
} from '@app/router/redux/routeHandler';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
export const Summary = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);
  return <div>This is Summary Page</div>;
};
