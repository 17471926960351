import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {formatFilters, formatHrFilters} from '@app/utils/util';
import {
  listOfEmployeesStateActions,
  fetchListOfEmployeesCreatorTypeName,
  fetchWelcomeKitDropDownCreatorTypeName,
  fetchManagersRatingDropDownCreatorTypeName,
  StaffHoldDropDownCreatorTypeName,
  CompanyAssetDropDownCreatorTypeName,
  fetchListOfEmployeesSuggestionsCreatorTypeName,
} from './slice';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {listOfEmployeesStateSelector} from './selectors';

/**
 * List Of Employees watcher
 */
export function* fetchListOfEmployeesDataWatcher() {
  yield takeLatest(
    fetchListOfEmployeesCreatorTypeName,
    fetchListOfEmployeesDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchListOfEmployeesDataWatcherWorker(): any {
  try {
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.listofemployees.getlistofemployeesTableRecord}`;
    let pageNo = yield select(
      listOfEmployeesStateSelector.getListOfEmployeesPageNo(),
    );
    let perPage = yield select(
      listOfEmployeesStateSelector.getListOfEmployeesPerPage(),
    );
    const gridData = yield call(
      NetworkService.post,
      url,
      {pageNo: pageNo + 1, rowPerPage: perPage},
      {},
    );
    //const gridData = yield call(NetworkService.post, url, {'pageNo':1,'rowPerPage':50}, {});

    yield put(
      listOfEmployeesStateActions.setListOfEmployees(
        gridData?.data.employeeList,
      ),
    );
    yield put(
      listOfEmployeesStateActions.setListOfEmployeesSnapshot(
        gridData?.data.employeeList,
      ),
    );
    yield put(
      listOfEmployeesStateActions.setTotalCount(gridData.data?.totalCount),
    );

    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));

    const filter = yield select(filterSelector.getFilterOptions());

    let requestExcel = {
      ...filter,
      pageNo: 1,
      rowPerPage: gridData.data?.totalCount,
    };
    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );

    yield put(
      appSliceActions.setExcelData({
        data: responseExcel?.data?.employeeList,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'name'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managerName'},
          {label: 'Designation', key: 'designation'},
          {label: 'State', key: 'state'},
          {label: 'DCR', key: 'isDCRDisabled'},
          {label: 'Incentive', key: 'isExpenseDisabled'},
          {label: 'Salary', key: 'isSalaryDisabled'},
          {label: 'Expense', key: 'isDCRDisabled'},
          {label: 'Input & Sample', key: 'isInputAndSamplesDisabled'},
          {label: 'Welcome Kit', key: 'receivedWelcomeKit'},
          {label: 'Company Assets', key: 'assignedCompanyAssets'},
          {label: 'Promotion', key: 'isPromoted'},
          {label: 'Designation', key: 'designation'},
          {label: "Manager's Rating", key: 'managerRating'},
        ],
      }),
    );
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        Division: {
          completeApplied: false,
          collapsed: true,
          options: formatFilters(gridData?.data.employeeList, 'division'),
        },
        States: {
          completeApplied: false,
          collapsed: true,
          options: formatFilters(gridData?.data.employeeList, 'state'),
        },
        'Designation Change': {
          completeApplied: false,
          collapsed: true,
          options: {
            Yes: false,
            No: false,
          },
        },
        Promotion: {
          completeApplied: false,
          collapsed: true,
          options: {
            Yes: false,
            No: false,
          },
        },
        ...filters,
      }),
    );

    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching list of employees!',
      ToastType.ERROR,
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesError(error));
    setTimeout(function* () {
      yield put(listOfEmployeesStateActions.clearListOfEmployeesError());
    }, 5000);
  } finally {
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));
  }
}

/**
 * STAFF HOLD DROPDOWN WATCHER
 */
export function* fetchStaffHoldDropDownDataWatcher() {
  yield takeLatest(
    StaffHoldDropDownCreatorTypeName,
    fetchStaffHoldDropDownDataWatcherWorker,
  );
}

/**
 * STAFF HOLD DROPDOWN Worker
 */
export function* fetchStaffHoldDropDownDataWatcherWorker(): any {
  try {
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.listofemployees.getStaffHold}`;
    const response = yield call(NetworkService.get, url, {}, {});
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        ...filters,
        'Staff Hold': {
          completeApplied: false,
          collapsed: true,
          options: formatHrFilters(response?.data, 'displayName'),
        },
      }),
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staffhold dropdown!',
      ToastType.ERROR,
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesError(error));
    setTimeout(function* () {
      yield put(listOfEmployeesStateActions.clearListOfEmployeesError());
    }, 5000);
  }
}

/**
 *  Company Asset DROPDOWN WATCHER
 */
export function* fetchCompanyAssetDropDownDataWatcher() {
  yield takeLatest(
    CompanyAssetDropDownCreatorTypeName,
    fetchCompanyAssetDropDownDataWatcherWorker,
  );
}

/**
 *  Company Asset DROPDOWN Watcher Worker
 */
export function* fetchCompanyAssetDropDownDataWatcherWorker(): any {
  try {
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.listofemployees.getCompanyAssets}`;
    const response = yield call(NetworkService.get, url, {}, {});
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        ...filters,
        'Company Assets': {
          completeApplied: false,
          collapsed: true,
          options: formatHrFilters(response?.data, 'displayText'),
        },
      }),
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching company assets dropdown!',
      ToastType.ERROR,
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesError(error));
    setTimeout(function* () {
      yield put(listOfEmployeesStateActions.clearListOfEmployeesError());
    }, 5000);
  }
}

/**
 *  Welcome Kit DROPDOWN WATCHER
 */
export function* fetchWelcomeKitDropDownDataWatcher() {
  yield takeLatest(
    fetchWelcomeKitDropDownCreatorTypeName,
    fetchWelcomeKitDropDownDataWatcherWorker,
  );
}

/**
 *  Welcome Kit DROPDOWN Watcher Worker
 */
export function* fetchWelcomeKitDropDownDataWatcherWorker(): any {
  try {
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.listofemployees.getWelcomeKit}`;
    const response = yield call(NetworkService.get, url, {}, {});
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        ...filters,
        'Welcome Kit': {
          completeApplied: false,
          collapsed: true,
          options: formatHrFilters(response?.data, 'displayText'),
        },
      }),
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching welcome kit dropdown!',
      ToastType.ERROR,
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesError(error));
    setTimeout(function* () {
      yield put(listOfEmployeesStateActions.clearListOfEmployeesError());
    }, 5000);
  }
}

/**
 *  Managers Rating DROPDOWN WATCHER
 */
export function* fetchManagersRatingDropDownDataWatcher() {
  yield takeLatest(
    fetchManagersRatingDropDownCreatorTypeName,
    fetchManagersRatingDropDownDataWatcherWorker,
  );
}

/**
 *  Managers Rating DROPDOWN Watcher Worker
 */
export function* fetchManagersRatingDropDownDataWatcherWorker(): any {
  try {
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.listofemployees.getManagerRating}`;
    const response = yield call(NetworkService.get, url, {}, {});
    const filters = yield select(filterSelector.getFilterState());
    yield put(
      filterStateActions.setFilterState({
        ...filters,
        "Manager's Rating": {
          completeApplied: false,
          collapsed: true,
          options: formatHrFilters(response?.data, 'displayText'),
        },
      }),
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching managers rating dropdown!',
      ToastType.ERROR,
    );
    yield put(listOfEmployeesStateActions.setListOfEmployeesError(error));
    setTimeout(function* () {
      yield put(listOfEmployeesStateActions.clearListOfEmployeesError());
    }, 5000);
  }
}

/**
 * List of employees suggestions watcher
 */
export function* fetchListOfEmployeesSuggestionsWatcher() {
  yield takeLatest(
    fetchListOfEmployeesSuggestionsCreatorTypeName,
    fetchListOfEmployeesSuggestionsWatcherWorker,
  );
}

/**
 * List of employees suggestions Worker
 */
export function* fetchListOfEmployeesSuggestionsWatcherWorker(
  action: any,
): any {
  try {
    yield put(listOfEmployeesStateActions.setAutoSuggestLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.listofemployees.suggestions}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(listOfEmployeesStateActions.setAutoSuggestData(response.data));
    yield put(listOfEmployeesStateActions.setAutoSuggestLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(listOfEmployeesStateActions.setListOfEmployeesError(error));
    setTimeout(function* () {
      yield put(listOfEmployeesStateActions.clearListOfEmployeesError());
    }, 5000);
  }
}
