import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {
  navbarComponentName,
  routeStateActions,
} from '@app/router/redux/routeHandler';
import {useEffect, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';

import ActionTable from './components/actionTable';
import {Container, Stack} from '@mui/system';
import {
  fetchDivisionDataCreator,
  fetchUserApprovalDataCreator,
} from '../redux-api/slice';
import {strings} from '@app/common/strings';
import {manageUserRoleStyles as styles} from './styles';

export const ManageUserRole = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  const divisionData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard?.divisionData,
  );
  const [selectedDivisionId, setSelectedDivisionId] = useState<number>(0);

  const handleDivisionChange = (divisionId: number) => {
    setSelectedDivisionId(divisionId);
  };

  const handleGoButtonClick = () => {
    dispatch(fetchUserApprovalDataCreator(selectedDivisionId));
  };

  useEffect(() => {
    dispatch(fetchDivisionDataCreator(userLoginInfo?.staffPositionId));
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);

  useEffect(() => {
    if (divisionData?.length > 0) {
      setSelectedDivisionId(divisionData[0]?.divisionId);
      dispatch(fetchUserApprovalDataCreator(divisionData[0]?.divisionId));
    }
  }, [divisionData]);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Stack
          bgcolor={'#f4f4f4'}
          padding={2}
          paddingLeft={3}
          marginBottom={2}
          spacing={3}
          alignItems="center"
          direction="row">
          <FormControl
            sx={[styles.productListingDropDown, styles.dropdownBgWhite]}>
            <InputLabel
              size="small"
              id="division"
              sx={{fontSize: 12.7, color: '#1c1939B3'}}>
              {strings.zho.division}
            </InputLabel>
            <Select
              sx={[styles.productListingDropDown]}
              id="division"
              data-testid="division-dropdown"
              size="small"
              value={selectedDivisionId || ''}
              onChange={e => handleDivisionChange(Number(e.target.value))}
              label={strings.zho.division}>
              {divisionData?.map((division: any) => {
                return (
                  <MenuItem
                    sx={{fontSize: 12.7, color: '#1c1939'}}
                    value={division?.divisionId}>
                    {division?.divisionName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            sx={styles.goButton}
            data-testid="button-go"
            variant="outlined"
            onClick={handleGoButtonClick}
            size="medium">
            {strings.zho.go}
          </Button>
        </Stack>
        <Container maxWidth={false}>
          <Box>
            <Typography sx={styles.tableTitleText}>
              {strings.zho.fcAdmin.manageUserRole.requestFormApprovalHeaderText}
            </Typography>

            <ActionTable
              tableType="request"
              divisionId={selectedDivisionId}
              staffPositionId={userLoginInfo?.staffPositionId}
            />
          </Box>

          <Box sx={{marginBottom: 4}}>
            <Typography sx={styles.tableTitleText}>
              {strings.zho.fcAdmin.manageUserRole.orderApprovalHeaderText}
            </Typography>
            <ActionTable
              tableType="order"
              divisionId={selectedDivisionId}
              staffPositionId={userLoginInfo?.staffPositionId}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};
