import { createSelector } from '@reduxjs/toolkit';

export const appSelector = {
  getAppLoading: () => {
    return createSelector(
      [(state: any) => state.appState?.appLoading],
      appLoading => appLoading,
    );
  },
  getUserInfo: () => {
    return createSelector(
      [(state: any) => state.appState.userInfo],
      userInfo => userInfo,
    );
  },
  getUserInfoError: () => {
    return createSelector(
      [(state: any) => state.appState.userInfoError],
      userInfoError => userInfoError,
    );
  },
  getUserInfoErrorMessage: () => {
    return createSelector(
      [(state: any) => state.appState.userInfoErrorMessage],
      userInfoErrorMessage => userInfoErrorMessage,
    );
  },
  getShowCannotAccessModal: () => {
    return createSelector(
      [(state: any) => state.appState.showCannotAccessModal],
      showCannotAccessModal => showCannotAccessModal,
    );
  },
  getShowCannotAccessModalLoading: () => {
    return createSelector(
      [(state: any) => state.appState.showCannotAccessModalLoading],
      showCannotAccessModalLoading => showCannotAccessModalLoading,
    );
  },
  getCannotAccessModalUsers: () => {
    return createSelector(
      [(state: any) => state.appState.cannotAccessModalUsers],
      cannotAccessModalUsers => cannotAccessModalUsers,
    );
  },
  getIsEditAccessAvailable: () => {
    return createSelector(
      [(state: any) => state.appState.isEditAccessAvailable],
      isEditAccessAvailable => isEditAccessAvailable,
    );
  },
  getIsBulkAccessAvailable: () => {
    return createSelector(
      [(state: any) => state.appState.isBulkAccessAvailable],
      isBulkAccessAvailable => isBulkAccessAvailable,
    );
  },
  getNotifications: () => {
    return createSelector(
      [(state: any) => state.appState.notifications],
      notifications => notifications,
    );
  },
  getAppSettings: () => {
    return createSelector(
      [(state: any) => state.appState.appSettings],
      appSettings => appSettings,
    );
  },
  getExcelData: () => {
    return createSelector(
      [(state: any) => state.appState.excel],
      data => data,
    );
  },
  getExcelDataLoading: () => {
    return createSelector(
      [(state: any) => state.appState.excelDataLoading],
      loading => loading,
    );
  },
  getDivisionDropDown: () => {
    return createSelector(
      [(state: any) => state.appState.divisionDropDown],
      divisionDropDown => divisionDropDown,
    );
  },
  getStateDropDown: () => {
    return createSelector(
      [(state: any) => state.appState.stateDropDown],
      stateDropDown => stateDropDown,
    );
  },
  getStateDropDownIds: () => {
    return createSelector(
      [(state: any) => state.appState.stateIds],
      stateIds => stateIds,
    );
  },
  getDivisionDropDownIds: () => {
    return createSelector(
      [(state: any) => state.appState.divisionIds],
      divisionIds => divisionIds,
    );
  },
  getRolesDropDown: () => {
    return createSelector(
      [(state: any) => state.appState.rolesDropDown],
      rolesDropDown => rolesDropDown,
    );
  },
  getStaffHoldConfigMasterDetails: () => {
    return createSelector(
      [(state: any) => state.appState.staffholdconfigmasterdetails],
      staffholdconfigmasterdetails => staffholdconfigmasterdetails,
    );
  },
};
