import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';
import theme from '@app/themes';

const drawerWidth = 240;

export const useStyles = makeStyles({
  checkbox: {
    color: `${theme.colors.primary} !important`,
    transform: 'scale(.8)',
  },
  parentListItemText: {
    color: theme.colors.primary,
    fontSize: 12,
    fontWeight: 700,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  listItemText: {
    color: theme.colors.primary,
    fontSize: 12,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  marginLeft15: {
    marginLeft: 15,
  },
  backgroundWhite: {
    backgroundColor: '#ffffff',
  },
  backgroundGray: {
    backgroundColor: '#f5f8f9',
    display: 'none',
  },
  chipFilter: {
    color: '#534e91',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
  },
  chipClearAll: {
    color: '#534e91',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
  },
  paper: {
    width: drawerWidth - 40,
    backgroundColor: '#ffffff',
    position: 'fixed',
    bottom: 20,
    right: 20,
    boxShadow: 'none',
    borderRadius: '4px',
    zIndex: 100,
    padding: '0px 15px',
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '100%',
    height: '35px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
  paperButtonDisabled: {
    border: `3px solid ${Colors.grey[100]}`,
    backgroundColor: `${Colors.grey[100]} !important`,
  },
  showMoreLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'center',
  },
  applyBox: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  },
  defaultBox: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },
  actionButton: {
    borderRadius: 10,
    border: `1.5px solid ${Colors.primary}`,
    width: '30%',
  },
  disabledActionButton: {
    border: `1.5px solid ${Colors.disabled}`,
  },
  indeterminateBox: {
    height: 7.5,
    width: 7.5,
    backgroundColor: Colors.primary,
    position: 'absolute',
  },
  filterIcon: {
    color: '#534e91',
    fontWeight: 'bold',
    backgroundColor: 'ghostWhite',
    marginLeft: 38,
    marginTop: 10,
  },
  sortIcon: {
    color: '#534e91',
    fontWeight: 'bold',
    backgroundColor: 'ghostWhite',
    marginTop: 10,
  },
});
