import { useEffect, useState, useCallback } from 'react';
import {
  debounce,
} from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { welcomeKitStateSelector } from '@app/screens/employee-specific-utilities/pages/welcome-kit/redux/selector';
import {
  fetchWelcomeKitCreator,
  fetchWelcomeKitSuggestionsCreator,
  welcomeKitStateActions,
} from '@app/screens/employee-specific-utilities/pages/welcome-kit/redux/slice';
import { SearchBar } from '../search-bar';
export const Utilw: any = {
  handleTypeChange:null,
  handleSearch:null,
  autocompleteOnChangeHandler:null,
  textBoxOnChangeHandler:null,
};
type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarWelcomekit = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [type, setType] = useState<EmployeeType>('ALL');

  const handleTypeChange = useCallback(function(value: EmployeeType){
    setType(value);
    dispatch(welcomeKitStateActions.setSearchText(""));
    dispatch(welcomeKitStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}))
  },[])

  const {
    snapshot, searchText, suggestionsLoading,
    selectedAutoSuggestValue, autoSuggestData,
  } = useSelector(
    welcomeKitStateSelector.getWelcomeKit(),
    shallowEqual,
  );

 const handleSearch = useCallback(
    debounce((searchText: string) => dispatch(fetchWelcomeKitSuggestionsCreator(searchText)), 600),
    []
  )

  const autocompleteOnChangeHandler = useCallback(function(value:any){
    if (!value) {
      dispatch(fetchWelcomeKitCreator());
    }

    dispatch(
      welcomeKitStateActions.setSelectedAutoSuggest(
        value,
      ),
    );
  },[])

  const textBoxOnChangeHandler = useCallback(function(event:any){
    dispatch(
      welcomeKitStateActions.setSearchText(
        event.target.value,
      ),
    );
  },[])

  useEffect(()=>{
    dispatch(welcomeKitStateActions.setSearchText(""));
    dispatch(welcomeKitStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}))
  },[])

  useEffect(() => {
    searchText.length > 0 && handleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    selectedAutoSuggestValue &&
      Object.keys(selectedAutoSuggestValue).length > 0 &&
      dispatch(
        welcomeKitStateActions.setWelcomeKit(
          snapshot?.length>0 && snapshot.filter(
            (emp: any) => emp?.name === selectedAutoSuggestValue?.name
          )
        )
      )
  }, [selectedAutoSuggestValue]);

  useEffect(() => {
    if (type !== "ALL") {
      const newSnap = snapshot?.length>0 && snapshot.filter((emp: any) => emp?.role === type)
      dispatch(welcomeKitStateActions.setWelcomeKit(newSnap))
    } else {
      dispatch(welcomeKitStateActions.setWelcomeKit(snapshot));
    }
  }, [type, snapshot])
 Utilw .handleTypeChange=handleTypeChange;
 Utilw.handleSearch = handleSearch;
 Utilw.autocompleteOnChangeHandler=autocompleteOnChangeHandler;
 Utilw.textBoxOnChangeHandler=textBoxOnChangeHandler;
  return (
    <SearchBar 
   
    open={open} 
    setOpen={setOpen} 
    autocompleteOnChangeHandler={autocompleteOnChangeHandler}
    textBoxOnChangeHandler={textBoxOnChangeHandler}
    autoSuggestData={autoSuggestData}
    selectedAutoSuggestValue={selectedAutoSuggestValue}
    suggestionsLoading={suggestionsLoading}
    handleTypeChange={handleTypeChange}     
    />
  );
};
