import {useState, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {cdcCandidatesStateSelector} from '@app/screens/employee-specific-utilities/pages/cdc-candidates/redux/selectors';
import {
  fetchCdcCandidatesCreator,
  cdcCandidatesStateActions,
} from '@app/screens/employee-specific-utilities/pages/cdc-candidates/redux/slice';
import {filterSelector} from '../../components/right-sidebar/redux/filterHandlerSelector';
import {filterStateActions} from '../../components/right-sidebar/redux/filterHandler';
import {SearchBar} from '../search-bar';
import {store} from '../../App';

export const UtilSearch: any = {
  handleTypeChange: null,
  textBoxOnChangeHandler: null,
};

type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarCDCCandidates = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const request = useSelector(filterSelector.getFilterOptions());

  const handleTypeChange = useCallback(
    function (value: EmployeeType) {
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          roleIds: value === 'ALL' ? null : value,
        }),
      );
      dispatch(
        cdcCandidatesStateActions.setCdcCandidatesLoadingPerPage({
          pageNo: 0,
          perPage: 20,
        }),
      );
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchCdcCandidatesCreator());
    },
    [request],
  );

  const {suggestionsLoading, selectedAutoSuggestValue, data} = useSelector(
    cdcCandidatesStateSelector.getCdcCandidatesState(),
    shallowEqual,
  );

  const textBoxOnChangeHandler = useCallback(function (value: any) {
    let filterReq = store?.getState()?.filterState?.filterOptions;
    dispatch(
      filterStateActions.setFilterOptions({
        ...filterReq,
        searchData: value,
      }),
    );
    dispatch(
      cdcCandidatesStateActions.setCdcCandidatesLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    if (value?.length >= 3) {
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchCdcCandidatesCreator());
    } else if (value?.length == 0) {
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchCdcCandidatesCreator());
    }
  }, []);
  UtilSearch.handleTypeChange = handleTypeChange;
  UtilSearch.textBoxOnChangeHandler = textBoxOnChangeHandler;

  return (
    <SearchBar
      open={open}
      setOpen={setOpen}
      autocompleteOnChangeHandler={() => {}}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={data}
      selectedAutoSuggestValue={selectedAutoSuggestValue}
      suggestionsLoading={suggestionsLoading}
      handleTypeChange={handleTypeChange}
    />
  );
};
