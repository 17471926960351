import { createSelector } from "@reduxjs/toolkit";

export const recentUpdatesStateSelector = {
  getRecentUpdatesState: () => {
    return createSelector(
      [(state: any) => state.recentUpdates],
      recentUpdatesGrid => recentUpdatesGrid,
    );
  },
  getTotalCount: () => {
    return createSelector(
      [(state: any) => state.recentUpdates.totalCount],
      totalCount => totalCount,
    ); 
  },
  getRecentUpdatesLoading: () => {
    return createSelector(
      [(state: any) => state.recentUpdates.loading],
      loading => loading,
    );
  },
  getRecentUpdatesPageNo: () => {  
    return createSelector(
      [(state: any) => state.recentUpdates.pageNo],
      recentUpdates => recentUpdates,
    ); 
  },
  getRecentUpdatesPerPage: () => {
    return createSelector(
      [(state: any) => state.recentUpdates.rowsPerPage],
      recentUpdates => recentUpdates,
    );
  },
  getRecentUpdatesGrid: () => {
    return createSelector(
      [(state: any) => state.recentUpdates.data],
      recentUpdatesGrid => recentUpdatesGrid,
    );
  },
  getRecentUpdatesError: () => {
    return createSelector([(state: any) => state.recentUpdates], error => ({
      error: error?.cdcCandidatesError,
      message: error?.cdcCandidatesMessage,
    }));
  },  
}