import React from 'react';
import {Grid} from '@mui/material';
import {CustomTextFieldTP} from './CustomTextFieldTP';
import {useStyles} from './styles';

interface CustomTextFieldRowTPProps {
  righTlable: string;
  rightValue: string;
  leftLable: string;
  leftValue: string;
  extraStyle?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
}

const CustomTextFieldRowTP = ({
  righTlable,
  rightValue,
  leftLable,
  leftValue,
  extraStyle,
  textFieldStyle,
}: CustomTextFieldRowTPProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.TextFieldRowContainer}
      style={extraStyle}>
      <CustomTextFieldTP
        label={righTlable}
        value={rightValue}
        customStyle={textFieldStyle}
      />
      <CustomTextFieldTP
        label={leftLable}
        value={leftValue}
        customStyle={textFieldStyle}
      />
    </Grid>
  );
};

export default CustomTextFieldRowTP;
