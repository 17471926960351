import {createAction, createSlice} from '@reduxjs/toolkit';

export const companyAssetsInitialState = {
  companyAssets: [],
  totalCount:0,
  snapshot: [],
  count: 0,
  loading: false,
  success: false,
  error: false,
  history: [],
  historyLoading: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
  pageNo: 0,
  perPage: 10,
};

export const fetchCompanyAssetsCreator = createAction(
  'COMPANY_ASSETS/FETCH_COMPANY_ASSETS',
);
export const fetchCompanyAssetsCreatorTypeName =
  fetchCompanyAssetsCreator().type;

export const fetchCompanyAssetsHistoryCreator = createAction<any>(
  'COMPANY_ASSETS/FETCH_NESTED_COMPANY_ASSETS',
);
export const fetchCompanyAssetsHistoryCreatorTypeName =
  fetchCompanyAssetsHistoryCreator(null).type;

export const fetchCompanyAssetsSuggestoionsCreator = createAction<any>(
  'COMPANY_ASSETS/FETCH_COMPANY_ASSETS_SUGGESTOIONS',
);
export const fetchCompanyAssetsSuggestoionsCreatorTypeName =
  fetchCompanyAssetsSuggestoionsCreator(null).type;

const companyAssetsStateHandler = createSlice({
  name: 'COMPANY_ASSETS',
  initialState: companyAssetsInitialState,
  reducers: {
    setCompanyAssets: (prevState, action) => {
      return {
        ...prevState,
        companyAssets: action.payload,
      };
    },
    setCompanyAssetsSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapshot: action.payload,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setLoading: (prevState, action) => {
      return {
        ...prevState,
        loading: action.payload,
      };
    },
    setSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapshot: action.payload,
      };
    },
    setError: (prevState, action) => {
      return {
        ...prevState,
        error: true,
        errorMessage: action?.payload,
      };
    },
    clearError: prevState => {
      return {
        ...prevState,
        error: false,
        errorMessage: '',
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
    setPagination : (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        perPage : action.payload.rowsPerPage
      }
    }
  },
});

export const companyAssetsStateActions = companyAssetsStateHandler.actions;
export const companyAssetsStateReducer = companyAssetsStateHandler.reducer;
