import React, {useRef} from 'react';
import './styles.css';
import {Stack, width} from '@mui/system';
import ZhoToggleSeperator from './zhoToggleSeperator';
import {RootStateOrAny, shallowEqual, useSelector} from 'react-redux';

interface Segment {
  label: string;
  value: string;
  ref: React.RefObject<HTMLDivElement>;
}

interface FilterProps {
  role: number;
}

const Togglefilter: React.FC<FilterProps> = ({role}) => {
  const handleToggleChange = () => {};

  const screenIndex = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const fcSegments = [
    {
      label: 'Pending Approval',
      value: 'Pending Approval',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Partially Approved',
      value: 'Partially Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Approved',
      value: 'Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Rejected',
      value: 'Rejected',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Expired',
      value: 'Expired',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  const hoOrders = [
    {
      label: 'Pending Approval',
      value: 'Pending Approval',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Approved',
      value: 'Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Rejected',
      value: 'Rejected',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  const hoInvoice = [
    {
      label: 'Pending POD Upload',
      value: 'Pending POD Upload',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Pending POD Approval',
      value: 'Pending POD Approval',
      ref: useRef<HTMLDivElement>(null),
    },

    {
      label: 'Closed',
      value: 'Closed',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  return (
    <Stack direction="row" justifyContent="flex-end" sx={{padding: '0px 15px'}}>
      <ZhoToggleSeperator
        name="group-1"
        controlRef={useRef()}
        defaultIndex={role === 1 ? 0 : screenIndex === 0 ? 0 : 1}
        callback={handleToggleChange}
        segments={role === 1 ? fcSegments : role === 2 ? hoOrders : hoInvoice}
        itemStyle={{padding: '2px 8px', width: 'fit-content'}}
      />
    </Stack>
  );
};

export default Togglefilter;
