import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Checkbox,
  Container,
  Button,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {useStyles} from '../../styles';
import {Fragment, useEffect, useRef, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Stack, styled} from '@mui/system';
import ToggleTable from './toggleTable';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import {
  fetchRequestFormDataCreator,
  fetchRequestFormInnerDataCreator,
  fetchReviewFormApprovalDataCreator,
  fetchReviewFormProductDataCreator,
  fetchFormApprovalReviewCreator,
} from '../../../redux-api/slice';
import {updateRequestPayload} from '../../../redux/tabName';
import {strings} from '@app/common/strings';
import {ToastType} from '@app/services/toaster';
import {ToasterService} from '@app/services';
import dateUtils from '@app/screens/zho/utils/dateFormat';

interface CheckBoxState {
  id: number;
  checked: boolean;
}

export const RequestFormTable = ({
  handleToggleModal,
  setRequestFormId,
  userLoginInfo,
  modalOpen,
  setModalOpen,
}: any) => {
  const classes = useStyles();
  const {DateFormat, IstFormat} = dateUtils;
  const [openCompetitor, setOpenCompetitor] = useState({id: 0, open: false});
  const [requestIds, setRequestIds] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const requestID = useRef('');
  const dispatch = useDispatch();
  const tableData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.requestformData,
  );

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const requestFormPayload = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.requestPayload,
  );

  const RecallDetails = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.reviewFormApprovalPeriodCompleted,
  );

  const ApprovalReviewCompleted = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.formApprovalReviewCompleted,
  );

  const requestFormDataLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.requestFormLoading,
  );

  const [checkBox, setCheckBox] = useState<CheckBoxState[]>([]);

  const toggleCheckBox = (type: any, id: number) => {
    setCheckBox((prevCheckBox: any) => {
      let updatedRequestIds: number[] = [...requestIds];

      if (type === 'all') {
        const allChecked = prevCheckBox.every(
          (item: any) => item.checked === true,
        );
        const newCheckBoxState = prevCheckBox.map((item: any) => ({
          ...item,
          checked: !allChecked,
        }));

        // Update requestIds based on allChecked status
        updatedRequestIds = allChecked
          ? []
          : tableData.requestList?.map(
              (request: {requestId: number}) => request.requestId,
            ) || [];

        setRequestIds(updatedRequestIds);
        return newCheckBoxState;
      } else {
        const newCheckBoxState = prevCheckBox.map((item: any) => {
          if (item.id === type) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return item;
        });

        // Update requestIds based on individual checkbox state
        const isChecked = newCheckBoxState.find(
          (item: any) => item.id === type,
        )?.checked;
        if (isChecked) {
          updatedRequestIds.push(id);
        } else {
          updatedRequestIds = updatedRequestIds.filter(
            requestId => requestId !== id,
          );
        }

        setRequestIds(updatedRequestIds);
        return newCheckBoxState;
      }
    });
  };

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#f5b825';
    } else if (status === 1) {
      return '#5abea6';
    } else if (status === 3 || status === 4) {
      return '#db3d3d';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const handleApprovalReviewForm = () => {
    if (requestIds.length === 0) {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please select a request to Approve!',
        ToastType.ERROR,
      );
    }
    if (requestIds.length > 0) {
      dispatch(
        fetchFormApprovalReviewCreator(
          userLoginInfo?.staffPositionId,
          userLoginInfo?.userId,
          requestIds,
          'approval',
        ),
      );

      setRequestIds([]);

      // setTimeout(() => {
      //   setRequestIds([]);
      // }, 100);
    }
  };

  const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '5px',
      padding: '0.3rem',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  useEffect(() => {
    dispatch(
      updateRequestPayload({
        ...requestFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );
    // dispatch(fetchStateDataCreator(userLoginInfo?.staffPositionId));
    dispatch(
      fetchRequestFormDataCreator(
        {
          ...requestFormPayload,
          pageSize: rowsPerPage,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
        },
        0,
      ),
    );
    if (tableData?.requestList) {
      const initialCheckBoxState = tableData.requestList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
  }, []);

  useEffect(() => {
    if (
      requestID.current !== undefined &&
      requestID.current !== null &&
      requestID.current !== ''
    ) {
      dispatch(fetchReviewFormProductDataCreator(requestID.current));
    }
  }, [RecallDetails]);

  useEffect(() => {
    dispatch(
      fetchRequestFormDataCreator(
        {
          ...requestFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
        },
        statusToggleNo,
      ),
    );
    if (
      requestID.current !== undefined &&
      requestID.current !== null &&
      requestID.current !== ''
    ) {
      dispatch(fetchReviewFormApprovalDataCreator(requestID.current));
      dispatch(fetchReviewFormProductDataCreator(requestID.current));
    }

    if (tableData?.requestList) {
      const initialCheckBoxState = tableData.requestList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
  }, [ApprovalReviewCompleted]);

  useEffect(() => {
    if (tableData?.requestList) {
      const initialCheckBoxState = tableData.requestList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }

    // setRequestIds([]);
  }, [tableData]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    setOpenCompetitor({id: 0, open: false});
  }, [statusToggleNo]);

  const handleInnerClickData = (requestId: number, index: number) => {
    setOpenCompetitor({
      id: index,
      open: true,
    });
    dispatch(fetchRequestFormInnerDataCreator(requestId, false));
  };

  const handleTooltipOpen = (index: number) => {
    setModalOpen({id: index, isOpen: true});
  };
  const handleTooltipClose = (index: number | null) => {
    setModalOpen({id: index, isOpen: false});
  };

  const handleOpenReviewPopup = (requestId: number) => {
    handleToggleModal();
    handleTooltipClose(null);
    dispatch(fetchReviewFormApprovalDataCreator(requestId));
    dispatch(fetchReviewFormProductDataCreator(requestId));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    console.log('this is the requestpayload', requestFormPayload);
    handleTooltipClose(null);
    setPage(newPage);
    dispatch(
      fetchRequestFormDataCreator(
        {
          ...requestFormPayload,
          pageSize: rowsPerPage,
          pageNumber: newPage, // Send one-based page number to the API
          currentStaffPositionId: userLoginInfo?.staffPositionId,
        },
        statusToggleNo,
      ),
    );
    dispatch(
      updateRequestPayload({
        ...requestFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: rowsPerPage,
        pageNumber: newPage, // API expects one-based page number
      }),
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    handleTooltipClose(null);
    setRowsPerPage(newPageSize);
    setPage(0); // Reset to the first page (zero-based for Material-UI)
    dispatch(
      fetchRequestFormDataCreator(
        {
          ...requestFormPayload,
          pageSize: newPageSize,
          pageNumber: 0, // API expects one-based page number
          currentStaffPositionId: userLoginInfo?.staffPositionId,
        },
        statusToggleNo,
      ),
    );
    dispatch(
      updateRequestPayload({
        ...requestFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: newPageSize,
        pageNumber: 0, // API expects one-based page number
      }),
    );
  };

  return (
    <Container
      sx={{
        marginTop: '10px',
        position: 'relative',
        maxWidth: '100% !important',
        padding: '0px  !important',
      }}>
      {statusToggleNo === 1 && tableData?.requestList?.length > 0 && (
        <Stack
          marginBottom="12px"
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Box>
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              onClick={handleApprovalReviewForm}>
              {strings.approve}
            </Button>
          </Box>
        </Stack>
      )}

      {!requestFormDataLoader ? (
        <>
          <TableContainer className={`${classes.noBoxShd}`} component={Paper}>
            <Table sx={{borderCollapse: 'separate', borderSpacing: '0px 1px'}}>
              <TableHead>
                <TableRow className={`${classes.requestformTableHeading}   `}>
                  {statusToggleNo === 1 &&
                    tableData?.requestList?.length > 0 && (
                      <TableCell
                        sx={{border: 'none', padding: '0px !important'}}>
                        <Checkbox
                          sx={{color: '#322b7c !important'}}
                          data-testid="check-all-requestform"
                          checked={
                            checkBox?.length > 0 &&
                            checkBox.every(
                              (data: any) => data?.checked === true,
                            )
                          }
                          onClick={() => toggleCheckBox('all', 0)}
                        />
                      </TableCell>
                    )}
                  <TableCell
                    sx={{
                      paddingLeft: '44px',
                      border: 'none',
                      minWidth: '180px !important',
                    }}
                    className={`${classes.requestformTableHeading}  `}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.requestFormNo}
                  </TableCell>

                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingWidth} `}>
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      padding: '10px 15px',
                      minWidth: '125px',
                    }}
                    className={`${classes.requestformTableHeading}`}>
                    {strings.zho.customer}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingWidth} `}>
                    {strings.hq}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '10px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingWidth} `}>
                    {strings.status}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={classes.requestformTableHeading}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '100px',
                      padding: '0px 10px !important',
                    }}
                    className={classes.requestformTableHeading}>
                    {strings.zho.mrName}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '10px !important'}}
                    className={`${classes.requestformTableHeading} `}>
                    {strings.actions}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.requestList?.length > 0 ? (
                  tableData?.requestList?.map((data: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow className={classes.requestformTbleBoxSdw}>
                          {statusToggleNo === 1 && (
                            <TableCell
                              className={classes.checkBoxShd}
                              sx={{
                                border: 'none !important',
                                padding: '0px !important',
                              }}>
                              <Checkbox
                                sx={{color: '#322b7c !important'}}
                                data-testid="check-requestform"
                                checked={checkBox[index]?.checked || false}
                                onClick={() =>
                                  toggleCheckBox(index, data?.requestId)
                                }
                              />
                            </TableCell>
                          )}
                          <TableCell
                            sx={{padding: '0px 6px !important'}}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont} ${
                              classes.productTableLeftBorder
                            } ${classes.borderTopLeft} `}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center">
                              <Box width="30px" display="inline-block">
                                <IconButton
                                  data-testid="show-comp-prod"
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}>
                                  {/* <ReplayIcon color="primary" /> */}
                                  {openCompetitor.open &&
                                  openCompetitor.id === index ? (
                                    <RemoveCircleOutlineOutlinedIcon
                                      sx={{
                                        height: '18px',
                                        width: '18px',
                                      }}
                                      color="primary"
                                      onClick={() =>
                                        setOpenCompetitor({
                                          id: index,
                                          open: false,
                                        })
                                      }
                                    />
                                  ) : (
                                    <AddCircleOutlineOutlinedIcon
                                      sx={{height: '18px', width: '18px'}}
                                      color="primary"
                                      onClick={() =>
                                        handleInnerClickData(
                                          data?.requestId,
                                          index,
                                        )
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Box
                                fontSize="11.7px"
                                minWidth="160px"
                                display="inline-block">
                                {data?.requestFormNo}
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            {data?.createdOn !== null
                              ? DateFormat(data?.createdOn)
                              : '---'}
                          </TableCell>
                          <TableCell
                            sx={{padding: '10px 15px !important'}}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}
                              justifyContent="space-between">
                              <Box>
                                {data?.stockistNamelist[0]?.length > 12
                                  ? `${data.stockistNamelist[0].slice(
                                      0,
                                      12,
                                    )}...`
                                  : data.stockistNamelist[0]}
                              </Box>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <LightTooltip
                                  title={
                                    <>
                                      {data?.stockistNamelist.map(
                                        (data: any, index: number) => (
                                          <Typography
                                            fontSize="11.7px"
                                            fontWeight="400"
                                            variant="caption"
                                            key={index}>
                                            {data}
                                          </Typography>
                                        ),
                                      )}
                                    </>
                                  }>
                                  <InfoOutlinedIcon
                                    data-testid="show-customers"
                                    sx={{height: '15px', width: '15px'}}
                                    color="primary"
                                  />
                                </LightTooltip>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '0px !important',
                              wordBreak: 'break-all !important',
                            }}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            {data?.hq}
                          </TableCell>
                          <TableCell
                            sx={{padding: '10px !important'}}
                            style={{
                              color: getStatusColor(data?.statusCode),
                              fontWeight: '600',
                              padding: '10px !important',
                              minWidth: '90px',
                            }}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            {data?.status}
                          </TableCell>
                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            {data?.chemist}
                          </TableCell>
                          <TableCell
                            sx={{padding: '10px !important'}}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            {data?.mrName}
                          </TableCell>
                          <TableCell
                            sx={{padding: '10px !important'}}
                            onClick={() => {
                              handleOpenReviewPopup(data?.requestId);
                              setRequestFormId(data?.requestId);
                              requestID.current = data?.requestId;
                            }}
                            className={`  ${
                              tableData?.requestList[index]?.isHighlighted
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}  ${
                              classes.borderTopRight
                            } ${classes.productTableRightBorder}`}>
                            <Typography
                              data-testid="review-request"
                              sx={{fontSize: '11.7px', cursor: 'pointer'}}
                              variant="subtitle2"
                              color="primary">
                              Review
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {openCompetitor.open && openCompetitor.id === index && (
                          <TableRow>
                            {statusToggleNo === 1 && (
                              <TableCell sx={{border: 'none'}}></TableCell>
                            )}
                            <TableCell
                              sx={{
                                padding: '0px 0px 8px 0px',
                                border: 'none !important',
                              }}
                              colSpan={7}>
                              <ToggleTable />
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{textAlign: 'center'}} colSpan={9}>
                      Currently there is no request!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={+tableData?.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box
          className={classes.loader}
          sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default RequestFormTable;
