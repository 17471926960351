import {Box, Drawer, Typography, Stack} from '@mui/material';
import NotificationModal from '@app/components/right-sidebar/NotificationModal';

const drawerWidth = 270;

const styles = {
  backgroundWhite: {
    backgroundColor: '#fff',
  },
  drawer: {
    minWidth: drawerWidth,
    width: 'auto',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      border: 0,
    },
  },
  paperProps: {
    backgroundColor: '#f5f8f9',
  },
};

const RightSidebarView = () => {
  return (
    <Drawer
      PaperProps={{sx: styles.paperProps}}
      sx={styles.drawer}
      variant="permanent"
      anchor="right">
      <NotificationModal />
      <Box
        margin="20px"
        borderRadius="20px"
        sx={styles.backgroundWhite}
        className="hide-scrollbar"
        height="100vh"
        overflow="auto">
        <Box padding="15px">
          <Typography variant="subtitle1" gutterBottom>
            Performance
          </Typography>
          <Stack
            marginBottom={2}
            direction="row"
            justifyContent="space-between">
            <Box>
              <Typography fontSize="11.5px" variant="subtitle1" gutterBottom>
                Satyender Jain
              </Typography>
              <Box>
                <Typography
                  minWidth="130px"
                  fontSize="10px"
                  variant="subtitle2"
                  color="#7090b0"
                  gutterBottom>
                  Order Approved/Rejected
                </Typography>
                <Box fontSize="11px" color="#322b7c" component="span">
                  16
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                whiteSpace="nowrap"
                fontSize="11.5px"
                variant="subtitle1"
                gutterBottom>
                22-04-2024
              </Typography>
            </Box>
          </Stack>
          <Stack
            marginBottom={2}
            direction="row"
            justifyContent="space-between">
            <Box>
              <Typography fontSize="11.5px" variant="subtitle1" gutterBottom>
                Ramesh Venkat
              </Typography>
              <Box>
                <Typography
                  minWidth="130px"
                  fontSize="10px"
                  variant="subtitle2"
                  color="#7090b0"
                  gutterBottom>
                  Order Approved/Rejected
                </Typography>
                <Box fontSize="11px" color="#322b7c" component="span">
                  20
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                whiteSpace="nowrap"
                fontSize="11.5px"
                variant="subtitle1"
                gutterBottom>
                22-04-2024
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RightSidebarView;
