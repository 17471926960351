import {useState, useEffect} from 'react';
import {
  FormControl,
  Autocomplete,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface CustomProps {
  data: any[];
  dropDownTitle: string;
  backgroundColor?: string;
  onClose?: any;
  onInputChange?: any;
  noOptionsText?: string;
  resetFlag?: boolean;
  setRF?: any;
  checkboxEnabled?: boolean;
}

const CustomSelect = (props: CustomProps) => {
  console.log(props, 'props');
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const styles = {
    selectStyle: {
      borderRadius: '5px',
      height: '30px',
      backgroundColor: props.backgroundColor,
      '& .MuiOutlinedInput-root': {
        padding: '4px 8px',
      },
      '& .MuiInputLabel-root': {
        top: '-4.5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '2px',
      },
      '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
        minWidth: '6px',
        width: '6px',
      },
    },
    inputStyles: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '70%',
      margin: '0px',
      textOverflow: 'ellipsis',
    },
  };
  const [assignState, setAssignState] = useState<string[]>([]);
  const [checkboxEnabled, setCheckboxEnabled] = useState(
    props?.checkboxEnabled,
  );

  const handleAssingnState = (event: any, newValue: any | null) => {
    setAssignState(newValue);
    newValue && newValue.length === 0 && props.onClose && props.onClose([]);
  };

  useEffect(() => {
    props.resetFlag && setAssignState([]);
    props.resetFlag && props.setRF && props.setRF(false);
  }, [props.resetFlag]);

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        limitTags={2}
        disableCloseOnSelect
        options={props.data || []}
        noOptionsText={props.noOptionsText ? props.noOptionsText : 'No Options'}
        multiple
        value={assignState}
        onChange={handleAssingnState}
        onClose={() =>
          props.onClose &&
          props.onClose(assignState.map((state: any) => state.value))
        }
        renderOption={(props, option, {selected}) => (
          <li {...props}>
            {checkboxEnabled && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{marginRight: 8, height: '10px'}}
                checked={selected}
              />
            )}
            {option.label}
          </li>
        )}
        onInputChange={(e, value) =>
          props.onInputChange && props.onInputChange(value)
        }
        sx={styles.selectStyle}
        renderInput={params => (
          <TextField label={props.dropDownTitle} {...params} />
        )}
        renderTags={values => (
          <Typography variant="body2" sx={styles.inputStyles}>
            {values.map(value => value.label).join(', ')}
          </Typography>
        )}
      />
    </FormControl>
  );
};

export default CustomSelect;
