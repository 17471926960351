import {useState, useEffect} from 'react';
import {Box, Chip, Paper, Button, BottomNavigation} from '@mui/material';
import {useStyles} from './styles';
import CheckList from '@app/components/widgets/CheckList';
import SliderComp from '@app/components/widgets/Slider';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {employee360StateSelector} from '@app/screens/not-assigned/pages/employee360/redux/selectors';
import {employee360StateActions} from '@app/screens/not-assigned/pages/employee360/redux';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import {fetchEmp360DefaultGenderAndReligionActionCreator} from '@app/screens/not-assigned/pages/employee360/redux/slice';

interface Filter {
  Gender: string[];
  Religion: string[];
  MaxManagerRating: number;
  MinManagerRating: number;
  MaxWorkExperience: number;
  MinWorkExperience: number;
}

export default function Employee360RightSidebar() {
  const dispatch = useDispatch();
  const [clear, setClear] = useState<boolean>(false);
  const classes = useStyles();
  const applyButtonDisabled = false;
  const [showFilters, setShowFilters] = useState(false);
  const [selectedGenders, setSelectedGender] = useState<string[]>([]);
  const [selectedReligion, setSelectedReligion] = useState<string[]>([]);
  const [selectedWE, setSelectedWE] = useState<number[]>([]);
  const [selectedRating, setSelectedRating] = useState<number[]>([]);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const filters = useSelector(
    employee360StateSelector.getEmp360EmpFilters(),
    shallowEqual,
  );

  const isProfileActive = useSelector(
    employee360StateSelector.getEmp360IsProfileActive(),
    shallowEqual,
  );

  const defaultGenderAndReligion = useSelector(
    employee360StateSelector.getEmployee360DefaultGenderandReligion(),
    // shallowEqual,
  );

  // const defaultGenderAndReligionLoading =

  useEffect(() => {
    if (filtersVisible) {
      dispatch(fetchEmp360DefaultGenderAndReligionActionCreator());
    }
  }, []);

  useEffect(() => {
    setShowFilters(
      filters?.Religion?.length > 1 ||
        filters?.Gender?.length > 1 ||
        filters?.MaxManagerRating !== filters?.MinManagerRating ||
        filters?.MaxWorkExperience !== filters?.MinWorkExperience,
    );
  }, [filters]);

  const handleFilterIconClick = () => {
    setFiltersVisible(prev => !prev);
  };

  const handleClearFilters = () => {
    setClear(prev => !prev);
    setSelectedGender([]);
    setSelectedReligion([]);
    setSelectedWE([filters.MinWorkExperience, filters.MaxWorkExperience]);
    setSelectedRating([filters.MinManagerRating, filters.MaxManagerRating]);
  };
 
  console.log('filters',filters)
  return (
    <>
      <Box>
        <Chip
          icon={<FilterAltOutlinedIcon onClick={handleFilterIconClick} />}
          size="medium"
          className={classes.filterIcon}
        />
        <Chip
          icon={<SortOutlinedIcon />}
          size="medium"
          className={classes.sortIcon}
        />
      </Box>
      {filtersVisible && (
        <Box
          margin="20px"
          marginTop="10px"
          borderRadius="20px"
          className={`${
            !isProfileActive ? classes.backgroundWhite : classes.backgroundGray
          } hide-scrollbar`}
          height="100vh"
          overflow={'scroll'}>
          <div>
            <Box
              display={'flex'}
              justifyContent="space-between"
              marginBottom={'10px'}
              padding={'10px'}>
              <Chip
                label="Filter"
                size="small"
                className={classes.chipFilter}
              />
              <Chip
                label="Clear All"
                size="small"
                clickable
                onClick={handleClearFilters}
                data-testid="clear-filters"
                className={classes.chipFilter}
              />
            </Box>
            <CheckList
              clear={clear}
              parentTitle={'Gender'}
              data-testid={'Emp_Gender'}
              onCheck={(checkedItems: number[]) => {
                const tempGenderFilter = checkedItems.map(
                  (i: number) => filters.Gender[i],
                );
                setSelectedGender(tempGenderFilter);
              }}
              data={
                filters?.Gender.length > 0
                  ? filters?.Gender?.map((g: any) => {
                      console.log('g1', g);
                      return {label: g, isChecked: true};
                    })
                  : defaultGenderAndReligion?.Gender?.map((g: any) => {
                      console.log('g2', g);
                      return {label: g.label, isChecked: true};
                    })
              }
            />
            <CheckList
              clear={clear}
              onCheck={(checkedItems: number[]) => {
                const tempReligionFilter = checkedItems.map(
                  (i: number) => filters.Religion[i],
                );
                setSelectedReligion(tempReligionFilter);
              }}
              parentTitle={'Religion'}
              data-testid={'Emp_Religion'}
              data={
                filters?.Religion.length > 0
                  ? filters?.Religion?.map((r: any) => {
                      return {label: r, isChecked: true};
                    })
                  : defaultGenderAndReligion?.Religion?.map((r: any) => {
                      return {label: r.label, isChecked: true};
                    })
              }
            />
            {filters.MaxWorkExperience !== filters.MinWorkExperience ? (
              <SliderComp
                title="Work Experience"
                clear={clear}
                min={filters.MinWorkExperience}
                max={filters.MaxWorkExperience}
                onChange={(newValue: number[]) => setSelectedWE(newValue)}
              />
            ) : (
              <SliderComp
                title="Work Experience"
                clear={clear}
                min={0}
                max={5}
                onChange={(newValue: number[]) => setSelectedRating(newValue)}
              />
            )}
            {filters.MaxManagerRating !== filters.MinManagerRating ? (
              <SliderComp
                title="Manager Rating"
                clear={clear}
                min={filters.MinManagerRating}
                max={filters.MaxManagerRating}
                onChange={(newValue: number[]) => setSelectedRating(newValue)}
              />
            ) : (
              <SliderComp
                title="Manager Rating"
                clear={clear}
                min={0}
                max={5}
                onChange={(newValue: number[]) => setSelectedRating(newValue)}
              />
            )}
            <Paper className={`${classes.paper} height-100`} elevation={3}>
              <BottomNavigation showLabels value={0}>
                <Box className={classes.applyBox}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      let filterRatings = {};
                      if (
                        selectedRating[0] !== filters.MinManagerRating ||
                        selectedRating[1] !== filters.MaxManagerRating
                      ) {
                        filterRatings = {
                          ...filterRatings,
                          minManagerRating: selectedRating[0],
                          maxManagerRating: selectedRating[1],
                        };
                      }

                      if (
                        selectedWE[0] !== filters.MinWorkExperience ||
                        selectedWE[1] !== filters.MinWorkExperience
                      ) {
                        filterRatings = {
                          ...filterRatings,
                          minWorkExperience: selectedWE[0],
                          maxWorkExperience: selectedWE[1],
                        };
                      }
                      dispatch(
                        employee360StateActions.setEmp360EmpFiltersApplied({
                          filters: {
                            Gender:
                              selectedGenders.length < filters.Gender.length
                                && selectedGenders
                                // : defaultGenderAndReligion.Gender,
                                ,
                            Religion:
                              selectedReligion.length < filters.Religion.length
                                &&selectedReligion
                                // : defaultGenderAndReligion.Religion,
                          },
                          filterRatings: filterRatings,
                        }),
                      );
                    }}
                    className={`${classes.paperButton} ${
                      applyButtonDisabled && classes.paperButtonDisabled
                    }`}
                    disabled={applyButtonDisabled}
                    data-testid={'apply_filters'}>
                    Apply
                  </Button>
                </Box>
              </BottomNavigation>
            </Paper>
          </div>
        </Box>
      )}
    </>
  );
}
