import {API_PATH, HTTP_OK} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import moment from 'moment';
import {select} from 'redux-saga/effects';
import {cdcCandidatesStateSelector} from './selectors';
import {appSelector} from '@app/store/selectors';
import * as XLSX from 'xlsx';
import {
  fetchCdcCandidatesCreatorTypeName,
  fetchCdcCandidatesSuggestionsCreatorTypeName,
  sendCdcCandidatesNotificationCreatorTypeName,
  fetchCdcCandidatesHistoryCreatorTypeName,
  cdcCandidatesStateActions,
  fetchCdcCandidatesFilterCreatorTypeName,
  uploadCDCCandidatespopUpTableDataType,
  fetchCdcEmailTemplateDataCreatorTypeName,
  fetchSendEmailCreatorTypeName,
} from './slice';

/**
 * Cdc Candidates watcher
 */
export function* fetchCdcCandidatesDataWatcher() {
  yield takeLatest(
    fetchCdcCandidatesCreatorTypeName,
    fetchCdcCandidatesDataWatcherWorker,
  );
}

/**
 * Cdc Candidates Worker
 */
export function* fetchCdcCandidatesDataWatcherWorker(): any {
  try {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(true));
    yield put(appSliceActions.setExcelDataLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.getAll}`;
    let pageNo = yield select(
      cdcCandidatesStateSelector.getCDCCandidatesPageNo(),
    );
    let perPage = yield select(
      cdcCandidatesStateSelector.getCDCCandidatesPerPage(),
    );
    const stateByAdminData = yield select(appSelector.getStateDropDown());
    const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
    const divisionIds = yield select(appSelector.getDivisionDropDownIds());
    const stateIds = yield select(appSelector.getStateDropDownIds());
    const filters = yield select(filterSelector.getFilterState());
    const filterApplied = yield select(filterSelector.getFilterApplied());
    const filter = yield select(filterSelector.getFilterOptions());
    if (!filterApplied) {
      // if (divisionByAdminData?.length > 0) {
        const options: any = {};
        const optionsState: any = {};
        divisionByAdminData?.map((item: any) => {
          options[item.label] = false;
          return null;
        });
        stateByAdminData?.map((item: any) => {
          optionsState[item.label] = false;
          return null;
        });
        yield put(
          filterStateActions.setFilterState({
            ...filters,
            Division: {
              completeApplied: false,
              collapsed: true,
              options,
            },
            States: {
              completeApplied: false,
              collapsed: true,
              options: optionsState,
            },
          }),
        );
      // }
    }
    let request = {
      searchData: '',
      pageNo: pageNo + 1,
      rowPerPage: perPage,
      stateIds: stateIds,
      divisionIds: divisionIds,
      roleIds: null,
    };
    if (filterApplied) {
      request = {
        ...filter,
        pageNo: pageNo + 1,
        rowPerPage: perPage,
      };
      if (filter?.stateIds?.length == 0) {
        request.stateIds = stateIds;
      }
      if (filter?.divisionIds?.length == 0) {
        request.divisionIds = divisionIds;
      }
    }
    const response = yield call(NetworkService.post, url, request, {});
    yield put(
      cdcCandidatesStateActions.setCdcCandidates(response?.data?.cdcData),
    );
    yield put(
      cdcCandidatesStateActions.setCdcCandidatesSnapshot(
        response?.data?.cdcData,
      ),
    );
    yield put(
      cdcCandidatesStateActions.setTotalCount(response?.data?.totalCount),
    );

    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));

    let requestExcel = {
      ...filter,
      pageNo: 1,
      rowPerPage: response.data?.totalCount,
    };

    if (filter.stateIds.length == 0) {
      requestExcel.stateIds = stateIds;
    }
    if (filter.divisionIds.length == 0) {
      requestExcel.divisionIds = divisionIds;
    }

    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );

    yield put(
      appSliceActions.setExcelData({
        data:
          responseExcel?.data?.cdcData?.map((item: any) => {
            return {
              ...item,
              ['Promotion']: item.isPromoted ? 'Enable' : 'Disable',
              ['Designation']: item.isDesignationChangeRequired
                ? 'Enable'
                : 'Disable',
              ['lastPromotionDate']: item?.lastPromotionDate
                ? moment(item?.lastPromotionDate).format('DD/MM/YY')
                : null,
              ['developmentCentre']: item?.developmentCentre,
              ['isPromoted']: item?.isPromoted ? 'Enable' : 'Disable',
              ['isDesignationChangeRequired']: item?.isDesignationChangeRequired
                ? 'Enable'
                : 'Disable',
              // ['isPromoted']: item?.isPromoted?.toLowerCase(),
              // ['isDesignationChangeRequired']:
              //   item?.isDesignationChangeRequired?.toLowerCase(),
            };
          }) ?? [],
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'employeeName'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managerName'},
          {label: 'Promotion', key: 'isPromoted'},
          {label: 'Designation', key: 'isDesignationChangeRequired'},
          {label: 'Manager Rating', key: 'managerrating'},
          {label: 'Work Experience', key: 'workExperience'},
          {label: 'Last Promotion Date', key: 'lastPromotionDate'},
          {label: 'Development Center', key: 'developmentCentre'},
        ],
      }),
    );

    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  } finally {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  }
}

export function* fetchCdcCandidatesFilterDataWatcher() {
  yield takeLatest(
    fetchCdcCandidatesFilterCreatorTypeName,
    fetchCdcCandidatesFilterDataWatcherWorker,
  );
}

export function* fetchCdcCandidatesFilterDataWatcherWorker(): any {
  try {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(true));
    const divisionIds = yield select(appSelector.getDivisionDropDownIds());
    const stateIds = yield select(appSelector.getStateDropDownIds());
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.getAll}`;
    let pageNo = yield select(
      cdcCandidatesStateSelector.getCDCCandidatesPageNo(),
    );
    let perPage = yield select(
      cdcCandidatesStateSelector.getCDCCandidatesPerPage(),
    );
    const filter = yield select(filterSelector.getFilterOptions());
    let request = {
      ...filter,
      pageNo: pageNo + 1,
      rowPerPage: perPage,
    };
    if (filter.stateIds.length == 0) {
      request.stateIds = stateIds;
    }
    if (filter.divisionIds.length == 0) {
      request.divisionIds = divisionIds;
    }
    const response = yield call(NetworkService.post, url, request, {});
    yield put(
      cdcCandidatesStateActions.setCdcCandidates(response.data.cdcData),
    );
    yield put(
      cdcCandidatesStateActions.setCdcCandidatesSnapshot(response.data.cdcData),
    );
    yield put(
      cdcCandidatesStateActions.setTotalCount(response.data?.totalCount),
    );
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  } finally {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
  }
}

/**
 * Cdc Candidates suggestions watcher
 */
export function* fetchCdcSuggestionsWatcher() {
  yield takeLatest(
    fetchCdcCandidatesSuggestionsCreatorTypeName,
    fetchCdcSuggestionsWatcherWorker,
  );
}

/**
 * Cdc Candidates suggestions Worker
 */
export function* fetchCdcSuggestionsWatcherWorker(action: any): any {
  try {
    yield put(cdcCandidatesStateActions.setAutoSuggestLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.suggestions}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(cdcCandidatesStateActions.setAutoSuggestData(response.data));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching cdc suggestions!',
      ToastType.ERROR,
    );
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  } finally {
    yield put(cdcCandidatesStateActions.setAutoSuggestLoading(false));
  }
}

/**
 * Cdc Candidates notification watcher
 */
export function* sendCdcNotificationWatcher() {
  yield takeLatest(
    sendCdcCandidatesNotificationCreatorTypeName,
    sendCdcNotificationWatcherWorker,
  );
}

/**
 * Cdc Candidates notification Worker
 */
export function* sendCdcNotificationWatcherWorker(action: any): any {
  try {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.notification}`;
    yield call(NetworkService.post, url, [
      {
        ...action?.payload,
      },
    ]);

    ToasterService.showToaster(
      'Successfully sent the notification!',
      ToastType.SUCCESS,
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while sending cdc notifications!',
      ToastType.ERROR,
    );
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  } finally {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
  }
}

/**
 * Cdc Candidates history watcher
 */
export function* fetchCdcCandidatesHistoryWatcher() {
  yield takeLatest(
    fetchCdcCandidatesHistoryCreatorTypeName,
    fetchCdcCandidatesHistoryWatcherWorker,
  );
}

/**
 * Cdc Candidates history Worker
 */
export function* fetchCdcCandidatesHistoryWatcherWorker(action: any): any {
  try {
    // yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.deepdive}/${action?.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(cdcCandidatesStateActions.setHistory(response.data));
    // yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while sending cdc notifications!',
      ToastType.ERROR,
    );
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  }
}

/**
 * Cdc Candidates popup data  watcher
 */

export function* cdcCandidatesPopUpDataWatcher() {
  yield takeLatest(
    uploadCDCCandidatespopUpTableDataType,
    cdcCandidatesPopUpDataWatcherWorker,
  );
}

/**
 * Cdc Candidates popup data worker
 */

export function* cdcCandidatesPopUpDataWatcherWorker(action: any): any {
  try {
    yield put(cdcCandidatesStateActions.setUploadedExcelLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.uploadtemplatetoblob}`;
    const buffer = yield action.payload.xlsx.writeBuffer();

    const excelFileObject = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    console.log('excelFileObject', excelFileObject.stream());
    const excelFormData = new FormData();
    excelFormData?.append('fileForUpload', excelFileObject, 'CDC.xlsx');
    const response = yield call(NetworkService.post, url, excelFormData);
    yield put(
      cdcCandidatesStateActions.setUploadedExcel(
        response?.data?.isFileUploaded,
      ),
    );
    yield put(
      cdcCandidatesStateActions.setUploadedExcelPath(response?.data?.filePath),
    );
    yield put(cdcCandidatesStateActions.setUploadedExcelLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while Uploading Excel file!',
      ToastType.ERROR,
    );

    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
    yield put(cdcCandidatesStateActions.setUploadedExcelLoading(false));
  }
}

export function* cdcEmailTemplateDataWatcher() {
  yield takeLatest(
    fetchCdcEmailTemplateDataCreatorTypeName,
    cdcEmailTemplateDataWatcherWorker,
  );
}

export function* cdcEmailTemplateDataWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.cdcEmailTemplateData}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(
      cdcCandidatesStateActions.setCdcEmailTemplateData(response?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while Email Template Data Loading!',
      ToastType.ERROR,
    );
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  } finally {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
  }
}

export function* sendEmailDataWatcher() {
  yield takeLatest(fetchSendEmailCreatorTypeName, sendEmailWatcherWorker);
}

export function* sendEmailWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.cdcCandidates.sendEmail}`;
    const response = yield call(NetworkService.post, url, {
      ...action?.payload,
    });
    if (response?.status === HTTP_OK) {
      yield put(cdcCandidatesStateActions.setSendCdcEmailSuccess(true));
      ToasterService.showToaster(
        'Email sent successfully to the CDC team!',
        ToastType.SUCCESS,
      );
    } else {
      ToasterService.showToaster(
        'Something went wrong while sending the email to cdc candidates!',
        ToastType.ERROR,
      );
    }
  } catch (error) {
    yield put(cdcCandidatesStateActions.setCdcCandidatesError(error));
    setTimeout(function* () {
      yield put(cdcCandidatesStateActions.clearCdcCandidatesError());
    }, 5000);
  } finally {
    yield put(cdcCandidatesStateActions.setCdcCandidatesLoading(false));
  }
}
