import {useState, useEffect} from 'react';
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import Colors from '@app/themes/colors';

interface CustomProps {
  clear: boolean;
  data: any[];
  parentTitle: string;
  onCheck: any;
}
const CheckList = (props: CustomProps) => {
  const [itemState, setItemState] = useState<any[]>([...props.data]);
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState<number[]>(
    Array.from({length: props.data.length}, (x, i) => {
      return i;
    }),
  );
  const [clickedCheckBox, setClickedCheckBox] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    setItemState([...props.data]);
  },[props]);

  useEffect(() => {
    props.onCheck(checked);
  }, [checked]);

  useEffect(() => {
    const checked = Array.from({length: props.data.length}, (x, i) => {
      return i;
    });
    setChecked(checked);
  }, [props.clear]);

  console.log('props.data',props.data);
  console.log('itemState',itemState);

  return (
    <List sx={{padding: '4px 0px'}}>
      <ListItemButton
        onClick={handleClick}
        sx={{
          height: '30px',
        }}>
        <ListItemIcon>
          <Checkbox
            onClick={() => {
              if (checked.length === itemState.length) {
                setChecked([]);
                setOpen(!open);
              } else {
                const newChecked = itemState.map((_value, index) => index);
                setChecked([...newChecked]);
                setOpen(true);
              }
              setClickedCheckBox(!clickedCheckBox);
            }}
            checked={checked.length === itemState.length}
            disableRipple
            indeterminate={
              checked.length < itemState.length && checked.length > 0
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={props.parentTitle}
          primaryTypographyProps={{
            style: {color: Colors.blue[1600], fontSize: 12},
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {itemState.map((item, index) => {
            const labelId = `checkbox-list-label-${props.parentTitle}-${index}`;
            return (
              <ListItemButton
                sx={{pl: 4, height: '30px'}}
                onClick={handleToggle(index)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(index) !== -1}
                    tabIndex={-1}
                    disableRipple
                    data-testid={labelId}
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    style: {color: Colors.blue[1600], fontSize: 12},
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};
export default CheckList;
