import {createAction, createSlice} from '@reduxjs/toolkit';

export const fcDashboardInitialState = {
  requestFormnoSearch: '',

  // Dropdown for state
  stateLoading: false,
  stateData: [],
  stateError: false,
  stateMsg: '',

  // Dropdown for hq
  hqLoading: false,
  hqData: [],
  hqError: false,
  hqMsg: '',

  // Dropdown for mr
  mrLoading: false,
  mrData: [],
  mrError: false,
  mrMsg: '',

  // Main request fomm table
  requestFormLoading: false,
  requestformData: [],
  requestformError: false,
  requestformMsg: '',

  // requestForm Inner Table toggle
  requestInnerFormLoading: false,
  requestInnerFormData: [],
  requestInnerformError: false,
  requestInnerformMsg: '',

  // division Dropdown
  divisionLoading: false,
  divisionData: [],
  divisionError: false,
  divisionMsg: '',

  // Manage user role
  userApprovalLoading: false,
  userApprovalData: [],
  userApprovalError: false,
  userApprovalMsg: '',

  // Update user role
  updateuserApprovalloading: false,
  updateUserApprovalCompleted: false,
  updateUserApprovalError: false,
  updateUserApprovalMsg: '',

  // Submit Add product discount
  addProductTotalDiscountloading: false,
  addProductTotalDiscountCompleted: false,
  addProductTotalDiscountError: false,
  adddProductTotalDiscountMsg: '',

  // Add product dropdown
  productForDiscountLoading: false,
  productForDiscountData: [],
  productForDiscountError: false,
  productForDiscountMsg: '',

  // List product dropdown
  productListFromTotalDiscountFilterLoading: false,
  productListFromTotalDiscountFilterData: [],
  productListFromTotalDiscountFilterError: false,
  productListFromTotalDiscountFilterMsg: '',

  // List product data
  productListFromTotalDiscountLoading: false,
  productListFromTotalDiscountData: [],
  productListFromTotalDiscountError: false,
  productListFromTotalDiscountMsg: '',

  // Download product excel template
  generateProductExcelFileLoading: false,
  generateProductExcelFileData: [],
  generateProductExcelFileError: false,
  generateProductExcelFileMsg: '',

  // Upload product excel file
  uploadProductExcelFileloading: false,
  uploadProductExcelFileCompleted: false,
  uploadProductExcelFileData: [],
  uploadProductExcelFileError: false,
  uploadProductExcelFileMsg: '',

  // Update product total discount
  updateProductTotalDiscountloading: false,
  updateProductTotalDiscountCompleted: false,
  updateProductTotalDiscountData: [],
  updateProductTotalDiscountError: false,
  updateProductTotalDiscountMsg: '',

  // delete product total discount
  deleteProductTotalDiscountloading: false,
  deleteProductTotalDiscountCompleted: false,
  deleteProductTotalDiscountData: [],
  deleteProductTotalDiscountError: false,
  deleteProductTotalDiscountMsg: '',

  // reviewform popup approval status
  reviewFormApprovalLoading: false,
  reviewFormApprovalData: [],
  reviewFormApprovalError: false,
  reviewFormApprovalMsg: '',

  // reviewProduct form details
  reviewFormProductLoading: false,
  reviewFormProductData: [],
  reviewFormProductError: false,
  reviewFormProductMsg: '',

  // updating Approval Period
  reviewFormApprovalPeriodLoading: false,
  reviewFormApprovalPeriodError: false,
  reviewFormApprovalPeriodMsg: '',
  reviewFormApprovalPeriodCompleted: false,

  // updating the product detail additional quantity
  reviewFormAdditonalQtyLoading: false,
  reviewFormAdditonalQtyError: false,
  reviewFormAdditonalQtyMsg: '',
  reviewFormAdditonalQtyCompleted: false,

  // deleting the product detail page
  reviewFormDeleteProductLoading: false,
  reviewFormDeleteProductError: false,
  reviewFormDeleteProductMsg: '',

  // reverting the product detail page
  reviewFormRevertProductLoading: false,
  reviewFormRevertProductError: false,
  reviewFormRevertProductMsg: '',

  //Approval request
  formApprovalReviewLoading: false,
  formApprovalReivewError: false,
  formApprovalReviewMsg: '',
  formApprovalReviewCompleted: false,

  //reject Request
  formRejectReviewLoading: false,
  formRejectReivewError: false,
  formRejectReviewMsg: '',

  //rejected remarks dropdown
  reviewRejectRemarksLoading: false,
  reviewRejectRemarksError: false,
  reviewRejectRemarksData: [],
  reviewRejectRemarksMsg: '',

  //product by Division Id
  manageFilterProductLoading: false,
  manageFilterProductError: false,
  manageFilterProductData: [],
  manageFilterProductMsg: '',

  //candf by candF Id
  manageFilterCandFLoading: false,
  manageFilterCandFError: false,
  manageFilterCandFData: [],
  manageFilterCandFMsg: '',

  // hq by candf id
  manageFilterHqLoading: false,
  manageFilterHqError: false,
  manageFilterHqData: [],
  manageFilterHqMsg: '',

  // customer by candf id and division id
  manageFilterCustomerLoading: false,
  manageFilterCustomerError: false,
  manageFilterCustomerData: [],
  manageFilterCustomerMsg: '',

  // chemist by hq and division id
  manageFilterChemistLoading: false,
  manageFilterChemistError: false,
  manageFilterChemistData: [],
  manageFilterChemistMsg: '',

  // addproduct in managestatus
  manageProductAddLoading: false,
  manageProductAddError: false,
  manageProductAddResponse: [],
  manageProductAddMsg: '',

  //get data for manageproduct status

  manageProductTableLoading: false,
  manageProductTableError: false,
  manageProductTableData: [],
  manageProductTableMsg: '',
  mangeProductTableCount: 0,

  // get details for product for popup
  manageProductPopupLoading: false,
  manageProductPopupError: false,
  manageProductPopupData: [],
  manageProductPopupMsg: false,

  //deleted manage product
  manageProductDeleteLoading: false,
  manageProductDeleteError: false,
  manageProductDeleteMsg: false,
};

//  for state Drodopdown
export const fetchStateDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_STATE_DATA',
  currentStaffPositionId => ({payload: currentStaffPositionId}),
);

export const fetchStateDataTypeName = fetchStateDataCreator.type;

// for hq Dropdown
export const fetchHqDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_HQ_DATA',
  currentStateId => ({payload: currentStateId}),
);

export const fetchHqDataTypeName = fetchHqDataCreator.type;

// for mr Dropdown
export const fetchMrDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_Mr_DATA',
  selectedHqIds => ({payload: selectedHqIds}),
);

export const fetchMrDataTypeName = fetchMrDataCreator.type;

// for request form table fc
export const fetchRequestFormDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_FormTableData',
  (currentRequestStatus, statusToggleNo) => ({
    payload: {currentRequestStatus, statusToggleNo},
  }),
);

export const fetchRequestFormDataTypeName = fetchRequestFormDataCreator.type;

// for request form inner table fc
export const fetchRequestFormInnerDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_InnerFormTableData',
  (currentRequestID, isDraftValue) => ({
    payload: {currentRequestID, isDraftValue},
  }),
);

export const fetchRequestFormInnerDataTypeName =
  fetchRequestFormInnerDataCreator.type;

// for division dropdown
export const fetchDivisionDataCreator = createAction(
  'MANAGE_USER_ROLE/DIVISION_DATA',
  adminId => ({
    payload: {adminId},
  }),
);

export const fetchDivisionDataTypeName = fetchDivisionDataCreator.type;

// for User Approval table
export const fetchUserApprovalDataCreator = createAction(
  'MANAGE_USER_ROLE/USER_APPROVAL',
  divisionId => ({
    payload: {divisionId},
  }),
);

export const fetchUserApprovalDataTypeName = fetchUserApprovalDataCreator.type;

// for Updating User Approval table
export const fetchUpdateUserApprovalDataCreator = createAction(
  'MANAGE_USER_ROLE/UPDATE_APPROVAL',
  (tableType, tableData) => ({
    payload: {tableType, tableData},
  }),
);

export const fetchUpdateUserApprovalDataTypeName =
  fetchUpdateUserApprovalDataCreator.type;

// for adding Product Total discount
export const fetchAddProductTotalDiscountDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/ADD_PRODUCT_DISCOUNT',
  payload => ({
    payload: {payload},
  }),
);

export const fetchAddProductTotalDiscountDataTypeName =
  fetchAddProductTotalDiscountDataCreator.type;

// for Product list for discount
export const fetchProductForDiscountDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/PRODUCT_LIST',
  divisionId => ({
    payload: {divisionId},
  }),
);

export const fetchProductForDiscountDataTypeName =
  fetchProductForDiscountDataCreator.type;

// for Product list from total discount filter
export const fetchProductListFromTotalDiscountFilterDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/PRODUCT_LIST_FORM_TOTALDISCOUNT_FILTER',
  divisionId => ({
    payload: {divisionId},
  }),
);

export const fetchProductListFromTotalDiscountFilterDataTypeName =
  fetchProductListFromTotalDiscountFilterDataCreator.type;

// for Product list from total discount
export const fetchProductListFromTotalDiscountDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/PRODUCT_LIST_FORM_TOTALDISCOUNT',
  payload => ({
    payload: {payload},
  }),
);

export const fetchProductListFromTotalDiscountDataTypeName =
  fetchProductListFromTotalDiscountDataCreator.type;

// for getting excel template download
export const fetchGenerateProductExcelFileDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/GET_EXCEL_TEMPLATE',
  divisionId => ({
    payload: {divisionId},
  }),
);

export const fetchGenerateProductExcelFileDataTypeName =
  fetchGenerateProductExcelFileDataCreator.type;

// for uploading product excel file
export const fetchUploadProductExcelFileDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/UPLOAD_EXCEL',
  payload => ({
    payload: {payload},
  }),
);

export const fetchUploadProductExcelFileDataTypeName =
  fetchUploadProductExcelFileDataCreator.type;

// for updating product total discount
export const fetchUpdateProductTotalDiscountDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/UPLOAD_TOTAL_DISCOUNT',
  payload => ({
    payload: {payload},
  }),
);

export const fetchUpdateProductTotalDiscountDataTypeName =
  fetchUpdateProductTotalDiscountDataCreator.type;

// for deleting product total discount
export const fetchDeleteProductTotalDiscountDataCreator = createAction(
  'UPDATE_TOTAL_TRADE_DISCOUNT/DELETE_TOTAL_DISCOUNT',
  payload => ({
    payload: {payload},
  }),
);

export const fetchDeleteProductTotalDiscountDataTypeName =
  fetchDeleteProductTotalDiscountDataCreator.type;

// for review form approval data
export const fetchReviewFormApprovalDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_ReviewFormApprovalData',
  currentRequestID => ({
    payload: {currentRequestID},
  }),
);

export const fetchReviewFormApprovalDataTypeName =
  fetchReviewFormApprovalDataCreator.type;

// for review Form Product details Data
export const fetchReviewFormProductDataCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_ReviewFormProductData',
  currentRequestID => ({
    payload: {currentRequestID},
  }),
);

export const fetchReviewFormProductDataTypeName =
  fetchReviewFormProductDataCreator.type;

// for updating approval period
export const fetchReviewUpdateApprovalPeriodCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_ReviewUpdateApprovalPeriod',
  currentRequestID => ({
    payload: {currentRequestID},
  }),
);

export const fetchReviewUpdateApprovalPeriodTypeName =
  fetchReviewUpdateApprovalPeriodCreator.type;

// for upating additonal discount
export const fetchReviewFormUpdateAdditonalQtyCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_reviewFormUpdateAdditonalQty',
  (currentUserId, reqPayload) => ({
    payload: {currentUserId, reqPayload},
  }),
);

export const fetchReviewFormUpdateAdditonalQtyTypeName =
  fetchReviewFormUpdateAdditonalQtyCreator.type;

// for Deleting product listing
export const fetchReviewFormDeleteProductCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_reviewFormDeleteProduct',
  (currentId, userId) => ({
    payload: {currentId, userId},
  }),
);

export const fetchReviewFormDeleteProductTypeName =
  fetchReviewFormDeleteProductCreator.type;

// for Reverting product listing
export const fetchReviewFormRevertProductCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_reviewFormRevertProduct',
  currentId => ({
    payload: {currentId},
  }),
);

export const fetchReviewFormRevertProductTypeName =
  fetchReviewFormRevertProductCreator.type;

// for Approval request
export const fetchFormApprovalReviewCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_reviewFormApproveRequest',
  (currentStaffId, currentUserId, requestIds, typeApproval) => ({
    payload: {currentStaffId, currentUserId, requestIds, typeApproval},
  }),
);

export const fetchFormApprovalReviewTypeName =
  fetchFormApprovalReviewCreator.type;

// for rejecting product listing
export const fetchFormRejectReviewCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_reviewFormRejectRequest',
  (currentStaffId, rejectionRemarks, currentUserId, requestId) => ({
    payload: {currentStaffId, rejectionRemarks, currentUserId, requestId},
  }),
);

export const fetchReviewFormRejectProductTypeName =
  fetchFormRejectReviewCreator.type;

//rejecting remarks dropdown
export const fetchFormRejectReviewDropDownCreator = createAction(
  'REQUEST_FORM_HANDLER//FETCH_reviewFormRejectDropdown',
);

export const fetchFormRejectReviewDropDownTypeName =
  fetchFormRejectReviewDropDownCreator.type;

// to get product by Division Id

export const fetchManageFilterProductCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageFilterProductDropdown',
  divisionId => ({
    payload: {divisionId},
  }),
);

export const fetchManageFilterProductTypeName =
  fetchManageFilterProductCreator.type;

// to get candf by Division Id

export const fetchManageFilterCandFCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageFilterCandFDropdown',
  candFId => ({
    payload: {candFId},
  }),
);

export const fetchManageFilterCandFTypeName =
  fetchManageFilterCandFCreator.type;

/// to get hq by cand Id

export const fetchManageFilterHqCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageFilterHqDropdown',
  candFId => ({
    payload: {candFId},
  }),
);

export const fetchManageFilterHqTypeName = fetchManageFilterHqCreator.type;

// to get customer by candf id and division

export const fetchManageFilterCustomerCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageFilterCustomerDropdown',
  (divisionId, candFIds) => ({
    payload: {divisionId, candFIds},
  }),
);

export const fetchManageFilterCustomerTypeName =
  fetchManageFilterCustomerCreator.type;

// to get chemist by hqid and division

export const fetchManageFilterChemistCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageFilterChemistDropdown',
  (divisionId, hqIds) => ({
    payload: {divisionId, hqIds},
  }),
);

export const fetchManageFilterChemistTypeName =
  fetchManageFilterChemistCreator.type;

// to add product in manageProduct

export const fetchManageProductAddCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageProductAdd',
  (addPayload, payObj) => ({
    payload: {addPayload, payObj},
  }),
);

export const fetchManageProductAddTypeName = fetchManageProductAddCreator.type;

/// to get table data for manage product staatus

export const fetchManageProductTableCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageProductTable',
  payObj => ({
    payload: {payObj},
  }),
);

export const fetchManageProductTableTypeName =
  fetchManageProductTableCreator.type;

// to get details popup view for manage product status

export const fetchManageProductPopupDetailsCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageProductPopupDetails',
  userId => ({
    payload: {userId},
  }),
);

export const fetchManageProductPopupDetailsTypeName =
  fetchManageProductPopupDetailsCreator.type;

// to delete manage product items

export const fetchManageProductDeleteCreator = createAction(
  'MANAGE_PRODUCT_STATUS_HANDLER//FETCH_manageProductDelete',
  (pId, payObj) => ({
    payload: {pId, payObj},
  }),
);

export const fetchManageProductDeleteTypeName =
  fetchManageProductDeleteCreator.type;

// creating the slice
const fcDashboardStatehandler = createSlice({
  name: 'FC_DASHBOARD_HANDLER',
  initialState: fcDashboardInitialState,
  reducers: {
    // filter selected data
    setStateLoading: prevState => {
      return {
        ...prevState,
        stateLoading: true,
        stateData: [],
        stateError: false,
        stateMsg: '',
      };
    },

    setStateData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        stateLoading: false,
        stateData: payload,
        stateError: false,
      };
    },

    setStateError: prevState => {
      return {
        ...prevState,
        stateLoading: false,
        stateData: [],
        stateError: true,
        // stateMsg:'',
      };
    },

    // filter selected data
    setHqLoading: prevState => {
      return {
        ...prevState,
        hqLoading: true,
        hqData: [],
        hqError: false,
        hqMsg: '',
      };
    },

    setHqData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqLoading: false,
        hqData: payload,
        hqError: false,
      };
    },

    setHqError: prevState => {
      return {
        ...prevState,
        hqLoading: false,
        hqData: [],
        hqError: true,
        // stateMsg:'',
      };
    },

    // filter selected data
    setMrLoading: prevState => {
      return {
        ...prevState,
        mrLoading: true,
        mrData: [],
        mrError: false,
        mrMsg: '',
      };
    },

    setMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        mrLoading: false,
        mrData: payload,
        mrError: false,
      };
    },

    setMrError: prevState => {
      return {
        ...prevState,
        mrLoading: false,
        mrData: [],
        mrError: true,
      };
    },

    // filter selected data
    setRequestFormLoading: prevState => {
      return {
        ...prevState,
        requestFormLoading: true,
        requestformData: [],
        requestformError: false,
        requestformMsg: '',
      };
    },

    setRequestFormData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        requestFormLoading: false,
        requestformData: payload,
        requestformError: false,
      };
    },

    setRequestFormError: prevState => {
      return {
        ...prevState,
        requestFormLoading: false,
        requestformData: [],
        requestformError: true,
      };
    },

    //  request form Inner table data
    setRequestInnerFormLoading: prevState => {
      return {
        ...prevState,
        requestInnerFormLoading: true,
        requestInnerFormData: [],
        requestInnerformError: false,
        requestInnerformMsg: '',
      };
    },

    setRequestInnerFormData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        requestInnerFormLoading: false,
        requestInnerFormData: payload,
        requestInnerformError: false,
      };
    },

    setRequestInnerFormError: prevState => {
      return {
        ...prevState,
        requestInnerFormLoading: false,
        requestInnerFormData: [],
        requestInnerformError: true,
      };
    },

    // divison dropdown data
    setDivisionLoading: prevState => {
      return {
        ...prevState,
        divisionLoading: true,
        divisionData: [],
        divisionError: false,
        divisionMsg: '',
      };
    },

    setDivisionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionLoading: false,
        divisionData: payload,
        divisionError: false,
      };
    },

    setDivisionError: prevState => {
      return {
        ...prevState,
        divisionLoading: false,
        divisionData: [],
        divisionError: true,
      };
    },

    // User Approval data
    setUserApprovalLoading: prevState => {
      return {
        ...prevState,
        userApprovalLoading: true,
        userApprovalData: [],
        userApprovalError: false,
        userApprovalMsg: '',
      };
    },

    setUserApprovalData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userApprovalLoading: false,
        userApprovalData: payload,
        userApprovalError: false,
      };
    },

    setUserApprovalError: prevState => {
      return {
        ...prevState,
        userApprovalLoading: false,
        userApprovalData: [],
        userApprovalError: true,
      };
    },

    // Update User Approval data
    setUpdateUserApprovalLoading: prevState => {
      return {
        ...prevState,
        updateUserApprovalLoading: true,
        updateUserApprovalError: false,
        updateUserApprovalMsg: '',
      };
    },

    setUpdateUserApprovalCompleted: prevState => {
      return {
        ...prevState,
        updateUserApprovalCompleted: !prevState.updateUserApprovalCompleted,
      };
    },

    setUpdateUserApprovalError: prevState => {
      return {
        ...prevState,
        udateUserApprovalLoading: false,
        updateUserApprovalError: true,
      };
    },

    // Add Product Total Discount
    setAddProductTotalDiscountLoading: prevState => {
      return {
        ...prevState,
        addProductTotalDiscountLoading: true,
        addProductTotalDiscountError: false,
        addProductTotalDiscountMsg: '',
      };
    },

    setAddProductTotalDiscountCompleted: prevState => {
      return {
        ...prevState,
        addProductTotalDiscountCompleted:
          !prevState.addProductTotalDiscountCompleted,
      };
    },

    setAddProductTotalDiscountError: prevState => {
      return {
        ...prevState,
        addProductTotalDiscountLoading: false,
        addProductTotalDiscountError: true,
      };
    },

    //  Product for Discount Data
    setProductForDiscountLoading: prevState => {
      return {
        ...prevState,
        productForDiscountLoading: true,
        productForDiscountData: [],
        productForDiscountError: false,
        productForDiscountMsg: '',
      };
    },

    setProductForDiscountData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        productForDiscountLoading: false,
        productForDiscountData: payload,
        productForDiscountError: false,
      };
    },

    setProductForDiscountError: prevState => {
      return {
        ...prevState,
        productForDiscountLoading: false,
        productForDiscountData: [],
        productForDiscountError: true,
      };
    },

    //  Product List From total discount filter
    setProductListFromTotalDiscountFilterLoading: prevState => {
      return {
        ...prevState,
        productListFromTotalDiscountFilterLoading: true,
      };
    },

    setProductListFromTotalDiscountFilterData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        productListFromTotalDiscountFilterData: payload,
      };
    },

    setProductListFromTotalDiscountFilterError: prevState => {
      return {
        ...prevState,
        productListFromTotalDiscountFilterError: true,
      };
    },

    //  Product List From total discount data
    setProductListFromTotalDiscountLoading: prevState => {
      return {
        ...prevState,
        productListFromTotalDiscountLoading: true,
      };
    },

    setProductListFromTotalDiscountData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        productListFromTotalDiscountData: payload,
        productListFromTotalDiscountLoading: false,
      };
    },

    setProductListFromTotalDiscountError: prevState => {
      return {
        ...prevState,
        productListFromTotalDiscountError: true,
      };
    },

    //  Download product template excel
    setGenerateProductExcelFileLoading: prevState => {
      return {
        ...prevState,
        generateProductExcelFileLoading: true,
      };
    },

    setGenerateProductExcelFileData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateProductExcelFileData: payload,
      };
    },

    setGenerateProductExcelFileError: prevState => {
      return {
        ...prevState,
        generateProductExcelFileError: true,
      };
    },

    // UPload product excel file
    setUploadProductExcelFileLoading: prevState => {
      return {
        ...prevState,
        uploadProductExcelFileLoading: true,
        uploadProductExcelFileError: false,
        uploadProductExcelFileMsg: '',
      };
    },

    setUploadProductExcelFileData: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        uploadProductExcelFileData: payload,
        uploadProductExcelFileloading: false,
        uploadProductExcelFileError: false,
      };
    },

    setUploadProductExcelFileCompleted: prevState => {
      return {
        ...prevState,
        uploadProductExcelFileCompleted:
          !prevState.uploadProductExcelFileCompleted,
      };
    },

    setUploadProductExcelFileError: prevState => {
      return {
        ...prevState,
        uploadProductExcelFileLoading: false,
        uploadProductExcelFileData: [],
        uploadProductExcelFileError: true,
      };
    },

    // Update product total discount
    setUpdateProductTotalDiscountLoading: prevState => {
      return {
        ...prevState,
        updateProductTotalDiscountLoading: true,
        updateProductTotalDiscountError: false,
        updateProductTotalDiscountMsg: '',
      };
    },

    setUpdateProductTotalDiscountData: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        updateProductTotalDiscountData: payload,
        updateProductTotalDiscountloading: false,
        updateProductTotalDiscountError: false,
      };
    },

    setUpdateProductTotalDiscountCompleted: prevState => {
      return {
        ...prevState,
        updateProductTotalDiscountCompleted:
          !prevState.updateProductTotalDiscountCompleted,
        updateProductTotalDiscountLoading: false,
      };
    },

    setUpdateProductTotalDiscountError: prevState => {
      return {
        ...prevState,
        updateProductExcelFileLoading: false,
        updateProductExcelFileData: [],
        updateProductExcelFileError: true,
      };
    },

    // delete product total discount
    setDeleteProductTotalDiscountLoading: prevState => {
      return {
        ...prevState,
        deleteProductTotalDiscountLoading: true,
        deleteProductTotalDiscountError: false,
        deleteProductTotalDiscountMsg: '',
      };
    },

    setDeleteProductTotalDiscountData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        deleteProductTotalDiscountData: payload,
        deleteProductTotalDiscountloading: false,
        deleteProductTotalDiscountError: false,
      };
    },

    setDeleteProductTotalDiscountCompleted: prevState => {
      return {
        ...prevState,
        deleteProductTotalDiscountCompleted:
          !prevState.deleteProductTotalDiscountCompleted,
        deleteProductTotalDiscountLoading: false,
      };
    },

    setDeleteProductTotalDiscountError: prevState => {
      return {
        ...prevState,
        deleteProductExcelFileLoading: false,
        deleteProductExcelFileData: [],
        deleteProductExcelFileError: true,
      };
    },

    /// review from approval
    setReviewFormApprovalLoading: prevState => {
      return {
        ...prevState,
        reviewFormApprovalLoading: true,
        reviewFormApprovalData: [],
        reviewFormApprovalError: false,
        reviewFormApprovalMsg: '',
      };
    },

    setReviewFormApprovalData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reviewFormApprovalLoading: false,
        reviewFormApprovalData: payload,
        reviewFormApprovalError: false,
      };
    },

    setReviewFormApprovalError: prevState => {
      return {
        ...prevState,
        reviewFormApprovalLoading: false,
        reviewFormApprovalData: [],
        reviewFormApprovalError: true,
      };
    },

    /// review from Product Data

    setReviewFormProductLoading: prevState => {
      return {
        ...prevState,
        reviewFormProductLoading: true,
        reviewFormProductData: [],
        reviewFormProductError: false,
        reviewFormProductMsg: '',
      };
    },

    setReviewFormProductData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reviewFormProductLoading: false,
        reviewFormProductData: payload,
        reviewFormProductError: false,
      };
    },

    setReviewFormProductError: prevState => {
      return {
        ...prevState,
        reviewFormProductLoading: false,
        reviewFormProductData: [],
        reviewFormProductError: true,
      };
    },

    /// Update the Approval period
    setReviewFormApprovalPeriodLoading: prevState => {
      return {
        ...prevState,
        reviewFormApprovalPeriodLoading: true,
        reviewFormApprovalPeriodError: false,
        reviewFormApprovalPeriodMsg: '',
      };
    },

    setReviewFormApprovalPeriodError: prevState => {
      return {
        ...prevState,
        reviewFormApprovalPeriodLoading: false,
        reviewFormApprovalPeriodError: true,
      };
    },

    setReviewFormApprovalPeriodReCall: prevState => {
      return {
        ...prevState,
        reviewFormApprovalPeriodCompleted:
          !prevState.reviewFormApprovalPeriodCompleted,
      };
    },

    /// Update the additional discout period
    setReviewFormAdditonalQtyLoading: prevState => {
      return {
        ...prevState,
        reviewFormAdditonalQtyLoading: true,
        reviewFormAdditonalQtyError: false,
        reviewFormAdditonalQtyMsg: '',
      };
    },

    setReviewFormAdditonalQtyError: prevState => {
      return {
        ...prevState,
        reviewFormAdditonalQtyLoading: false,
        reviewFormAdditonalQtyError: true,
      };
    },

    setReviewFormAdditonalQtyReCall: prevState => {
      return {
        ...prevState,
        reviewFormAdditonalQtyCompleted:
          !prevState.reviewFormAdditonalQtyCompleted,
      };
    },

    /// Deleting the Product
    setReviewFormDeleteProductLoading: prevState => {
      return {
        ...prevState,
        reviewFormDeleteProductLoading: true,
        reviewFormDeleteProductError: false,
        reviewFormDeleteProductMsg: '',
      };
    },

    setReviewFormDeleteProductError: prevState => {
      return {
        ...prevState,
        reviewFormDeleteProductLoading: false,
        reviewFormDeleteProductError: true,
      };
    },

    /// Reverting the Product
    setReviewFormRevertProductLoading: prevState => {
      return {
        ...prevState,
        reviewFormRevertProductLoading: true,
        reviewFormRevertProductError: false,
        reviewFormRevertProductMsg: '',
      };
    },

    setReviewFormRevertProductError: prevState => {
      return {
        ...prevState,
        reviewFormRevertProductLoading: false,
        reviewFormRevertProductError: true,
      };
    },

    /// Review form approval
    setFormApprovalReviewLoading: prevState => {
      return {
        ...prevState,
        formApprovalReviewLoading: true,
        formApprovalReviewwError: false,
        formApprovalReviewMsg: '',
      };
    },

    setFormApprovalReviewError: prevState => {
      return {
        ...prevState,
        formApprovalReviewLoading: false,
        formApprovalReviewError: true,
      };
    },

    setFormApprovalReviewReCall: prevState => {
      return {
        ...prevState,
        formApprovalReviewCompleted: !prevState.formApprovalReviewCompleted,
      };
    },

    /// Reverting the Product
    setFormRejectProductLoading: prevState => {
      return {
        ...prevState,
        formRejectReviewLoading: true,
        formRejectReivewError: false,
        formRejectReviewMsg: '',
      };
    },

    setFormRejectProductError: prevState => {
      return {
        ...prevState,
        formRejectReviewLoading: false,
        formRejectReivewError: true,
      };
    },

    setFormRejectReviewReCall: prevState => {
      return {
        ...prevState,
        formApprovalReviewCompleted: !prevState.formApprovalReviewCompleted,
      };
    },

    // rejected remarks dropdown

    setReviewRejectRemarksLoading: prevState => {
      return {
        ...prevState,
        reviewRejectRemarksLoading: true,
        reviewRejectRemarksData: [],
        reviewRejectRemarksError: false,
        reviewRejectRemarksMsg: '',
      };
    },

    setReviewRejectRemarksData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reviewRejectRemarksLoading: false,
        reviewRejectRemarksData: payload,
        reviewRejectRemarksError: false,
      };
    },

    setReviewRejectRemarksError: prevState => {
      return {
        ...prevState,
        reviewRejectRemarksLoading: false,
        reviewRejectRemarksData: [],
        reviewRejectRemarksError: true,
        // stateMsg:'',
      };
    },

    //product by Division Id

    setManageFilterProductLoading: prevState => {
      return {
        ...prevState,
        manageFilterProductLoading: true,
        manageFilterProductData: [],
        manageFilterProductError: false,
        manageFilterProductMsg: '',
      };
    },

    setManageFilterProductData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageFilterProductLoading: false,
        manageFilterProductData: payload,
        manageFilterProductError: false,
      };
    },

    setManageFilterProductError: prevState => {
      return {
        ...prevState,
        manageFilterProductLoading: false,
        manageFilterProductData: [],
        manageFilterProductError: true,
        // stateMsg:'',
      };
    },

    /// candf by division id

    setManageFilterCandFLoading: prevState => {
      return {
        ...prevState,
        manageFilterCandFLoading: true,
        manageFilterCandFData: [],
        manageFilterCandFError: false,
        manageFilterCandFMsg: '',
      };
    },

    setManageFilterCandFData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageFilterCandFLoading: false,
        manageFilterCandFData: payload,
        manageFilterCandFError: false,
      };
    },

    setManageFilterCandFError: prevState => {
      return {
        ...prevState,
        manageFilterCandFLoading: false,
        manageFilterCandFData: [],
        manageFilterCandFError: true,
      };
    },

    // to get hq by candf id

    setManageFilterHqLoading: prevState => {
      return {
        ...prevState,
        manageFilterHqLoading: true,
        manageFilterHqData: [],
        manageFilterHqError: false,
        manageFilterHqMsg: '',
      };
    },

    setManageFilterHqData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageFilterHqLoading: false,
        manageFilterHqData: payload,
        manageFilterHqError: false,
      };
    },

    setManageFilterHqError: prevState => {
      return {
        ...prevState,
        manageFilterHqLoading: false,
        manageFilterHqData: [],
        manageFilterHqError: true,
      };
    },

    // to get customer by candf id and division id

    setManageFilterCustomerLoading: prevState => {
      return {
        ...prevState,
        manageFilterCustomerLoading: true,
        manageFilterCustomerData: [],
        manageFilterCustomerError: false,
        manageFilterCustomerMsg: '',
      };
    },

    setManageFilterCustomerData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageFilterCustomerLoading: false,
        manageFilterCustomerData: payload,
        manageFilterCustomerError: false,
      };
    },

    setManageFilterCustomerError: prevState => {
      return {
        ...prevState,
        manageFilterCustomerLoading: false,
        manageFilterCustomerData: [],
        manageFilterCustomerError: true,
      };
    },

    // to get chemist by hqid and division id

    setManageFilterChemistLoading: prevState => {
      return {
        ...prevState,
        manageFilterChemistLoading: true,
        manageFilterChemistData: [],
        manageFilterChemistError: false,
        manageFilterChemistMsg: '',
      };
    },

    setManageFilterChemistData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageFilterChemistLoading: false,
        manageFilterChemistData: payload,
        manageFilterChemistError: false,
      };
    },

    setManageFilterChemistError: prevState => {
      return {
        ...prevState,
        manageFilterChemistLoading: false,
        manageFilterChemistData: [],
        manageFilterChemistError: true,
      };
    },

    // to add product in manage product status
    setManageProductAddMsgLoading: prevState => {
      return {
        ...prevState,
        manageProductAddLoading: true,
        manageProductAddResponse: [],
        manageProductAddError: false,
        manageProductAddMsg: '',
      };
    },

    setManageProductAddMsgError: prevState => {
      return {
        ...prevState,
        manageProductAddResponse: [],
        manageProductAddLoading: false,
        manageProductAddError: true,
      };
    },

    setManageProductAddReponse: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageProductAddResponse: payload,
        manageProductAddLoading: false,
        manageProductAddError: true,
      };
    },

    // to get manage product table Data

    setManageProductTableLoading: prevState => {
      return {
        ...prevState,
        manageProductTableLoading: true,
        manageProductTableData: [],
        manageProductTableError: false,
        manageProductTableMsg: '',
      };
    },

    setManageProductTableData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageProductTableLoading: false,
        manageProductTableData: payload,
        manageProductTableError: false,
      };
    },

    setMangeProductTableCount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageProductTableLoading: false,
        mangeProductTableCount: payload,
        manageProductTableError: false,
      };
    },

    setManageProductTableError: prevState => {
      return {
        ...prevState,
        manageProductTableLoading: false,
        manageProductTableData: [],
        manageProductTableError: true,
      };
    },

    // to get manage product popup details

    setManageProductPopupLoading: prevState => {
      return {
        ...prevState,
        manageProductPopupLoading: true,
        manageProductPopupData: [],
        manageProductPopupError: false,
      };
    },

    setManageProductPopupData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        manageProductPopupLoading: false,
        manageProductPopupData: payload,
        manageProductPopupError: false,
      };
    },

    setManageProductPopupError: prevState => {
      return {
        ...prevState,
        manageProductPopupLoading: false,
        manageProductPopupData: [],
        manageProductPopupError: true,
      };
    },

    // to delete the product

    setManageProductDeleteLoading: prevState => {
      return {
        ...prevState,
        manageProductDeleteLoading: true,
        manageProductDeleteError: false,
      };
    },

    setmanageProductDeleteError: prevState => {
      return {
        ...prevState,
        manageProductDeleteLoading: false,
        manageProductDeleteError: true,
      };
    },
  },
});

export const fcDashboardActions = fcDashboardStatehandler.actions;
export const fcDashboardReducer = fcDashboardStatehandler.reducer;
