import {useEffect, useState, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';

import {listOfEmployeesStateSelector} from '@app/screens/employee-specific-utilities/pages/list-of-employees/redux/selectors';
import {
  fetchListOfEmployeesCreator,
  listOfEmployeesStateActions,
  fetchListOfEmployeesSuggestionsCreator,
} from '@app/screens/employee-specific-utilities/pages/list-of-employees/redux/slice';
import {debounce} from 'lodash';
import {SearchBar} from '../search-bar';
export const UtilList: any = {
  textBoxOnChangeHandler: null,
  handleTypeChange: null,
  handleSearch: null,
  autocompleteOnChangeHandler: null,
};

type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarListOfEmployees = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [type, setType] = useState<EmployeeType>('ALL');

  const handleTypeChange = useCallback(function (value: EmployeeType) {
    setType(value);
    dispatch(listOfEmployeesStateActions.setSearchText(''));
    dispatch(
      listOfEmployeesStateActions.setSelectedAutoSuggest({
        employeeId: '',
        employeeName: '',
        name: '',
        staffPositionId: 0,
      }),
    );
  }, []);

  const {
    listOfEmployeesSnapshot,
    searchText,
    suggestionsLoading,
    selectedAutoSuggestValue,
    autoSuggestData,
  } = useSelector(
    listOfEmployeesStateSelector.getListOfEmployeesState(),
    shallowEqual,
  );

  const handleSearch = useCallback(
    debounce(
      (searchText: string) =>
        dispatch(fetchListOfEmployeesSuggestionsCreator(searchText)),
      600,
    ),
    [],
  );

  const autocompleteOnChangeHandler = useCallback(function (value: any) {
    if (!value) {
      dispatch(fetchListOfEmployeesCreator());
    }

    dispatch(listOfEmployeesStateActions.setSelectedAutoSuggest(value));
  }, []);

  const textBoxOnChangeHandler = useCallback(function (event: any) {
    dispatch(listOfEmployeesStateActions.setSearchText(event.target.value));
  }, []);

  useEffect(() => {
    dispatch(listOfEmployeesStateActions.setSearchText(''));
    dispatch(
      listOfEmployeesStateActions.setSelectedAutoSuggest({
        employeeId: '',
        employeeName: '',
        name: '',
        staffPositionId: 0,
      }),
    );
  }, []);

  useEffect(() => {
    searchText.length > 0 && handleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    selectedAutoSuggestValue &&
      Object.keys(selectedAutoSuggestValue).length > 0 &&
      dispatch(
        listOfEmployeesStateActions.setListOfEmployees(
          listOfEmployeesSnapshot?.length > 0 &&
            listOfEmployeesSnapshot.filter(
              (emp: any) => emp?.name === selectedAutoSuggestValue?.name,
            ),
        ),
      );
  }, [selectedAutoSuggestValue]);

  useEffect(() => {
    if (type === 'ALL') {
      dispatch(
        listOfEmployeesStateActions.setListOfEmployees(listOfEmployeesSnapshot),
      );
    } else {
      const newSnap =
        listOfEmployeesSnapshot?.length > 0 &&
        listOfEmployeesSnapshot.filter((emp: any) => emp?.role === type);
      dispatch(listOfEmployeesStateActions.setListOfEmployees(newSnap));
    }
  }, [type]);

  UtilList.textBoxOnChangeHandler = textBoxOnChangeHandler;
  UtilList.handleTypeChange = handleTypeChange;
  UtilList.handleSearch = handleSearch;
  UtilList.autocompleteOnChangeHandler = autocompleteOnChangeHandler;
  return (
    <SearchBar
      open={open}
      setOpen={setOpen}
      autocompleteOnChangeHandler={autocompleteOnChangeHandler}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={autoSuggestData}
      selectedAutoSuggestValue={selectedAutoSuggestValue}
      suggestionsLoading={suggestionsLoading}
      handleTypeChange={handleTypeChange}
    />
  );
};
