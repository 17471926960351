import {maxWidth} from '@mui/system';
import jsPDF from 'jspdf';
import autoTable, {RowInput} from 'jspdf-autotable';
// Somewhere in your type declarations file or at the top of your file
declare module 'jspdf-autotable' {
  interface AutoTableOptions {
    theme?: 'striped' | 'grid' | 'plain';
  }
}

function wrappedText({
  text,
  textWidth,
  doc,
  fontSize = 10,
  fontType = 'normal',
  lineSpacing = 7,
  xPosition = 10,
  initialYPosition = 10,
  pageWrapInitialYPosition = 10,
}: any) {
  var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
  var pageHeight = doc.internal.pageSize.height; // Get page height, well use this for auto-paging
  var pageWidth = doc.internal.pageSize.width;
  doc.setFontSize(fontSize);

  var cursorY = initialYPosition;

  textLines.forEach((lineText: any): any => {
    if (cursorY > pageHeight) {
      // Auto-paging
      doc.addPage();
      cursorY = pageWrapInitialYPosition;
    }
    doc.text(xPosition, cursorY, lineText);
    cursorY += lineSpacing;
  });
}

const exportDataToPDF = async (
  pdfColumnName: any,
  data: any,
  fileName: any,
  callback: any,
  search: any,
) => {
  const text = `Sample/Item name(s):  ${search}`;
  const doc = new jsPDF('l', 'mm', 'a4', true);
  doc.setFontSize(60);
  doc.text(`Ledger Report`, 45, 85);
  doc.setFontSize(8);
  var textLines = doc.splitTextToSize(text, 290); // Split the text into lines
  console.log(textLines, textLines.length, 'textLines');

  const pdfData: RowInput[] = data.map((rowData: any) => {
    return pdfColumnName.map((item: any) => {
      if (item.name === 'transactionDate') {
        return rowData[item.name].split('T')[0];
      } else {
        return rowData[item.name];
      }
    });
  });
  const addHeader = () => {
    // Header Title with staff details
    doc.setFontSize(8);
    doc.text(`Staff Name: ${data[0].staffname}`, 12, 15);
    doc.text(`SAP Code: ${data[0].sapcode}`, 12, 22);
    doc.text(`Division: ${data[0].divisionName}`, 12, 29);
    doc.text(`Designation: ${data[0].designationName}`, 12, 36);
    doc.text(`HQ Name: ${data[0].hqName}`, 12, 43);
    doc.setFontSize(10);
    wrappedText({
      text,
      textWidth: 290,
      doc,
      // Optional
      fontSize: '6',
      fontType: 'normal',
      lineSpacing: 5, // Space between lines
      xPosition: 12, // Text offset from left of document
      initialYPosition: 50, // Initial offset from top of document; set based on prior objects in document
      pageWrapInitialYPosition: 10, // Initial offset from top of document when page-wrapping
    });
  };
  autoTable(doc, {
    html: '#table1',
    styles: {lineWidth: 60},
    didDrawPage: addHeader,
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    startY: 50,
    pageBreak: 'always',
  });

  autoTable(doc, {
    startY: textLines?.length * 55,
    head: [pdfColumnName],
    body: pdfData,
    theme: 'grid' as any, // Type assertion to bypass TypeScript checks
    styles: {
      cellPadding: 1,
      valign: 'middle',
      fontSize: 7,
      halign: 'center',
      textColor: 'black',
      lineColor: 'black',
    },
    headStyles: {
      lineWidth: 0.2,
      lineColor: 'black',
      textColor: 'black',
      fillColor: '#ffe699',
      fontSize: 7.5,
      fontStyle: 'normal',
      halign: 'center',
    },
  });

  const pageCount = doc.getCurrentPageInfo();

  const date = new Date();
  const minutes = date.getMinutes();
  const dateStr = `${date.toLocaleDateString()} ${date.getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  doc.deletePage(1);
  for (let i = 1; i <= pageCount.pageNumber-1; i++) {
    doc.setPage(i);
    doc.setFontSize(8);
    doc.text(dateStr, 14, doc.internal.pageSize.height - 10);
    doc.text(
      `Page ${i} of ${pageCount.pageNumber}`,
      264,
      doc.internal.pageSize.height - 10,
    );
  }

  doc.save(`${fileName}.pdf`);
  callback();
};

export default exportDataToPDF;
