import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useStyles} from '../style';
import {CircularProgress, Grid, SvgIcon} from '@mui/material';
import {EditIcon} from '@app/screens/Styles/style';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {useState, useEffect} from 'react';
import {Stack} from '@mui/system';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {strings} from '@app/common/strings';
import dateUtils from '@app/screens/zho/utils/dateFormat';
const OrderDetails = ({userLoginInfo}: any) => {
  const classes = useStyles();
  const {DateFormat} = dateUtils;
  const productData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductData,
  );

  const productLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductLoading,
  );

  return (
    <Box
      sx={{margin: '20px 0px 15px 0px'}}
      className={`${classes.headingMarginBottom}  `}>
      <Typography
        className={`${classes.headingMarginBottom} ${classes.RfHeading} `}
        fontSize="15.7px"
        variant="h6">
        {strings.details}
      </Typography>

      <Box className={`${classes.detailsBox}`}>
        {!productLoader ? (
          <Grid className={classes.headingMarginBottom} container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.createdBy}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.createdBy}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box>
                <Typography
                  sx={{textTransform: 'uppercase'}}
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.hoAdmin.dashboard.modalPopup.orderFormNo}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.orderFormNo}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box paddingLeft={1.5}>
                <Typography
                  sx={{textTransform: 'uppercase'}}
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.fcAdmin.dashboard.tableHeading.requestFormNo}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.requestFormNo}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.date}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.date !== null
                    ? DateFormat(productData?.date)
                    : '---'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.candf}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.candF}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box paddingLeft={1.5}>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.customer}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.stockistName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.chemistName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  textTransform="uppercase"
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.zho.mrName}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.mrName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box paddingLeft={1.5}>
                <Typography
                  className={classes.defaultLightText}
                  variant="subtitle1">
                  {strings.hq}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="subtitle1">
                  {productData?.hq}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box
            className={classes.loader}
            sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderDetails;
