import {makeStyles, DefaultTheme} from '@mui/styles';

interface CheckBoxObj {
  months: string[];
  checked: boolean;
}

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  downloadExcelLogo: {
    width: 25,
    marginRight: 5,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'end',
  },
  dropdown: {
    backgroundColor: '#f2f2f2',
    height: 55,
    width: 250,
  },
  buttonGroup: {
    alignSelf: 'end',
    height: 40,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#332b77',
  },
  btn: {
    backgroundColor: '#332b77',
    // width: 60,
    borderRadius: 20,
  },
  root: {
    width: '120px',
    height: '40px',
    padding: '2px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#fff',
      },
    },
  },
});
