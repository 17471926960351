import { useEffect, useState, useCallback } from 'react';
import {
  debounce,
} from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { recentUpdatesStateSelector } from '@app/screens/employee-specific-utilities/pages/recent-updates/redux/selector';
import {
  fetchRecentUpdatesCreator,
  fetchRecentUpdatesSuggestionsCreator,
  recentUpdatesStateActions,
} from '@app/screens/employee-specific-utilities/pages/recent-updates/redux/slice';
import { SearchBar } from '../search-bar';

type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarRecentUpdates = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [type, setType] = useState<EmployeeType>('ALL');

  const handleTypeChange = useCallback(function(value: EmployeeType){
    setType(value);
    dispatch(recentUpdatesStateActions.setSearchText(""));
    dispatch(recentUpdatesStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}))
  },[])

  const {
    snapShot, searchText, suggestionsLoading,
    selectedAutoSuggestValue, autoSuggestData,
  } = useSelector(
    recentUpdatesStateSelector.getRecentUpdatesState(),
    shallowEqual,
  );


  const handleSearch = useCallback(
    debounce((searchText: string) => dispatch(fetchRecentUpdatesSuggestionsCreator(searchText)), 600),
    []
  )

  const autocompleteOnChangeHandler = useCallback((value:any)=>{
    if (!value) {
      dispatch(fetchRecentUpdatesCreator());
    }

    dispatch(
      recentUpdatesStateActions.setSelectedAutoSuggest(
        value,
      ),
    );
  },[])

  const textBoxOnChangeHandler = useCallback((event:any)=>{
    dispatch(
      recentUpdatesStateActions.setSearchText(
        event.target.value,
      ),
    );
  },[])

  useEffect(()=>{
    dispatch(recentUpdatesStateActions.setSearchText(""));
    dispatch(recentUpdatesStateActions.setSelectedAutoSuggest({employeeId:"",employeeName:"",name:"",staffPositionId:0}))
  },[])

  useEffect(() => {
    searchText.length > 0 && handleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    selectedAutoSuggestValue &&
      Object.keys(selectedAutoSuggestValue).length > 0 &&
      dispatch(
        recentUpdatesStateActions.setRecentUpdates(
          snapShot?.length>0 && snapShot.filter(
            (emp: any) => emp?.employeeName === selectedAutoSuggestValue?.name
          )
        )
      )
  }, [selectedAutoSuggestValue]);

  useEffect(() => {
    if (type !== "ALL") {
      const newSnap = snapShot?.length>0 && snapShot.filter(
        (emp: any) => emp?.role === type
      )
      dispatch(
        recentUpdatesStateActions.setRecentUpdates(
          newSnap
        )
      )
    } else {
      dispatch(recentUpdatesStateActions.setRecentUpdates(snapShot));
    }
  }, [type])

  return (
    <SearchBar 
      open={open} 
      setOpen={setOpen} 
      autocompleteOnChangeHandler={autocompleteOnChangeHandler}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={autoSuggestData}
      selectedAutoSuggestValue={selectedAutoSuggestValue}
      suggestionsLoading={suggestionsLoading}
      handleTypeChange={handleTypeChange}     
      />
  );
};
