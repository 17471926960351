import Typography from '@mui/material/Typography';
import {Box, Button, FormControl, MenuItem, Select, Stack} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {
  fetchCnfAuditDivisionCreator,
  fetchCNFHeadquartersCreator,
  fetchCnfReturnItemsFilterCreator,
  fetchCNFStatesAndDivisionsCreator,
  inventoryModuleCnfActions,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/slice';
import {adminCnfSelector} from '@app/screens/inventory-management/pages/cnf-return-items/redux/selectors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {appSelector} from '@app/store/selectors';
import GenerateReportModal from '../../../screens/inventory-management/pages/cnf-return-items/reportGenerateModal';
const styles = {
  containerCNF: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filterContainerCNF: {
    width: '99%',
    height: 'auto',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControlCNF: {
    margin: '8px',
  },
  iconCNF: {
    marginTop: '4px',
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CNFApprovalRequestNavbar = () => {
  const cnfStates = useSelector(adminCnfSelector.getCNFStates());
  const cnfHeadquarters = useSelector(adminCnfSelector.getCNFHeadquarters());
  const [cnfState, setCnfState] = useState([]);
  const [cnfHq, setCnfHq] = useState('');
  const [cnfDivision, setCnfDivision] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const selectedCnfState = useSelector(adminCnfSelector.getSelectedCnfState());
  const writeAccess = useSelector(appSelector.getUserInfo());
  const selectedCnfDivision = useSelector(
    adminCnfSelector.getSelectedCnfDivision(),
  );
  const LoadingCnfheadQuater = useSelector(
    adminCnfSelector.getCnfHeadQuarterLoading(),
  );

  const [cnfStateData, setCnfStateData] = useState([]);
  const [cnfDivisionData, setCnfDivisionData] = useState([]);
  const [cnfHqData, setCnfHqData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCNFStatesAndDivisionsCreator());
  }, []);
  useEffect(() => {
    if (cnfStates?.stateData) {
      const States: any = [{id: 0, stateName: 'ALL'}, ...cnfStates.stateData];
      setCnfStateData(States);
    }
  }, [cnfStates]);
  const handleStateChange = (e: any) => {
    const CNF: any = cnfStates?.divisionData
      ? [{id: 0, name: 'ALL', shortName: 'ALL'}, ...cnfStates.divisionData]
      : [{id: 0, name: 'ALL', shortName: 'ALL'}];
    const StateID: any =
      e.target.value === 0
        ? cnfStateData?.map((item: any) => item.id)
        : [e.target.value];
    setCnfDivisionData(CNF);
    setCnfDivision('');
    setCnfHq('');
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(StateID));
    setCnfState(StateID);
    dispatch(inventoryModuleCnfActions.setCnfRequestApiCalled(false));
  };
  const handleHeadquarters = (e: any) => {
    const hqID: any =
      e.target.value === 0
        ? cnfHqData?.map((item: any) => item.hqId)
        : [e.target.value];
    dispatch(inventoryModuleCnfActions.setSelectedCnfHeadquarter(hqID));

    setCnfHq(hqID);
  };

  const handleGoClick = () => {
    const req = {
      stateId: selectedCnfState,
      divisionIds: selectedCnfDivision,
      hQsId: cnfHq,
      pageNumber: 1,
    };
    dispatch(fetchCnfReturnItemsFilterCreator(req));
    dispatch(inventoryModuleCnfActions.setPageNumber(1));
  };
  const handleDivisionChange = (e: any) => {
    const divID: any =
      e.target.value === 0
        ? cnfDivisionData?.map((item: any) => item.id)
        : [e.target.value];
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(divID));

    setCnfDivision(divID);
    const reqPayload = {
      stateId: [...cnfState],
      divisionId: [...divID],
    };
    dispatch(fetchCNFHeadquartersCreator(reqPayload));
    setCnfHq('');
    dispatch(inventoryModuleCnfActions.setCnfRequestApiCalled(false));
  };

  const DivisionValue = () => {
    if (cnfDivision !== '') {
      return selectedCnfDivision && selectedCnfDivision.length > 1
        ? 0
        : selectedCnfDivision[0];
    }
    return '';
  };
  const headQuaterValue = () => {
    if (cnfHq !== '') {
      return cnfHq && cnfHq.length > 1 ? 0 : cnfHq[0];
    }
    return '';
  };

  useEffect(() => {
    const CNFHq: any = [{hqId: 0, hqNames: 'ALL'}, ...cnfHeadquarters];
    setCnfHqData(CNFHq);
  }, [cnfHeadquarters]);
  const enableButton =
    selectedCnfState === '' || selectedCnfDivision === '' || cnfHq === '';

  const handleOpen = () => {
    dispatch(fetchCnfAuditDivisionCreator());
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={styles.containerCNF}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <Typography
          data-testid={'cnf return request approval'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
          marginLeft={'15px'}>
          CNF Return Request Approval
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignContent="center"
          marginRight={1}>
          <Button
            variant="contained"
            size="small"
            onClick={handleOpen}
            sx={{textTransform: 'none', height: 30, marginRight: 1.7}}>
            Audit Report
          </Button>
          <GenerateReportModal
            open={modalOpen}
            close={handleClose}
            buttonText={'Download Report'}
          />
        </Stack>
      </Box>
      <Box sx={styles.filterContainerCNF}>
        <FormControl sx={styles.formControlCNF} fullWidth>
          <InputLabel id="select-state-label">Select State</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              id: 'selectState-dropdown',
              'data-testid': 'selectState-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-state-label"
            onChange={handleStateChange}
            disabled={!writeAccess?.isWriteAccess}
            value={
              cnfHq && selectedCnfState && selectedCnfState.length > 1
                ? 0
                : selectedCnfState[0]
            }
            MenuProps={MenuProps}
            label="Select State">
            {cnfStateData &&
              cnfStateData != undefined &&
              cnfStateData.length > 0 &&
              cnfStateData.map((stateData: any) => {
                return (
                  <MenuItem value={stateData.id} key={stateData.value}>
                    {stateData.stateName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.formControlCNF} fullWidth>
          <InputLabel id="select-division-label">Select Division</InputLabel>
          <Select
            inputProps={{
              'data-testid': 'selectDivision-dropdown',
            }}
            sx={{backgroundColor: '#ffffff'}}
            variant="outlined"
            labelId="select-division-label"
            label="Select Division"
            disabled={!writeAccess?.isWriteAccess}
            onChange={(e: any) => handleDivisionChange(e)}
            MenuProps={MenuProps}
            value={DivisionValue()}>
            {cnfStates &&
              cnfDivisionData != undefined &&
              cnfDivisionData.length > 0 &&
              cnfDivisionData?.map((divisionData: any) => {
                return (
                  <MenuItem value={divisionData.id} key={divisionData.value}>
                    {divisionData.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.formControlCNF} fullWidth>
          <InputLabel id="select-hq-label">Select HeadQuarters</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              'data-testid': 'selectHeadQuarters-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-hq-label"
            label="Select HeadQuarters"
            MenuProps={MenuProps}
            disabled={!writeAccess?.isWriteAccess}
            onChange={e => handleHeadquarters(e)}
            value={headQuaterValue()}>
            {!LoadingCnfheadQuater &&
              cnfHqData &&
              cnfHqData.length > 0 &&
              cnfHqData.map((hqData: any) => {
                return (
                  <MenuItem value={hqData.hqId} key={hqData.hqId}>
                    {hqData.hqNames}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Button
          data-testid={'go-button'}
          sx={styles.formControlCNF}
          variant="contained"
          size="small"
          onClick={handleGoClick}
          disabled={enableButton}>
          Go
        </Button>
      </Box>
    </Box>
  );
};
