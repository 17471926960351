import {createAction, createSlice} from '@reduxjs/toolkit';

export const listOfEmployeesInitialState = {
  listOfEmployees: [],
  totalCount:0,
  listOfEmployeesSnapshot: [],
  listOfEmployeesCount: 0,
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
  divisionDropDown: [],
  stateDropDown: [],
  staffHoldDropDown: [],
  companyAssetDropDown: [],
  promotionDropDown: [],
  designationDropDown: [],
  pageNo: 0,
  rowsPerPage: 10,
};

export const fetchDivisionDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_DIVISION_DROPDOWN',
);

export const DivisionDropDownCreatorTypeName =
  fetchDivisionDropDownCreator().type;

export const fetchStateDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STATE_DROPDOWN',
);

export const StateDropDownCreatorTypeName = fetchStateDropDownCreator().type;

export const fetchStaffHoldDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFFHOLD_DROPDOWN',
);

export const StaffHoldDropDownCreatorTypeName =
  fetchStaffHoldDropDownCreator().type;

export const fetchCompanyAssetDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_COMPANY_ASSET_DROPDOWN',
);

export const CompanyAssetDropDownCreatorTypeName =
  fetchCompanyAssetDropDownCreator().type;

export const fetchPromotionDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_PROMOTION_DROPDOWN',
);

export const PromotionDropDownCreatorTypeName =
  fetchPromotionDropDownCreator().type;

export const fetchDesignationDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_DESIGNATION_DROPDOWN',
);

export const DesignationDropDownCreatorTypeName =
  fetchDesignationDropDownCreator().type;

export const fetchWelcomeKitDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_WELCOME_KIT_DROPDOWN',
);

export const fetchWelcomeKitDropDownCreatorTypeName =
  fetchWelcomeKitDropDownCreator().type;

export const fetchManagersRatingDropDownCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_MANAGERS_RATING_DROPDOWN',
);

export const fetchManagersRatingDropDownCreatorTypeName =
  fetchManagersRatingDropDownCreator().type;

export const fetchListOfEmployeesCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_LIST_OF_EMPLOYEES',
);

export const fetchListOfEmployeesCreatorTypeName =
  fetchListOfEmployeesCreator().type;

export const fetchListOfEmployeesSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_LIST_OF_EMPLOYEES_SUGGESTIONS',
);

export const fetchListOfEmployeesSuggestionsCreatorTypeName =
  fetchListOfEmployeesSuggestionsCreator(null).type;

const listOfEmployeesStateHandler = createSlice({
  name: 'LIST_OF_EMPLOYEES',
  initialState: listOfEmployeesInitialState,
  reducers: {
    setListOfEmployeesLoading: (prevState, action) => {
      return {
        ...prevState,
        listOfEmployeesLoading: action?.payload,
      };
    },
    setListOfEmployeesSuccess: (prevState, action) => {
      return {
        ...prevState,
        listOfEmployeeSuccess: action?.payload,
      };
    },
    setListOfEmployees: (prevState, action) => {
      return {
        ...prevState,
        listOfEmployees: action?.payload,
      };
    },
    setListOfEmployeesLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      }
    },
   
    setListOfEmployeesSnapshot: (prevState, action) => {
      return {
        ...prevState,
        listOfEmployeesSnapshot: action?.payload,
      };
    },
    setListOfEmployeesError: (prevState, action) => {
      return {
        ...prevState,
        listOfEmployeesError: true,
        listOfEmployeesErrorMessage: action?.payload,
      };
    },
    clearListOfEmployeesError: prevState => {
      return {
        ...prevState,
        listOfEmployeesError: false,
        listOfEmployeesMessage: '',
      };
    },
    setDivisionDropdown: (prevState, action) => {
      return {
        ...prevState,
        divisionDropDown: action?.payload,
      };
    },
    setStateDropdown: (prevState, action) => {
      return {
        ...prevState,
        stateDropDown: action?.payload,
      };
    },
    setStaffHoldDropdown: (prevState, action) => {
      return {
        ...prevState,
        staffHoldDropDown: action?.payload,
      };
    },
    setCompanyAssetDropdown: (prevState, action) => {
      return {
        ...prevState,
        companyAssetDropDown: action?.payload,
      };
    },
    setPromotionDropdown: (prevState, action) => {
      return {
        ...prevState,
        promotionDropDown: action?.payload,
      };
    },
    setDesignationDropdown: (prevState, action) => {
      return {
        ...prevState,
        designationDropDown: action?.payload,
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
  },
});

export const listOfEmployeesStateActions = listOfEmployeesStateHandler.actions;
export const listOfEmployeesStateReducer = listOfEmployeesStateHandler.reducer;
