/* eslint-disable no-unneeded-ternary */
import Button from '@mui/material/Button';
import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useCallback} from 'react';

export const CustomModal = ({
  header,
  footer,
  children,
  open,
  onClose,
  maxWidth,
  fullWidth,
  footerClass,
  search,
  searchClass,
  backdropClick = false,
  isCloseIconVisible = true,
  style,
}: {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  isCloseIconVisible?: boolean;
  maxWidth?: false | Breakpoint;
  fullWidth?: boolean;
  footerClass?: any;
  search?: any;
  searchClass?: any;
  backdropClick?: boolean;
  style?: React.CSSProperties; // <-- Define the style prop
}) => {
  const theme = useTheme();
  const TriggerEvent = useCallback(() => {
    return (
      <button
        data-testid="close-component-new"
        className={'hiddenButton'}
        onClick={() => {
          handleClose();
        }}
      />
    );
  }, []);
  const handleClose = useCallback(() => {
    if (backdropClick) return;
  }, []);
  return (
    <div>
      <TriggerEvent />
      <Dialog
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        maxWidth={maxWidth ? maxWidth : 'lg'}
        open={open}
        data-testid="Modal-Close"
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '100vh', // Set maximum height to viewport height
            overflowY: 'hidden', // Hide vertical overflow
            ...style, // Apply additional styles
          },
        }}
        style={style}>
        <DialogTitle>
          <Box display={'flex'} justifyContent="space-between">
            <div>{header}</div>
            <Box sx={searchClass || ''}>
              <Box>{search}</Box>
              {isCloseIconVisible && (
                <Button data-testid="Close-Button" onClick={onClose}>
                  <CloseIcon sx={{color: '#1c1939'}} />
                </Button>
              )}
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Grid container>
              <Box
                sx={[
                  {
                    '& .text-align-center': {
                      textAlign: 'center',
                    },
                    '& .MuiDataGrid-renderingZone': {
                      maxHeight: 'none !important',
                    },
                    '& .MuiDataGrid-cell': {
                      lineHeight: 'unset !important',
                      maxHeight: 'none !important',
                      whiteSpace: 'normal',
                    },
                    '& .MuiDataGrid-row': {
                      maxHeight: 'none !important',
                    },
                  },
                  {width: '100%'},
                ]}>
                {children}
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={footerClass || ''}>
          {footer ? (
            footer
          ) : (
            <Box display={'flex'} justifyContent="center" width={'100%'}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '5px',
                  width: '150px',
                  boxShadow: 'none',
                  marginLeft: '10px',
                }}
                onClick={onClose}>
                Close
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
