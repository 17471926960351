import { NetworkService, ToasterService } from '@app/services'
import { ToastType } from '@app/services/toaster';
import { HTTP_OK } from '@app/common/constants';

export const getFileTypeWeb = (fileName: any) => {
    if (fileName.includes('csv')) {
      return 'csv';
    } else {
      return '';
    }
  };
  
  export const base64Downloader = (base64String: any, file: any) => {
    const linkSource = `data:${getFileTypeWeb(file)};base64,${base64String}`;
    const downloadLink = document.createElement('a');
    const fileName = file;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export const jsonDownloader = (json: any) => {
    const downloadLink = document.createElement('a');
    const file = new Blob([json], {type: 'application/json'});
    const fileName = `${new Date().getTime()}.csv`;
    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = fileName;
    downloadLink.click();
    ToasterService.showToaster('File Downloaded.', ToastType.SUCCESS);
  };

  export const convertJsonToCSV = (arr: any) => {
    const array = [Object.keys(arr[0])].concat(arr)
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }
  
  export const handleDownloadWeb = (url: string, payload: any, file?: string) => {
    console.log(payload, 'payload');
    try {
      const response: any = NetworkService.post(url, payload);
      if (response?.data?.error) {
        throw new Error(response.error);
      }
      if (response?.data) {
        const data = convertJsonToCSV(response?.data)
        jsonDownloader(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const base64DownloaderWeb = (base64: any, file: string) => {
    const linkSource = `data:${getFileTypeWeb(file)};base64,${base64}`;
    const downloadLink = document.createElement('a');
    const fileName = file;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export const downloadFile = async (url: string, fileName: string, blobFolderName: string) => {
    try {
      const payload = {
        blobName: fileName,
        blobFolderName,
      };
      const response: any = await NetworkService.post(url, payload);
      if (HTTP_OK !== response?.status) { 
        ToasterService.showToaster('Failed to Download File.', ToastType.ERROR);
        return;
      } 
      if (response?.data) {
        base64DownloaderWeb(response?.data, fileName);
      }
    } catch (err) {
      console.log(err);
    }
  };
