import {PLAN_AND_MEET_PAGE_SIZE} from '@app/common/constants';
import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IPlanAndMeetInitialState {
  masterData: any[];
  doctorInfo: any;
  doctorInfoError: any;
  loader: boolean;
  roles: any[];
  qualification: any[];
  searchText: string;
  gridOptions: any;
  totalRows: number;
  chemists: any[];
  products: any[];
  approvalList: any[];
  approve: any;
  reject: any;
  docRequestState: any;
  chemistSurvey: any[];
  approveRequests: any[];
  doctorList: any[];
  patches: any[];
  allAreas: any[];
  workingDay: any[];
  detailReports: any[];
  doctorRequests: any;
  chemistRequests: any;
  doctorExistsData: any[];
  previewDoctor: any;
  doctorRemoveLoading: boolean;
  chemistRemoveLoading: boolean;
  doctorRemoveDone: boolean;
  chemistRemoveDone: boolean;
}
export interface IMTPUnlockReasons {
  id: number;
  isFineApplicable: boolean;
  reason: string;
}

export const doctorApprovalState: IPlanAndMeetInitialState = {
  masterData: [],
  doctorInfo: {},
  doctorInfoError: null,
  approvalList: [],
  qualification: [],
  chemists: [],
  products: [],
  approve: false,
  reject: false,
  docRequestState: false,
  loader: false,
  roles: [],
  searchText: '',
  totalRows: 0,
  chemistSurvey: [],
  doctorList: [],
  approveRequests: [],
  detailReports: [],
  patches: [],
  allAreas: [],
  workingDay: [],
  gridOptions: {
    pageNo: 0,
    pageSize: PLAN_AND_MEET_PAGE_SIZE,
  },
  doctorRequests: {},
  chemistRequests: {},
  doctorExistsData: [],
  previewDoctor: {},
  doctorRemoveLoading: false,
  chemistRemoveLoading: false,
  doctorRemoveDone: false,
  chemistRemoveDone: false,
};

export const masterDataCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/MASTER_DATA',
);
export const masterDataType = masterDataCreator({}).type;

export const doctorInfoCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/DOCTOR_INFO',
);
export const doctorInfoCreatorType = doctorInfoCreator({}).type;

export const chemistsCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/CHEMISTS',
);
export const chemistsCreatorType = chemistsCreator({}).type;

export const productsCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/PRODUCTS',
);
export const productsCreatorType = productsCreator({}).type;

export const doctorQualification = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/DOCTOR_QUQLIFICATION',
);
export const doctorQualificationType = doctorQualification({}).type;

export const approveCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/APPROVE',
);
export const approveCreatorType = approveCreator({}).type;

export const rejectCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/REJECT',
);
export const rejectCreatorType = rejectCreator({}).type;

export const approveRequestCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_APPROVE_REQ',
);
export const approveRequestType = approveRequestCreator({}).type;

export const searchDoctorCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/SEARCH_DOCTOR',
);
export const searchDoctorType = searchDoctorCreator({}).type;

export const detailReportCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/DETAIL_REPORT',
);
export const detailReportType = detailReportCreator({}).type;

export const getPatchesCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_PATCHES',
);
export const getPatchesType = getPatchesCreator({}).type;

export const getAreasCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_ALL_AREAS',
);
export const getAreasType = getPatchesCreator({}).type;

export const getWorkingDayCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_ALL_WORKING_DAY',
);
export const getWorkingDayType = getPatchesCreator({}).type;

export const downloadDetailReportCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/DOWNLOAD_DETAIL_REPORT',
);
export const downloadDetailReportType = downloadDetailReportCreator({}).type;

export const searchApprovalRequestsCreator = createAction<any>(
  'DOCTOR_APPROVAL/SEARCH_APPROVAL_REQUESTS',
);
export const searchApprovalRequestsCreatorType = searchApprovalRequestsCreator(
  {},
).type;

export const doctorRequestsCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_DOCTOR_REQUEST_CREATOR',
);
export const doctorRequestsCreatorType = doctorRequestsCreator({}).type;

export const approveDoctorRequestCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/APPROVE_DOCTOR_REQUEST_CREATOR',
);
export const approveDoctorRequestCreatorType = approveDoctorRequestCreator(
  {},
).type;

export const rejectDoctorRequestCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/REJECT_DOCTOR_REQUEST_CREATOR',
);
export const rejectDoctorRequestCreatorType = rejectDoctorRequestCreator(
  {},
).type;

export const chemistRequestsCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_CHEMIST_REQUEST_CREATOR',
);
export const chemistRequestsCreatorType = chemistRequestsCreator({}).type;

export const approveChemistRequestCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/APPROVE_CHEMIST_REQUEST_CREATOR',
);
export const approveChemistRequestCreatorType = approveChemistRequestCreator(
  {},
).type;

export const rejectChemistRequestCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/REJECT_CHEMIST_REQUEST_CREATOR',
);
export const rejectChemistRequestCreatorType = rejectChemistRequestCreator(
  {},
).type;

export const doctorExistsCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_DOCTOR_EXISTS',
);
export const doctorExistsCreatorType = doctorExistsCreator({}).type;

export const previewDoctorCreator = createAction<any>(
  'DOCTOR_APPROVAL_HANDLER/GET_PREVIEW_DOCTOR',
);
export const previewDoctorCreatorType = previewDoctorCreator({}).type;

const docApprovalHandler = createSlice({
  name: 'DOCTOR_APPROVAL_HANDLER',
  initialState: doctorApprovalState,
  reducers: {
    setMasterData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        masterData: payload,
      };
    },
    getAllWorkingDay: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        workingDay: payload,
      };
    },
    getPatches: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        patches: payload,
      };
    },
    getAreas: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        allAreas: payload,
      };
    },
    setDetailReport: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        detailReports: payload,
      };
    },
    setApproveRequests: (prevState, action) => {
      const {payload} = action;
      payload.map((data: any, key: any) => {
        return (data.id = key);
      });
      return {
        ...prevState,
        approveRequests: payload,
      };
    },
    setSearchDoctor: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorList: payload,
      };
    },
    setDoctorInfo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorInfo: payload,
      };
    },
    setChemists: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        chemists: payload,
      };
    },
    setProducts: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        products: payload,
      };
    },
    setApprove: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approve: payload,
      };
    },
    setChemistSurvey: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        chemistSurvey: payload,
      };
    },
    setQualification: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        qualification: payload,
      };
    },
    setReject: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reject: payload,
      };
    },
    setDocRequestState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        docRequestState: payload,
      };
    },

    setDoctorApproveLoader: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        loader: payload,
      };
    },
    setTotalRowsInGrid: (prevState, action) => {
      return {
        ...prevState,
        totalRows: action.payload,
      };
    },
    setPlanAndMeetSelectedRoles: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        qualification: payload,
      };
    },
    setSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        searchText: payload,
      };
    },
    setDocInfoError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorInfoError: payload,
      };
    },
    setDoctorRequests: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorRequests: payload,
      };
    },
    setChemistRequests: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        chemistRequests: payload,
      };
    },
    setDoctorExistsRequests: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorExistsData: payload,
      };
    },
    setPreviewDoctor: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        previewDoctor: payload,
      };
    },
    setDoctorRemoveLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorRemoveLoading: payload,
      };
    },
    setDoctorRemoveDone: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorRemoveDone: payload,
      };
    },
    resetDoctorRemoveDone: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorRemoveDone: false,
      };
    },
    setChemistRemoveLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        chemistRemoveLoading: payload,
      };
    },
    setChemistRemoveDone: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        chemistRemoveDone: payload,
      };
    },
    resetChemistRemoveDone: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        chemistRemoveDone: false,
      };
    },
  },
});

export const doctorApprovalActions = docApprovalHandler.actions;
export const doctorApprovalReducer = docApprovalHandler.reducer;
