import { createAction, createSlice } from "@reduxjs/toolkit";

export const recentUpdatesInitialState = {
  data: [],
  totalCount:0,
  snapShot: [],
  loading: false,
  success: false,
  error: false,
  history: [],
  historyLoading: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
  pageNo: 0,
  rowsPerPage: 10,
}

export const fetchRecentUpdatesCreator = createAction(
  'RECENTUPDATESS/FETCH_RECENTUPDATES',
);
export const fetchRecentUpdatesCreatorTypeName = fetchRecentUpdatesCreator().type;

export const fetchRecentUpdatesHistoryCreator = createAction<any>(
  'RECENTUPDATESS/FETCH_RECENTUPDATES_HISTORY',
);
export const fetchRecentUpdatesHistoryCreatorTypeName = fetchRecentUpdatesHistoryCreator(null).type;

export const fetchRecentUpdatesSuggestionsCreator = createAction<any>(
  'RECENTUPDATESS/FETCH_RECENTUPDATES_SUGGESTIONS',
);
export const fetchRecentUpdatesSuggestionsCreatorTypeName = fetchRecentUpdatesSuggestionsCreator(null).type;
  
const recentUpdatesStateHandler = createSlice({
  name: 'RECENT_UPDATES',
  initialState: recentUpdatesInitialState,
  reducers: {
    setRecentUpdatesLoading: (prevState, action) => {
      return {
        ...prevState,
        loading: action?.payload,
      };
    },
    setRecentUpdatesSuccess: (prevState, action) => {
      return {
        ...prevState,
        success: action?.payload,
      };
    },
    setRecentUpdates: (prevState, action) => {
      return {
        ...prevState,
        data: action?.payload,
      };
    },
    setRecentUpdatesLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setRecentUpdatesSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapShot: action?.payload,
      };
    },
    setRecentUpdatesError: (prevState, action) => {
      return {
        ...prevState,
        error: true,
        errorMessage: action?.payload,
      };
    },
    clearRecentUpdatesError: prevState => {
      return {
        ...prevState,
        error: false,
        errorMessage: '',
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
  }
})


export const recentUpdatesStateActions = recentUpdatesStateHandler.actions;
export const recentUpdatesStateReducer = recentUpdatesStateHandler.reducer;