import React, {useRef, useState, useEffect} from 'react';
import './styles.css';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  clearRequestPayload,
  setStatusToggle,
  updateRequestPayload,
} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import {
  clearRequestPayloadOrder,
  updateRequestPayloadOrder,
} from '../../pages/ho-admin/redux/orderStore';
import {fetchRequestFormDataCreator} from '@app/screens/zho/pages/fc-admin/redux-api/slice';
import {fetchOrderFormDataCreator} from '../../pages/ho-admin/redux-api/slice';

type SegmentedControlProps = {
  name: string;
  segments: any[];
  callback: (value?: any, index?: number) => void;
  defaultIndex?: number;
  controlRef: any;
  containerStyle?: any;
  itemStyle?: any;
};

export const ZhoToggleSeperator: React.FC<SegmentedControlProps> = ({
  name,
  segments,
  callback,
  defaultIndex = 1,
  controlRef,
  containerStyle = {},
  itemStyle = {},
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef();
  const dispatch = useDispatch();

  const userLoginInfo = useSelector(
    (state: RootStateOrAny) => state.appState.userInfo,
  );

  const screenNo = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.screenNo,
  );

  const screenIndex = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    componentReady.current = true;
    return () => {
      dispatch(setStatusToggle(1));
    };
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const {offsetWidth, offsetLeft} = activeSegmentRef.current;
    const {style} = controlRef.current;

    style.setProperty('--highlight-width', `${offsetWidth + 12}px`);
    style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value: any, index: number) => {
    setActiveIndex(index);
    callback(value, index);
  };

  const handleClick = (i: number) => {
    if (i !== activeIndex) {
      dispatch(setStatusToggle(i));

      if (screenNo === 1) {
        dispatch(
          updateRequestPayload({
            currentStaffPositionId: 0,
            pageSize: 10,
            pageNumber: 0,
            requestFormNo: '',
            mrStaffIds: [],
            state: '',
            hq: [],
            startDate: '',
            endDate: '',
          }),
        );
        dispatch(
          fetchRequestFormDataCreator(
            {
              currentStaffPositionId: userLoginInfo?.staffPositionId,
              pageSize: 10,
              pageNumber: 0,
              requestFormNo: '',
              mrStaffIds: [],
              state: '',
              hq: [],
              startDate: '',
              endDate: '',
            },
            i,
          ),
        );
        dispatch(clearRequestPayload());
      } else if (screenNo === 2) {
        if (screenIndex === 0) {
          switch (i) {
            case 0:
              i = 1;
              break;
            case 1:
              i = 2;
              break;
            case 2:
              i = 3;
              break;
            default:
              if (i === segments.length - 1) {
                i = 0;
              } else {
                i = 1; // Default case
              }
              break;
          }
          dispatch(
            updateRequestPayloadOrder({
              currentStaffPositionId: userLoginInfo?.staffPositionId,
              division: [],
              pageSize: 10,
              pageNumber: 0,
              formNo: '',
              mrStaffIds: [],
              state: '',
              hq: [],
              startDate: '',
              endDate: '',
            }),
          );
          dispatch(
            fetchOrderFormDataCreator(
              {
                currentStaffPositionId: userLoginInfo?.staffPositionId,
                division: [],
                pageSize: 10,
                pageNumber: 0,
                formNo: '',
                mrStaffIds: [],
                state: '',
                hq: [],
                startDate: '',
                endDate: '',
              },
              i,
            ),
          );
          dispatch(clearRequestPayloadOrder());
        } else {
          switch (i) {
            case 0:
              i = 2;
              break;
            case 1:
              i = 1;
              break;
            case 2:
              i = 3;
              break;
            default:
              if (i === segments.length - 1) {
                i = 0;
              } else {
                i = 2; // Default case
              }
              break;
          }
        }
      }
    }
  };

  return (
    <div className="controls-container" ref={controlRef} style={containerStyle}>
      <div
        className={`controls ${componentReady.current ? 'ready' : 'idle'}`}
        style={{width: '100%'}}>
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${i === activeIndex ? 'active' : 'inactive'}`}
            style={itemStyle}
            ref={item.ref}>
            <input
              onClick={() => {
                handleClick(i);
              }}
              data-testid={'toogle-switch-button-' + i}
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label style={{fontSize: '12.7px'}} htmlFor={item.label}>
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZhoToggleSeperator;
