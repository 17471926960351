import {useState, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';

import {feedbacksStateSelector} from '@app/screens/employee-specific-utilities/pages/feedback/redux/selectors';
import {
  fetchFeedbacksCreator,
  feedbacksStateActions,
  feedbackDataGridStateActions,
} from '@app/screens/employee-specific-utilities/pages/feedback/redux/slice';
import {filterSelector} from '../../components/right-sidebar/redux/filterHandlerSelector';
import {filterStateActions} from '../../components/right-sidebar/redux/filterHandler';
import {SearchBar} from '../search-bar';
import {store} from '../../App';
export const UtilF: any = {
  handleTypeChange: null,
};
type EmployeeType = 'ALL' | 'MR' | 'FLM' | 'SLM';

export const SearchBarFeedback = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const filter = useSelector(filterSelector.getFilterOptions());
  const handleTypeChange = useCallback(
    function (value: EmployeeType) {
      dispatch(
        filterStateActions.setFilterOptions({
          ...filter,
          pageNo: 0,
          rowPerPage: 20,
          roleIds: value === 'ALL' ? null : value,
        }),
      );
      dispatch(
        feedbackDataGridStateActions.setFeedbacksLoadingPerPage({
          pageNo: 0,
          perPage: 20,
        }),
      );
      dispatch(filterStateActions.setFilterApplied(true));
      dispatch(fetchFeedbacksCreator());
    },
    [filter],
  );

  const {data, selectedAutoSuggestValue, suggestionsLoading} = useSelector(
    feedbacksStateSelector.getFeedbacksState(),
    shallowEqual,
  );

  const textBoxOnChangeHandler = useCallback(function (value: any) {
    let filterReq = store?.getState()?.filterState?.filterOptions;
    dispatch(
      filterStateActions.setFilterOptions({
        ...filterReq,
        searchData: value,
      }),
    );
    dispatch(
      feedbackDataGridStateActions.setFeedbacksLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    dispatch(filterStateActions.setFilterApplied(true));
    dispatch(fetchFeedbacksCreator());
  }, []);
  UtilF.handleTypeChange = handleTypeChange;
  return (
    <SearchBar
      open={open}
      setOpen={setOpen}
      autocompleteOnChangeHandler={() => {}}
      textBoxOnChangeHandler={textBoxOnChangeHandler}
      autoSuggestData={data}
      selectedAutoSuggestValue={selectedAutoSuggestValue}
      suggestionsLoading={suggestionsLoading}
      handleTypeChange={handleTypeChange}
    />
  );
};
