import {useEffect, useState, useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {debounce} from 'lodash';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
  Autocomplete,
  FormControl,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {CSVLink} from 'react-csv';

import Colors from '@app/themes/colors';
import excelLogo from '@app/assets/images/excelLogo.png';
import CustomSelect from '@app/components/widgets/CustomSelect';
import {employee360StateSelector} from '@app/screens/not-assigned/pages/employee360/redux/selectors';
import {
  employee360StateActions,
  fetchEmp360DivisionsCreator,
  fetchEmp360EmpExcelCreator,
  fetchEmp360EmpListCreator,
  fetchEmp360SearchUserCreator,
} from '@app/screens/not-assigned/pages/employee360/redux/slice';
import {ToastType} from '@app/services/toaster';
import {ToasterService} from '@app/services';
import Emp360ProfileHeader from '../Emp360Profile';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    borderRadius: '10px',
    color: 'black',
    backgroundColor: '#dde8ec',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '15px',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  detailSection: {
    minHeight: '75px',
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
  },
  dropDownContainer: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  excelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    flexGrow: 1,
    paddingRight: '30px',
    width: '250px',
    fontWeight: '600',
    color: '#1c1939',
  },
  searchStyle: {
    //height: '50px',
    background: Colors.grey[1700],
  },
};

const headers = [
  {label: 'ID', key: 'employeeId'},
  {label: 'Name', key: 'name'},
  {label: 'Divison', key: 'divisionName'},
  {label: 'HQ', key: 'hqName'},
  {label: 'Manager Rating', key: 'managerRating'},
  {label: 'Rating Date', key: 'managerRatingDate'},
  {label: 'Work Experience', key: 'workExperience'},
  {label: 'Active', key: 'isActive'},
];

function Employee360NavBar() {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState({});
  const divisions = useSelector(
    employee360StateSelector.getEmp360Divisions(),
    shallowEqual,
  );
  const selectedDivision = useSelector(
    employee360StateSelector.getEmp360SelectedDivisions(),
    shallowEqual,
  );
  const searchUserData = useSelector(
    employee360StateSelector.getEmp360SearchUser(),
    shallowEqual,
  );
  const searchUserDataLoading = useSelector(
    employee360StateSelector.getEmp360SearchUserLoading(),
    shallowEqual,
  );
  const empListLength = useSelector(
    employee360StateSelector.getEmp360EmpListLength(),
    shallowEqual,
  );

  const empExcelData = useSelector(
    employee360StateSelector.getEmp360EmpExcel(),
    shallowEqual,
  );

  const empExcelLoading = useSelector(
    employee360StateSelector.getEmp360EmpExcelLoading(),
    shallowEqual,
  );

  const empExcelAction = useSelector(
    employee360StateSelector.getEmp360EmpExcelLatestAction(),
    shallowEqual,
  );

  const isProfileActive = useSelector(
    employee360StateSelector.getEmp360IsProfileActive(),
    shallowEqual,
  );

  useEffect(() => {
    if (empExcelData.length === empListLength) {
      document.getElementById('dowload_csv')?.click();
    } else if (empListLength > 0) {
      ToasterService.showToaster(
        'Something went wrong while downloding employee list excel!',
        ToastType.ERROR,
      );
    }
  }, [empExcelData]);

  const [searchUserQuery, setSearchUserQuery] = useState('');

  const handleUserSelection = (event: any, newValue: any | null) => {
    newValue &&
      newValue.value &&
      dispatch(
        fetchEmp360EmpListCreator({
          stateList: [],
          divisionList: [],
          hierarchyList: [],
          hqList: [],
          staffPositionIds: [newValue.value],
        }),
      );

    setSelectedUser(newValue || {});
  };

  const handleDebounceFn = (selectedDivision: any, searchUserQuery: any) => {
    dispatch(
      fetchEmp360SearchUserCreator({
        division: selectedDivision,
        searchData: searchUserQuery,
      }),
    );
  };
  const debouncedSearchUser = useCallback(debounce(handleDebounceFn, 500), []);

  const selectDivision = (selectedDivisionValues: any) => {
    dispatch(
      employee360StateActions.setEmp360SelectedDivisions(
        selectedDivisionValues,
      ),
    );
  };

  useEffect(() => {
    if (
      searchUserQuery &&
      searchUserQuery.length &&
      searchUserQuery.length > 2
    ) {
      debouncedSearchUser(selectedDivision, searchUserQuery);
    }
  }, [searchUserQuery]);

  useEffect(() => {
    dispatch(fetchEmp360DivisionsCreator());
    return () => {
      dispatch(employee360StateActions.setEmp360Divisions([]));
      dispatch(employee360StateActions.setEmp360DivisionsError(''));
      dispatch(employee360StateActions.setEmp360DivisionsLoading(false));
      dispatch(employee360StateActions.setEmp360SearchUser([]));
      dispatch(employee360StateActions.setEmp360SearchUserError(''));
      dispatch(employee360StateActions.setEmp360SearchUserLoading(false));
    };
  }, []);

  console.log('empExcelAction', empExcelAction);

  return (
    <>
      {isProfileActive && <Emp360ProfileHeader />}
      <Box
        sx={{...styles.container, display: isProfileActive ? 'none' : 'flex'}}>
        <Box sx={styles.title}>
          <Box sx={styles.dropDownContainer}>
            <Typography variant="h6" sx={styles.titleStyle}>
              Employee 360
            </Typography>

            <CustomSelect
              dropDownTitle={'Divisions'}
              data={divisions}
              onClose={selectDivision}
            />
          </Box>

          <Button
            onClick={() => {
              empListLength > 0 &&
                ToasterService.showToaster(
                  'Excel download will begin shortly!',
                  ToastType.SUCCESS_ACTION,
                );
              dispatch(fetchEmp360EmpExcelCreator(empExcelAction));
            }}
            data-testid={'download-excel-container'}>
            <Box sx={styles.excelContainer}>
              <img
                src={excelLogo}
                alt="Download excel file"
                style={{
                  width: '30px',
                  height: '30px',
                }}
              />

              <Typography
                data-testid={'download-excel'}
                sx={{textTransform: 'capitalize'}}
                variant={'caption'}>
                Download Excel
              </Typography>
              {empExcelLoading && <CircularProgress size={25} />}
            </Box>
          </Button>
        </Box>

        <FormControl>
          <Autocomplete
            sx={styles.searchStyle}
            placeholder="Search here"
            noOptionsText={
              (searchUserQuery.length < 3 &&
                'Start typing to search employee.') ||
              (searchUserDataLoading && (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <CircularProgress size={25} />
                </Box>
              ))
            }
            options={searchUserData}
            onChange={handleUserSelection}
            value={selectedUser}
            getOptionLabel={(option?: any) => option.label || ''}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={'Search here'}
                onChange={event => {
                  setSearchUserQuery(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <CSVLink
          data={empExcelData}
          headers={headers}
          filename={'employee-list.csv'}
          id="dowload_csv"
        />
      </Box>
    </>
  );
}

export default Employee360NavBar;
