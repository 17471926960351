import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import Scrollbar from 'react-scrollbars-custom';
import {useStyles} from '@app/screens/zho/styles';
import {useStyles as useStyles2} from '../styles';
import {strings} from '@app/common/strings';
import {Stack} from '@mui/system';
import Modal from 'react-modal';
import {useEffect, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fcDashboardActions,
  fetchDivisionDataCreator,
  fetchManageFilterCandFCreator,
  fetchManageFilterChemistCreator,
  fetchManageFilterCustomerCreator,
  fetchManageFilterHqCreator,
  fetchManageFilterProductCreator,
  fetchManageProductAddCreator,
  fetchManageProductTableCreator,
} from '../../redux-api/slice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {ErrorTableBlock} from './errorTableBlock';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Division {
  divisionId: number;
  divisionName: string;
}
interface CandFDataItem {
  candFId: string;
  candFName: string;
  candFCity: string;
}
interface Product {
  id: number;
  name: string;
  standardBaseQty: number;
  standardBonusQty: number;
  mrp: number;
  ptr: number;
  pts: number;
}

const ManageProductFilter = ({
  userLoginInfo,
  addProductPayload,
  setAddProductPayload,
  pageNo,
  clearPayload,
}: any) => {
  const [division, setDivision] = useState<null | number>(null);
  const [candF, setCndF] = useState<number[]>([]);
  const [hq, setHq] = useState<number[]>([]);
  const [product, setProduct] = useState<null | number>(null);
  const [customer, setCustomer] = useState<number[]>([]);
  const [chemist, setChemist] = useState<number[]>([]);
  const classes = useStyles();
  const classes2 = useStyles2();
  const dispatch = useDispatch();
  const [candFDataWithSelectAll, setCandFDataWithSelectAll] = useState<
    CandFDataItem[]
  >([]);
  const [candFAll, setCandFAll] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<{
    focus: boolean;
    id: number | null;
  }>({
    focus: false,
    id: null,
  });

  const [modalOpen, setModalOpen] = useState(false);

  const handleInputFocus = (id: number) => {
    setIsFocused({
      focus: true,
      id,
    });
  };

  const handleInputBlur = () => {
    setIsFocused({
      focus: false,
      id: null,
    });
  };

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const divisionData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionData,
  );

  const divisionLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionLoading,
  );

  ///products
  const productData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterProductData,
  );

  const productLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterProductLoading,
  );

  ///candf
  const candFData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterCandFData,
  );

  const candFLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterCandFLoading,
  );

  ///hq
  const hqData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterHqData,
  );

  const hqLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterHqLoading,
  );

  //customer
  const customerData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterCustomerData,
  );

  const customerLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterCustomerLoading,
  );

  //chemist
  const chemistData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterChemistData,
  );

  const chemistLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageFilterChemistLoading,
  );

  const manageProductAddLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageProductAddLoading,
  );

  const blockedProductResponse = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageProductAddResponse,
  );

  console.log('this is the blocked respone', blockedProductResponse);

  const handleFilterChange = (value: any, select: string, type: string) => {
    if (select === 'single') {
      const id = value && value[type] !== null ? value[type] : null;
      if (type === 'divisionId') {
        setDivision(id);
        setCndF([]);
        setCandFDataWithSelectAll([]);
        clearingFilterApiData();
        setCandFAll(false);
        setChemist([]);
        setProduct(null);
        setCustomer([]);
        setHq([]);
        dispatch(fetchManageFilterProductCreator(+id));
        dispatch(fetchManageFilterCandFCreator(+id));
        setAddProductPayload((prePayLoad: any) => ({
          ...prePayLoad,
          divisionId: id,
          createdBy: userLoginInfo.userId,
        }));
      } else {
        setAddProductPayload((prePayLoad: any) => ({
          ...prePayLoad,
          productId: id,
        }));
        setProduct(id);
      }
    } else {
      const isSelectAll = value.some(
        (item: any) => item[type] === 'select_all',
      );
      let selectedIds: any;

      if (isSelectAll) {
        // If "Select All" is selected, select all options
        if (type === 'candFId') {
          selectedIds =
            candF.length === candFData.length
              ? []
              : candFData.map((item: any) => item.candFId);
          if (selectedIds.length > 0) {
            setCandFAll(true);
            dispatch(fcDashboardActions.setManageFilterHqData([]));
            dispatch(fcDashboardActions.setManageFilterCustomerData([]));
            setHq([]);
            setCustomer([]);
            setChemist([]);
          } else {
            setCandFAll(false);
          }
        } else if (type === 'hqId') {
          selectedIds =
            hq.length === hqData.length
              ? []
              : hqData.map((item: any) => item.hqId);
        } else if (type === 'stockistCode') {
          selectedIds =
            customer.length === customerData.length
              ? []
              : customerData.map((item: any) => item.stockistCode);
        }
      } else {
        // If "Select All" is not selected, use the selected values
        selectedIds = value.map((item: any) => item[type]);
        if (type === 'candFId') {
          if (selectedIds.length > 3) {
            ToasterService.hideToaster();
            ToasterService.showToaster(
              strings.zho.fcAdmin.manageProductStatus.filterMaxLimit,
              ToastType.ERROR,
            );
            selectedIds = selectedIds.slice(0, 3);
            return;
            // Optionally notify the user or handle the case where more than 3 items are selected
          }
        }
      }

      switch (type) {
        case 'candFId':
          setCndF(selectedIds);
          if (selectedIds.length === 0) {
            setCandFAll(false);
          }
          setHq([]);
          setCustomer([]);
          setChemist([]);
          setAddProductPayload((prePayLoad: any) => ({
            ...prePayLoad,
            candFid: selectedIds,
          }));
          console.log(selectedIds.length);
          if (
            selectedIds.length !== 0 &&
            candFData.length !== selectedIds.length &&
            selectedIds.length <= 3
          ) {
            dispatch(fetchManageFilterHqCreator(selectedIds));
            dispatch(fetchManageFilterCustomerCreator(division, selectedIds));
          }
          break;
        case 'hqId':
          setHq(selectedIds);
          setChemist([]);
          setAddProductPayload((prePayLoad: any) => ({
            ...prePayLoad,
            hqid: selectedIds,
          }));
          if (chemistData.length === 0) {
            dispatch(fetchManageFilterChemistCreator(division, selectedIds));
          }
          break;
        case 'stockistCode':
          const toStringArray = (arr: number[]) => arr.map(String);
          setAddProductPayload((prePayLoad: any) => ({
            ...prePayLoad,
            stockistId: toStringArray(selectedIds),
          }));
          setCustomer(selectedIds);
          break;
        default:
          setAddProductPayload((prePayLoad: any) => ({
            ...prePayLoad,
            partyId: selectedIds,
          }));
          setChemist(selectedIds);
          break;
      }
    }
  };

  const handleSubmit = () => {
    if (division !== null) {
      dispatch(
        fetchManageProductAddCreator(addProductPayload, {
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageSize: 10,
          pageNumber: pageNo,
        }),
      );
      handleCancel();
      clearPayload();
    } else {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please select the Division!',
        ToastType.ERROR,
      );
    }
  };

  useEffect(() => {
    dispatch(fetchDivisionDataCreator(userLoginInfo?.staffPositionId));
    return () => {
      dispatch(fcDashboardActions.setDivisionData([]));
      clearingFilterApiData();
      dispatch(fcDashboardActions.setManageProductAddReponse([]));
      setModalOpen(false);
    };
  }, []);

  useEffect(() => {
    setCandFDataWithSelectAll([
      {candFId: 'select_all', candFName: 'Select All', candFCity: ''},
      ...candFData,
    ]);
  }, [candFData]);

  useEffect(() => {
    // if (division !== null && !manageProductAddLoader) {
    //   dispatch(
    //     fetchManageProductTableCreator({
    //       currentStaffPositionId: userLoginInfo?.staffPositionId,
    //       pageSize: 10,
    //       pageNumber: pageNo,
    //     }),
    //   );
    // }

    if (blockedProductResponse.length !== 0) {
      setModalOpen(true);
    }
  }, [manageProductAddLoader]);

  const clearingFilterApiData = () => {
    dispatch(fcDashboardActions.setManageFilterProductData([]));
    dispatch(fcDashboardActions.setManageFilterCandFData([]));
    dispatch(fcDashboardActions.setManageFilterHqData([]));
    dispatch(fcDashboardActions.setManageFilterCustomerData([]));
    dispatch(fcDashboardActions.setManageFilterChemistData([]));
  };

  const handleCancel = () => {
    clearingFilterApiData();
    setDivision(null);
    setProduct(null);
    setCndF([]);
    setCandFDataWithSelectAll([]);
    setCandFAll(false);
    setHq([]);
    setCustomer([]);
    setChemist([]);
    setChemist([]);
    clearPayload();
  };
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff !important',
        marginTop: '0px !important',
        padding: '17px !important',
      }}
      className={classes.toolbarRequestForm}>
      <Modal
        isOpen={modalOpen}
        onAfterOpen={disableScroll}
        onAfterClose={enableScroll}
        style={{
          content: {
            width: '60%',
            minWidth: '320px',
            height: 'fit-content',
            margin: 'auto',
            right: 0,
            backgroundColor: '#ffffff',
            boxShadow: ' 0 4 #000000 26.7 0 25%',
            borderRadius: '8px',
            padding: '0px',
          },
          overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
        }}>
        <ErrorTableBlock
          blockedData={blockedProductResponse}
          handleToggleModal={handleToggleModal}
        />
      </Modal>

      <Stack direction="column" width="100%" gap="1rem">
        <Scrollbar
          style={{
            width: '100%',
            height: '60px',
            marginRight: '10px',
          }}>
          <Grid
            sx={{
              paddingTop: '3px',
              justifyContent: 'center',
              alignItems: 'flex-end',
              backgroundColor: '#ffffff !important',
            }}
            container
            className={classes.filterGrid}
            spacing={1}>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Autocomplete
                  value={
                    division
                      ? divisionData.find(
                          (item: any) => item.divisionId === division,
                        )
                      : null
                  }
                  onChange={(e, value) =>
                    handleFilterChange(value, 'single', 'divisionId')
                  }
                  noOptionsText="No options"
                  data-testid="test-product-dropdown"
                  key={'search-Division'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',

                    '& .MuiAutocomplete-input': {
                      width: '1px !important',
                      minWidth: '1px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },

                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },

                    '& .MuiAutocomplete-noOptions': {
                      zIndex: 1000,
                    },
                  }}
                  options={divisionData}
                  getOptionLabel={(option: Division) =>
                    option?.divisionName?.length > 13
                      ? option?.divisionName?.slice(0, 13) + '...'
                      : option?.divisionName
                  }
                  renderOption={(props, option: Division) => (
                    <li
                      {...props}
                      key={option.divisionId}
                      style={{
                        fontSize: '12.7px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      {option.divisionName}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Division'}
                      className={classes2.textField}
                      label="Division"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {divisionLoading ? (
                              <CircularProgress
                                className={classes2.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),

                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: Division[], getTagProps) => {
                    if (value.length === 0) return null;
                    const firstLabel =
                      value[0]?.divisionName?.slice(0, 13) + '...';
                    const additionalCount =
                      value.length > 1
                        ? ` ${value.length} Products selected`
                        : '';
                    if (value.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Autocomplete
                  value={
                    product
                      ? productData.find((item: Product) => item.id === product)
                      : null
                  }
                  onChange={(e, value) =>
                    handleFilterChange(value, 'single', 'id')
                  }
                  noOptionsText="No options"
                  data-testid="test-product-dropdown"
                  key={'search-product'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',

                    '& .MuiAutocomplete-input': {
                      width: '1px !important',
                      minWidth: '1px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  options={productData}
                  getOptionLabel={(option: Product) =>
                    option?.name?.length > 13
                      ? option?.name?.slice(0, 13) + '...'
                      : option?.name
                  }
                  renderOption={(props, option: Product) => (
                    <li
                      {...props}
                      key={option?.id}
                      style={{
                        fontSize: '12.7px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Product'}
                      className={classes2.textField}
                      label="Product"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {productLoader ? (
                              <CircularProgress
                                className={classes2.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: Product[], getTagProps) => {
                    if (value.length === 0) return null;
                    const firstLabel = value[0]?.name?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1
                        ? ` ${value?.length} Products selected`
                        : '';
                    if (value?.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Autocomplete
                  noOptionsText="No options"
                  disableCloseOnSelect
                  data-testid="test-product-dropdown"
                  onChange={(e, value) =>
                    handleFilterChange(value, 'multiple', 'candFId')
                  }
                  value={candFDataWithSelectAll.filter((item: any) =>
                    candF.includes(item.candFId),
                  )}
                  key={'search-product'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',
                    '& .MuiAutocomplete-input': {
                      width: '2px !important',
                      minWidth: '2px !important',
                      paddingLeft: '1px !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={
                    division !== null ? candFDataWithSelectAll : candFData
                  }
                  getOptionLabel={option => option.candFName}
                  renderOption={(props, option, {selected}) => {
                    const isSelectAll = candFAll; // assuming candFAll is a boolean indicating if "Select All" is selected
                    const isDisabled =
                      isSelectAll && option.candFId !== 'select_all';

                    return (
                      <li
                        {...props}
                        key={option.candFId}
                        style={{
                          fontSize: '12.7px',
                          padding: '0px',
                          color: '#1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                          pointerEvents: isDisabled ? 'none' : 'auto', // Disable click if needed
                          opacity: isDisabled ? 0.6 : 1, // Make disabled options look different
                        }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={
                            option.candFId === 'select_all'
                              ? candF.length === candFData.length
                              : selected
                          }
                          disabled={isDisabled} // Disable the checkbox if needed
                        />
                        {`${option.candFName} ${
                          option.candFCity
                            ? `(${option.candFCity}, ${option.candFId})`
                            : ''
                        }`}
                      </li>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'C&F'}
                      className={classes2.textField}
                      label={'C&F'}
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(1),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {candFLoading ? (
                              <CircularProgress
                                className={classes2.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              candF.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 1
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 1) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;

                    const firstLabel =
                      value[0]?.candFName?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1
                        ? ` ${value?.length} C&F's selected`
                        : '';

                    if (value?.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '7px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '7px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Autocomplete
                  disabled={candFAll}
                  noOptionsText="No options"
                  disableCloseOnSelect
                  data-testid="test-product-dropdown"
                  onChange={(e, value) =>
                    handleFilterChange(value, 'multiple', 'hqId')
                  }
                  value={hqData.filter((item: any) => hq.includes(item.hqId))}
                  key={'search Hq'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',
                    '& .MuiAutocomplete-input': {
                      width: '2px !important',
                      minWidth: '2px !important',
                      paddingLeft: '1px !important',
                      cursor: candFAll ? 'not-allowed' : '',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={hqData}
                  getOptionLabel={(option: any) => option.hqName}
                  renderOption={(props, option, {selected}) => (
                    <li
                      {...props}
                      key={option.hqId}
                      style={{
                        fontSize: '12.7px',
                        padding: '0px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={
                          option.hqId === 'select_all'
                            ? hq.length === hqData.length
                            : selected
                        }
                      />
                      {option.hqName}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'HQ'}
                      className={classes2.textField}
                      label="HQ"
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(2),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {hqLoading && !candFAll ? (
                              <CircularProgress
                                className={classes2.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              hq.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 2
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 2) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;

                    const firstLabel = value[0]?.hqName?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1
                        ? ` ${value?.length} HQ's selected`
                        : '';

                    if (value?.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Autocomplete
                  noOptionsText="No options"
                  disabled={candFAll}
                  disableCloseOnSelect
                  onChange={(e, value) =>
                    handleFilterChange(value, 'multiple', 'stockistCode')
                  }
                  value={customerData.filter((item: any) =>
                    customer.includes(item.stockistCode),
                  )}
                  data-testid="test-product-dropdown"
                  key={'search-Customer'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    paddingRight: '0px !important',
                    '& .MuiAutocomplete-input': {
                      width: '2px !important',
                      minWidth: '2px !important',
                      paddingLeft: '1px !important',
                      cursor: candFAll ? 'not-allowed' : '',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={customerData}
                  getOptionLabel={(option: any) =>
                    option?.stockistName1?.length > 13
                      ? option.stockistName1?.slice(0, 13) + '...'
                      : option.stockistName1
                  }
                  renderOption={(props, option: any, {selected}, index) => (
                    <li
                      {...props}
                      key={+option.stockistCode}
                      style={{
                        fontSize: '12.7px',
                        padding: '0px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={
                          option.stockistCode === 'select_all'
                            ? customer.length === customerData.length
                            : selected
                        }
                      />
                      {`${option?.stockistName1} (${option?.stockistCity}, ${option?.stockistCode}) `}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Customer'}
                      className={classes2.textField}
                      label="Customer"
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(3),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {customerLoading && !candFAll ? (
                              <CircularProgress
                                className={classes2.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              customer.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 3
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: any, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 3) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;
                    const firstLabel =
                      value[0]?.stockistName1?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1
                        ? ` ${value.length} Customers's selected`
                        : '';
                    if (value.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Autocomplete
                  disabled={candFAll}
                  disableCloseOnSelect
                  onChange={(e, value) =>
                    handleFilterChange(value, 'multiple', 'id')
                  }
                  value={chemistData.filter((item: any) =>
                    chemist.includes(item.id),
                  )}
                  noOptionsText="No options"
                  data-testid="test-product-dropdown"
                  key={'search-Chemist'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',

                    '& .MuiAutocomplete-input': {
                      width: '2px !important',
                      minWidth: '2px !important',
                      paddingLeft: '1px !important',
                      cursor: candFAll ? 'not-allowed' : '',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={chemistData}
                  getOptionLabel={(option: any) =>
                    option?.name?.length > 13
                      ? option?.name?.slice(0, 13) + '...'
                      : option?.name
                  }
                  renderOption={(props, option: any, {selected}) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        fontSize: '12.7px',
                        padding: '0px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Chemist'}
                      className={classes2.textField}
                      label="Chemist"
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(4),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {chemistLoading ? (
                              <CircularProgress
                                className={classes2.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              chemist.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 4
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: any, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 4) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;
                    const firstLabel = value[0]?.name?.slice(0, 13) + '...';
                    const additionalCount =
                      value.length > 1
                        ? ` ${value.length} Chemist's selected`
                        : '';
                    if (value.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Scrollbar>
        <Box>
          <Button
            sx={{
              border: '1px solid #655bcd !important',
              width: '110px !important',
            }}
            data-testid="button-apply"
            variant="outlined"
            size="large"
            onClick={handleCancel}
            className={classes.paperButton}>
            {strings.cancel}
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{width: '110px !important'}}
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}>
            {strings.zho.submit}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ManageProductFilter;
