import {createAction, createSlice} from '@reduxjs/toolkit';

export const staffHoldInitialState = {
  staffHold: [],
  updatedStaffHoldRow: {},
  resignedStaffHold: [],
  totalCount: 0,
  staffHoldSnapShot: [],
  employeesOnHold: [],
  employeesOnHoldSnapShot: [],
  employeesOnHoldLoading: false,
  history: [],
  historyLoading: false,
  employeesOnHoldAutoSuggestion: {},
  employeesOnHoldAutoSuggestData: [],
  staffHoldCount: 0,
  staffHoldLoading: false,
  staffHoldSuggestLoading: false,
  staffHoldSuccess: false,
  staffHoldError: false,
  staffHoldErrorMessage: '',
  searchText: '',
  employeesOnHoldSearchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  pageNo: 0,
  rowsPerPage: 20,
  deepdive: [],
  deepdiveLoading: false,
  employeesOnHoldPageNo: 0,
  employeesOnHoldrowsPerPage: 20,
  employeesOnHoldTotalCount: 0,
};

export const fetchStaffHoldCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFF_HOLD',
);
export const fetchStaffHoldCreatorTypeName = fetchStaffHoldCreator().type;

export const updateStaffHoldCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/UPDATE_STAFF_HOLD',
);
export const updateStaffHoldCreatorTypeName = updateStaffHoldCreator({}).type;

export const fetchEmployeesOnHoldCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_EMPLOYEES_ON_HOLD',
);
export const fetchEmployeesOnHoldCreatorTypeName =
  fetchEmployeesOnHoldCreator().type;

export const fetchStaffHoldHistoryCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFF_HOLD_HISTORY',
);
export const fetchStaffHoldHistoryCreatorTypeName =
  fetchStaffHoldHistoryCreator(null).type;

export const updateEmployeeHoldStatusCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFF_HOLD_HISTORY',
);
export const updateEmployeeHoldStatusCreatorTypeName =
  updateEmployeeHoldStatusCreator().type;

export const fetchStaffHoldSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFF_HOLD_SUGGESTIONS',
);
export const fetchStaffHoldSuggestionsCreatorTypeName =
  fetchStaffHoldSuggestionsCreator(null).type;

export const fetchHoldEmployeeSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_HOLD_EMPLOYEES_SUGGESTIONS',
);
export const fetchHoldEmployeeSuggestionsCreatorTypeName =
  fetchHoldEmployeeSuggestionsCreator(null).type;

export const fetchStaffHoldDeepdiveCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFF_HOLD_DEEPDIVE',
);
export const fetchStaffHoldDeepdiveCreatorTypeName =
  fetchStaffHoldDeepdiveCreator(null).type;

export const fetchStaffHoldFilterCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_STAFF_HOLD_FILTER_DATA',
);

export const fetchStaffHoldFilterCreatorTypeName =
  fetchStaffHoldFilterCreator().type;

export const resignedStaffHoldCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/RESIGNED_STAFF_HOLD',
);
export const resignedStaffHoldCreatorTypeName = resignedStaffHoldCreator(
  {},
).type;

const staffHoldStateHandler = createSlice({
  name: 'STAFF_HOLD',
  initialState: staffHoldInitialState,
  reducers: {
    setStaffHoldLoading: (prevState, action) => {
      return {
        ...prevState,
        staffHoldLoading: action?.payload,
      };
    },
    setStaffHoldSuggestionsLoading: (prevState, action) => {
      return {
        ...prevState,
        staffHoldSuggestLoading: action?.payload,
      };
    },
    setUpdatedStuffHoldRow: (prevState, action) => {
      return {
        ...prevState,
        updatedStaffHoldRow: action?.payload,
      };
    },
    setEmployeeType: (prevState, action) => {
      return {
        ...prevState,
        employeeType: action?.payload,
      };
    },
    setStaffHoldSuccess: (prevState, action) => {
      return {
        ...prevState,
        staffHoldSuccess: action?.payload,
      };
    },
    setStaffHold: (prevState, action) => {
      return {
        ...prevState,
        staffHold: action?.payload,
      };
    },
    setStaffHoldLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },

    setStaffHoldSnapshot: (prevState, action) => {
      return {
        ...prevState,
        staffHoldSnapShot: action?.payload,
      };
    },
    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
    setEmployeesOnHold: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHold: action?.payload,
      };
    },
    setEmployeesOnHoldSnapshot: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldSnapShot: action?.payload,
      };
    },
    setEmployeesOnHoldPerPage: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldPageNo: action.payload.pageNo,
        employeesOnHoldrowsPerPage: action.payload.perPage,
      };
    },
    setEmployeesOnHoldTotalCount: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldTotalCount: action.payload,
      };
    },
    setEmployeesOnHoldLoading: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldLoading: action?.payload,
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setEmployeesOnHoldSearchText: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldSearchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setSelectedEmployeesOnHoldAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldAutoSuggestion: action?.payload,
      };
    },
    setEmployeesOnHoldAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        employeesOnHoldAutoSuggestData: action?.payload,
      };
    },
    setStaffHoldError: (prevState, action) => {
      return {
        ...prevState,
        staffHoldError: true,
        staffHoldErrorMessage: action?.payload,
      };
    },
    clearStaffHoldError: prevState => {
      return {
        ...prevState,
        staffHoldError: false,
        staffHoldErrorMessage: '',
      };
    },
    setDeepdive: (prevState, action) => {
      return {
        ...prevState,
        deepdive: action?.payload,
      };
    },
    setDeepdiveLoading: (prevState, action) => {
      return {
        ...prevState,
        deepdiveLoading: action?.payload,
      };
    },

    setResignedStaffHold: (prevState, action) => {
      return {
        ...prevState,
        resignedStaffHold: action?.payload,
      };
    },
  },
});

export const staffHoldStateActions = staffHoldStateHandler.actions;
export const staffHoldStateReducer = staffHoldStateHandler.reducer;
