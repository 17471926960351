import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  setRightSidebarToggle,
  setScreenNo,
  setSubTabSwitch,
} from '../redux/tabName';
import {navbarComponentName} from '@app/router/redux/routeHandler';

import {Box} from '@mui/material';
import ManageProductView from './components/ManageProductView';
import ManageProductFilter from './components/manageProductFilter';

const ManageProductStatus = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  const [addProductPayload, setAddProductPayload] = useState({
    divisionId: 0,
    candFid: [],
    hqid: [],
    partyId: [],
    stockistId: [],
    productId: null,
    createdBy: 0,
  });
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageChange = (type: string) => {
    if (type === 'inc') {
      setPageNo(pageNo + 1);
    } else {
      if (pageNo === 0) {
        setPageNo(0);
      } else {
        setPageNo(pageNo - 1);
      }
    }
  };

  console.log(
    'this is manage prodict layourd we are sending',
    addProductPayload,
  );

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    dispatch(setRightSidebarToggle(false));
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(setSubTabSwitch(1));
    };
  }, []);

  const clearPayload = () => {
    setAddProductPayload({
      divisionId: 0,
      candFid: [],
      hqid: [],
      partyId: [],
      stockistId: [],
      productId: null,
      createdBy: 0,
    });
  };
  return (
    <Box
      sx={{maxHeight: 'calc(100vh - 155px)', overflow: 'auto'}}
      className="hgghgi-ff">
      <ManageProductFilter
        handlePageChange={handlePageChange}
        pageNo={pageNo}
        addProductPayload={addProductPayload}
        setAddProductPayload={setAddProductPayload}
        userLoginInfo={userLoginInfo}
        margin={true}
        clearPayload={clearPayload}
      />
      <ManageProductView
        handlePageChange={handlePageChange}
        pageNo={pageNo}
        setPageNo={setPageNo}
        setTotalCount={setTotalCount}
        totalCount={totalCount}
        userLoginInfo={userLoginInfo}
      />
    </Box>
  );
};

export default ManageProductStatus;
