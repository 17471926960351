import {useState, useCallback} from 'react';
import Excel from 'exceljs';
import {kebabCase} from 'lodash';
import Typography from '@mui/material/Typography';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box, Button} from '@mui/material';

import {SearchBarSelectedEmployees} from '@app/components/search-bar-selected-employees';
import {SearchBarStaffHold} from '@app/components/search-bar-staff-hold';
import {SearchBarHrApproval} from '@app/components/search-bar-hrapproval';
import {SearchBarCDCCandidates} from '@app/components/search-bar-cdc-candidates';
import {SearchBarFeedback} from '@app/components/search-bar-feedback';
import {SearchBarListOfEmployees} from '@app/components/search-bar-list-of-employees';
import {ListOfEmployeesOnHold} from '@app/screens/employee-specific-utilities/components/staff-hold/listOfEmployeesOnHold';
import {appSelector} from '@app/store/selectors';
import Colors from '@app/themes/colors';
import {SearchBarWelcomekit} from '@app/components/search-bar-welcome-kit';
import {SearchBarRecentUpdates} from '@app/components/search-bar-recent-updates';
import {SearchBarCompanyAssets} from '@app/components/search-bar-company-assets';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '../../../screens/employee-specific-utilities/helpers';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import CircularProgress from '@mui/material/CircularProgress';
import {staffHoldStateActions} from '@app/screens/employee-specific-utilities/pages/staff-hold/redux';
import {fitToColumnExcelJsAoA} from '@app/screens/employee-specific-utilities/components/cdc-candidates/emailSendFuncPopup';

const styleSheet = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    flexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    button: {
      borderRadius: '5px',
      fontStyle: 'normal',
      textAlign: 'center',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      fontSize: 12.7,
    },
  };
};

export const EmployeeSpecificUtilitiesNavBar = () => {
  const styles = styleSheet();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [holdEmployeesPopupVisible, setHoldEmployeesPopupVisible] =
    useState<boolean>(false);

  const resetFilterOptions = () => {
    dispatch(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchData: '',
        pageNo: 0,
        rowPerPage: 20,
        divisionIds: [],
        stateIds: [],
        roleIds: null,
      }),
    );
    dispatch(filterStateActions.setFilterApplied(false));
    dispatch(
      staffHoldStateActions.setEmployeesOnHoldPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
  };

  const openEmployeesPopup = useCallback(() => {
    resetFilterOptions();
    setHoldEmployeesPopupVisible(true);
  }, []);

  const closeEmployeesPopup = useCallback(() => {
    resetFilterOptions();
    setHoldEmployeesPopupVisible(false);
  }, []);

  const excelDataLoading = useSelector(appSelector.getExcelDataLoading());

  const excelInfo = useSelector(appSelector.getExcelData());

  const isEditAccessAvailable = useSelector(
    appSelector.getIsEditAccessAvailable(),
  );
  const componentName = useSelector(
    routeSelector.getNavbarComponentName(),
    shallowEqual,
  );

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );

  const downloadExcel = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(
      `${kebabCase(navbarComponentHeading?.props?.children)}`,
    );
    console.log('excelInfo', excelInfo);

    let header = excelInfo.headers.map((obj: any) => Object.values(obj)[0]);
    let keysArray = excelInfo.headers.map((obj: any) => Object.values(obj)[1]);
    // let arrayOfArraysData = excelInfo.data.map((obj: any) =>
    //   keysArray
    //     .filter((key: any) => Object.prototype.hasOwnProperty.call(obj, key))
    //     .map((key: any) => obj[key]),
    // );

    let arrayOfArraysData = excelInfo.data.map((obj: any) => {
      let row = new Array(keysArray.length);
      keysArray
        .filter((key: any) => {
          return Object.prototype.hasOwnProperty.call(obj, key);
        })
        .forEach((key: any) => {
          row.splice(keysArray.indexOf(key), 0, obj[key]);
        });
      return row;
    });
    console.log('arrayOfArraysData', arrayOfArraysData);
    console.log('keysArray', keysArray);
    worksheet.addRow(header);
    let currentRowIdx = worksheet.rowCount;
    header.forEach((columnName: any, colIdx: number) => {
      worksheet.getCell(currentRowIdx, colIdx + 1).border = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'},
      };
      worksheet.getCell(currentRowIdx, colIdx + 1).font = {
        bold: true,
        size: 12,
      };
      worksheet.getCell(currentRowIdx, colIdx + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '538DD5'},
      };
    });
    arrayOfArraysData.forEach((rowData: any) => {
      worksheet.addRow(rowData);
      currentRowIdx = worksheet.rowCount;

      // Apply styles to columns with headers
      rowData.forEach((cellValue: any, colIdx: number) => {
        if (header[colIdx]) {
          worksheet.getCell(currentRowIdx, colIdx + 1).border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'},
          };
          worksheet.getCell(currentRowIdx, colIdx + 1).font = {size: 11};
        }
      });
    });
    fitToColumnExcelJsAoA(worksheet, arrayOfArraysData, header);
    workbook.xlsx
      .writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${kebabCase(
          navbarComponentHeading?.props?.children,
        )}.xlsx`;

        // Trigger the download
        downloadLink.click();

        // Clean up
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const renderSubComponent = () => {
    switch (navbarComponentHeading?.props?.children) {
      case 'Selected Employees':
        return <SearchBarSelectedEmployees />;
      case 'Staff Hold':
        return <SearchBarStaffHold />;
      case 'CDC Candidates':
        return <SearchBarCDCCandidates />;
      case 'Feedback':
        return <SearchBarFeedback />;
      case 'Welcome Kit':
        return <SearchBarWelcomekit />;
      case 'Company Assets':
        return <SearchBarCompanyAssets />;
      case 'List Of Employees':
        return <SearchBarListOfEmployees />;
      case 'Recent Updates':
        return <SearchBarRecentUpdates />;
      case 'HR Approvals':
        return <SearchBarHrApproval />;
      default:
        break;
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>

        <Box justifyContent="space-between">
          {componentName === navbarComponentName.staffHold && (
            <Button
              disabled={!isEditAccessAvailable}
              variant="text"
              className={classes.button}
              sx={{
                ...styles.button,
                ...(!isEditAccessAvailable && {color: 'grey'}),
                marginRight: '35px',
              }}
              onClick={openEmployeesPopup}>
              List of employees on hold
            </Button>
          )}
          <Button
            variant="text"
            onClick={downloadExcel}
            disabled={!excelInfo?.data}
            className={classes.button}
            startIcon={
              <img
                alt="excel"
                width={20}
                src={require('../../../assets/images/MSExcel.png')}
              />
            }
            sx={{
              ...styles.button,
              ...(!isEditAccessAvailable && {color: 'grey'}),
            }}>
            {excelDataLoading ? (
              <CircularProgress size={20} style={{color: Colors.primary}} />
            ) : (
              <Typography
                style={{
                  color: Colors.primary,
                  textDecoration: 'none',
                  fontSize: 12.7,
                }}>
                Download Excel
              </Typography>
            )}
          </Button>
        </Box>
      </Box>

      {renderSubComponent()}
      {holdEmployeesPopupVisible && (
        <ListOfEmployeesOnHold
          visible={holdEmployeesPopupVisible}
          onClose={closeEmployeesPopup}
        />
      )}
    </Box>
  );
};
