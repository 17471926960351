import {createAction, createSlice} from '@reduxjs/toolkit';

export const hrApprovalInitialState = {
  data: [],
  totalCount: 0,
  snapShot: [],
  loading: false,
  success: false,
  error: false,
  history: [],
  historyLoading: false,
  errorMessage: '',
  searchText: '',
  selectedAutoSuggestValue: {},
  autoSuggestData: [],
  suggestionsLoading: false,
  userdeepdivecomparisondata: {},
  hrApprovalDeepdiveData: [],
  hrAdminReject: {},
  hrAdminApprove: {},
  deepDiveLoading: false,
  isApproveSuccess: false,
  isRejectSuccess: false,
  isFailed: false,
  documentData: null,
  loadingChanges: false,
  hrApprovalAutoSuggestValue:{},
  hrApprovalAutoSuggestData:[],
  hrApprovalSuggestLoading: false
};

export const hrApprovalDatagridInitialState = {
  pageNo: 0,
  rowsPerPage: 20,
};

const hrApprovalDatagridStateHandler = createSlice({
  name: 'HRAPPROVALDATAGRID',
  initialState: hrApprovalDatagridInitialState,
  reducers: {
    setHrApprovalsLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
    // setFeedbackDeleteResponse: (prevState, action) => {
    //   return {
    //     ...prevState,
    //     deleteFeedbackResponse: true,
    //   };
    // },
    // setAddFeedbackResponse: (prevState, action) => {
    //   return {
    //     ...prevState,
    //     addFeedbackResponse: action?.payload,
    //   };
    // },
    // setSelectedFeedback: (prevState, action) => {
    //   return {
    //     ...prevState,
    //     selectedFeedback: action?.payload,
    //   };
    // },
  },
});
export const fetchHrAminRejectactionCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/HR_ADMIN_REJECTION',
);
export const fetchHrAminRejectactionCreatorTypeName =
  fetchHrAminRejectactionCreator(null).type;

export const fetchHrAminApproveCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/HR_ADMIN_APPROVE',
);
export const fetchHrAminApproveCreatorTypeName =
  fetchHrAminApproveCreator(null).type;

export const fetchHrApprovalsCreator = createAction(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_HRAPPROVALS',
);
export const fetchHrApprovalsCreatorTypeName = fetchHrApprovalsCreator().type;

export const fetchHrApprovalsSuggestionsCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_HRAPPROVAL_SUGGESTIONS',
);
export const fetchHrApprovalsSuggestionsCreatorTypeName =
  fetchHrApprovalsSuggestionsCreator(null).type;

export const fetchHrApprovalsHistoryCreator = createAction<any>(
  'EMPLOYEE_SPECIFIC_UTILITIES/FETCH_HRAPPROVAL_HISTORY',
);
export const fetchHrApprovalsHistoryCreatorTypeName =
  fetchHrApprovalsHistoryCreator(null).type;

export const fetchHrPreviewCreator = createAction<any>('HR_PREVIEW');
export const fetchHrPreviewCreatorTypeName = fetchHrPreviewCreator(null).type;

export const fetchHrRejectCreator = createAction<any>('HR_REJECT');
export const fetchHrRejectTypeName = fetchHrRejectCreator(null).type;

export const fetchHrChangesCreator = createAction<any>('HR_CHANGES');
export const fetchHrChangesTypeName = fetchHrChangesCreator(null).type;

export const fetchHrAutoSuggestCreator = createAction<any>('HR_CHANGES');
export const fetchHrAutoSuggestTypeName = fetchHrAutoSuggestCreator(null).type;

const hrApprovalStateHandler = createSlice({
  name: 'HRAPPROVALS',
  initialState: hrApprovalInitialState,
  reducers: {
    setHrApprovalLoading: (prevState, action) => {
      return {
        ...prevState,
        loading: action?.payload,
      };
    },
    setChangesLoading: (prevState, action) => {
      return {
        ...prevState,
        loadingChanges: action?.payload,
      };
    },
    setHrApprovalSuccess: (prevState, action) => {
      return {
        ...prevState,
        success: action?.payload,
      };
    },
    setHrApprovals: (prevState, action) => {
      return {
        ...prevState,
        data: action?.payload,
      };
    },

    setTotalCount: (prevState, action) => {
      return {
        ...prevState,
        totalCount: action.payload,
      };
    },
    setHrApprovalsSnapshot: (prevState, action) => {
      return {
        ...prevState,
        snapShot: action?.payload,
      };
    },
    setHrApprovalsError: (prevState, action) => {
      return {
        ...prevState,
        error: true,
        errorMessage: action?.payload,
      };
    },
    clearHrApprovalsError: prevState => {
      return {
        ...prevState,
        error: false,
        errorMessage: '',
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action?.payload,
      };
    },
    setSelectedAutoSuggest: (prevState, action) => {
      return {
        ...prevState,
        selectedAutoSuggestValue: action?.payload,
      };
    },
    setAutoSuggestLoading: (prevState, action) => {
      return {
        ...prevState,
        suggestionsLoading: action?.payload,
      };
    },
    setAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        autoSuggestData: action?.payload,
      };
    },
    setHistory: (prevState, action) => {
      return {
        ...prevState,
        history: action?.payload,
      };
    },
    setHistoryLoading: (prevState, action) => {
      return {
        ...prevState,
        historyLoading: action?.payload,
      };
    },
    setDocumentData: (prevState, action) => {
      return {
        ...prevState,
        documentData: action?.payload,
      };
    },
    setHrApprovalDeepdiveLoading: (prevState, action) => {
      return {
        ...prevState,
        deepDiveLoading: action?.payload,
      };
    },
    setUserDeepdiveComparisonData: (prevState, action) => {
      return {
        ...prevState,
        userdeepdivecomparisondata: action?.payload,
      };
    },
    setHrApprovalDeepdiveData: (prevState, action) => {
      return {
        ...prevState,
        hrApprovalDeepdiveData: action?.payload,
      };
    },
    setHrAdminRejection: (prevState, action) => {
      return {
        ...prevState,
        hrAdminReject: action?.payload,
      };
    },
    setHrAdminApprove: (prevState, action) => {
      return {
        ...prevState,
        hrAdminApprove: action?.payload,
      };
    },
    setHrAdminApproveSuccess: (prevState, action) => {
      return {
        ...prevState,
        isApproveSuccess: action?.payload,
      };
    },
    setHrAdminRejectSuccess: (prevState, action) => {
      return {
        ...prevState,
        isRejectSuccess: action?.payload,
      };
    },
    setHrAdminApprOrRejectFail: (prevState, action) => {
      return {
        ...prevState,
        isFailed: action?.payload,
      };
    },
    setHrApprovalSuggestionsLoading: (prevState, action) => {
      return {
        ...prevState,
        hrApprovalSuggestLoading: action?.payload,
      };
    },
    setHrApprovalAutoSuggestData: (prevState, action) => {
      return {
        ...prevState,
        getHrApprovalAutoSuggestData: action?.payload,
      };
    },
    setHrApprovalAutoSuggestValue: (prevState, action) => {
      return {
        ...prevState,
        hrApprovalAutoSuggestValue: action?.payload,
      };
    },
    setHrApprovalLoadingPerPage: (prevState, action) => {
      return {
        ...prevState,
        pageNo: action.payload.pageNo,
        rowsPerPage: action.payload.perPage,
      };
    },
  },
});

export const hrApprovalStateActions = hrApprovalStateHandler.actions;
export const hrApprovalStateReducer = hrApprovalStateHandler.reducer;
export const hrApprovalDataGridStateActions =
  hrApprovalDatagridStateHandler.actions;
export const hrApprovalDataGridReducer = hrApprovalDatagridStateHandler.reducer;
