import React, {useState, useCallback, useEffect} from 'react';
import Excel from 'exceljs';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import {Label} from '@app/components/elements/Label';
import excelLogo from '@app/assets/images/excelLogo.png';
import {useStyles} from '../../helpers';
import {cdcCandidatesStateSelector} from '../../pages/cdc-candidates/redux/selectors';
import {
  cdcCandidatesStateActions,
  fetchCdcCandidatesCreator,
  fetchSendEmailCreator,
  uploadCDCCandidatespopUpTableData,
} from '../../pages/cdc-candidates/redux/slice';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {appSelector} from '@app/store/selectors';

export const fitToColumnExcelJsAoA = (
  worksheet: any,
  data: any[][],
  header: string[],
  divisor?: any,
) => {
  if (!data || data.length === 0 || !data[0]) {
    return;
  }

  const columnCount = header.length;

  for (let columnIndex = 1; columnIndex <= columnCount; columnIndex++) {
    const maxColumnWidth = Math.max(
      header[columnIndex - 1].length * 10,
      ...data.map(row =>
        row[columnIndex - 1] ? row[columnIndex - 1].toString().length * 10 : 0,
      ),
    );

    worksheet.getColumn(columnIndex).width = divisor
      ? maxColumnWidth / divisor
      : maxColumnWidth / 7;
  }
};

export const UtilEmail: any = {
  handleButton: null,
  uploadingTheExcelSheet: null,
  validateEmail: null,
  errorStatus: null,
  resetStatus: null,
  validateEmailOnBlur: null,
  handleNextButtonClick: null,
  emailSubjectOrBody: null,
  helperTextMsgs: null,
  downloadExcel: null,
};
interface EmailSendFuncPopupProps {
  open: boolean;
  onClose: () => void;
}

const EmailSendFuncPopup: React.FC<EmailSendFuncPopupProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sendEmailtoCDCPopupModalPage, setSendEmailtoCDCPopupModalPage] =
    useState(0);

  const [onChangeValuesInCC, setOnChangeValuesInCC] = useState<string>('');
  const [onChangeValuesInTo, setOnChangeValuesInTo] = useState<string>('');
  const [onChangeValuesInBCC, setOnChangeValuesInBCC] = useState<string>('');
  const [toErrorMsg, setToErrorMsg] = useState<string>('');
  const [toError, setToError] = useState<boolean>(false);
  const [ccErrorMsg, setCcErrorMsg] = useState<string>('');
  const [ccError, setCcError] = useState<boolean>(false);
  const [bccErrorMsg, setBccErrorMsg] = useState<string>('');
  const [bccError, setBccError] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<any>([]);

  const [loaderShowingOnSuccess, setLoaderShowingOnSuccess] =
    useState<boolean>(false);

  useEffect(() => {
    emailTemplate?.to?.length !== 0 && isSendButtonDisabled(false);
  }, []);

  useEffect(() => {
    setOnChangeValuesInCC(emailTemplate?.cc);
    setOnChangeValuesInTo(emailTemplate?.to);
    setOnChangeValuesInBCC(emailTemplate?.bcc);
  }, []);

  const AdminInfo = useSelector(appSelector.getUserInfo());
  const PreExceldata = useSelector(
    cdcCandidatesStateSelector.getPreExceldata(),
  );
  const FinalPreExceldata = useSelector(
    cdcCandidatesStateSelector.getFinalPreExceldata(),
  );
  const excelLoading = useSelector(
    cdcCandidatesStateSelector.getUploadedExcelLoading(),
  );
  const emailTemplate = useSelector(
    cdcCandidatesStateSelector.getCdcEmailTemplateData(),
  );

  const UploadedExcelPath = useSelector(
    cdcCandidatesStateSelector.getUploadedExcelPath(),
  );

  const SendCdcEmailSuccess = useSelector(
    cdcCandidatesStateSelector.getSendCdcEmailSuccess(),
  );

  const [emailBody, setEmailBody] = useState<string>(
    emailTemplate?.content || ' ',
  );
  const [emailSubject, setEmailSubject] = useState<string>(
    emailTemplate?.subject || ' ',
  );

  const handleSuccessActions = useCallback(() => {
    if (SendCdcEmailSuccess) {
      dispatch(cdcCandidatesStateActions.resetPreExcelData([]));
      dispatch(cdcCandidatesStateActions.resetFinalPreExcelData([]));
      dispatch(fetchCdcCandidatesCreator());
      dispatch(cdcCandidatesStateActions.setSendCdcEmailSuccess(false));
      setLoaderShowingOnSuccess(false);
      onClose();
    }
  }, [SendCdcEmailSuccess]);
  console.log('SendCdcEmailSuccess', SendCdcEmailSuccess);

  useEffect(() => {
    handleSuccessActions();
  }, [SendCdcEmailSuccess]);

  const handleButton = () => {
    const sentToDevCenterDetails: any = [];

    PreExceldata.forEach((item: any) => {
      sentToDevCenterDetails.push({
        managerFeedbackDetailId: item.managerFeddbackDetailsId,
        sentToDevCenter: item.developmentCenterId === 1,
      });
    });
    const payload = {
      id: 0,
      templateId: emailTemplate?.id,
      from: null,
      to: onChangeValuesInTo,
      cc: onChangeValuesInCC,
      bcc: onChangeValuesInBCC,
      subject: emailSubjectOrBody(
        emailSubject !== ' ' ? emailSubject : emailTemplate?.subject,
      )?.replace(/\n/g, '<br/>'),
      content: emailSubjectOrBody(
        emailBody !== ' ' ? emailBody : emailTemplate?.content,
      ).replace(/\n/g, '<br/>'),
      attachment: UploadedExcelPath,
      sentToDevCenterDetails: sentToDevCenterDetails,
    };
    dispatch(fetchSendEmailCreator(payload));
    setLoaderShowingOnSuccess(true);
    handleSuccessActions();
  };

  const getExcelWorkBook = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('CDCList');
    let header = Object.keys(excelData[0]);
    let arrayOfArraysData = excelData.map((obj: any) => Object.values(obj));
    worksheet.addRow(header);
    let currentRowIdx = worksheet.rowCount;
    header.forEach((columnName, colIdx) => {
      worksheet.getCell(currentRowIdx, colIdx + 1).border = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'},
      };
      worksheet.getCell(currentRowIdx, colIdx + 1).font = {
        bold: true,
        size: 12,
      };
      worksheet.getCell(currentRowIdx, colIdx + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '538DD5'},
      };
    });
    arrayOfArraysData.forEach((rowData: any) => {
      worksheet.addRow(rowData);
      currentRowIdx = worksheet.rowCount;

      // Apply styles to columns with headers
      rowData.forEach((cellValue: any, colIdx: number) => {
        if (header[colIdx]) {
          console.log('header[colIdx]', header[colIdx]);
          worksheet.getCell(currentRowIdx, colIdx + 1).border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'},
          };
          worksheet.getCell(currentRowIdx, colIdx + 1).font = {size: 11};
          if (header[colIdx] == 'Employee 360 Link') {
            worksheet.getCell(currentRowIdx, colIdx + 1).style = {
              font: {color: {argb: '0000FF'}, underline: true, size: 11},
            };
            worksheet.getCell(currentRowIdx, colIdx + 1).border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'},
            };
          }
        }
      });
    });
    fitToColumnExcelJsAoA(worksheet, arrayOfArraysData, header);
    return workbook;
  };

  const uploadingTheExcelSheet = () => {
    const excelData1 = FinalPreExceldata.map((row: any) => {
      let newRow = {
        'Employee Name': row?.employeeName,
        'Manager Rating': row?.managerrating,
        'Current Designation ': row?.currentDesignation,
        'Employee 360 Link': 'link',
      };
      return newRow;
    });
    setExcelData(excelData1);
  };

  useEffect(() => {
    if (excelData.length > 0) {
      const workbook = getExcelWorkBook();
      dispatch(uploadCDCCandidatespopUpTableData(workbook));
    }
  }, [excelData]);

  const downloadExcel = () => {
    const workbook = getExcelWorkBook();
    workbook.xlsx
      .writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'CDCList.xlsx';

        // Trigger the download
        downloadLink.click();

        // Clean up
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch(error => {
        console.error(error);
      });
  };
  const sendEmailtoCDCPopupColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name Of Employee',
      width: 250,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'left',
      renderCell: ({row}: GridCellParams<any>) => {
        return row.employeeName;
      },
    },
    {
      field: 'designation',
      headerName: 'Current Designation',
      width: 210,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'left',
      renderCell: ({row}: GridCellParams<any>) => {
        return row.currentDesignation;
      },
    },
    {
      field: 'managerrating',
      headerName: 'Manager Rating',
      width: 156,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'link',
      headerName: 'Employee 360 Link',
      width: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'left',

      renderCell: ({row}: GridCellParams<any>) => {
        return (
          <a href="">
            {/* <Button variant="text" size="medium" color="primary"> */}
            Link
            {/* </Button> */}
          </a>
        );
      },
    },
  ];

  const handleNextButtonClick = () => {
    setSendEmailtoCDCPopupModalPage(1);
    uploadingTheExcelSheet();
  };

  const emailSubjectOrBody = (email: string) => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', {month: 'long'});
    const currentYear = currentDate.getFullYear().toString();

    return email
      .replace(/#month/g, currentMonth)
      .replace(/#year/g, currentYear)
      .replace(/#adminname/g, AdminInfo?.name || '');
  };

  const validateEmail = (emails: string, type: string) => {
    let emailsString = emails;
    let emaiList = emailsString?.split(',');
    const emailRegex = /^[a-zA-Z0-9._-]+@smarterp.com$/;
    console.log('emaiList', emaiList);
    let nonValidEmails = emaiList?.filter(res => {
      return !res?.trim().match(emailRegex);
    });
    validateEmailOnBlur(nonValidEmails, type);
  };

  const errorStatus = (nonValidEmails: any[], type: string) => {
    let nonValidEmailsMsg = nonValidEmails.join(',');
    switch (type) {
      case 'To':
        setToErrorMsg(nonValidEmailsMsg);
        setToError(true);
        break;
      case 'CC':
        setCcErrorMsg(nonValidEmailsMsg);
        setCcError(true);
        break;
      case 'BCC':
        setBccErrorMsg(nonValidEmailsMsg);
        setBccError(true);
        break;
    }
  };

  const resetStatus = (type: string) => {
    switch (type) {
      case 'To':
        setToErrorMsg('');
        setToError(false);
        break;
      case 'CC':
        setCcErrorMsg('');
        setCcError(false);
        break;
      case 'BCC':
        setBccErrorMsg('');
        setBccError(false);
        break;
    }
  };

  const validateEmailOnBlur = (nonValidEmails: any, type: string) => {
    if (nonValidEmails?.length > 0) {
      errorStatus(nonValidEmails, type);
    } else {
      resetStatus(type);
    }
  };

  const getRowIdHandler = useCallback(() => Math.random().toString(), []);

  const isSendButtonDisabled = (params?: boolean) => {
    return (
      toError ||
      bccError ||
      ccError ||
      emailTemplate?.content?.length === 0 ||
      emailTemplate?.subject?.length === 0 ||
      onChangeValuesInTo?.length === 0 ||
      params
    );
  };

  const firstPage = () => (
    <>
      <DialogContent
        sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'rgba(221, 232, 236, 1)',
          },
        }}>
        <DataGrid
          rows={FinalPreExceldata}
          columns={sendEmailtoCDCPopupColumns}
          getRowId={getRowIdHandler}
          initialState={{pagination: {pageSize: 10}}}
          headerHeight={30}
          sx={{
            height: 450,
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleNextButtonClick}>
          Next
        </Button>
      </DialogActions>
    </>
  );

  const helperTextMsgs = (type: string) => {
    switch (type) {
      case 'To':
        return toErrorMsg?.length > 0
          ? toErrorMsg?.split(',')?.length > 1
            ? ' are not  valid emails'
            : ' is not a valid email'
          : '';
        break;

      case 'CC':
        return ccErrorMsg?.length > 0
          ? ccErrorMsg.split(',')?.length > 1
            ? ' are not  valid emails'
            : ' is not a valid email'
          : '';
        break;

      case 'BCC':
        return bccErrorMsg?.length > 0
          ? bccErrorMsg?.split(',')?.length > 1
            ? ' are not  valid emails'
            : ' is not a valid email'
          : '';
        break;
    }
  };

  const secondPage = () => (
    <>
      <DialogContent>
        <TextField
          id="standard-basic"
          variant="standard"
          defaultValue={emailTemplate.to}
          helperText={toErrorMsg + helperTextMsgs('To')}
          error={toError}
          onFocus={e => {
            setOnChangeValuesInTo(e.target.value.replace(/\s/g, ''));
          }}
          onChange={e => {
            setOnChangeValuesInTo(e.target.value.replace(/\s/g, ''));
          }}
          onBlur={() => {
            String(onChangeValuesInTo).length > 0
              ? validateEmail(onChangeValuesInTo, 'To')
              : resetStatus('To');
          }}
          InputProps={{
            style: {
              fontStyle: 'Verdana',
              fontSize: '14px',
              color: '#070e46',
            },
            onKeyDown: event => {
              if (event.key === ' ') {
                event.preventDefault();
              }
            },
            startAdornment: (
              <InputAdornment position="start">
                <Button variant="outlined" disabled sx={{marginBottom: '5px'}}>
                  To
                </Button>
              </InputAdornment>
            ),
          }}
          autoFocus
          required
          margin="dense"
          type="email"
          fullWidth
        />
        <TextField
          id="standard-basic"
          variant="standard"
          defaultValue={emailTemplate.cc}
          helperText={ccErrorMsg + helperTextMsgs('CC')}
          error={ccError}
          onFocus={e => {
            setOnChangeValuesInCC(e.target.value.replace(/\s/g, ''));
          }}
          onChange={e => {
            setOnChangeValuesInCC(e.target.value.replace(/\s/g, ''));
          }}
          onBlur={() => {
            String(onChangeValuesInCC).length > 0
              ? validateEmail(onChangeValuesInCC, 'CC')
              : resetStatus('CC');
          }}
          InputProps={{
            onKeyDown: event => {
              if (event.key === ' ') {
                event.preventDefault();
              }
            },
            style: {
              fontStyle: 'Verdana',
              fontSize: '14px',
              color: '#070e46',
            },
            startAdornment: (
              <InputAdornment position="start">
                <Button
                  variant="outlined"
                  disabled
                  sx={{
                    flexDirection: 'row-reverse',
                    display: 'flex',
                    marginBottom: '5px',
                  }}>
                  CC
                </Button>
              </InputAdornment>
            ),
          }}
          autoFocus
          required
          margin="dense"
          type="email"
          fullWidth
        />
        <TextField
          id="standard-basic"
          variant="standard"
          defaultValue={emailTemplate.bcc}
          helperText={bccErrorMsg + helperTextMsgs('BCC')}
          error={bccError}
          onFocus={e => {
            setOnChangeValuesInBCC(e.target.value.replace(/\s/g, ''));
          }}
          onChange={e => {
            setOnChangeValuesInBCC(e.target.value.replace(/\s/g, ''));
          }}
          onBlur={() => {
            String(onChangeValuesInBCC).length > 0
              ? validateEmail(onChangeValuesInBCC, 'BCC')
              : resetStatus('BCC');
          }}
          InputProps={{
            onKeyDown: event => {
              if (event.key === ' ') {
                event.preventDefault();
              }
            },
            style: {
              fontStyle: 'Verdana',
              fontSize: '14px',
              color: '#070e46',
            },
            startAdornment: (
              <InputAdornment position="start">
                <Button variant="outlined" disabled sx={{marginBottom: '5px'}}>
                  BCC
                </Button>
              </InputAdornment>
            ),
          }}
          autoFocus
          required
          margin="dense"
          fullWidth
        />

        <TextField
          id="standard-basic"
          variant="standard"
          defaultValue={emailSubjectOrBody(emailTemplate.subject)}
          onChange={e => {
            setEmailSubject(e.target.value);
          }}
          InputProps={{
            style: {
              fontStyle: 'Verdana',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#070e46',
            },
            startAdornment: (
              <InputAdornment position="start">Subject:</InputAdornment>
            ),
          }}
          autoFocus
          required
          margin="dense"
          fullWidth
        />

        <Paper
          elevation={0}
          sx={{
            height: 270,
            background: 'white',
            marginTop: '20px',
          }}>
          <Button variant="outlined" onClick={downloadExcel}>
            <img
              src={excelLogo}
              alt="Logo"
              className={classes.downloadExcelLogo}
            />
            <Label
              title={emailSubjectOrBody(emailTemplate.subject)}
              variant="h6"
              textColor={'#322b7c'}
              fontWeight={'800'}
              hideToolTip
            />
          </Button>
          <TextField
            style={{
              height: 150,
              background: 'white',
              marginTop: '20px',
              borderRadius: '0px',
              display: 'flex',
              border: '0px ',
              fontSize: '1px',
            }}
            InputProps={{
              style: {
                fontStyle: 'Verdana',
                fontSize: '14px',
                color: '#070e46',
              },
            }}
            rows={12}
            id="outlined-textarea"
            defaultValue={emailSubjectOrBody(emailTemplate?.content)?.replace(
              /<br\/>/g,
              '\n',
            )}
            multiline
            onChange={e => {
              setEmailBody(e.target.value);
            }}
            fullWidth></TextField>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleButton}
          disabled={isSendButtonDisabled()}>
          Send
        </Button>
      </DialogActions>
    </>
  );

  const renderCDCPopupPages = () => {
    {
      if (sendEmailtoCDCPopupModalPage === 0) {
        return firstPage();
      } else {
        return secondPage();
      }
    }
  };
  UtilEmail.handleButton = handleButton;
  UtilEmail.uploadingTheExcelSheet = uploadingTheExcelSheet;
  UtilEmail.validateEmail = validateEmail;
  UtilEmail.errorStatus = errorStatus;
  UtilEmail.resetStatus = resetStatus;
  UtilEmail.validateEmailOnBlur = validateEmailOnBlur;
  UtilEmail.handleNextButtonClick = handleNextButtonClick;
  UtilEmail.emailSubjectOrBody = emailSubjectOrBody;
  UtilEmail.helperTextMsgs = helperTextMsgs;
  UtilEmail.downloadExcel = downloadExcel;
  return (
    <Dialog
      open={open}
      sx={{justifyContent: 'middle'}}
      fullWidth
      maxWidth={'md'}
      onClose={onClose}>
      <DialogTitle>Send email to CDC Team</DialogTitle>
      <Box
        sx={{
          margin: 2,
          border: 0.5,
          borderColor: '#E0E0E0',
          height: '560px',
        }}>
        {excelLoading || loaderShowingOnSuccess ? (
          <div
            style={{
              height: '500px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <CircularProgress />
          </div>
        ) : (
          renderCDCPopupPages()
        )}
      </Box>
    </Dialog>
  );
};
export default EmailSendFuncPopup;
