import {Button, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {ErrorTableStyles as styles} from './errorTableStyles';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {fcDashboardActions} from '../../redux-api';
import {useStyles as tableStyle} from '@app/screens/zho/components/grid-table/styles';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {strings} from '@app/common/strings';

export const ErrorTableBlock = ({handleToggleModal, blockedData}: any) => {
  const tableStyles = tableStyle();

  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    {
      field: 'divisionName',
      headerName: strings.division,
      headerClassName: 'boldHeader',
      width: 150,
      sortable: false,
      valueGetter: params => params.row.divisionName || '---',
      //   cellClassName: classes.noBorder,
    },
    {
      field: 'productName',
      headerName: strings.product,
      headerClassName: 'boldHeader',
      width: 200,
      sortable: false,
      valueGetter: params => params.row.productName || '---',
    },
    {
      field: 'candFId',
      headerName: strings.zho.candf,
      headerClassName: 'boldHeader',
      width: 100,
      sortable: false,
      valueGetter: params => params.row.candFId || '---',
    },
    {
      field: 'hqName',
      headerName: strings.hq,
      headerClassName: 'boldHeader',
      width: 130,
      sortable: false,
      valueGetter: params => params.row.hqName || '---',
    },
    {
      field: 'stockistName',
      headerName: strings.zho.customer,
      headerClassName: 'boldHeader',
      width: 180,
      sortable: false,
      valueGetter: params => params.row.stockistName || '---',
    },
    {
      field: 'partyName',
      headerName: strings.zho.chemist,
      headerClassName: 'boldHeader',
      width: 180,
      sortable: false,
      valueGetter: params => params.row.partyName || '---',
    },
  ];

  return (
    <>
      <Box
        sx={[
          styles.modalSection,
          styles.modalHeader,
          {display: 'flex', gap: 2, flexWrap: 'wrap'},
        ]}>
        <Typography sx={{fontSize: '12.7px'}}>
          {strings.zho.fcAdmin.updateLimit.successfulInserts}{' '}
          {blockedData?.successfulInsertCount}
        </Typography>
        {blockedData?.existingCombinations?.length > 0 && (
          <Typography sx={{fontSize: '12.7px'}}>
            {blockedData?.existingCombinations?.length === 1
              ? strings.zho.fcAdmin.manageProductStatus.blockingMessageSingle
              : strings.zho.fcAdmin.manageProductStatus.blockingMessageMultiple}
          </Typography>
        )}
      </Box>
      <Box sx={[styles.modalSection, {padding: '0px'}]}>
        {blockedData?.existingCombinations?.length > 0 ? (
          <Box className={tableStyles.container} sx={{padding: '0 0 0 10px'}}>
            <DataGrid
              sx={[styles.errorTable]}
              rows={blockedData?.existingCombinations}
              disableSelectionOnClick
              columns={columns}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
              getRowId={row => row.productId || Math.random()}
              rowsPerPageOptions={[10, 20, 50, 100]}
            />
          </Box>
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              padding: '20px',
              fontWeight: 400,
              fontSize: '12.7px',
            }}>
            All combinations of products blocked successfully
          </Typography>
        )}
      </Box>
      <Box sx={[styles.modalSection, styles.modalFooter]}>
        <Button
          data-testid="close-errorTable"
          variant="contained"
          sx={{fontSize: '12.7px', textTransform: 'capitalize'}}
          onClick={handleToggleModal}>
          {strings.zho.close}
        </Button>
      </Box>
    </>
  );
};
