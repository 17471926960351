import {createSelector} from '@reduxjs/toolkit';

export const listOfEmployeesStateSelector = {
  getListOfEmployeesState: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees],
      adminusers => adminusers,
    );
  },
  getListOfEmployees: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.listOfEmployees],
      adminusers => adminusers,
    );
  },
  getTotalCount: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.totalCount],
      totalCount => totalCount,
    ); 
  },
  getListOfEmployeesPageNo: () => {  
    return createSelector(
      [(state: any) => state.listOfEmployees.pageNo],
      listOfEmployees => listOfEmployees,
    ); 
  },
  getListOfEmployeesPerPage: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.rowsPerPage],
      listOfEmployees => listOfEmployees,
    );
  },
 
  getListOfEmployeesLoading: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.listOfEmployeesLoading],
      loading => loading,
    );
  },
  getListOfEmployeesError: () => {
    return createSelector([(state: any) => state.listOfEmployees], error => ({
      error: error?.listOfEmployeesError,
      message: error?.listOfEmployeesErrorMessage,
    }));
  },
  getDivisionDropDown: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.divisionDropDown],
      divisionDropDown => divisionDropDown,
    );
  },
  getStateDropDown: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.stateDropDown],
      stateDropDown => stateDropDown,
    );
  },
  getStaffHoldDropDown: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.staffHoldDropDown],
      staffHoldDropDown => staffHoldDropDown,
    );
  },
  getCompanyAssetDropDown: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.companyAssetDropDown],
      companyAssetDropDown => companyAssetDropDown,
    );
  },
  getPromotionDropDown: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.promotionDropDown],
      promotionDropDown => promotionDropDown,
    );
  },
  getDesignationDropDown: () => {
    return createSelector(
      [(state: any) => state.listOfEmployees.designationDropDown],
      designationDropDown => designationDropDown,
    );
  },
};
