import {createSelector} from '@reduxjs/toolkit';

export const hrApprovalStateSelector = {
  getHrApprovals: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.data],
      data => data,
    );
  },
  getHrApprovalsState: () => {
    return createSelector([(state: any) => state.hrApprovals], data => data);
  },
  getHrApprovalsSnapshot: () => {
    return createSelector(
      [(state: any) => state.feedback.snapShot],
      data => data,
    );
  },
  getHrApprovalsLoading: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.loading],
      loading => loading,
    );
  },
  getHrChangesLoading: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.loadingChanges],
      loading => loading,
    );
  },
  getHrApprovalsTotalCount: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.totalCount],
      feedback => feedback,
    );
  },
  getHrApprovalsError: () => {
    return createSelector([(state: any) => state.hrApprovals], error => ({
      error: error?.hrApprovalError,
      message: error?.hrApprovalsMessage,
    }));
  },
  getHistory: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.history],
      data => data,
    );
  },
  getHistoryLoading: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.historyLoading],
      data => data,
    );
  },
  getDocumentData: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.documentData],
      data => data,
    );
  },

  // getDeletedFeedbackResponse: () => {
  //   return createSelector(
  //     [(state: any) => state.feedback],
  //     feedback => feedback,
  //   );
  // },
  getHrApprovalDeepdiveData: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.historyLoading],
      data => data,
    );
  },
  getUserDeepdiveComparisonData: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.userdeepdivecomparisondata],
      data => data,
    );
  },
  getHrApprovalSuggestionsLoading: () => {
    return createSelector(
      [(state: any) => state.staffHold.hrApprovalSuggestLoading],
      loading => loading,
    );
  },
  getHrApprovalAutoSuggestData: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.hrApprovalAutoSuggestData],
      data => data,
    );
  },
  getHrApprovalAutoSuggestValue: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.hrApprovalAutoSuggestValue],
      data => data,
    );
  },
  getHrAdminRejection: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.hrAminReject],
      data => data,
    );
  },
  getHrAdminApprove: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.hrAdminApprove],
      data => data,
    );
  },
  getHrAdminRejectSuccess: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.isRejectSuccess],
      data => data,
    );
  },
  getHrAdminApproveSuccess: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.isApproveSuccess],
      data => data,
    );
  },
  getHrAdminApprOrRejectFail: () => {
    return createSelector(
      [(state: any) => state.hrApprovals.isFail],
      data => data,
    );
  },
};

export const getHrApprovalsDataGridSeletor = {
  getHrApprovalsPageNo: () => {
    return createSelector(
      [(state: any) => state.hrApprovalsDataGrid.pageNo],
      data => data,
    );
  },

  getHrApprovalsPerPage: () => {
    return createSelector(
      [(state: any) => state.hrApprovalsDataGrid.rowsPerPage],
      data => data,
    );
  },
  // getAddFeedbackResponse: () => {
  //   return createSelector(
  //     [(state: any) => state.feedbackDataGrid],
  //     feedback => feedback,
  //   );
  // },
};
