import moment from 'moment';
import {userManagementStateActions} from '@app/screens/access-management/pages/user-management/redux';
import {userManagementStateSelector} from '@app/screens/access-management/pages/user-management/redux/selectors';
import {fetchAdminUsersCreatorTypeName} from '@app/screens/access-management/pages/user-management/redux/slice';
import {API_PATH} from '@app/common/constants';
import {componentManagementStateActions} from '@app/screens/component-management/components/redux';
import {ComponentManagementSelector} from '@app/screens/component-management/components/redux/selectors';
import {ConfigurationManagementSelector} from '@app/screens/configuration-management/components/redux/seletors';

import {
  configurationManagementStateActions,
  createProRataMiscExpenseCreator,
  fetchExpenseTypeDateGridCreator,
  fetchExpenseTypeSettingGridCreator,
  fetchExpenseTypeSettingGridCreatorTypeName,
} from '@app/screens/configuration-management/components/redux/slice';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {
  expenseDAMasterStateActions,
  fetchExpenseDAMasterGridCreator,
  fetchExpenseDAMasterGridCreatorTypeName,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {expenseTAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-ta-master/redux/selectors';
import {
  expenseTAMasterStateActions,
  fetchExpenseTAMasterGridCreator,
  fetchExpenseTAMasterGridCreatorTypeName,
} from '@app/screens/master-data-management/pages/expense-ta-master/redux/slice';
import {generateReportStateActions} from '@app/screens/module-specific-utilities/pages/generateReport/redux';
import {generateReportStateSelector} from '@app/screens/module-specific-utilities/pages/generateReport/redux/selectors';
import {fetchGenerateReportCreatorTypeName} from '@app/screens/module-specific-utilities/pages/generateReport/redux/slice';
import {
  attachMTPObjectToFilters,
  createFilterObject,
} from '@app/helper/plan-and-meet';
import {select} from 'redux-saga/effects';
import {NetworkService} from '@app/services';
import {takeLatest, put, call, delay} from '@redux-saga/core/effects';

import {
  applyFilterCreatorTypeName,
  filterStateActions,
  resetDefaultFilterCreatorTypeName,
  setDefaultFilterCreatorTypeName,
} from './filterHandler';
import {filterSelector} from './filterHandlerSelector';
import {
  planAndMeetSelector,
  planAndMeetStatusStateActions,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import {cdcCandidatesStateSelector} from '@app/screens/employee-specific-utilities/pages/cdc-candidates/redux/selectors';
import {cdcCandidatesStateActions} from '@app/screens/employee-specific-utilities/pages/cdc-candidates/redux/slice';
import {companyAssetsStateSelector} from '@app/screens/employee-specific-utilities/pages/company-assets/redux/selector';
import {companyAssetsStateActions} from '@app/screens/employee-specific-utilities/pages/company-assets/redux/slice';
import {listOfEmployeesStateSelector} from '@app/screens/employee-specific-utilities/pages/list-of-employees/redux/selectors';
import {listOfEmployeesStateActions} from '@app/screens/employee-specific-utilities/pages/list-of-employees/redux/slice';
import {recentUpdatesStateSelector} from '@app/screens/employee-specific-utilities/pages/recent-updates/redux/selector';
import {selectedEmployeeStateSelector} from '@app/screens/employee-specific-utilities/pages/selected-employees/redux/selectors';
import {selectedEmployeesStateActions} from '@app/screens/employee-specific-utilities/pages/selected-employees/redux/slice';
import {staffHoldStateSelector} from '@app/screens/employee-specific-utilities/pages/staff-hold/redux/selectors';
import {
  fetchStaffHoldCreator,
  staffHoldStateActions,
  fetchStaffHoldFilterCreator,
} from '@app/screens/employee-specific-utilities/pages/staff-hold/redux/slice';
import {
  fetchCdcCandidatesCreator,
  fetchCdcCandidatesFilterCreator,
} from '@app/screens/employee-specific-utilities/pages/cdc-candidates/redux/slice';
import {appSelector} from '@app/store/selectors';
import {recentUpdatesStateActions} from '@app/screens/employee-specific-utilities/pages/recent-updates/redux';
import {feedbacksStateSelector} from '@app/screens/employee-specific-utilities/pages/feedback/redux/selectors';
import {feedbacksStateActions} from '@app/screens/employee-specific-utilities/pages/feedback/redux';
import {
  feedbackDataGridStateActions,
  fetchFeedbacksCreator,
} from '@app/screens/employee-specific-utilities/pages/feedback/redux/slice';
import {welcomeKitStateSelector} from '@app/screens/employee-specific-utilities/pages/welcome-kit/redux/selector';
import {welcomeKitStateActions} from '@app/screens/employee-specific-utilities/pages/welcome-kit/redux';
import {routeSelector} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {hrApprovalStateActions} from '@app/screens/employee-specific-utilities/pages/hr-approval/redux';
import {fetchHrApprovalsCreator} from '@app/screens/employee-specific-utilities/pages/hr-approval/redux/slice';

const getMonthIndex = (month: string) => {
  const date = new Date(`${month} 1, 2000`);
  return date.getMonth() + 1;
};

const fetchAllUsersFlag = (states: any, divisions: any, accessGroups: any) => {
  let fetchAllUsers = true;
  if (!accessGroups?.completeApplied) {
    Object.keys(accessGroups?.options || []).forEach(key => {
      if (accessGroups?.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (!divisions?.completeApplied) {
    Object.keys(divisions?.options || []).forEach(key => {
      if (divisions?.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (!states?.completeApplied) {
    Object.keys(states?.options || []).forEach(key => {
      if (states?.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (
    states?.completeApplied ||
    divisions?.completeApplied ||
    accessGroups?.completeApplied
  ) {
    fetchAllUsers = false;
  }

  return fetchAllUsers;
};
const fetchAllSelectedEmployeesFlag = (divisions: any, states: any) => {
  let fetchAllSelectedEmployees = true;
  if (!divisions?.completeApplied) {
    Object.keys(divisions?.options || []).forEach(key => {
      if (divisions?.options?.[key]) {
        fetchAllSelectedEmployees = false;
      }
    });
  }
  if (!states?.completeApplied) {
    Object.keys(states?.options || []).forEach(key => {
      if (states?.options[key]) {
        fetchAllSelectedEmployees = false;
      }
    });
  }
  return fetchAllSelectedEmployees;
};

const fetchAllSalaryTableFlag = (divisions: any, states: any) => {
  let fetchAllUsers = true;
  if (!divisions?.completeApplied) {
    Object.keys(divisions?.options || []).forEach(key => {
      if (divisions?.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (!states?.completeApplied) {
    Object.keys(states?.options || []).forEach(key => {
      if (states?.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (states?.completeApplied || divisions?.completeApplied) {
    fetchAllUsers = false;
  }

  return fetchAllUsers;
};

const fetchAllFlag = (
  division: any,
  designation: any,
  hq: any,
  year?: any,
  month?: any,
) => {
  let fetchAll = true;
  if (!division.completeApplied) {
    Object.keys(division.options).forEach(key => {
      if (division.options[key]) {
        fetchAll = false;
      }
    });
  }
  if (!designation.completeApplied) {
    Object.keys(designation.options).forEach(key => {
      if (designation.options[key]) {
        fetchAll = false;
      }
    });
  }
  if (!hq.completeApplied) {
    Object.keys(hq.options).forEach(key => {
      if (hq.options[key]) {
        fetchAll = false;
      }
    });
  }
  if (year) {
    if (!year?.completeApplied) {
      Object.keys(year.options).forEach(key => {
        if (year.options[key]) {
          fetchAll = false;
        }
      });
    }
  }

  if (month) {
    if (!month?.completeApplied) {
      Object.keys(month.options).forEach(key => {
        if (month.options[key]) {
          fetchAll = false;
        }
      });
    }
  }
  if (
    designation.completeApplied ||
    division.completeApplied ||
    hq.completeApplied ||
    year?.completeApplied ||
    month?.completeApplied
  ) {
    fetchAll = false;
  }

  return fetchAll;
};

const isValidAppliedExpenseCategory = (
  appliedExpenseCategory: any,
  Data: any,
) => {
  let isValid1 = true;
  if (appliedExpenseCategory.length > 0) {
    isValid1 = false;
    appliedExpenseCategory?.forEach((expenseCategory: any) => {
      if (Data?.expenseCategory === expenseCategory) {
        isValid1 = true;
      }
    });
  }
  return isValid1;
};
const isValidAppliedAccessGroups = (appliedAccessGroups: any, user: any) => {
  let isValid1 = true;
  if (appliedAccessGroups.length > 0) {
    isValid1 = false;
    appliedAccessGroups?.forEach((accessGroup: any) => {
      user?.accessGroup?.forEach((accessGroupInternal: any) => {
        if (accessGroupInternal.accessGroupName === accessGroup) {
          isValid1 = true;
        }
      });
    });
  }
  return isValid1;
};
const isValidAppliedStates = (appliedStates: any, user: any) => {
  let isValid3 = true;
  if (appliedStates.length > 0) {
    isValid3 = false;
    appliedStates?.forEach((state: any) => {
      user?.state?.forEach((stateInternal: any) => {
        if (stateInternal.name === state) {
          isValid3 = true;
        }
      });
    });
  }
  return isValid3;
};
const isValidAppliedDivisions = (appliedDivisions: any, user: any) => {
  let isValid2 = true;
  if (appliedDivisions.length > 0) {
    isValid2 = false;
    appliedDivisions?.forEach((division: any) => {
      user?.division?.forEach((divisionInternal: any) => {
        if (divisionInternal.name === division) {
          isValid2 = true;
        }
      });
    });
  }
  return isValid2;
};
const isValidAppliedDivision = (appliedDivision: any, expenseMAster: any) => {
  let isValid2 = true;
  if (appliedDivision.length > 0) {
    isValid2 = false;
    appliedDivision?.forEach((division: any) => {
      if (
        expenseMAster?.division !== undefined &&
        expenseMAster?.division === division
      ) {
        isValid2 = true;
      } else if (expenseMAster?.divisionName === division) {
        isValid2 = true;
      }
    });
  }
  return isValid2;
};
const isValidAppliedDesignation = (
  appliedDesignation: any,
  expenseMAster: any,
) => {
  let isValid1 = true;
  if (appliedDesignation.length > 0) {
    isValid1 = false;
    appliedDesignation?.forEach((designation: any) => {
      if (
        expenseMAster?.designation !== undefined &&
        expenseMAster?.designation === designation
      ) {
        isValid1 = true;
      } else if (expenseMAster?.designationName === designation) {
        isValid1 = true;
      } else if (expenseMAster?.staffDesignationName === designation) {
        isValid1 = true;
      }
    });
  }
  return isValid1;
};
const isValidAppliedhq = (appliedhq: any, expenseMaster: any) => {
  let isValid3 = true;
  if (appliedhq.length > 0) {
    isValid3 = false;
    appliedhq?.forEach((hq: any) => {
      if (expenseMaster?.hq !== undefined && expenseMaster?.hq === hq) {
        isValid3 = true;
      } else if (expenseMaster?.hqName === hq) {
        isValid3 = true;
      }
    });
  }
  return isValid3;
};
const isValidAppliedYear = (appliedYear: any, expenseMaster: any) => {
  let isValid2 = true;
  if (appliedYear.length > 0) {
    isValid2 = false;
    appliedYear?.forEach((year: any) => {
      if (
        expenseMaster?.year !== undefined &&
        expenseMaster?.expenseYear === parseInt(year)
      ) {
        isValid2 = true;
      } else if (expenseMaster?.expenseYear === parseInt(year)) {
        isValid2 = true;
      }
    });
  }
  return isValid2;
};
const isValidAppliedMonth = (appliedMonth: any, expenseMaster: any) => {
  let isValid2 = true;
  if (appliedMonth.length > 0) {
    isValid2 = false;
    appliedMonth?.forEach((month: any) => {
      if (
        expenseMaster?.month !== undefined &&
        expenseMaster?.expenseMonth === getMonthIndex(month)
      ) {
        isValid2 = true;
      } else if (expenseMaster?.expenseMonth === getMonthIndex(month)) {
        isValid2 = true;
      }
    });
  }
  return isValid2;
};

const isValidExpenseType = ({
  expenseType,
  appliedExpenseCategory,
}: {
  expenseType: any;
  appliedExpenseCategory: any;
}) => {
  const isValid1 = isValidAppliedExpenseCategory(
    appliedExpenseCategory,
    expenseType,
  );
  return isValid1;
};

const isValidUser = ({
  user,
  appliedAccessGroups,
  appliedDivisions,
  appliedStates,
}: {
  user: any;
  appliedAccessGroups: any;
  appliedDivisions: any;
  appliedStates: any;
}) => {
  const isValid1 = isValidAppliedAccessGroups(appliedAccessGroups, user);
  const isValid2 = isValidAppliedDivisions(appliedDivisions, user);
  const isValid3 = isValidAppliedStates(appliedStates, user);
  return isValid1 && isValid2 && isValid3;
};
const isValidMaster = ({
  Master,
  appliedDivision,
  appliedDesignation,
  appliedhq,
  appliedYear,
  appliedMonth,
}: {
  Master: any;
  appliedDivision: any;
  appliedDesignation: any;
  appliedhq: any;
  appliedYear?: any;
  appliedMonth?: any;
}) => {
  const isValid1 = isValidAppliedDivision(appliedDivision, Master);
  const isValid2 = isValidAppliedDesignation(appliedDesignation, Master);
  const isValid3 = isValidAppliedhq(appliedhq, Master);
  const isValid4 = isValidAppliedYear(appliedYear, Master);
  const isValid5 = isValidAppliedMonth(appliedMonth, Master);
  return isValid1 && isValid2 && isValid3 && isValid4 && isValid5;
};

const getfilteredUsers = ({
  adminUsers,
  isUserActive,
  appliedAccessGroups,
  appliedDivisions,
  appliedStates,
}: {
  adminUsers: any;
  isUserActive: any;
  appliedAccessGroups: any;
  appliedDivisions: any;
  appliedStates: any;
}) => {
  return adminUsers
    .filter((user: any) => {
      if (isUserActive && !user.isActive) {
        return false;
      }
      if (!isUserActive && user.isActive) {
        return false;
      }
      return true;
    })
    .filter((user: any) => {
      return isValidUser({
        user,
        appliedAccessGroups,
        appliedDivisions,
        appliedStates,
      });
    });
};

const getfilteredExpenseType = ({
  expenseType,
  isUserActive,
  appliedExpenseCategory,
}: {
  expenseType: any;
  isUserActive: any;
  appliedExpenseCategory: any;
}) => {
  return expenseType
    .filter((expenseType: any) => {
      if (isUserActive && !expenseType.isActive) {
        return false;
      }
      if (!isUserActive && expenseType.isActive) {
        return false;
      }
      return true;
    })
    .filter((expenseType: any) => {
      return isValidExpenseType({
        expenseType,
        appliedExpenseCategory,
      });
    });
};
const getfilteredExpense = ({
  gridData,
  isActiveStatus,
  appliedDivision,
  appliedDesignation,
  appliedhq,
}: {
  gridData: any;
  isActiveStatus: any;
  appliedDesignation: any;
  appliedDivision: any;
  appliedhq: any;
}) => {
  return gridData
    .filter((Master: any) => {
      if (isActiveStatus && !Master.isActive) {
        return false;
      }
      if (!isActiveStatus && Master.isActive) {
        return false;
      }
      return true;
    })
    .filter((Master: any) => {
      return isValidMaster({
        Master,
        appliedDivision,
        appliedDesignation,
        appliedhq,
      });
    });
};
const getfilteredGenerateReport = ({
  gridData,
  appliedDivision,
  appliedDesignation,
  appliedhq,
  appliedYear,
  appliedMonth,
}: {
  gridData: any;
  appliedDesignation: any;
  appliedDivision: any;
  appliedhq: any;
  appliedYear: any;
  appliedMonth: any;
}) => {
  return gridData.filter((Master: any) => {
    return isValidMaster({
      Master,
      appliedDivision,
      appliedDesignation,
      appliedhq,
      appliedYear,
      appliedMonth,
    });
  });
};

/**
 * Employee Details Watcher
 */
export function* applyFilterWatcher() {
  yield takeLatest(applyFilterCreatorTypeName, applyFilterWatcherWorker);
}
export function* applySetDefaultFilterWatcher() {
  yield takeLatest(
    setDefaultFilterCreatorTypeName,
    setDefaultFilterWatcherWorker,
  );
  yield takeLatest(
    resetDefaultFilterCreatorTypeName,
    resetDefaultFilterWatcherWorker,
  );
}
/**
 * set default filters
 */
export function* setDefaultFilterWatcherWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const filters = yield select(filterSelector.getFilterState());
    const request = createFilterObject(filters, obj?.filters);
    const updatedRequest = attachMTPObjectToFilters(request, filters);
    const url = API_PATH.planAndMeetStatus.defaultfilters;
    yield put(filterStateActions.setFilterLoader(true));
    const response = yield call(NetworkService.post, url, updatedRequest);
    yield put(
      planAndMeetStatusStateActions.setPreferredFilters(updatedRequest),
    );
    yield put(
      filterStateActions.setDefaultFilterResponse({
        ...response,
        actionType: action.payload,
      }),
    );
    yield put(filterStateActions.setFilterLoader(false));
  } catch (error) {
    yield put(filterStateActions.setFilterLoader(false));
  }
}
/**
 * Employee Details Worker
 */
// export function* applyFilterWatcherWorker(): any {
//   const filterState = yield select(filterSelector.getFilterState());
//   const componentName = yield select(routeSelector.getNavbarComponentName());
//   const stateByAdminData = yield select(appSelector.getStateDropDown());
//   const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
//   const request = yield select(filterSelector.getFilterOptions());
//   const states = filterState?.States;
//   const divisions = filterState?.Division;
//   const accessGroups = filterState['Access Groups'];
//   const companyAssets = filterState['Company Assets'];
//   const welcomeKits = filterState['Welcome Kit'];
//   const staffHolds = filterState['Staff Hold'];
//   const managersRatings = filterState["Manager's Rating"];
//   const promotions = filterState.Promotion;
//   const designations = filterState['Designation Change'];
//   const fetchAllSelectedEmployees = fetchAllSelectedEmployeesFlag(
//     divisions,
//     states,
//   );
//   const fetchAllSalaryTable = fetchAllSalaryTableFlag(divisions, states);
//   const fetchAllUsers = fetchAllUsersFlag(states, divisions, accessGroups);
//   const fetchAllListOfEmployees = fetchAllListOfEmployeesFlag(
//     divisions,
//     states,
//     companyAssets,
//     welcomeKits,
//     staffHolds,
//     managersRatings,
//     promotions,
//     designations,
//   );

//   if (filterState.States && filterState.Divisions && filterState['Access Groups']) {
//     yield put(userManagementStateActions.setAdminUsersLoading(true));
//     const states = filterState.States;
//     const divisions = filterState.Divisions;
//     const accessGroups = filterState['Access Groups'];
//     const fetchAllUsers = fetchAllUsersFlag(states, divisions, accessGroups);
//     if (fetchAllUsers) {
//       yield put({ type: fetchAdminUsersCreatorTypeName, payload: {} });
//     } else {
//       const adminUsers = yield select(
//         userManagementStateSelector.getAdminUsersSnaShot(),
//       );
//       const isUserActive = yield select(
//         userManagementStateSelector.getIsActiveState(),
//       );
//       const appliedAccessGroups = Object.keys(accessGroups.options).filter(
//         key => accessGroups.options[key],
//       );
//       const appliedDivisions = Object.keys(divisions.options).filter(
//         key => divisions.options[key],
//       );
//       const appliedStates = Object.keys(states.options).filter(
//         key => states.options[key],
//       );

//       const filteredUsers = getfilteredUsers({
//         adminUsers,
//         isUserActive,
//         appliedAccessGroups,
//         appliedDivisions,
//         appliedStates,
//       });
//       yield put(userManagementStateActions.setAdminUsers(filteredUsers));
//     }

//     yield put(userManagementStateActions.setAdminUsersLoading(false));
//   }

// }
/**
 * set default filters
 */
export function* applyFilterWatcherWorker(): any {
  yield put(userManagementStateActions.setAdminUsersLoading(true));
  const filterState = yield select(filterSelector.getFilterState());
  const componentName = yield select(routeSelector.getNavbarComponentName());
  const stateByAdminData = yield select(appSelector.getStateDropDown());
  const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
  const request = yield select(filterSelector.getFilterOptions());
  const states = filterState.States;
  const divisions = filterState.Division;
  const accessGroups = filterState['Access Groups'];
  const companyAssets = filterState['Company Assets'];
  const welcomeKits = filterState['Welcome Kit'];
  const staffHolds = filterState['Staff Hold'];
  const managersRatings = filterState["Manager's Rating"];
  const promotions = filterState.Promotion;
  const designations = filterState['Designation Change'];

  const division = filterState?.Division;
  const year = filterState.Year;
  const month = filterState.Month;
  const designation = filterState?.Designation;
  const HQsDropdown = yield select(
    expenseDAMasterStateSelector.getHQDropdown(),
  );
  const divisionsDropDown = yield select(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const designationsDropdown = yield select(
    expenseDAMasterStateSelector.getDesignationsDropdown(),
  );
  const navbarComponentHeading = yield select(
    routeSelector.getNavbarComponentHeading(),
  );

  const hq = filterState?.HQ;
  const ComponentExpenseDAMaster = yield select(
    expenseDAMasterStateSelector.getIsExpenseDaMaster(),
  );
  const isGenerateReport = yield select(
    generateReportStateSelector.getIsGenerateReport(),
  );
  const IsExpenseTaMaster = yield select(
    expenseTAMasterStateSelector.getIsExpenseTaMaster(),
  );
  const currentPageExpenseDate = yield select(
    ConfigurationManagementSelector.getconfigurationManagementPage(),
  );
  const isActiveStatus = yield select(
    expenseDAMasterStateSelector.getIsActiveState(),
  );
  const fetchAllSelectedEmployees = fetchAllSelectedEmployeesFlag(
    divisions,
    states,
  );
  const fetchAllSalaryTable = fetchAllSalaryTableFlag(divisions, states);
  const fetchAllUsers = fetchAllUsersFlag(states, divisions, accessGroups);
  const fetchAllListOfEmployees = fetchAllListOfEmployeesFlag(
    divisions,
    states,
    companyAssets,
    welcomeKits,
    staffHolds,
    managersRatings,
    promotions,
    designations,
  );

  if (componentName === navbarComponentName.MasterDataManagement) {
    if (ComponentExpenseDAMaster) {
      const fetchAll = fetchAllFlag(division, designation, hq);

      if (fetchAll) {
        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds: [],
            designationIds: [],
            hqIds: [],
          }),
        );
        yield put(fetchExpenseDAMasterGridCreator(null));
      } else {
        const appliedDivision = Object.keys(division.options).filter(
          key => division.options[key],
        );

        const appliedDesignation = Object.keys(designation.options).filter(
          key => designation.options[key],
        );
        const appliedhq =
          Object.keys(hq?.options).filter(key => hq?.options[key]) ?? [];

        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds:
              getFilter(divisionsDropDown, appliedDivision, 'label', 'value') ??
              [],
            designationIds:
              getFilter(
                designationsDropdown,
                appliedDesignation,
                'label',
                'value',
              ) ?? [],
            hqIds:
              getFilter(
                HQsDropdown,
                appliedhq,
                'headQuaterName',
                'headQuaterId',
              ) ?? [],
          }),
        );
        yield put(fetchExpenseDAMasterGridCreator(request));
      }
    }
    if (IsExpenseTaMaster) {
      const fetchAll = fetchAllFlag(division, designation, hq);
      if (fetchAll) {
        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds: [],
            designationIds: [],
            hqIds: [],
          }),
        );
        yield put(fetchExpenseTAMasterGridCreator(null));
      } else {
        const appliedDivision = Object.keys(division.options).filter(
          key => division.options[key],
        );

        const appliedDesignation = Object.keys(designation.options).filter(
          key => designation.options[key],
        );
        const appliedhq =
          Object.keys(hq?.options).filter(key => hq?.options[key]) ?? [];
        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds:
              getFilter(divisionsDropDown, appliedDivision, 'label', 'value') ??
              [],
            designationIds:
              getFilter(
                designationsDropdown,
                appliedDesignation,
                'label',
                'value',
              ) ?? [],
            hqIds:
              getFilter(
                HQsDropdown,
                appliedhq,
                'headQuaterName',
                'headQuaterId',
              ) ?? [],
          }),
        );
        yield put(fetchExpenseTAMasterGridCreator(request));
      }
    }
  }

  if (componentName === navbarComponentName.ConfigurationManagement) {
    const fetchAll = fetchAllFlag(division, designation, hq);
    if (navbarComponentHeading?.props?.children === 'Pro Rata Misc. Expense') {
      if (fetchAll) {
        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds: [],
            designationIds: [],
            hqIds: [],
          }),
        );
        yield put(createProRataMiscExpenseCreator(null));
      } else {
        const appliedDivision = Object.keys(division.options).filter(
          key => division.options[key],
        );

        const appliedDesignation = Object.keys(designation.options).filter(
          key => designation.options[key],
        );
        const appliedhq =
          Object.keys(hq?.options).filter(key => hq?.options[key]) ?? [];

        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds:
              getFilter(divisionsDropDown, appliedDivision, 'label', 'value') ??
              [],
            designationIds:
              getFilter(
                designationsDropdown,
                appliedDesignation,
                'label',
                'value',
              ) ?? [],
            hqIds:
              getFilter(
                HQsDropdown,
                appliedhq,
                'headQuaterName',
                'headQuaterId',
              ) ?? [],
          }),
        );
        yield put(createProRataMiscExpenseCreator(null));
      }
    }
    if (navbarComponentHeading?.props?.children === 'Expense Type Settings') {
      if (fetchAll) {
        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds: [],
            designationIds: [],
            hqIds: [],
          }),
        );
        yield put(fetchExpenseTypeSettingGridCreator(null));
      } else {
        const appliedDivision = Object.keys(division.options).filter(
          key => division.options[key],
        );

        const appliedDesignation = Object.keys(designation.options).filter(
          key => designation.options[key],
        );
        const appliedhq =
          Object.keys(hq?.options).filter(key => hq?.options[key]) ?? [];

        yield put(
          filterStateActions.setFilterOptions({
            ...request,
            divisionIds:
              getFilter(divisionsDropDown, appliedDivision, 'label', 'value') ??
              [],
            designationIds:
              getFilter(
                designationsDropdown,
                appliedDesignation,
                'label',
                'value',
              ) ?? [],
            hqIds:
              getFilter(
                HQsDropdown,
                appliedhq,
                'headQuaterName',
                'headQuaterId',
              ) ?? [],
          }),
        );
        yield put(fetchExpenseTypeSettingGridCreator(null));
      }
    }
  }

  if (
    filterState.States &&
    filterState.Divisions &&
    filterState['Access Groups']
  ) {
    yield put(userManagementStateActions.setAdminUsersLoading(true));

    yield delay(300);

    const states = filterState.States;
    const divisions = filterState.Divisions;
    const accessGroups = filterState['Access Groups'];
    const fetchAllUsers = fetchAllUsersFlag(states, divisions, accessGroups);
    if (fetchAllUsers) {
      yield put({type: fetchAdminUsersCreatorTypeName, payload: {}});
    } else {
      const adminUsers = yield select(
        userManagementStateSelector.getAdminUsersSnaShot(),
      );
      const isUserActive = yield select(
        userManagementStateSelector.getIsActiveState(),
      );
      const appliedAccessGroups = Object.keys(accessGroups.options).filter(
        key => accessGroups.options[key],
      );
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states.options).filter(
        key => states.options[key],
      );
      const filteredUsers = getfilteredUsers({
        adminUsers,
        isUserActive,
        appliedAccessGroups,
        appliedDivisions,
        appliedStates,
      });
      yield put(userManagementStateActions.setAdminUsers(filteredUsers));
    }

    yield put(userManagementStateActions.setAdminUsersLoading(false));
  }
  if (isGenerateReport) {
    yield put(generateReportStateActions.setGenerateReportLoading(true));
    yield delay(300);
    const fetchAll = fetchAllFlag(division, designation, hq, year, month);
    if (fetchAll) {
      yield put({type: fetchGenerateReportCreatorTypeName, payload: {}});
    } else {
      const gridData = yield select(
        generateReportStateSelector.getGenerateReportSnaShot(),
      );
      const appliedDivision = Object.keys(division.options).filter(
        key => division.options[key],
      );
      const appliedDesignation = Object.keys(designation.options).filter(
        key => designation.options[key],
      );
      const appliedhq = Object.keys(hq.options).filter(key => hq.options[key]);
      const appliedYear = Object.keys(year.options).filter(
        key => year.options[key],
      );
      const appliedMonth = Object.keys(month.options).filter(
        key => month.options[key],
      );
      const filtered = getfilteredGenerateReport({
        gridData,
        appliedDivision,
        appliedDesignation,
        appliedhq,
        appliedYear,
        appliedMonth,
      });
      yield put(generateReportStateActions.setGenerateReport(filtered));
    }
    yield put(generateReportStateActions.setGenerateReportLoading(false));
  }

  if (componentName === navbarComponentName.ComponentManagement) {
    yield put(componentManagementStateActions.setExpenseTypeLoading(true));
    yield delay(300);
    const expenseCategory = filterState.ExpenseCategory;
    const isUserActive = yield select(
      ComponentManagementSelector.getIsActiveState(),
    );
    const expenseType = yield select(
      ComponentManagementSelector.getExpenseTypeSnaShot(),
    );
    const appliedExpenseCategory = Object.keys(expenseCategory.options).filter(
      key => expenseCategory.options[key],
    );

    const filteredExpenseType = getfilteredExpenseType({
      expenseType,
      isUserActive,
      appliedExpenseCategory,
    });
    yield put(
      componentManagementStateActions.setExpenseType(filteredExpenseType),
    );
    yield put(componentManagementStateActions.setExpenseTypeLoading(false));
  }

  if (componentName === navbarComponentName.selectedEmployees) {
    const fetchAllSelectedEmployees = fetchAllSelectedEmployeesFlag(
      divisions,
      states,
    );
    if (fetchAllSelectedEmployees) {
      const selectedEmployeesSnapshot = yield select(
        selectedEmployeeStateSelector.getSelectedEmployeesSnapshot(),
      );
      yield put(
        selectedEmployeesStateActions.setSelectedEmployees(
          selectedEmployeesSnapshot,
        ),
      );
    } else {
      const selectedEmployeesSnapshot = yield select(
        selectedEmployeeStateSelector.getSelectedEmployeesSnapshot(),
      );
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states?.options || {}).filter(
        key => states.options[key],
      );
      const filteredSelectedEmployees = getFilteredSalaryTable({
        snapshot: selectedEmployeesSnapshot,
        appliedDivisions,
        appliedStates,
      });
      yield put(
        selectedEmployeesStateActions.setSelectedEmployees(
          filteredSelectedEmployees,
        ),
      );
    }
  }

  if (componentName === navbarComponentName.staffHold) {
    const appliedDivisions = Object.keys(divisions.options).filter(
      key => divisions.options[key],
    );
    const appliedStates = Object.keys(states?.options || {}).filter(
      key => states.options[key],
    );
    yield put(
      filterStateActions.setFilterOptions({
        ...request,
        divisionIds:
          getFilter(divisionByAdminData, appliedDivisions, 'label', 'value') ??
          [],
        stateIds:
          getFilter(stateByAdminData, appliedStates, 'label', 'value') ?? [],
      }),
    );
    yield put(
      staffHoldStateActions.setStaffHoldLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    yield put(filterStateActions.setFilterApplied(true));
    yield put(fetchStaffHoldCreator());
  }

  if (componentName === navbarComponentName.hrApproval) {
    const appliedDivisions = Object.keys(divisions.options).filter(
      key => divisions.options[key],
    );
    const appliedStates = Object.keys(states?.options || {}).filter(
      key => states.options[key],
    );
    yield put(
      filterStateActions.setFilterOptions({
        ...request,
        divisionIds:
          getFilter(divisionByAdminData, appliedDivisions, 'label', 'value') ??
          [],
        stateIds:
          getFilter(stateByAdminData, appliedStates, 'label', 'value') ?? [],
      }),
    );
    yield put(
      hrApprovalStateActions.setHrApprovalLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    yield put(filterStateActions.setFilterApplied(true));
    yield put(fetchHrApprovalsCreator());
  }

  if (componentName === navbarComponentName.welcomeKit) {
    const fetchAllSelectedEmployees = fetchAllSelectedEmployeesFlag(
      divisions,
      states,
    );
    if (fetchAllSelectedEmployees) {
      const {snapshot} = yield select(welcomeKitStateSelector.getWelcomeKit());
      yield put(welcomeKitStateActions.setWelcomeKit(snapshot));
    } else {
      const {snapshot} = yield select(welcomeKitStateSelector.getWelcomeKit());
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states?.options || {}).filter(
        key => states.options[key],
      );
      yield put(
        filterStateActions.setFilterOptions({
          ...request,
          divisionIds:
            getFilter(
              divisionByAdminData,
              appliedDivisions,
              'label',
              'value',
            ) ?? [],
          stateIds:
            getFilter(stateByAdminData, appliedStates, 'label', 'value') ?? [],
          roleIds: [0],
        }),
      );
      const filteredWelcomeKit = getFilteredSalaryTable({
        snapshot,
        appliedDivisions,
        appliedStates,
      });
      yield put(welcomeKitStateActions.setWelcomeKit(filteredWelcomeKit));
    }
  }

  if (componentName === navbarComponentName.cdcCandidates) {
    const appliedDivisions = Object.keys(divisions.options).filter(
      key => divisions.options[key],
    );
    const appliedStates = Object.keys(states?.options || {}).filter(
      key => states.options[key],
    );
    yield put(
      filterStateActions.setFilterOptions({
        ...request,
        divisionIds:
          getFilter(divisionByAdminData, appliedDivisions, 'label', 'value') ??
          [],
        stateIds:
          getFilter(stateByAdminData, appliedStates, 'label', 'value') ?? [],
      }),
    );
    yield put(
      cdcCandidatesStateActions.setCdcCandidatesLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    yield put(filterStateActions.setFilterApplied(true));
    yield put(fetchCdcCandidatesCreator());
  }

  if (componentName === navbarComponentName.feedback) {
    const appliedDivisions = Object.keys(divisions.options).filter(
      key => divisions.options[key],
    );
    const appliedStates = Object.keys(states.options).filter(
      key => states.options[key],
    );
    yield put(
      filterStateActions.setFilterOptions({
        ...request,
        divisionIds:
          getFilter(divisionByAdminData, appliedDivisions, 'label', 'value') ??
          [],
        stateIds:
          getFilter(stateByAdminData, appliedStates, 'label', 'value') ?? [],
      }),
    );
    yield put(
      feedbackDataGridStateActions.setFeedbacksLoadingPerPage({
        pageNo: 0,
        perPage: 20,
      }),
    );
    yield put(filterStateActions.setFilterApplied(true));
    yield put(fetchFeedbacksCreator());
  }

  if (componentName === navbarComponentName.companyAssets) {
    const fetchAllSalaryTable = fetchAllSalaryTableFlag(divisions, states);
    if (fetchAllSalaryTable) {
      const {snapshot} = yield select(
        companyAssetsStateSelector.getCompanyAssets(),
      );
      yield put(companyAssetsStateActions.setCompanyAssets(snapshot));
    } else {
      const {snapshot} = yield select(
        companyAssetsStateSelector.getCompanyAssets(),
      );
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states.options).filter(
        key => states.options[key],
      );
      const filteredCompanyAssets = getFilteredSalaryTable({
        snapshot,
        appliedDivisions,
        appliedStates,
      });
      yield put(
        companyAssetsStateActions.setCompanyAssets(filteredCompanyAssets),
      );
    }
  }

  if (componentName === navbarComponentName.recentUpdates) {
    const fetchAllSalaryTable = fetchAllSalaryTableFlag(divisions, states);
    if (fetchAllSalaryTable) {
      const {snapShot} = yield select(
        recentUpdatesStateSelector.getRecentUpdatesState(),
      );
      yield put(recentUpdatesStateActions.setRecentUpdates(snapShot));
    } else {
      const {snapShot} = yield select(
        recentUpdatesStateSelector.getRecentUpdatesState(),
      );
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states.options).filter(
        key => states.options[key],
      );
      const filteredRecentUpdates = getFilteredSalaryTable({
        snapshot: snapShot,
        appliedDivisions,
        appliedStates,
      });
      yield put(
        recentUpdatesStateActions.setRecentUpdates(filteredRecentUpdates),
      );
    }
  }

  if (componentName === navbarComponentName.listOfEmployees) {
    const fetchAllListOfEmployees = fetchAllListOfEmployeesFlag(
      divisions,
      states,
      companyAssets,
      welcomeKits,
      staffHolds,
      managersRatings,
      promotions,
      designations,
    );
    if (fetchAllListOfEmployees) {
      const {listOfEmployeesSnapshot} = yield select(
        listOfEmployeesStateSelector.getListOfEmployeesState(),
      );
      yield put(
        listOfEmployeesStateActions.setListOfEmployees(listOfEmployeesSnapshot),
      );
    } else {
      const {listOfEmployeesSnapshot: snapshot} = yield select(
        listOfEmployeesStateSelector.getListOfEmployeesState(),
      );
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states.options).filter(
        key => states.options[key],
      );
      const appliedCompanyAssets = Object.keys(
        companyAssets?.options || {},
      ).filter(key => companyAssets.options[key]);
      const appliedWelcomeKits = Object.keys(welcomeKits.options).filter(
        key => welcomeKits.options[key],
      );
      const appliedStaffHolds = Object.keys(staffHolds.options).filter(
        key => staffHolds.options[key],
      );
      const appliedManagersRatings = Object.keys(
        managersRatings.options,
      ).filter(key => managersRatings.options[key]);
      const appliedPromotions = Object.keys(promotions.options).filter(
        key => promotions.options[key],
      );
      const appliedDesignations = Object.keys(designations.options).filter(
        key => designations.options[key],
      );

      const filteredEmployees = getFilteredListOfEmployees({
        snapshot,
        appliedDivisions,
        appliedStates,
        appliedCompanyAssets,
        appliedWelcomeKits,
        appliedStaffHolds,
        appliedManagersRatings,
        appliedPromotions,
        appliedDesignations,
      });
      yield put(
        listOfEmployeesStateActions.setListOfEmployees(filteredEmployees),
      );
    }
  }

  yield put(userManagementStateActions.setAdminUsersLoading(false));
}
/**
 * reset default filters
 */
export function* resetDefaultFilterWatcherWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.resetFilters;
    yield put(filterStateActions.setFilterLoader(true));
    const response = yield call(NetworkService.put, url);
    yield put(
      filterStateActions.setDefaultFilterResponse({
        ...response,
        actionType: action?.payload,
      }),
    );
    yield put(planAndMeetStatusStateActions.setPreferredFilters({}));
    yield put(filterStateActions.setFilterLoader(false));
  } catch (error) {
    yield put(filterStateActions.setFilterLoader(false));
  }
}
export const getFilter = (itemA: any, itemB: any[], label: any, value: any) => {
  let Users = itemA?.filter((item1: any) => {
    return itemB?.find(item2 => {
      return item1?.['' + label + ''] === item2;
    });
  });
  return Users?.map((values: any) => {
    return values?.['' + value + ''];
  });
};

const getFilteredEmployees = ({
  snapshot,
  appliedDivisions,
  appliedStates,
}: {
  snapshot: any;
  appliedDivisions: any;
  appliedStates: any;
}) => {
  return snapshot.filter((emp: any) => {
    return appliedDivisions.includes(emp.division);
  });
};

const getFilteredSalaryTable = ({
  snapshot = [],
  appliedDivisions,
  appliedStates,
}: {
  snapshot: any;
  appliedDivisions: any;
  appliedStates: any;
}) => {
  return snapshot.filter((row: any) => {
    const isValidDivision = isValidAppliedDivisions(appliedDivisions, {
      ...row,
      division: [{name: row?.division}],
    });
    const isValidState = isValidAppliedStates(appliedStates, {
      ...row,
      state: [{name: row?.state}],
    });
    return isValidDivision && isValidState;
  });
};

const isValidRange = (ranges: string[], rating: number) => {
  const checkRange = (range: string) => {
    switch (range) {
      case '1-5':
        return rating >= 1 && rating <= 5;
      case '5-7':
        return rating >= 5 && rating <= 7;
      case '7-8':
        return rating >= 7 && rating <= 8;
      case '8-10':
        return rating >= 8 && rating <= 10;
      default:
        return true;
    }
  };
  let isValidFlag = checkRange(ranges[0]);
  ranges.forEach(range => {
    isValidFlag = isValidFlag || checkRange(range);
  });

  return isValidFlag;
};

const isValidDateRange = (ranges: string[], date: number) => {
  if (!date) return true;
  const checkRange = (range: string) => {
    switch (range) {
      case 'Last 30 days':
        return moment(date).diff(moment.now(), 'days') <= 30;
      case 'Last 60 days':
        return moment(date).diff(moment.now(), 'days') <= 60;
      default:
        return true;
    }
  };
  let isValidFlag = checkRange(ranges[0]);
  ranges.forEach(range => {
    isValidFlag = isValidFlag || checkRange(range);
  });

  return isValidFlag;
};

const isValidBoolean = (selectedOptions: string[], value: boolean) => {
  if (selectedOptions.length === 0) return true;
  return selectedOptions.map(option => option === 'Yes').includes(value);
};

const isValidStaffOption = (options: string[], row: any) => {
  let isValidFlag = true;
  const checkFlag = (value: string) => {
    switch (value) {
      case 'DCR':
        return row?.isDCRDisabled;
      case 'Expense':
        return row?.isExpenseDisabled;
      case 'Incentive':
        return row?.isIncentiveDisabled;
      case 'Input and Sample':
        return row?.isInputAndSamplesDisabled;
      case 'Salary':
        return row?.isSalaryDisabled;
      default:
        return true;
    }
  };
  isValidFlag = checkFlag(options[0]);
  options.forEach(option => {
    isValidFlag = isValidFlag && checkFlag(option);
  });
  return isValidFlag;
};

const isValidSubset = (options: string[], array: any[]): boolean => {
  let isValidFlag = true;
  if (options.length !== 0) {
    isValidFlag = array.some(option => {
      return options.includes(option);
    });
  }
  return isValidFlag;
};

const getFilteredListOfEmployees = ({
  snapshot,
  appliedDivisions,
  appliedStates,
  appliedCompanyAssets,
  appliedStaffHolds,
  appliedWelcomeKits,
  appliedManagersRatings,
  appliedPromotions,
  appliedDesignations,
}: {
  snapshot: any;
  appliedDivisions: any;
  appliedStates: any;
  appliedCompanyAssets: any;
  appliedWelcomeKits: any;
  appliedStaffHolds: any;
  appliedManagersRatings: any;
  appliedPromotions: any;
  appliedDesignations: any;
}) => {
  return snapshot.filter((row: any) => {
    const isValidDivision = isValidAppliedDivisions(appliedDivisions, {
      ...row,
      division: [{name: row?.division}],
    });
    const isValidState =
      appliedStates.length > 0 ? appliedStates.includes(row?.state) : true;
    const isValidPromotion = isValidBoolean(appliedPromotions, row?.isPromoted);
    const isValidDesignation = isValidBoolean(
      appliedDesignations,
      row?.changeInDesignation,
    );
    const isValidManagerRating = isValidRange(
      appliedManagersRatings,
      row?.managerRating,
    );
    const isValidWelcomeKits = isValidDateRange(
      appliedWelcomeKits,
      row?.welcomeKitReceivedDate,
    );
    const isvalidStaffHold = isValidStaffOption(appliedStaffHolds, row);
    const isValidCompanyAssets = isValidSubset(
      appliedCompanyAssets,
      (row?.assignedCompanyAssets || '').split(','),
    );

    return (
      isValidDivision &&
      isValidState &&
      isValidPromotion &&
      isValidDesignation &&
      isValidManagerRating &&
      isValidWelcomeKits &&
      isvalidStaffHold &&
      isValidCompanyAssets
    );
  });
};

const fetchAllListOfEmployeesFlag = (
  divisions: any,
  states: any,
  companyAssets: any,
  welcomeKits: any,
  staffHolds: any,
  managersRatings: any,
  promotions: any,
  designations: any,
) => {
  let fetchAllEmployees = true;
  if (!divisions?.completeApplied) {
    Object.keys(divisions?.options || []).forEach(key => {
      if (divisions?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!states?.completeApplied) {
    Object.keys(states?.options || []).forEach(key => {
      if (states?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!companyAssets?.completeApplied) {
    Object.keys(companyAssets?.options || []).forEach(key => {
      if (companyAssets?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!welcomeKits?.completeApplied) {
    Object.keys(welcomeKits?.options || []).forEach(key => {
      if (welcomeKits?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!staffHolds?.completeApplied) {
    Object.keys(staffHolds?.options || []).forEach(key => {
      if (staffHolds?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!managersRatings?.completeApplied) {
    Object.keys(managersRatings?.options || []).forEach(key => {
      if (managersRatings?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!promotions?.completeApplied) {
    Object.keys(promotions?.options || []).forEach(key => {
      if (promotions?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (!designations?.completeApplied) {
    Object.keys(designations?.options || []).forEach(key => {
      if (designations?.options[key]) {
        fetchAllEmployees = false;
      }
    });
  }
  if (
    states?.completeApplied ||
    divisions?.completeApplied ||
    companyAssets?.completeApplied ||
    welcomeKits?.completeApplied ||
    staffHolds?.completeApplied ||
    managersRatings?.completeApplied ||
    promotions?.completeApplied ||
    designations?.completeApplied
  ) {
    fetchAllEmployees = false;
  }

  return fetchAllEmployees;
};
