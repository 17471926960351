import { API_PATH, HTTP_OK } from '@app/common/constants';
import { authTokenActions } from '@app/screens/generic/Login/redux';
import { ToasterService } from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import { ToastType } from '@app/services/toaster';
import { call, takeLatest, put } from '@redux-saga/core/effects';
import {
  appSliceActions,
  fetchCannotAccessModalUsersCreatorTypeName,
  fetchNotificationsCreatorTypeName,
  fetchUserInfoCreatorTypeName,
  markNotificationTypeName,
  fetchUserAppSettingsCreatorTypeName,
  fetchDivisionAdminDropdownCreatorTypeName,
  fetchStateAdminDropdownCreatorTypeName,
  fetchRolesDropDownDataCreatorTypeName,
  fetchStaffHoldConfigMasterDetailsCreatorTypeName,
} from '../reducers/appSlice';

/**
 * Employee Details Watcher
 */
export function* fetchUserInfoWatcher() {
  yield takeLatest(fetchUserInfoCreatorTypeName, fetchUserInfoWatcherWorker);
}
export function* markNotificationReadWatcher() {
  yield takeLatest(markNotificationTypeName, markNotificationReadWorker);
}
export function* fetchUserAppSettingsWatcher() {
  yield takeLatest(
    fetchUserAppSettingsCreatorTypeName,
    fetchUserAppSettingsWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchUserInfoWatcherWorker(): any {
  try {
    yield put(appSliceActions.setAppLoading(true));
    const url = `${API_PATH.main.userInfo}`;
    const userInfo = yield call(NetworkService.post, url, {});
    if (userInfo.status === HTTP_OK) {
      yield put(
        appSliceActions.setIsEditAccessAvailable(
          (userInfo?.data &&
            userInfo?.data[0] &&
            userInfo?.data[0].isWriteAccess) ||
          false,
        ),
      );
      yield put(
        appSliceActions.setIsBulkAccessAvailable(
          (userInfo?.data &&
            userInfo?.data[0] &&
            userInfo?.data[0].isBulkAccessEnable) ||
            false,
        ),
      );

      yield put(
        appSliceActions.setUserInfo(
          (userInfo?.data && userInfo?.data[0]) || {},
        ),
      );
    } else {
      if (userInfo.status === 400 && userInfo.data === 'Unauthorized user') {
        yield put(authTokenActions.setIsAuthorized(false));
        ToasterService.showToaster('Unauthorized user!', ToastType.ERROR);
      }
    }
    yield put(appSliceActions.setAppLoading(false));
  } catch (error) {
    yield put(appSliceActions.setUserInfoError(true));
    yield put(appSliceActions.setUserInfoErrorMessage(error));
    setTimeout(function* () {
      yield put(appSliceActions.clearError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchCannotAccessModalUsersWatcher() {
  yield takeLatest(
    fetchCannotAccessModalUsersCreatorTypeName,
    fetchCannotAccessModalUsersWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchCannotAccessModalUsersWorker(): any {
  try {
    yield put(appSliceActions.setShowCannotAccessModalLoading(true));
    const url = `${API_PATH.cannotAccess}`;
    const cannotAccessModalUsers = yield call(
      NetworkService.getOpen,
      url,
      {},
      {},
    );
    yield put(
      appSliceActions.setCannotAccessModalUsers(
        cannotAccessModalUsers?.data || [],
      ),
    );
    yield put(appSliceActions.setShowCannotAccessModalLoading(false));
  } catch (error) {
    yield put(appSliceActions.setShowCannotAccessModalLoading(false));
  }
}

/**
 * Notifications Worker
 */
export function* fetchNotificationsWatcher() {
  yield takeLatest(fetchNotificationsCreatorTypeName, fetchNotificationsWorker);
}

/**
 * Notifications Watcher
 */
export function* fetchNotificationsWorker(): any {
  try {
    const url = API_PATH.main.notifications;
    const response = yield call(NetworkService.get, url, {}, {}); // fix this

    yield put(appSliceActions.setNotifications(response.data));
  } catch (error) {
    yield put(appSliceActions.setNotifications([]));
  }
}

export function* markNotificationReadWorker(action: any): any {
  const notificationId = action.payload;
  let url = API_PATH.main.markAsRead;

  try {
    const response = yield call(NetworkService.put, url, [notificationId]);
    if (response?.status === HTTP_OK) {
      yield put(appSliceActions.updateNotificationReadStatus(action.payload));
    }
  } catch (error) {

  }
}
export function* fetchUserAppSettingsWatcherWorker(): any {
  try {
    const url = API_PATH.main.appSettings;
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(appSliceActions.setAppSettings(response.data));
  } catch (error) {
    yield put(appSliceActions.setAppSettings([]));
  }
}


export function* fetchStateDropdownAdminWatcher() {
  yield takeLatest(
    fetchStateAdminDropdownCreatorTypeName,
    fetchStateDropdownAdminWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchStateDropdownAdminWatcherWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.dropdowns.statesbyadmin}`;
    const stateDropdown = yield call(NetworkService.get, url, {}, {});
    let stateIds: number[] = [];
    stateDropdown?.data?.map((item: any, key: any) => {
      stateIds.push(item.value);
      return null;
    });
    let payload = {
      stateDropdownData: stateDropdown.data,
      stateIds: stateIds,
    };
    yield put(appSliceActions.setStateDropDown(payload));
  } catch (error) { }
}

/**
 * Employee Details Watcher
 */
export function* fetchDivisionDropdownAdminWatcher() {
  yield takeLatest(
    fetchDivisionAdminDropdownCreatorTypeName,
    fetchDivisionDropdownAdminWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchDivisionDropdownAdminWatcherWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.dropdowns.divisionbyadmin}`;
    const divisionDropdown = yield call(NetworkService.get, url, {}, {});
    let divisionIds: number[] = [];
    divisionDropdown?.data?.map((item: any, key: any) => {
      divisionIds.push(item.value);
      return null;
    });
    let payload = {
      divisionDropdownData: divisionDropdown.data,
      divisionIds: divisionIds,
    };
    yield put(appSliceActions.setDivisionDropDown(payload));
  } catch (error) { }
}

export function* fetchRolesDataWatcher() {
  yield takeLatest(
    fetchRolesDropDownDataCreatorTypeName,
    fetchRolesDropDownDataWorker,
  );
}

export function* fetchRolesDropDownDataWorker(): any {
  try {
    const url = API_PATH.approvalWorkflow.masterConfigData;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response?.status === HTTP_OK) {
      yield put(appSliceActions.setRolesDropDownData(response.data.roles));
    }
  } catch (error) { }
}

/**
 * StaffHoldConfigMasterDetail Watcher
 */
export function* fetchStaffHoldConfigMasterDetailsWatcher() {
  yield takeLatest(
    fetchStaffHoldConfigMasterDetailsCreatorTypeName,
    fetchStaffHoldConfigMasterDetailsWorker,
  );
}

/**
 * StaffHoldConfigMasterDetail Worker
 */
export function* fetchStaffHoldConfigMasterDetailsWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.StaffHoldConfigMasterDetails.masterDetails}`;
    const staffHoldConfigMasterDetails = yield call(
      NetworkService.get,
      url,
      {},
      {},
    );
    if (staffHoldConfigMasterDetails?.status === HTTP_OK) {
      yield put(
        appSliceActions.setStaffHoldConfigMasterDetails(
          staffHoldConfigMasterDetails?.data,
        ),
      );
    }
  } catch (error) { }
}
