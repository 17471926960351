import {API_PATH, BLOB_FOLDER_NAME} from '@app/common/constants';
import {downloadFile} from '@app/screens/module-specific-utilities/common';
import {Grid} from '@mui/material';
import {FileDownloadBoxTP} from './FileDownloadBoxTP';
import {useStyles} from './styles';
import CustomTextFieldRowTP from '../common-component/CustomTextFieldRowTP';
import {CustomTextFieldTP} from '../common-component/CustomTextFieldTP';

interface DoctorInfoProps {
  doctorInfoData: any;
}

const DoctorInfoForm: React.FC<DoctorInfoProps> = ({doctorInfoData}) => {
  const styleClass = useStyles();
  const downloadCard = () => {
    const blobFolderName: string = BLOB_FOLDER_NAME?.DOCTOR_TO_UNIVERSE;
    const file: string = doctorInfoData?.supportingDocumentPath;
    const url: string = API_PATH?.doctorApproval?.downloadDocument;
    downloadFile(url, file, blobFolderName);
  };
  return (
    <Grid container>
      <CustomTextFieldRowTP
        righTlable={'Name'}
        rightValue={doctorInfoData?.name}
        leftLable={'Gender'}
        leftValue={doctorInfoData?.gender}
      />
      <CustomTextFieldRowTP
        righTlable={'Address'}
        rightValue={doctorInfoData?.address}
        leftLable={'Pincode'}
        leftValue={doctorInfoData?.pinCode}
      />
      <CustomTextFieldRowTP
        righTlable={'City'}
        rightValue={doctorInfoData?.cityName}
        leftLable={'Mobile Number'}
        leftValue={doctorInfoData?.mobileNumber}
      />
      <CustomTextFieldRowTP
        righTlable={'Qualification '}
        rightValue={doctorInfoData?.qualificationName}
        leftLable={'Whatsapp Number'}
        leftValue={doctorInfoData?.whatsappNumber}
      />
      <CustomTextFieldRowTP
        righTlable={'Speciality by Qualification'}
        rightValue={doctorInfoData?.specialisationName}
        leftLable={'Landmark'}
        leftValue={doctorInfoData?.landmark}
      />
      <CustomTextFieldRowTP
        righTlable={'Area Mapped To Doctor'}
        rightValue={doctorInfoData?.areaMappedtoDoctorName}
        leftLable={'Practicing Since'}
        leftValue={
          doctorInfoData?.practicingSince
            ? new Date(doctorInfoData.practicingSince).toLocaleDateString()
            : ''
        }
      />
      <CustomTextFieldRowTP
        righTlable={'Date of Birth'}
        rightValue={
          doctorInfoData?.dateOfBirth
            ? new Date(doctorInfoData.dateOfBirth).toLocaleDateString()
            : ''
        }
        leftLable={'Registration Number'}
        leftValue={doctorInfoData?.registrationNumber}
      />
      <CustomTextFieldRowTP
        righTlable={'Date of Marriage'}
        rightValue={
          doctorInfoData?.dateOfMarriage
            ? new Date(doctorInfoData.dateOfMarriage).toLocaleDateString()
            : ''
        }
        leftLable={'Potential Code of Party'}
        leftValue={doctorInfoData?.potentialCodeOfParty}
      />
      <Grid container className={styleClass.TextFieldRowContainer}>
        <FileDownloadBoxTP
          label={'Upload Supporting Document'}
          fileName={doctorInfoData?.supportingDocumentPath}
          handleDownload={downloadCard}
          blobFolderName={BLOB_FOLDER_NAME?.DOCTOR_TO_UNIVERSE}
          url={API_PATH?.doctorApproval?.downloadDocument}
        />
        <CustomTextFieldTP label={'Remark'} value={doctorInfoData?.remark} />
      </Grid>
    </Grid>
  );
};
export default DoctorInfoForm;
