import {useEffect, useState} from 'react';
import {useSelector, RootStateOrAny, useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {RequestForm} from './request-form';
import {Summary} from './summary';
import {UpdateTotalTradeDiscount} from './update-total-trade-discount';
import {ManageUserRole} from './manage-user-role';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {
  navbarComponentName,
  routeStateActions,
} from '@app/router/redux/routeHandler';
import {setRightSidebarToggle, setScreenNo, SwitchingNo} from './redux/tabName';
import ManageProductStatus from './manage-product-status';
import {HOAdmin} from '../ho-admin';
import {useLocation} from 'react-router-dom';

export const FCAdmin = () => {
  const tab = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );
  const {pathname} = useLocation();
  const dispatch = useDispatch();

  const userLoginInfo = useSelector(
    (state: RootStateOrAny) => state.appState.userInfo,
  );

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.hoAdmin),
    );
    dispatch(setScreenNo(1));
    dispatch(setRightSidebarToggle(false));
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(setScreenNo(0));
      dispatch(SwitchingNo(0));
      dispatch(setRightSidebarToggle(false));
    };
  }, []);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <RequestForm userLoginInfo={userLoginInfo} />;
      case 1:
        return <Summary userLoginInfo={userLoginInfo} />;
      case 2:
        return <UpdateTotalTradeDiscount userLoginInfo={userLoginInfo} />;
      case 3:
        return <ManageUserRole userLoginInfo={userLoginInfo} />;
      case 4:
        return <ManageProductStatus userLoginInfo={userLoginInfo} />;
      default:
        return <RequestForm userLoginInfo={userLoginInfo} />;
    }
  };

  return (
    <Box sx={{marginTop: '-20px'}}>
      {pathname === '/fc-admin' ? renderComponent() : <HOAdmin />}
    </Box>
  );
};
