import {Grid, MenuItem, TextField, Typography} from '@mui/material';
import {useStyles} from './styles';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {useState} from 'react';
import {CustomTextFieldTP} from '../common-component/CustomTextFieldTP';
import CustomTitle from '../common-component/CustomTitle';

interface DoctorDetailsProps {
  doctorDetailsData: any;
  patchData: any;
  areaWeekList: any;
}

const DoctorDetailsForm: React.FC<DoctorDetailsProps> = ({
  doctorDetailsData,
  patchData,
  areaWeekList,
}) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const styleClass = useStyles();

  const getAreaList = () => {
    if (areaWeekList?.length > 0) {
      if (areaWeekList?.length > 5) {
        return areaWeekList
          ?.slice(0, 5)
          .map((week: any) => week?.name)
          .join('; ');
      }
      return areaWeekList?.map((week: any) => week?.name)?.join('; ');
    }
  };
  const viewMorePopup = () => {
    return (
      <CustomModal
        fullWidth={false}
        maxWidth={'xs'}
        open={true}
        onClose={() => setViewMore(false)}
        header={
          <CustomTitle
            title={
              doctorDetailsData?.['areaName']
                ? `(${doctorDetailsData['areaName']})`
                : ''
            }
            variant={'h6'}
          />
        }
        footer={<></>}>
        <div className={styleClass.container}>
          <Grid container spacing={4}>
            <Grid xs={12}>
              {areaWeekList?.map((week: any) => {
                return (
                  <MenuItem
                    key={week?.name}
                    sx={{color: '#1c1939', fontSize: '24.7'}}>
                    {week?.name}
                  </MenuItem>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    );
  };

  const areaRowField = () => {
    return (
      <Grid container style={{marginBottom: 10.7, flexDirection: 'row'}}>
        <TextField
          value={doctorDetailsData?.areaMappedtoDoctorName}
          InputProps={{
            readOnly: true,
            className: styleClass.areaTextField,
          }}
        />
        <Typography
          style={{
            opacity: 0.4,
            fontStyle: 'italic',
            fontSize: 12,
            fontFamily: 'Poppins',
            alignSelf: 'center',
            marginInlineStart: 10,
            width: 450,
          }}>
          {getAreaList()}
          {areaWeekList?.length > 5 && (
            <input
              tabIndex={0}
              type="button"
              style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
                background: 'white',
                border: 'none',
              }}
              onClick={() => setViewMore(true)}
              onKeyDown={() => setViewMore(true)}
              data-testid="seemore-button"
              value={'Seemore...'}
            />
          )}
        </Typography>
      </Grid>
    );
  };

  const rightColumn = () => {
    return (
      <Grid container className={styleClass.columnContainer}>
        <CustomTextFieldTP
          label={'Number of Patient/Day (A)'}
          value={doctorDetailsData?.numberofPatientsDay}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'Average Rx Value in ₹ (B)'}
          value={doctorDetailsData?.averageRxValue}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'OP Days (C)'}
          value={doctorDetailsData?.opDays}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'Industry Potential'}
          value={doctorDetailsData?.industryPotential}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'Category'}
          value={doctorDetailsData?.category}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'My Division Potential'}
          value={doctorDetailsData?.myDivisionPotential}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'Average Monthly Support'}
          value={doctorDetailsData?.averageMonthlySupport}
          customStyle={{marginBottom: 10.7}}
        />

        <CustomTextFieldTP
          label={'Is KYC/CORE'}
          value={doctorDetailsData?.isKycCore}
          customStyle={{marginBottom: 10.7}}
        />
        <CustomTextFieldTP
          label={'Speciality By Practice'}
          value={doctorDetailsData?.practiceName}
          customStyle={{marginBottom: 10.7}}
        />
      </Grid>
    );
  };

  const smallRowBox = (brandPotential: string, brandSupport: string) => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={styleClass.textFieldcontainer}
        style={{marginBottom: 10.7}}>
        <Grid item>
          <Typography className={styleClass.textFieldLabel}>
            Brand Potential/Support
          </Typography>
        </Grid>
        <Grid item style={{marginLeft: 17.5}}>
          <TextField
            value={brandPotential}
            InputProps={{
              readOnly: true,
              className: styleClass.smallTextField,
            }}
          />
        </Grid>
        <Grid item></Grid>
        <Grid item>
          <TextField
            value={brandSupport}
            InputProps={{
              readOnly: true,
              className: styleClass.smallTextField,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const leftColumn = () => {
    return (
      <Grid container className={styleClass.columnContainer}>
        <CustomTextFieldTP
          label={'Priority Product 1'}
          value={doctorDetailsData?.priorityProductName1}
          customStyle={{marginBottom: 10.7}}
        />
        {smallRowBox(
          doctorDetailsData?.brandPotential1,
          doctorDetailsData?.brandSupport1,
        )}
        <CustomTextFieldTP
          label={'Priority Product 2'}
          value={doctorDetailsData?.priorityProductName2}
          customStyle={{marginBottom: 10.7}}
        />
        {smallRowBox(
          doctorDetailsData?.brandPotential2,
          doctorDetailsData?.brandSupport2,
        )}
        {doctorDetailsData?.priorityProductName3 && (
          <div>
            <CustomTextFieldTP
              label={'Priority Product 3'}
              value={doctorDetailsData?.priorityProductName3}
              customStyle={{marginBottom: 10.7}}
            />
            {smallRowBox(
              doctorDetailsData?.brandPotential3,
              doctorDetailsData?.brandSupport3,
            )}
          </div>
        )}
        {doctorDetailsData?.priorityProductName4 && (
          <div>
            <CustomTextFieldTP
              label={'Priority Product 4'}
              value={doctorDetailsData?.priorityProductName4}
              customStyle={{marginBottom: 10.7}}
            />
            {smallRowBox(
              doctorDetailsData?.brandPotential4,
              doctorDetailsData?.brandSupport4,
            )}
          </div>
        )}
        <CustomTextFieldTP
          label={'Chemist-1 for Survey'}
          value={doctorDetailsData?.chemist1ForSurveyName}
          customStyle={{marginBottom: 10.7}}
        />
        {doctorDetailsData?.chemist2ForSurveyName && (
          <CustomTextFieldTP
            label={'Chemist-2 for Survey'}
            value={doctorDetailsData?.chemist2ForSurveyName}
            customStyle={{marginBottom: 10.7}}
          />
        )}
        {doctorDetailsData?.chemist3ForSurveyName && (
          <CustomTextFieldTP
            label={'Chemist-3 for Survey'}
            value={doctorDetailsData?.chemist3ForSurveyName}
            customStyle={{marginBottom: 10.7}}
          />
        )}
        {doctorDetailsData?.chemist4ForSurveyName && (
          <CustomTextFieldTP
            label={'Chemist-4 for Survey'}
            value={doctorDetailsData?.chemist4ForSurveyName}
            customStyle={{marginBottom: 10.7}}
          />
        )}
        {doctorDetailsData?.chemist5ForSurveyName && (
          <CustomTextFieldTP
            label={'Chemist-5 for Survey'}
            value={doctorDetailsData?.chemist5ForSurveyName}
            customStyle={{marginBottom: 10.7}}
          />
        )}
        <CustomTextFieldTP
          label={'Self Dispensing Doctor'}
          value={doctorDetailsData?.selfDispensingFlag ? 'Yes' : 'No'}
          customStyle={{marginBottom: 10.7}}
        />
      </Grid>
    );
  };

  const renderVisitsDay = (count: number) => {
    let patchName: string | null = null;
    const allPatches = patchData;
    const weekString = 'Week' + count;
    const dayString = 'Day' + count;
    const patchAvailable: any = allPatches?.find((data: any) =>
      data?.usedOn?.some(
        (patch: any) =>
          patch?.week ===
            Number(
              doctorDetailsData[weekString]
                ?.toString()
                .substr(weekString?.length - 1),
            ) && patch?.weekDay === doctorDetailsData[dayString],
      ),
    );
    if (patchAvailable) {
      patchName = patchAvailable?.displayName;
    }

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          width: 1000,
          marginBottom: 10.7,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center',
          flexWrap: 'nowrap',
        }}>
        <Grid item>
          <Typography className={styleClass.textFieldLabel}>
            {`Visit ${count} Day`}
          </Typography>
        </Grid>
        <Grid item style={{marginLeft: 26}}>
          <TextField
            value={
              doctorDetailsData?.[weekString]
                ? doctorDetailsData[weekString]
                : weekString
            }
            InputProps={{
              readOnly: true,
              className: styleClass.VisitPlanBox,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            value={
              doctorDetailsData?.[dayString]
                ? doctorDetailsData[dayString]
                : dayString
            }
            InputProps={{
              readOnly: true,
              className: styleClass.VisitPlanBox,
            }}
          />
        </Grid>
        <Typography
          style={{
            opacity: 0.4,
            fontStyle: 'italic',
            fontSize: 12,
            fontFamily: 'Poppins',
            alignContent: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginInlineStart: 10,
            width: 450,
          }}>
          {patchName
            ? `Patch ${patchName} for visit.`
            : `No patch found for visit.`}
        </Typography>
      </Grid>
    );
  };
  const visitPlanContainer = () => {
    return (
      <Grid>
        <CustomTitle title={'Visit Planning'} variant={'h6'} />
        <Grid
          container
          alignItems="center"
          className={styleClass.textFieldcontainer}
          style={{marginBottom: 10.7, width: 232, flexDirection: 'row'}}>
          <Grid item>
            <Typography className={styleClass.textFieldLabel}>
              No. of Visits/ Month
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              value={doctorDetailsData?.numberOfVisits}
              InputProps={{
                readOnly: true,
                className: styleClass.VisitPlanField,
              }}
            />
          </Grid>
        </Grid>
        <Grid>
          {Array.from(new Array(doctorDetailsData?.numberOfVisits).keys()).map(
            val => {
              return renderVisitsDay(val + 1);
            },
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Grid container>
        {areaRowField()}
        <Grid container className={styleClass.rowContainer}>
          {rightColumn()}
          {leftColumn()}
        </Grid>
        {visitPlanContainer()}
      </Grid>
      {viewMore && viewMorePopup()}
    </div>
  );
};
export default DoctorDetailsForm;
