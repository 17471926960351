import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {welcomeKitStateSelector} from './selector';
import {appSelector} from '@app/store/selectors';
import {
  fetchWelcomeKitCreatorTypeName,
  updateWelcomeKitCreatorTypeName,
  fetchWelcomeKitSuggestionsCreatorTypeName,
  fetchWelcomeKitHistoryCreatorTypeName,
  welcomeKitStateActions,
} from './slice';

/**
 * Welcome kit data watcher
 */
export function* fetchWelcomeKitDataWatcher() {
  yield takeLatest(
    fetchWelcomeKitCreatorTypeName,
    fetchWelcomeKitDataWatcherWorker,
  );
}

/**
 * Welcome kit data Worker
 */
export function* fetchWelcomeKitDataWatcherWorker(): any {
  try {
    yield put(welcomeKitStateActions.setWelcomeKitLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.welcomeKit.getAll}`;
    let pageNo = yield select(welcomeKitStateSelector.getWelcomeKitPageNo());
    let perPage = yield select(welcomeKitStateSelector.getWelcomeKitPerPage());
    const filter = yield select(filterSelector.getFilterOptions());
    const response = yield call(
      NetworkService.get,
      url,
      {pageNo: pageNo + 1, perPage: perPage},
      {},
    );
    yield put(
      welcomeKitStateActions.setWelcomeKit(response.data?.welcomeKitDtos),
    );
    yield put(
      welcomeKitStateActions.setWelcomeKitSnapshot(
        response.data?.welcomeKitDtos,
      ),
    );
    yield put(welcomeKitStateActions.setTotalCount(response.data?.totalCount));

    let requestExcel = {
      ...filter,
      pageNo: 1,
      rowPerPage: response.data?.totalCount,
    };
    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );

    yield put(
      appSliceActions.setExcelData({
        data: responseExcel?.data?.welcomeKitDtos,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'name'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managerName'},
          {label: 'Input', key: 'isInput'},
          {label: 'Welcome Kit', key: 'isWelcomeKit'},
          {label: 'Sample', key: 'isSample'},
        ],
      }),
    );
    const filters = yield select(filterSelector.getFilterState());
    const stateByAdminData = yield select(appSelector.getStateDropDown());
    const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
    if (divisionByAdminData.length > 0) {
      const options: any = {};
      const optionsState: any = {};
      divisionByAdminData?.map((item: any) => {
        options[item.label] = false;
        return null;
      });

      stateByAdminData?.map((item: any) => {
        optionsState[item.label] = false;
        return null;
      });
      yield put(
        filterStateActions.setFilterState({
          ...filters,
          Division: {
            completeApplied: false,
            collapsed: true,
            options,
          },
          States: {
            completeApplied: false,
            collapsed: true,
            options: optionsState,
          },
        }),
      );
    }
    yield put(welcomeKitStateActions.setWelcomeKitLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching welcome kit!',
      ToastType.ERROR,
    );
    yield put(welcomeKitStateActions.setWelcomeKitLoading(false));
  }
}

/**
 * Welcome kit update watcher
 */
export function* updateWelcomeKitDataWatcher() {
  yield takeLatest(
    updateWelcomeKitCreatorTypeName,
    updateWelcomeKitDataWatcherWorker,
  );
}

/**
 * Welcome kit update Worker
 */
export function* updateWelcomeKitDataWatcherWorker(action: any): any {
  try {
    yield put(welcomeKitStateActions.setWelcomeKitLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.welcomeKit.update}`;
    yield call(NetworkService.post, url, {...action?.payload});
    yield put(welcomeKitStateActions.setWelcomeKitLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while updating welcome kit!',
      ToastType.ERROR,
    );
  }
}

/**
 * Welcome kit suggestions watcher
 */
export function* fetchWelcomeKitSuggestionsWatcher() {
  yield takeLatest(
    fetchWelcomeKitSuggestionsCreatorTypeName,
    fetchWelcomeKitSuggestionsWatcherWorker,
  );
}

/**
 * Welcome kit suggestions Worker
 */
export function* fetchWelcomeKitSuggestionsWatcherWorker(action: any): any {
  try {
    yield put(welcomeKitStateActions.setAutoSuggestLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.welcomeKit.suggest}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(welcomeKitStateActions.setAutoSuggestData(response.data));
    yield put(welcomeKitStateActions.setAutoSuggestLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching welcome kit suggestions!',
      ToastType.ERROR,
    );
    yield put(welcomeKitStateActions.setWelcomeKitError(error));
    setTimeout(function* () {
      yield put(welcomeKitStateActions.clearWelcomeKitError());
    }, 5000);
  }
}

/**
 * Welcome kit history watcher
 */
export function* fetchWelcomeKitHistoryWatcher() {
  yield takeLatest(
    fetchWelcomeKitHistoryCreatorTypeName,
    fetchWelcomeKitHistoryWatcherWorker,
  );
}

/**
 * Welcome kit history Worker
 */
export function* fetchWelcomeKitHistoryWatcherWorker(action: any): any {
  try {
    // yield put(welcomeKitStateActions.setWelcomeKitLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.welcomeKit.history}/${action?.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(welcomeKitStateActions.setHistory(response.data));
    // yield put(welcomeKitStateActions.setWelcomeKitLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching welcome kit history!',
      ToastType.ERROR,
    );
    yield put(welcomeKitStateActions.setWelcomeKitError(error));
    setTimeout(function* () {
      yield put(welcomeKitStateActions.clearWelcomeKitError());
    }, 5000);
  }
}
